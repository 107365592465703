// fields : [["name"],["name"],["name","type"]]

import getItemRankFromNum from "Utils/getItemRankFromNum";
import sortByNum from "Utils/sortByNum";

export default function getFieldsFromItems(items) {
  const fields = [];

  const sortedItems = [...items].sort((a, b) => sortByNum(a.num, b.num));

  const processedRankMap = {};

  sortedItems.forEach((item) => {
    const rank = getItemRankFromNum(item);
    if (!processedRankMap[rank]) {
      const rankFields = Object.keys(item).filter(
        (key) => key !== "num" && key !== "id" && key !== "name"
      );
      let sortedFields = rankFields.sort((a, b) => a.localeCompare(b));
      if (item.name) sortedFields = ["name", ...sortedFields];
      fields.push(sortedFields);
      //
      processedRankMap[rank] = "ok";
    }
  });
  return fields;
}
