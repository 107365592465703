export default class RoomLabelsManager {
  constructor({webViewer}) {
    this.webViewer = webViewer;
  }

  loadRoomLabels() {}

  initRoomLabelsAnnotations(roomLabels) {
    try {
      this.deleteAllRoomLabelsAnnotations();
      this.addRoomLabelsAnnotations(roomLabels);
    } catch (e) {
      console.log("error", e);
    }
  }

  addRoomLabelsAnnotations(roomLabels) {
    roomLabels.forEach((roomLabel) => this.addRoomLabelAnnotation(roomLabel));
  }

  addRoomLabelAnnotation(roomLabel) {
    const {annotationManager, Annotations, documentViewer, Math} =
      this.webViewer.Core;
    const {p, bbox, label} = roomLabel;

    // annotations

    const padding = 10;

    const props = {
      PageNumber: p,
      X: bbox.x - padding,
      Y: bbox.y - padding,
      Width: bbox.width + padding * 2,
      Height: bbox.height + padding * 2,
      TextAlign: "center",
      TextVerticalAlign: "center",
      TextColor: new Annotations.Color(255, 0, 0, 1),
      StrokeColor: new Annotations.Color(0, 255, 0, 1),
      FillColor: new Annotations.Color(0, 255, 0, 0.1),
    };
    const annotation = new Annotations.FreeTextAnnotation(
      Annotations.FreeTextAnnotation.Intent.FreeText,
      props
    );

    //

    //annotation.setContents(label);
    annotation.setContents(".");
    annotation.setCustomData("type", "ROOM_LABEL");

    //

    annotationManager.addAnnotations([annotation]);
  }

  deleteAllRoomLabelsAnnotations() {
    const {annotationManager} = this.webViewer.Core;
    const annotations = annotationManager
      .getAnnotationsList()
      .filter((a) => a.getCustomData("type") === "ROOM_LABEL");
    annotationManager.deleteAnnotations(annotations);
  }

  focusOnRoomLabelAnnotation(id) {
    const {documentViewer} = this.webViewer.Core;
    const annotations = documentViewer
      .getAnnotationManager()
      .getAnnotationsList();
    const annotation = annotations.find((a) => a.Id === id);

    if (annotation) {
      const pageNumber = annotation.PageNumber;
      const zoom = documentViewer.getZoom();
      const x = annotation.X + annotation.Width / 2;
      const y = annotation.Y + annotation.Height / 2;

      documentViewer.setCurrentPage(pageNumber);
      documentViewer.zoomTo(zoom, {x, y});
    }
  }
}
