import React, {useEffect, useState} from "react";

import EditableImage from "./EditableImage";
import DialogImageViewer from "./DialogImageViewer";

export default function ContainerImage({
  url,
  onChange,
  container = "scene-files",
}) {
  // state

  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <DialogImageViewer
          open={open}
          onClose={() => setOpen(false)}
          src={url}
          scale={1}
        />
      )}
      <EditableImage
        url={url}
        onChange={onChange}
        container={container}
        onImageClick={() => setOpen(true)}
      />
    </>
  );
}
