import {useDispatch, useSelector} from "react-redux";
import {useParams, useLocation, useNavigate} from "react-router-dom";

import {setSelectedReportId} from "../reportsSlice";

import {Box} from "@mui/material";
import ListReports from "./ListReports";

import useReportsByScene from "../hooks/useReportsByScene";

export default function SectionReportsInLeftPanel({scene}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  // data

  const reports = useReportsByScene(scene, {
    filterByScope: true,
    sortByName: true,
    fetchData: true,
  });

  const selectedReportId = useSelector((s) => s.reports.selectedReportId);

  // helpers

  const selection = selectedReportId ? [selectedReportId] : [];

  // handlers

  function handleClick(report) {
    const reportId = report?.id;
    //
    const reportInSelection = selection?.includes(reportId);
    //
    if (reportInSelection) {
      dispatch(setSelectedReportId(null));
    } else {
      dispatch(setSelectedReportId(reportId));
    }
  }

  function handleLinkClick(report) {
    const path = location.pathname.replace(`/reports`, `/reports/${report.id}`);
    navigate(path);
  }

  return (
    <Box sx={{width: 1, display: "flex", flexDirection: "column"}}>
      <ListReports
        reports={reports}
        selection={selection}
        onClick={handleClick}
        onLinkClick={handleLinkClick}
      />
    </Box>
  );
}
