import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {
  createMaterial,
  setNewMaterial,
  setSelectedMaterialsClientIds,
  setIsCreatingRoomMaterial,
  setIsEditingRoomMaterials,
} from "../materialsSlice";
import {updateRoom} from "Features/rooms/roomsSlice";

import {Box, Button, alpha} from "@mui/material";
import {Add} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import useMaterialInSelectionPanel from "../hooks/useMaterialInSelectionPanel";
import useSelectedRoomInMaterials from "../hooks/useSelectedRoomInMaterials";

import addMaterialToMaterialsData from "../utils/addMaterialToMaterialsData";

export default function ActionsNewMaterialInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const createS = "Créer le matériaux";
  const cancelS = "Annuler";

  // data

  const newMaterial = useMaterialInSelectionPanel(scene);
  const materialsGroupId = useSelector(
    (s) => s.materials.selectedMaterialsGroupId
  );

  // data - room materials

  const selectedRoomInMaterials = useSelectedRoomInMaterials();
  const isCreatingRoomMaterial = useSelector(
    (s) => s.materials.isCreatingRoomMaterial
  );

  // state

  const [loading, setLoading] = useState(false);

  // disabled

  const disabled = !newMaterial.name;

  // handlers

  async function handleCreateClick() {
    try {
      setLoading(true);
      const newMat = {clientId: nanoid(), materialsGroupId, ...newMaterial};
      dispatch(createMaterial({accessToken, material: newMat}));
      dispatch(setSelectedMaterialsClientIds([newMat.clientId]));
      dispatch(setNewMaterial({}));

      // room materials
      if (isCreatingRoomMaterial) {
        const updatedMaterialsData = addMaterialToMaterialsData(
          newMat.clientId,
          materialsGroupId,
          selectedRoomInMaterials.materialsData
        );
        const updates = {
          id: selectedRoomInMaterials.id,
          roomsGroupId: selectedRoomInMaterials.roomsGroupId,
          materialsData: updatedMaterialsData,
        };
        dispatch(updateRoom({accessToken, updates}));
        dispatch(setIsCreatingRoomMaterial(false));
        dispatch(setIsEditingRoomMaterials(false));
      }

      // end
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  function handleCancelCreateRoomMaterial() {
    dispatch(setIsCreatingRoomMaterial(false));
    dispatch(setNewMaterial({}));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          "&>*:not(:last-child)": {mr: 1},
        }}
      >
        {isCreatingRoomMaterial && (
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleCancelCreateRoomMaterial}
          >
            {cancelS}
          </Button>
        )}
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateClick}
          disabled={disabled}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
