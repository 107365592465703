import {List, ListItemButton, Box, Typography} from "@mui/material";
import ListItemNewMaterial from "./ListItemNewMaterial";
import SearchBarObjectsV2 from "Features/search/components/SearchBarObjectsV2";

import getRoomsCountFromRoomsData from "../utils/getRoomsCountFromRoomsData";
import getItemRankFromNum from "Utils/getItemRankFromNum";
import getCategoryOrMaterialItemStyle from "../utils/getCategoryOrMaterialItemStyle";
import getArraysIntersection from "Utils/getArraysIntersection";

export default function ListCategoriesAndMaterials({
  items,
  onMaterialClick,
  selectedMaterialCategoryId,
  onSelectedMaterialCategoryIdChange,
  selectedMaterialsClientIds,
  onNewMaterial,
}) {
  // strings

  const noRoomsS = "Pas de pièces";

  // handlers

  function handleClick(item) {
    if (item.isMaterial) {
      if (onMaterialClick) onMaterialClick(item);
      onSelectedMaterialCategoryIdChange(item.materialCategoryId);
    } else if (item.isCategory) {
      onSelectedMaterialCategoryIdChange(item.id);
      const categoryMaterialsClientIds = item.materialClientIds;

      const removeMaterialSelection =
        getArraysIntersection([
          categoryMaterialsClientIds,
          selectedMaterialsClientIds,
        ]).length === 0;
      if (removeMaterialSelection) {
        onMaterialClick(null);
      }
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        overflow: "auto",
      }}
    >
      <List sx={{width: 1}} dense disablePadding>
        {items.map((item) => {
          //const selected = selection?.includes(material?.clientId);
          //const roomsCount = getRoomsCountFromRoomsData(material?.roomsData);
          //const noRooms = !roomsCount;
          // const roomsCountLabel = `${roomsCount} pièce${
          //   roomsCount > 1 ? "s" : ""
          // }`;
          const rank = getItemRankFromNum(item);
          let {bgcolor, color, fontWeight} =
            getCategoryOrMaterialItemStyle(item);
          const selected =
            item.isMaterial &&
            selectedMaterialsClientIds?.includes(item.clientId);
          if (selectedMaterialCategoryId === item.id && item.isCategory) {
            fontWeight = "bold";
          }
          return (
            <ListItemButton
              disableGutters
              divider
              key={item.id}
              selected={selected}
              onClick={() => handleClick(item)}
              sx={{bgcolor, color, p: 0}}
            >
              <Box sx={{px: 1, py: 0.5, width: 1}}>
                <Box sx={{pl: `${rank * 16}px`}}>
                  <Typography sx={{fontSize: 12, fontWeight}}>
                    {item?.name}
                  </Typography>
                </Box>

                {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{fontSize: 12, color: "text.secondary"}}>
                  {material?.code}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 12,
                    color: noRooms ? "error.main" : "text.secondary",
                  }}
                >
                  {noRooms ? noRoomsS : roomsCountLabel}
                </Typography>
              </Box> */}
              </Box>
            </ListItemButton>
          );
        })}
        {/* {onNewMaterial && (
        <ListItemNewMaterial
          onNewMaterial={onNewMaterial}
          variant="selectionPanel"
        />
      )} */}
      </List>
    </Box>
  );
}
