export default function getMaterialCategoryIndex(material, materials) {
  if (!materials?.length > 0) return 1;

  const similarMaterials = materials.filter(
    (m) =>
      m.id &&
      m.categoryType === material.categoryType &&
      m.categoryCode === material.categoryCode
  );

  const sortedSimilarMaterials = similarMaterials.sort((a, b) => {
    return a.createdAt - b.createdAt;
  });

  if (material.id) {
    return sortedSimilarMaterials.map((m) => m.id).indexOf(material.id) + 1;
  } else {
    return sortedSimilarMaterials.length + 1;
  }
}
