import {client} from "API/capla360";
import cleanMaterialToSync from "./utils/cleanMaterialToSync";

import remoteToStateMaterialsGroup from "./utils/remoteToStateMaterialsGroup";
import remoteToStateMaterial from "./utils/remoteToStateMaterial";
import stateToRemoteMaterial from "./utils/stateToRemoteMaterial";

// CREATE

export const createMaterialsGroupService = async ({
  accessToken,
  materialsGroup,
  fromScopeId,
}) => {
  //
  const params = {};
  if (fromScopeId) params.fromScope = fromScopeId;
  //
  const sceneId = materialsGroup.sceneId;
  const serializedMaterialsGroup = {
    ...materialsGroup,
    scene: sceneId,
  };
  delete serializedMaterialsGroup.sceneId;

  const response = await client.post(
    `scenes/${sceneId}/materialsgroups/`,
    serializedMaterialsGroup,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    }
  );
  const remoteGroup = response.data;
  const newMaterialsGroup = remoteToStateMaterialsGroup(remoteGroup);

  return {item: newMaterialsGroup, fromScopeId};
};

export const createMaterialService = async ({accessToken, material}) => {
  const materialsGroupId = material.materialsGroupId;
  const serializedMaterial = stateToRemoteMaterial(material);
  //
  const response = await client.post(
    `materialsgroups/${materialsGroupId}/materials/`,
    serializedMaterial,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  //
  const remoteMaterial = response.data;
  const item = remoteToStateMaterial(remoteMaterial);
  //
  return {item};
};

//  FETCH

export const fetchMaterialsGroupsService = async ({accessToken, sceneId}) => {
  const response = await client.get(`/scenes/${sceneId}/materialsgroups/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  //
  const remoteGroups = response.data;
  //
  return {
    items: remoteGroups.map((g) => remoteToStateMaterialsGroup(g)),
    sceneId,
  };
};

export const fetchMaterialsGroupService = async ({
  accessToken,
  sceneId,
  materialsGroupId,
}) => {
  const response = await client.get(
    `/scenes/${sceneId}/materials_groups/${materialsGroupId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {items: response.data, sceneId};
};

export const fetchMaterialsService = async ({
  accessToken,
  materialsGroupId,
}) => {
  const response = await client.get(
    `/materialsgroups/${materialsGroupId}/materials/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteMaterials = response.data;
  const items = remoteMaterials.map((m) => remoteToStateMaterial(m));
  return {items, materialsGroupId};
};

//  UPDATE

export const updateMaterialsGroupService = async ({accessToken, updates}) => {
  const sceneId = updates.sceneId;

  const response = await client.patch(
    `/scenes/${sceneId}/materialsgroups/${updates.id}/`,
    updates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteGroup = response.data;
  const updatedGroup = remoteToStateMaterialsGroup(remoteGroup);

  return {item: updatedGroup};
};

export const updateMaterialService = async ({accessToken, updates}) => {
  const materialsGroupId = updates.materialsGroupId;
  const materialId = updates.id;
  const remoteUpdates = stateToRemoteMaterial(updates);
  const response = await client.patch(
    `/materialsgroups/${materialsGroupId}/materials/${materialId}/`,
    remoteUpdates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const remoteMaterial = response.data;
  const item = remoteToStateMaterial(remoteMaterial);
  return {item};
};

//  DELETE

export const deleteMaterialsGroupService = async ({
  accessToken,
  materialsGroup,
}) => {
  const materialsGroupId = materialsGroup.id;
  const sceneId = materialsGroup.sceneId;
  console.log("try to delete typesMaterialsGroup", sceneId, materialsGroup);
  await client.delete(
    `/scenes/${sceneId}/materialsgroups/${materialsGroupId}/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return {materialsGroupId};
};

export const deleteMaterialService = async ({accessToken, material}) => {
  const materialsGroupId = material.materialsGroupId;
  const materialId = material.id;
  const materialClientId = material.clientId;
  await client.delete(
    `/materialsgroups/${materialsGroupId}/materials/${materialId}/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return {materialClientId};
};
