import {useState} from "react";

import {Box, CircularProgress, IconButton, Typography} from "@mui/material";
import {CloudUpload, Delete} from "@mui/icons-material";

import {isImageFile} from "../imageUtils";

import FileSelectorButton from "Features/files/components/FileSelectorButton";
import useAccessToken from "Features/auth/useAccessToken";
import {uploadFileService} from "Features/files/services";

export default function EditableImage({
  url,
  onChange,
  container,
  worksiteId,
  sceneId,
  listingId,
  organisationId,
  visitId,
  blobName,
  blobPrefix,
  onImageClick,
}) {
  const accessToken = useAccessToken();

  //strings

  const selectImageString = "Depuis mon ordinateur";
  // const saveString = "Save";
  const dragS = "Glisser & déposer une image";

  // state

  const [loading, setLoading] = useState(false);
  const [tempUrl, setTempUrl] = useState(null);
  const [hover, setHover] = useState();

  // helper

  let showedUrl = url ? url : tempUrl;
  if (loading) showedUrl = tempUrl;

  // handlers - drag & drop

  function handleImageClick() {
    if (onImageClick && url) onImageClick();
  }

  function handleDragEnter() {
    setHover(true);
  }
  function handleDragLeave() {
    setHover(false);
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("DROP");
    setHover(false);
    try {
      let file = e.dataTransfer.items[0].getAsFile();
      if (isImageFile(file)) {
        handleFileChange(file);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  // handlers - files

  async function handleFileChange(file) {
    setLoading(true);
    setTempUrl(URL.createObjectURL(file));

    let newBlobName;
    if (blobName) {
      blobName = newBlobName;
    } else if (blobPrefix) {
      blobName = blobPrefix + file.name;
    }
    const url = await uploadFileService({
      file,
      organisationId,
      visitId,
      worksiteId,
      listingId,
      sceneId,
      accessToken,
      container,
      blobName,
    });
    onChange(url);
    setTempUrl(null);
    setLoading(false);
  }

  function handleDelete() {
    onChange(null);
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        minHeight: 0,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundImage: `url(${showedUrl})`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        ...(showedUrl && {cursor: "pointer"}),
      }}
      onClick={handleImageClick}
    >
      {!url && !loading && (
        <Box sx={{flex: 1, p: 2, width: 1}}>
          <Box
            sx={{
              bgcolor: "background.default",
              position: "relative",
              width: 1,
              height: 1,
              py: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              sx={{
                position: "absolute",
                height: 1,
                width: 1,
                border: (theme) => `2px dashed ${theme.palette.divider}`,
                borderColor: (theme) =>
                  hover ? theme.palette.primary.flash : theme.palette.divider,
              }}
            />
            <CloudUpload color="action" />
            <Typography variant="body2" color="text.secondary" sx={{mt: 1}}>
              {dragS}
            </Typography>
          </Box>
        </Box>
      )}
      {!url && !loading && (
        <Box
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "left",
            color: "text.secondary",
          }}
        >
          <FileSelectorButton
            onFileChange={handleFileChange}
            buttonName={selectImageString}
            startIcon="computer"
            buttonVariant="inline"
            buttonColor="inherit"
          />
        </Box>
      )}
      {loading && <CircularProgress />}
      {url && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            bgcolor: "white",
            //border: (theme) => `1px solid ${theme.palette.primary.main}`,
            position: "absolute",
            top: 4,
            right: 4,
            zIndex: 100,
          }}
        >
          <IconButton size="small" color="primary" onClick={handleDelete}>
            <Delete fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
