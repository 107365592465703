import {useEffect, useState, useRef} from "react";

import {useDispatch, useSelector} from "react-redux";

import {
  setEditedBlock,
  setIsEditingBlock,
} from "Features/reportBlocks/blocksSlice";
import {setSelectedFixedViewer} from "Features/ui/uiSlice";
import {setSelectedZoneId} from "Features/zones/zonesSlice";

import {Paper, Box, Button} from "@mui/material";

import ContainerImage from "Features/images/components/ContainerImage";
import {saveBlob} from "Features/files/utils";

export default function FieldViewData({caplaEditor, item, value, onChange}) {
  // {zoneId,zoneImageUrl,imageUrl,zoom,markers,clusters,..}

  const dispatch = useDispatch();

  // string

  const editS = "Modifier le plan";
  const validateS = "Valider le nouveau plan";

  // ref

  const containerRef = useRef(null);

  // data

  const isEditing = useSelector((state) => state.blocks.isEditingBlock);
  const blueprintProps = useSelector((s) => s.zones.blueprintProps);

  // state

  const [width, setWidth] = useState(100);
  const [isImageUrlValid, setIsImageUrlValid] = useState(false);

  const height = width / blueprintProps.aspectRatio;
  console.log("FieldViewData", width, height, width / height);

  // effect

  useEffect(() => {
    if (containerRef.current) {
      const containerBbox = containerRef.current.getBoundingClientRect();
      setWidth(containerBbox.width);
    }
  }, [containerRef.current]);

  useEffect(() => {
    if (value.imageUrl) {
      fetch(value.imageUrl)
        .then((response) => {
          if (response.ok) {
            setIsImageUrlValid(true);
          } else {
            setIsImageUrlValid(false);
          }
        })
        .catch(() => {
          setIsImageUrlValid(false);
        });
    }
  }, [value?.imageUrl]);

  // helpers

  const imageUrl = isImageUrlValid ? value.imageUrl : value?.zoneImageUrl;

  // handlers

  function handleImageUrlChange(imageUrl) {
    const newValue = value ? {...value, imageUrl} : {imageUrl};
    onChange(newValue);
  }

  function handleEditClick() {
    dispatch(setIsEditingBlock(true));
    dispatch(setEditedBlock(item));
    dispatch(setSelectedFixedViewer("ZONE"));
    dispatch(setSelectedZoneId(value.zoneId));
  }

  async function handleValidateClick() {
    const imageFile =
      await caplaEditor.zonesEditor.getImageFileCropByBlueprint();
    const newImageUrl = URL.createObjectURL(imageFile);
    //
    const newValue = value
      ? {...value, imageUrl: newImageUrl, imageFile}
      : {imageUrl: newImageUrl, imageFile};
    //
    onChange(newValue);
  }

  return (
    <Box
      sx={{
        width: 1,
        p: 2,
        bgcolor: "background.default",
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <Paper
          elevation={isEditing ? 12 : 0}
          sx={{
            width,
            height,
            maxWidth: width,
            maxHeight: height,
          }}
        >
          <ContainerImage url={imageUrl} onChange={handleImageUrlChange} />
        </Paper>
      </Box>

      <Box sx={{width: 1, display: "flex", justifyContent: "center"}}>
        {!isEditing && (
          <Button variant="contained" onClick={handleEditClick}>
            {editS}
          </Button>
        )}
        {isEditing && (
          <Button variant="contained" onClick={handleValidateClick}>
            {validateS}
          </Button>
        )}
      </Box>
    </Box>
  );
}
