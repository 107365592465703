export default function remoteToStateSample(sample) {
  const stateSample = {...sample};

  // survey
  stateSample.surveyId = sample.survey;
  delete stateSample.survey;

  // scope
  stateSample.scopeId = sample.scope;
  delete stateSample.scope;

  // result
  return stateSample;
}
