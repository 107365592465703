import {useDispatch} from "react-redux";

import useSelectedZone from "../hooks/useSelectedZone";

import {Box, IconButton} from "@mui/material";
import {BugReport as DebugIcon} from "@mui/icons-material";

import getBlueprintBbox from "../utils/getBlueprintBbox";
import getPointerCoordsInStage from "../js/utilsZonesEditor.js/getPointerCoordsInStage";
import {blue} from "@mui/material/colors";

import {createWorker} from "tesseract.js";

export default function ButtonDebugZoneViewer({caplaEditor, scene}) {
  const dispatch = useDispatch();
  // data

  const selectedZone = useSelectedZone(scene);

  // helpers

  const url = selectedZone?.imageUrl;
  const width = selectedZone?.imageSize?.width;
  const height = selectedZone?.imageSize?.height;

  // handler

  async function handleClick() {
    const file = await caplaEditor.zonesEditor.getImageFileCropByBlueprint();
    const worker = await createWorker("fra");
    const ret = await worker.recognize(file);
    console.log("recognize text", ret.data.text);
    await worker.terminate();
  }

  return (
    <Box
      sx={{
        p: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IconButton
        sx={{width: 30, height: 30, borderRadius: "4px"}}
        onClick={handleClick}
      >
        <DebugIcon fontSize="small" />
      </IconButton>
    </Box>
  );
}
