import {useSelector, useDispatch} from "react-redux";

import {
  setNewMaterial,
  setEditedMaterial,
  setIsEditingMaterial,
  setSelectedMaterialCategoryId,
} from "../materialsSlice";

import useMaterialInSelectionPanel from "../hooks/useMaterialInSelectionPanel";
import useSelectedMaterialsNomenclature from "../hooks/useSelectedMaterialsNomenclature";
import useNomenclatureByMaterialsGroupId from "../hooks/useNomenclatureByMaterialGroupId";
import useRoomsGroupsWithRooms from "Features/rooms/hooks/useRoomsGroupsWithRooms";
import useMaterialsByScene from "../hooks/useMaterialsByScene";

import {Box} from "@mui/material";
import FormMaterial from "./FormMaterial";

export default function SectionMaterialInSelectionPanel({scene}) {
  const dispatch = useDispatch();

  // data

  const material = useMaterialInSelectionPanel();
  console.log("[SectionMaterialInSelectionPanel] material", material);

  const selectedMaterialsGroupId = useSelector(
    (s) => s.materials.selectedMaterialsGroupId
  );

  const materials = useMaterialsByScene(scene, {
    fitlerByMaterialsGroupId: selectedMaterialsGroupId,
  });

  const materialsNomenclature = useNomenclatureByMaterialsGroupId(
    selectedMaterialsGroupId
  );

  const roomsGroupsWithRooms = useRoomsGroupsWithRooms(scene, {
    filterByScope: true,
    fetchRooms: true,
    roomsWithParents: true,
  });

  // helpers

  const isNew = !material?.id;

  // handlers

  function handleChange(changedMaterial) {
    const materialCategoryId = changedMaterial.materialCategoryId;
    if (materialCategoryId) {
      dispatch(setSelectedMaterialCategoryId(materialCategoryId));
    }
    if (isNew) {
      dispatch(setNewMaterial(changedMaterial));
    } else {
      dispatch(setEditedMaterial(changedMaterial));
      dispatch(setIsEditingMaterial(true));
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        bgcolor: "background.default",
        display: "flex",
        flexDirection: "column",
        height: 1,
        minHeight: 0,
      }}
    >
      <FormMaterial
        scene={scene}
        material={material}
        onChange={handleChange}
        materials={materials}
        materialsNomenclature={materialsNomenclature}
        roomsGroupsWithRooms={roomsGroupsWithRooms}
      />
    </Box>
  );
}
