import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {setSelectionPanelWidth} from "../selectionSlice";

import {Box, Paper} from "@mui/material";

import useForceSelectionType from "../useForceSelectionType";
import useSceneModule from "Features/navigation/useSceneModule";

import ContainerModeSelector from "./ContainerModeSelector";

import PanelSelectionContent from "./PanelSelectionContent";

import PanelAutoExtractFromPdf from "Features/pdf/components/PanelAutoExtractFromPdf";

export default function PanelSelection({caplaEditor, scene, viewer}) {
  const dispatch = useDispatch();

  // data

  const sceneModule = useSceneModule();
  const forceSelectionType = useForceSelectionType();

  const width = useSelector((s) => s.selection.selectionPanelWidth);
  const defaultWidth = useSelector(
    (s) => s.selection.selectionPanelWidthDefault
  );

  const open = useSelector((s) => s.selection.openSelectionPanel);

  const selectedArticleId = useSelector((s) => s.articles.selectedArticleId);

  const isLargePanel = useSelector((s) => s.selection.panelIsLarge);

  const openPanelAutoExtract = useSelector((s) => s.pdf.openPanelAutoExtract);

  // effect to double size

  const doubleSize =
    sceneModule === "ARTICLES" && selectedArticleId && isLargePanel;

  useEffect(() => {
    if (doubleSize) {
      if (width !== defaultWidth * 2) {
        dispatch(setSelectionPanelWidth(defaultWidth * 2));
      }
    } else {
      if (width !== defaultWidth) {
        dispatch(setSelectionPanelWidth(defaultWidth));
      }
    }
  }, [sceneModule, doubleSize, isLargePanel]);

  // state

  const [mode, setMode] = useState("SCENE_MODULE");

  // helper • display

  //const display = (open || pageType === "HOME") && !["LIBRARY", "PRICINGS"].includes(sceneModule);
  const display =
    open && !["LIBRARY", "PRICINGS", "RELATIONS"].includes(sceneModule);

  // handlers

  function handleModeChange(mode) {
    setMode(mode);
  }

  return (
    <Box
      sx={{
        height: 1,
        //borderLeft: (theme) => `2px solid ${theme.palette.primary.flash}`,
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {openPanelAutoExtract && (
        <Box
          sx={{position: "absolute", top: 0, bottom: 0, width: 1, zIndex: 2}}
        >
          <PanelAutoExtractFromPdf caplaEditor={caplaEditor} />
        </Box>
      )}
      <Box sx={{width: 1, display: open ? "flex" : "none"}}>
        <ContainerModeSelector mode={mode} onModeChange={handleModeChange} />
      </Box>
      <Paper
        elevation={12}
        sx={{
          boxSizing: "border-box",
          display: display ? "flex" : "none",
          flexDirection: "column",
          width,
          minWidth: width,
          justifyContent: "space-between",
          flexGrow: 1,
          minHeight: 0,
          bgcolor: "common.white",
          //borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
          position: "relative",
        }}
      >
        {/* <PanelSelectionMain
          caplaEditor={caplaEditor}
          scene={scene}
          viewer={viewer}
          forceSelectionType={forceSelectionType}
        /> */}

        <PanelSelectionContent
          caplaEditor={caplaEditor}
          scene={scene}
          viewer={viewer}
          mode={mode}
        />
      </Paper>
    </Box>
  );
}
