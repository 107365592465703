export default function getStageDefaultScale(
  imageSize,
  blueprintBboxInContainer
) {
  //
  const imageWidth = imageSize.width;
  const imageHeight = imageSize.height;
  const imageR = imageWidth / imageHeight;
  //
  const blueprintWidth = blueprintBboxInContainer.width;
  const blueprintHeight = blueprintBboxInContainer.height;
  const blueprintR = blueprintWidth / blueprintHeight;

  const isPortrait = imageR < blueprintR;
  //
  let imageScale; // the scale to match the blueprint.
  if (isPortrait) {
    imageScale = imageHeight / blueprintHeight;
    // this.offsetX =
    //   (containerBbox.width * this.imageScale - imageBbox.width) / 2;
  } else {
    imageScale = imageWidth / blueprintWidth;
    // this.offsetY =
    //   (containerBbox.height * this.imageScale - imageBbox.height) / 2;
  }

  //
  const defaultScale = 1 / imageScale;
  return defaultScale;
}
