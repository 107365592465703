import {Box, List, ListItemButton, Typography} from "@mui/material";
import {DragIndicator as Handle, Edit} from "@mui/icons-material";

import {Draggable} from "react-beautiful-dnd";

export default function ListItemBlock({
  block,
  selected,
  onClick,
  index,
  draggable,
}) {
  // handlers

  function handleClick(block) {
    if (onClick && !draggable) onClick(block);
  }

  return (
    <Draggable draggableId={block.id} index={index} isDragDisabled={!draggable}>
      {(provided, _) => (
        <Box
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          sx={{width: 1}}
          //sx={{borderLeft: `4px solid red`}}
        >
          <ListItemButton
            key={block.id}
            sx={{p: 0, m: 0, width: 1}}
            selected={selected}
            onClick={() => handleClick(block)}
          >
            <Box sx={{p: 1, width: 1, display: "flex", alignItems: "center"}}>
              <Box
                sx={{
                  bgcolor: "common.white",
                  border: "4px",
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  width: 1,
                }}
              >
                {draggable && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      ml: -1,
                    }}
                  >
                    <Handle fontSize="small" color="action" />
                  </Box>
                )}
                <Typography variant="body2">{block.name}</Typography>
              </Box>
            </Box>
          </ListItemButton>
        </Box>
      )}
    </Draggable>
  );
}
