export default function getMaterialsMapFromMaterialsGroupsWithNomenclatureAndMaterials(
  materialsGroupsWithNomenclatureAndMaterials,
  options
) {
  if (!materialsGroupsWithNomenclatureAndMaterials) return {};
  //
  return materialsGroupsWithNomenclatureAndMaterials.reduce(
    (acc, materialsGroupWithMaterials) => {
      materialsGroupWithMaterials.materials.forEach((material) => {
        const key = options?.byClientId ? material.clientId : material.id;
        acc[key] = material;
      });
      return acc;
    },
    {}
  );
}
