import useSceneModule from "Features/navigation/useSceneModule";
import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import SectionLeftPanelActionsSceneHome from "Features/boxhome/components/SectionLeftPanelActionsSceneHome";
import SectionLeftPanelActionsElementTypes from "./SectionLeftPanelActionsElementTypes";
import SectionLeftPanelActionsPackages from "Features/elementPackages/components/SectionLeftPanelActionsPackages";
import SectionLeftPanelActionsInputs from "Features/inputs/components/SectionLeftPanelActionsInputs";
import SectionLeftPanelActionsMeasurements from "Features/measurements/components/SectionLeftPanelActionsMeasurements";
import SectionLeftPanelActionsSectors from "Features/sectors/components/SectionLeftPanelActionsSectors";
import SectionLeftPanelActionsZones from "Features/zones/components/SectionLeftPanelActionsZones";
import SectionLeftPanelActionsViews from "Features/views/components/SectionLeftPanelActionsViews";
import SectionLeftPanelActionsRessources from "Features/ressources/components/SectionLeftPanelActionsRessources";
import SectionLeftPanelActionsSurveys from "Features/surveys/components/SectionLeftPanelActionsSurveys";
import SectionLeftPanelActionsRooms from "Features/rooms/components/SectionLeftPanelActionsRooms";
import SectionLeftPanelActionsScopes from "Features/scopes/components/SectionLeftPanelActionsScopes";
import SectionLeftPanelActionsArticles from "Features/articles/components/SectionLeftPanelActionsArticles";
import SectionLeftPanelActionsMaterials from "Features/materials/components/SectionLeftPanelActionsMaterials";
import SectionLeftPanelActionsSamples from "Features/samples/components/SectionLeftPanelActionsSamples";
import SectionLeftPanelActionsReports from "Features/reports/components/SectionLeftPanelActionsReports";
import SectionLeftPanelActionsBlocks from "Features/reportBlocks/components/SectionLeftPanelActionsBlocks";

export default function SectionLeftPanelActions({scene, caplaEditor, viewer}) {
  // data

  const sceneModule = useSceneModule();
  const selectedTab = useSelector((s) => s.leftPanel.tabInEditMeasurements);

  return (
    <Box>
      {sceneModule === "HOME" && (
        <SectionLeftPanelActionsSceneHome
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "INPUTS" && (
        <SectionLeftPanelActionsInputs
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "ZONES" && (
        <SectionLeftPanelActionsZones scene={scene} caplaEditor={caplaEditor} />
      )}
      {sceneModule === "PACKAGES" && (
        <SectionLeftPanelActionsPackages
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "ELEMENT_TYPES" && (
        <SectionLeftPanelActionsElementTypes
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "RESSOURCES" && (
        <SectionLeftPanelActionsRessources
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {["ARTICLES", "SHARED"].includes(sceneModule) && (
        <SectionLeftPanelActionsArticles
          scene={scene}
          caplaEditor={caplaEditor}
          viewer={viewer}
        />
      )}

      {sceneModule === "EDIT_MEASUREMENTS" && (
        <SectionLeftPanelActionsMeasurements
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "SECTORS" && (
        <SectionLeftPanelActionsSectors
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "VIEWS" && (
        <SectionLeftPanelActionsViews scene={scene} caplaEditor={caplaEditor} />
      )}
      {sceneModule === "SURVEYS" && (
        <SectionLeftPanelActionsSurveys
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "ROOMS" && (
        <SectionLeftPanelActionsRooms scene={scene} caplaEditor={caplaEditor} />
      )}
      {sceneModule === "SCOPES" && (
        <SectionLeftPanelActionsScopes
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "MATERIALS" && (
        <SectionLeftPanelActionsMaterials
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "SAMPLES" && (
        <SectionLeftPanelActionsSamples
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "REPORTS" && (
        <SectionLeftPanelActionsReports
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "REPORT" && (
        <SectionLeftPanelActionsBlocks
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
    </Box>
  );
}
