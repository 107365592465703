import {useSelector} from "react-redux";
import useSelectedMaterialsNomenclature from "./useSelectedMaterialsNomenclature";

export default function useSelectedMaterialCategory() {
  const categoryId = useSelector((s) => s.materials.selectedMaterialCategoryId);
  const nomenclature = useSelectedMaterialsNomenclature();

  let category;

  if (categoryId === "other") return {id: "other", name: "Autre", code: "AU"};

  if (nomenclature?.tree?.items) {
    category = nomenclature?.tree?.items?.find(
      (item) => item.id === categoryId
    );
  }
  return category;
}
