import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import {
  Menu,
  Box,
  MenuList,
  MenuItem,
  Divider,
  ListItemIcon,
  Typography,
} from "@mui/material";

import useSceneModuleNameMap from "../useSceneModuleNameMap";
import useSceneModulePathMap from "../useSceneModulePathMap";
import useSceneModuleIconMap from "../useSceneModuleIconMap";
import useSceneModuleRelatedModulesMap from "../useSceneModuleRelatedModulesMap";

export default function MenuRelatedModules({
  sceneModule,
  scene,
  anchorEl,
  open,
  onClose,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // data

  const pathMap = useSceneModulePathMap({sceneId: scene?.id});
  const nameMap = useSceneModuleNameMap();
  const iconMap = useSceneModuleIconMap();
  const relatedModulesMap = useSceneModuleRelatedModulesMap();

  // helpers

  const relatedModules = relatedModulesMap[sceneModule] ?? [];

  // helpers

  const tools = relatedModules.map((relatedModule, index) => {
    return {
      id: relatedModule === "DIVIDER" ? "DIVIDER" + index : relatedModule,
      isDivider: relatedModule === "DIVIDER",
      handler: () => {
        navigate(pathMap[relatedModule]);
        onClose();
      },
      label: nameMap[relatedModule],
      icon: iconMap[relatedModule],
    };
  });

  return (
    <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
      {tools.map((tool) => {
        return (
          <Box key={tool.id} sx={{width: 1}}>
            {!tool.isDivider && (
              <MenuItem onClick={tool.handler}>
                <ListItemIcon>{tool.icon}</ListItemIcon>
                <Typography variant="body2">{tool.label}</Typography>
              </MenuItem>
            )}
            {tool.isDivider && <Divider />}
          </Box>
        );
      })}
    </Menu>
  );
}
