import {Box} from "@mui/material";
import {TreeItem} from "@mui/x-tree-view";
import {RichTreeView} from "@mui/x-tree-view/RichTreeView";

import TreeItemRoom from "Features/rooms/components/TreeItemRoom";

import buildTreeFromItemsWithNum from "Utils/buildTreeFromItemsWithNum";

export default function TreeRooms({
  rooms,
  checkboxSelection,
  selection,
  onSelectionChange,
}) {
  //

  const tree = buildTreeFromItemsWithNum(rooms);

  // handlers

  function handleSelectionChange(event, ids) {
    onSelectionChange(ids);
  }

  return (
    <RichTreeView
      items={tree}
      slots={{item: TreeItemRoom}}
      multiSelect={true}
      selectedItems={selection}
      checkboxSelection={checkboxSelection}
      onSelectedItemsChange={handleSelectionChange}
    />
  );
}
