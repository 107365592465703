const organisationEntitiesMap = {
  NOMENCLATURES: {
    name: "Nomenclatures",
  },
  FORM_TEMPLATES: {
    name: "Modèles de formulaire",
  },
  REPORT_TEMPLATES: {
    name: "Modèles de rapport",
  },
};

export default organisationEntitiesMap;
