import {client} from "API/capla360";
import remoteToStateReport from "Features/reports/utils/remoteToStateReport";
import stateToRemoteReport from "Features/reports/utils/stateToRemoteReport";

export async function fetchReportsService({accessToken, scopeId}) {
  const response = await client.get(`/scopes/${scopeId}/reports/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteReports = response.data;
  const items = remoteReports.map(remoteToStateReport);
  return {items, scopeId};
}

export async function createReportService({accessToken, report}) {
  const scopeId = report.scopeId;
  const newReport = stateToRemoteReport(report);
  const response = await client.post(`scopes/${scopeId}/reports/`, newReport, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteReport = response.data;
  const item = remoteToStateReport(remoteReport);
  return {item};
}

export async function fetchReportService({accessToken, scopeId, reportId}) {
  const response = await client.get(`/scopes/${scopeId}/reports/${reportId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteReport = response.data;
  const item = remoteToStateReport(remoteReport);
  return {item};
}

export async function fetchReportsByClientReferenceService({
  accessToken,
  organisationId,
  clientReference,
}) {
  const response = await client.get(
    `/organisations/${organisationId}/reports/by-client-reference/${clientReference}/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteReports = response.data;
  const items = remoteReports.map(remoteToStateReport);
  return {items, organisationId};
}

export async function updateReportService({accessToken, updates}) {
  const scopeId = updates.scopeId;
  const reportId = updates.id;
  const remoteUpdates = stateToRemoteReport(updates);
  const response = await client.patch(
    `/scopes/${scopeId}/reports/${reportId}/`,
    remoteUpdates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteReport = response.data;
  const item = remoteToStateReport(remoteReport);
  return {item};
}

export const deleteReportService = async ({accessToken, reportId, scopeId}) => {
  await client.delete(`/scopes/${scopeId}/reports/${reportId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return reportId;
};
