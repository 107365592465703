export default function getRoomsWithLabels(rooms, roomLabels) {
  const roomLabelsByRoomClientId = roomLabels.reduce((ac, cur) => {
    if (!ac[cur.roomClientId]) {
      ac[cur.roomClientId] = [cur];
    } else {
      ac[cur.roomClientId].push(cur);
    }

    return ac;
  }, {});

  const roomsWithLabels = rooms.map((room) => {
    return {...room, roomLabels: roomLabelsByRoomClientId[room.clientId] ?? []};
  });

  return roomsWithLabels;
}
