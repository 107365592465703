import {useDispatch, useSelector} from "react-redux";

import {Edit, Add, LibraryAdd as AddGroup, Label} from "@mui/icons-material";
import {Download, Upload, AutoAwesome as AutoIcon} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import {
  fetchRoomsGroups,
  deleteAllRooms,
  setRoomsViewVariant,
  setMultipleSelectionRooms,
  setSelectedRoomIds,
  updateRoomsGroup,
  createRoom,
  updateRoomsBatch,
} from "../roomsSlice";

import useRooms from "./useRooms";
import useSelectedRoomsGroup from "./useSelectedRoomsGroup";

import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";
import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";
import sanitizeString from "Utils/sanitizeString";
import {setOpenPanelAutoExtract} from "Features/pdf/pdfSlice";
import cleanRoomsGroupChildrenClientIds from "../utils/cleanRoomsGroupChildrenClientIds";
import cleanRoomsChildrenClientIds from "../utils/cleanRoomsChildrenClientIds";

export default function useActionsRoomsMap({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // data

  const selectedRoomsGroupId = useSelector((s) => s.rooms.selectedRoomsGroupId);
  const rooms = useRooms({
    filterByRoomsGroupId: selectedRoomsGroupId,
    withNum: true,
    sortByNum: true,
    withParentId: true, // to style the excel sheet.
  });
  const roomsGroup = useSelectedRoomsGroup();
  const multipleSelection = useSelector((s) => s.rooms.multipleSelectionRooms);
  const selectedRoomIds = useSelector((s) => s.rooms.selectedRoomIds);

  // handler

  function handleFetchData() {
    dispatch(fetchRoomsGroups({accessToken, sceneId: scene?.id}));
  }

  async function handleExportExcelRooms() {
    let fileName = "pièces.xlsx";
    if (roomsGroup) fileName = sanitizeString(roomsGroup.name) + ".xlsx";
    const wb = await createExcelWorkbook({
      template: "ROOMS",
      data: {rooms, roomsGroup},
    });
    downloadExcelFileFromWorkbook(wb, fileName);
  }

  function handleAutoExtract() {
    dispatch(setOpenPanelAutoExtract(true));
  }

  async function handleDeleteAllRooms() {
    await dispatch(
      deleteAllRooms({accessToken, roomsGroupId: selectedRoomsGroupId})
    );
  }

  function handleRoomsViewVariantChange(variant) {
    dispatch(setRoomsViewVariant(variant));
  }

  function handleToggleMultipleSelectionRooms() {
    dispatch(setMultipleSelectionRooms(!multipleSelection));
  }

  function handleCleanRoomsRelations() {
    // roomsGroup
    const {idsToRemove, newIds} = cleanRoomsGroupChildrenClientIds(
      roomsGroup,
      rooms
    );
    if (idsToRemove.length > 0) {
      const updates = {
        id: roomsGroup.id,
        sceneId: roomsGroup.sceneId,
        childrenClientIds: newIds,
      };
      dispatch(updateRoomsGroup({accessToken, updates}));
    }
    // rooms
    const roomsUpdates = cleanRoomsChildrenClientIds(rooms);
    if (roomsUpdates.length > 0) {
      dispatch(updateRoomsBatch({accessToken, updatesBatch: roomsUpdates}));
    }
  }

  // actions map

  const actionsMap = {
    fetchData: {
      label: "Récupérer les données",
      handler: handleFetchData,
    },
    createRoomsGroup: {
      icon: <AddGroup fontSize="small" />,
      name: "Arborescence de pièces",
      label: "Créer une arborescence de pièces",
    },
    deleteRoomsGroup: {
      label: "Supprimer l'arborescence des pièces",
      disabled: !selectedRoomsGroupId,
    },
    createRoom: {
      icon: <Add fontSize="small" />,
      name: "Pièce",
      label: "Créer une pièce",
    },
    createRoomsFromTemplate: {
      label: "Créer des pièces à partir de modèles",
    },
    editRoomsGroup: {
      icon: <Edit fontSize="small" />,
      name: "Editer",
      label: "Editer l'arborescene",
    },
    exportExcelRooms: {
      icon: <Download fontSize="small" />,
      name: "Exporter l'excel",
      label: "Exporter les pièces",
      handler: handleExportExcelRooms,
    },
    importExcelRooms: {
      icon: <Upload fontSize="small" />,
      name: "Importer l'excel",
      label: "Importer les pièces",
    },
    deleteAllRooms: {
      label: "Supprimer toutes les pièces",
      handler: handleDeleteAllRooms,
      disabled: !selectedRoomsGroupId,
    },
    deleteRooms: {
      label: "Supprimer les pièces",
      disabled: !selectedRoomIds?.length > 0,
    },
    cleanRoomsRelations: {
      label: "Nettoyer les relations",
      handler: handleCleanRoomsRelations,
    },
    autoExtract: {
      icon: <AutoIcon fontSize="small" />,
      label: "Auto-détection",
      handler: handleAutoExtract,
    },
    //
    setRoomsViewVariantToList: {
      label: "Vue liste",
      handler: () => handleRoomsViewVariantChange("LIST"),
    },
    setRoomsViewVariantToTree: {
      label: "Vue arbre",
      handler: () => handleRoomsViewVariantChange("TREE"),
    },
    setRoomsViewVariantToTreeList: {
      label: "Vue liste / arbre",
      handler: () => handleRoomsViewVariantChange("TREE_LIST"),
    },
    //
    toggleMultipleSelectionRooms: {
      label: multipleSelection
        ? "Désactiver la sélection multiple"
        : "Activer la sélection multiple",
      handler: handleToggleMultipleSelectionRooms,
    },
  };

  return actionsMap;
}
