import {useRef} from "react";

import {useSelector, useDispatch} from "react-redux";

import {setFormTabMaterial} from "../materialsSlice";

import useKvtMaterial from "Features/kvform/hooks/useKvtMaterial";

import {Box} from "@mui/material";

import KeyValueForm from "Features/kvform/components/KeyValueForm";
import getCategoriesByIdFromNomenclature from "../utils/getCategoriesByIdFromNomenclature";

import getMaterialCode from "../utils/getMaterialCode";
import getMaterialCategoryIndex from "../utils/getMaterialCategoryIndex";
import getCategoryCodes from "../utils/getCategoryCodes";
import getRoomsMapFromRoomsGroupsWithRooms from "Features/rooms/utils/getRoomsMapFromRoomsGroupsWithRooms";

export default function FormMaterial({
  scene,
  material,
  onChange,
  materials,
  materialsNomenclature,
  roomsGroupsWithRooms,
}) {
  const dispatch = useDispatch();
  const selectorContainerRef = useRef(null);

  const data = {materialsNomenclature, roomsGroupsWithRooms};
  const template = useKvtMaterial(data);

  // data

  const formTabMaterial = useSelector((s) => s.materials.formTabMaterial);

  // helper

  const roomsMap = getRoomsMapFromRoomsGroupsWithRooms(roomsGroupsWithRooms);

  // item

  const categoriesMap = getCategoriesByIdFromNomenclature(
    materialsNomenclature,
    {withChildren: true, withParents: true, addRootOtherItem: true}
  );
  const item = {...material};
  //
  item.materialCategory = categoriesMap[item.materialCategoryId];
  delete item.materialCategoryId;

  // roomsIds
  item.roomsIds = material.rooms?.map((room) => room.id) ?? [];
  delete item.rooms;

  // locked fields

  const lockedFields = ["code"];

  const materialCategory = categoriesMap[material?.materialCategoryId];
  if (materialCategory) {
    //
    const categoryCodes = getCategoryCodes(materialCategory);
    if (categoryCodes.length === 1) lockedFields.push("categoryCode");
    //
    if (materialCategory.type) lockedFields.push("categoryType");
  }

  // hidden fields

  const hiddenFields = [];
  if (!material.id) hiddenFields.push("roomsData");

  // handlers

  function handleItemChange(changedItem) {
    console.log("[FormMaterial] changedItem", changedItem);
    //
    const changedMaterial = {...material, ...changedItem};
    //
    if (changedItem.materialCategory) {
      // categoryId
      changedMaterial.materialCategoryId = changedItem.materialCategory?.id;

      // categoryType
      const categoryType = changedItem.materialCategory?.type;
      if (categoryType) changedMaterial.categoryType = categoryType;

      delete changedMaterial.materialCategory;
    }

    if (Array.isArray(changedItem.roomsIds)) {
      changedMaterial.rooms = changedItem.roomsIds.map(
        (roomId) => roomsMap[roomId]
      );
    }

    //
    onChange(changedMaterial);
  }

  return (
    <Box
      ref={selectorContainerRef}
      sx={{
        display: "flex",
        width: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        height: 1,
        bgcolor: (theme) => theme.palette.common.white,
        minHeight: 0,
      }}
    >
      <KeyValueForm
        template={template}
        item={item}
        onChange={handleItemChange}
        sizeKey={4}
        selectorContainerRef={selectorContainerRef}
        group={formTabMaterial}
        onGroupChange={(group) => dispatch(setFormTabMaterial(group))}
        lockedFields={lockedFields}
        hiddenFields={hiddenFields}
      />
    </Box>
  );
}
