import {Typography, Box} from "@mui/material";
import {Image} from "@mui/icons-material";

import ButtonCreateZone from "./ButtonCreateZone";

// import imageUrl from "../assets/zone.png";

export default function CardCreateZone({scene, caplaEditor}) {
  // const navigate = useNavigate();
  // const sceneId = scene?.id ? scene.id : scene?.clientId;

  // strings

  const title = "Fonds de plan";
  const description =
    "Définissez dans le PDF des zones rectangulaires pour isoler chaque coupe ou vue en plan";

  // const zoneModuleS = "Module fonds de plan";

  // data

  // const pathMap = useSceneModulePathMap(sceneId);

  // helpers

  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // handlers

  // function handleLaunchZonesModule() {
  //   navigate(pathMap["ZONES"]);
  // }

  return (
    <Box
      sx={{
        width: 1,
        p: 2,
        borderRadius: "4px",
        background,
        color: "common.white",
      }}
    >
      <Box sx={{display: "flex", alignItems: "center", mb: 1}}>
        <Image />
        <Typography variant="h6" sx={{ml: 2}}>
          {title}
        </Typography>
      </Box>

      <Typography variant="body2">{description}</Typography>

      <Box sx={{mt: 2}}>
        {/* <Button
          size="small"
          startIcon={<Launch />}
          onClick={handleLaunchZonesModule}
          color="inherit"
          variant="outlined"
        >
          {zoneModuleS}
        </Button> */}
        <ButtonCreateZone caplaEditor={caplaEditor} scene={scene} />
      </Box>
    </Box>
  );
}
