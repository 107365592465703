import {useState} from "react";

import {useDispatch} from "react-redux";

import {deleteSample} from "../samplesSlice";

import useSelectedSample from "../hooks/useSelectedSample";

import {Box} from "@mui/material";

import DeleteDialog from "Features/ui/components/DeleteDialog";

import useAccessToken from "Features/auth/useAccessToken";

export default function DialogDeleteSample({scene, open, onClose}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // state

  const [loading, setLoading] = useState(false);

  // data

  const selectedSample = useSelectedSample(scene);

  // handler

  function handleClose() {
    onClose();
    setLoading(false);
  }

  async function handleDeleteConfirm() {
    if (loading) return;
    setLoading(true);
    await dispatch(deleteSample({accessToken, sampleId: selectedSample.id}));
    setLoading(false);
    onClose();
  }

  return (
    <DeleteDialog
      open={open}
      onCancel={handleClose}
      onConfirm={handleDeleteConfirm}
      sample="sampleItem"
      loading={loading}
    />
  );
}
