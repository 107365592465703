import {useRef, useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";

import useSurveysByScene from "Features/surveys/hooks/useSurveysByScene";
import {fetchSamples} from "../samplesSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useSurveysWithSamples(scene, options) {
  //
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  //
  const surveysWithSamples = [];
  //
  const syncingSamplesBySurveyIdRef = useRef({});
  //
  const filterByScope = options?.filterByScope;
  const fetchSamplesOption = options?.fetchSamples;
  //
  const surveys = useSurveysByScene(scene, {
    filterByScope,
    sortByName: true,
  });
  const fetchedSamplesBySurveyId = useSelector(
    (s) => s.samples.fetchedSamplesBySurveyId
  );
  //
  const surveysHash = surveys.reduce((hash, survey) => {
    hash = hash + survey.id;
    return hash;
  }, "");

  // init - fetch samples

  useEffect(() => {
    surveys.forEach((survey) => {
      const samplesWereFetched = fetchedSamplesBySurveyId[survey.id];
      const isSyncingSamples = syncingSamplesBySurveyIdRef.current[survey.id];
      if (
        accessToken &&
        fetchSamplesOption &&
        !samplesWereFetched &&
        !isSyncingSamples
      ) {
        syncingSamplesBySurveyIdRef.current[survey.id] = true;
        dispatch(fetchSamples({surveyId: survey.id, accessToken}));
      }
    });
  }, [surveysHash, accessToken, fetchSamples]);

  //
  const samplesMap = useSelector((s) => s.samples.samplesMap);
  //
  let samples = samplesMap ? Object.values(samplesMap) : [];
  //
  surveys.forEach((survey) => {
    let surveySamples = samples.filter((r) => r.surveyId === survey.id);
    //
    const surveyWithSamples = {survey: survey, samples: surveySamples};
    surveysWithSamples.push(surveyWithSamples);
  });
  //
  return surveysWithSamples;
}
