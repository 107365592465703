import {client} from "API/capla360";
import remoteToStateBlock from "./utils/remoteToStateBlock";
import stateToRemoteBlock from "./utils/stateToRemoteBlock";

export async function fetchBlocksService({accessToken, reportId}) {
  const response = await client.get(`/reports/${reportId}/blocks/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteBlocks = response.data;
  const items = remoteBlocks.map(remoteToStateBlock);
  return {items, reportId};
}

export async function createBlockService({accessToken, block}) {
  const reportId = block.reportId;
  const newBlock = stateToRemoteBlock(block);
  if (accessToken && reportId) {
    const response = await client.post(
      `/reports/${reportId}/blocks/`,
      newBlock,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const remoteBlock = response.data;
    const item = remoteToStateBlock(remoteBlock);
    return {item};
  } else {
    throw new Error("missing accessToken or reportId");
  }
}

export async function fetchBlockService({accessToken, reportId, blockId}) {
  const response = await client.get(`/reports/${reportId}/blocks/${blockId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteBlock = response.data;
  const item = remoteToStateBlock(remoteBlock);
  return {item};
}

export async function updateBlockService({accessToken, updates}) {
  const reportId = updates.reportId;
  const blockId = updates.id;
  const remoteUpdates = stateToRemoteBlock(updates);

  const response = await client.patch(
    `/reports/${reportId}/blocks/${blockId}/`,
    remoteUpdates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteBlock = response.data;
  const item = remoteToStateBlock(remoteBlock);

  return {item};
}

export const deleteBlockService = async ({accessToken, reportId, blockId}) => {
  await client.delete(`/reports/${reportId}/blocks/${blockId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return blockId;
};
