export default function useKvtFormTemplate() {
  const kvt = {
    key: "formTemplate",
    fields: [
      {
        key: "name",
        name: "Nom du formulaire",
        type: "text",
      },
      {
        key: "data",
        name: "Modèle",
        type: "formTemplate",
      },
    ],
  };

  return kvt;
}
