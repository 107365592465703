import {useSelector} from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

export default function PageRooms() {
  // params

  const {caplaEditor, scene} = useOutletContext();
  const os = useSelector((s) => s.ui.openSections);

  if (os.outlet) return <Box sx={{width: 1}}></Box>;
  return <></>;
}
