import Fuse from "fuse.js";

const tokeniseStringWithQuotesBySpaces = (string) =>
  string.match(/("[^"]*?"|[^"\s]+)+(?=\s*|\s*$)/g) ?? [];

export default function getFoundObjects(objects, searchOptions) {
  const searchText = searchOptions?.searchText;
  const searchKeys = searchOptions?.searchKeys;

  // edge case

  if (!searchText) return objects;

  const options = {
    findAllMatches: true,
    ignoreLocation: true,
    keys: searchKeys,
    threshold: 0.0,
    distance: 0,
  };
  const fuse = new Fuse(objects, options);

  const newText = tokeniseStringWithQuotesBySpaces(searchText);

  const result = fuse.search({
    $and: newText.map((searchToken) => {
      const orFields = searchKeys.map((k) => ({[k]: searchToken}));
      return {$or: orFields};
    }),
  });

  const foundObjects = result.map((r) => r.item);

  return foundObjects;
}
