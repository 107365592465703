import {useState, useEffect} from "react";

import {useSelector} from "react-redux";

import {Box, IconButton, List, ListItemButton, Typography} from "@mui/material";
import {ArrowForwardIos as Arrow} from "@mui/icons-material";

import ListMaterialCategoriesVariantTreeParents from "./ListMaterialCategoriesVariantTreeParents";

import getItemChildrenFromNum from "Utils/getItemChildrenFromNum";
import getItemsMapById from "Utils/getItemsMapById";
import getOtherItemFromMaterialCategory from "../utils/getOtherItemFromMaterialCategory";

export default function ListMaterialCategoriesVariantTree({
  materialCategories,
  selection,
  onClick,
}) {
  // state

  const [parents, setParents] = useState([]);
  const [init, setInit] = useState(false);

  const showedRank = parents.length + 1;
  const lastParent = parents.length > 0 ? parents[parents.length - 1] : null;

  // helpers - categoriesById

  const categoriesById = getItemsMapById(materialCategories);
  categoriesById["other"] = {id: "other", name: "Autre"};

  // helper - init selection

  const selectedMaterialCategory = selection?.[0]
    ? categoriesById[selection[0]]
    : null;

  let initParents = [];
  let initSelectedMaterialCategory;

  if (selectedMaterialCategory) {
    if (selectedMaterialCategory?.id === "other") {
      initSelectedMaterialCategory = selectedMaterialCategory;
    } else if (selectedMaterialCategory?.children?.length > 0) {
      // select the other item
      initSelectedMaterialCategory = getOtherItemFromMaterialCategory(
        selectedMaterialCategory
      );
      initParents = [
        ...(selectedMaterialCategory?.parents ?? []),
        selectedMaterialCategory,
      ];
    } else {
      initSelectedMaterialCategory = selectedMaterialCategory;
      initParents = selectedMaterialCategory?.parents ?? [];
    }
  }

  // init

  console.log("debugafa 1212", initSelectedMaterialCategory, init);
  useEffect(() => {
    if (initSelectedMaterialCategory && !init) {
      console.log("[EFFECT] setParents", initParents, selectedMaterialCategory);
      setParents(initParents);
      //
      setInit(true);
    }
  }, [selection?.[0], init]);

  // helpers - items

  let items = getItemChildrenFromNum(lastParent, materialCategories);

  // Add otherItem to items
  const otherItem = {
    id: lastParent?.id ? lastParent.id + "-other" : "other",
    name: `Autre ${lastParent?.name ? `(${lastParent.name})` : ""}`,
    isOther: true,
    parentId: lastParent?.id,
    isCategory: true,
  };
  if (
    !lastParent?.isOther &&
    (!lastParent || lastParent?.children?.length > 0)
  ) {
    items.push(otherItem);
  }

  // helpers - selectedId

  // handlers

  function handleClick(materialCategory) {
    console.log("materialCategory", materialCategory);
    //
    if (materialCategory.isOther && lastParent) {
      onClick(lastParent);
    } else {
      if (materialCategory.children?.length > 0) {
        setParents([...parents, materialCategory]);
      } else {
        onClick(materialCategory);
      }
    }
  }

  function handleParentClick(parent) {
    const parentIndex = parents.findIndex((p) => p.id === parent.id);
    setParents(parents.slice(0, parentIndex + 1));
  }

  function handleRootClick() {
    setParents([]);
  }

  return (
    <Box>
      <ListMaterialCategoriesVariantTreeParents
        parents={parents}
        onParentClick={handleParentClick}
        onRootClick={handleRootClick}
      />
      <List dense disablePadding sx={{bgcolor: "common.white"}}>
        {items.map((item) => {
          const selected = item.id === initSelectedMaterialCategory?.id;
          const showArrow = item.children?.length > 0;
          const isOther = item.isOther;
          return (
            <ListItemButton
              key={item.id}
              divider
              disableGutters
              selected={selected}
              onClick={() => handleClick(item)}
              sx={{p: 0}}
            >
              <Box
                sx={{
                  px: 1,
                  py: 0.5,
                  width: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    ...(isOther && {
                      fontStyle: "italic",
                      color: "text.secondary",
                    }),
                  }}
                >
                  {item.name}
                </Typography>
                {showArrow && <Arrow fontSize="small" sx={{fill: "grey"}} />}
              </Box>
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
}
