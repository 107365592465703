import {useState} from "react";

import useActionsMaterialsMap from "../hooks/useActionsMaterialsMap";
import useSelectedMaterialsGroup from "../hooks/useSelectedMaterialsGroup";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogCreateMaterialsGroup from "./DialogCreateMaterialsGroup";
import DialogCreateMaterial from "./DialogCreateMaterial";
import DialogFsEditMaterialsGroup from "./DialogFsEditMaterialsGroup";
import DialogRenameMaterialsGroup from "./DialogRenameMaterialsGroup";
import DialogDeleteMaterialsGroup from "./DialogDeleteMaterialsGroup";
import DialogDeleteMaterial from "./DialogDeleteMaterial";
//import DialogImportMaterialsFromExcel from "./DialogImportMaterialsFromExcel";

export default function SectionActionsMaterials({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // data

  const selectedMaterialsGroup = useSelectedMaterialsGroup(scene);

  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  const [openDialogCreateMaterialsGroup, setOpenDialogCreateMaterialsGroup] =
    useState(false);

  const [openDialogDeleteMaterialsGroup, setOpenDialogDeleteMaterialsGroup] =
    useState(false);
  const [openDialogCreateMaterial, setOpenDialogCreateMaterial] =
    useState(false);
  //
  const [openDialogDeleteMaterial, setOpenDialogDeleteMaterial] =
    useState(false);
  //
  const [
    openDialogImportMaterialsFromExcel,
    setOpenDialogImportMaterialsFromExcel,
  ] = useState(false);
  //
  const [openDialogEditMaterialsGroup, setOpenDialogEditMaterialsGroup] =
    useState(false);

  const [openDialogRenameMaterialsGroup, setOpenDialogRenameMaterialsGroup] =
    useState(false);

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
    createMaterialsGroup: () => setOpenDialogCreateMaterialsGroup(true),
    deleteMaterialsGroup: () => setOpenDialogDeleteMaterialsGroup(true),
    createMaterial: () => setOpenDialogCreateMaterial(true),
    deleteMaterial: () => setOpenDialogDeleteMaterial(true),
    importExcelMaterials: () => setOpenDialogImportMaterialsFromExcel(true),
    editMaterialsGroup: () => setOpenDialogEditMaterialsGroup(true),
    renameMaterialsGroup: () => setOpenDialogRenameMaterialsGroup(true),
  };

  // handlers

  function handleClick(actionKey) {
    console.log("trigger", actionKey);
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // handlers - close Dialogs

  function handleCloseDialogCreateMaterialsGroup() {
    setOpenDialogCreateMaterialsGroup(false);
  }
  function handleCloseDialogCreateMaterial() {
    setOpenDialogCreateMaterial(false);
  }

  // data - handlers

  const actionsMap = useActionsMaterialsMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {openDialogCreateMaterialsGroup && (
        <DialogCreateMaterialsGroup
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateMaterialsGroup}
          onClose={handleCloseDialogCreateMaterialsGroup}
        />
      )}
      {openDialogCreateMaterial && (
        <DialogCreateMaterial
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateMaterial}
          onClose={handleCloseDialogCreateMaterial}
        />
      )}
      {openDialogEditMaterialsGroup && (
        <DialogFsEditMaterialsGroup
          open={openDialogEditMaterialsGroup}
          onClose={() => {
            setOpenDialogEditMaterialsGroup(false);
          }}
          scene={scene}
          materialsGroup={selectedMaterialsGroup}
        />
      )}
      {openDialogDeleteMaterialsGroup && (
        <DialogDeleteMaterialsGroup
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogDeleteMaterialsGroup}
          onClose={() => setOpenDialogDeleteMaterialsGroup(false)}
        />
      )}

      {openDialogDeleteMaterial && (
        <DialogDeleteMaterial
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogDeleteMaterial}
          onClose={() => setOpenDialogDeleteMaterial(false)}
        />
      )}
      {openDialogRenameMaterialsGroup && (
        <DialogRenameMaterialsGroup
          scene={scene}
          open={openDialogRenameMaterialsGroup}
          onClose={() => setOpenDialogRenameMaterialsGroup(false)}
        />
      )}
      {/* {openDialogImportMaterialsFromExcel && (
        <DialogImportMaterialsFromExcel
          open={openDialogImportMaterialsFromExcel}
          onClose={() => {
            setOpenDialogImportMaterialsFromExcel(false);
          }}
          scene={scene}
        />
      )} */}
    </>
  );
}
