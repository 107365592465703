import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  fetchFormTemplatesService,
  createFormTemplateService,
  fetchFormTemplateService,
  updateFormTemplateService,
  deleteFormTemplateService,
} from "./servicesFormTemplates";
import {
  fetchFormsService,
  createFormService,
  fetchFormService,
  updateFormService,
  deleteFormService,
} from "./servicesForms";

// TEMPLATE REPORTS

export const createFormTemplate = createAsyncThunk(
  "forms/createFormTemplate",
  createFormTemplateService
);

export const fetchFormTemplates = createAsyncThunk(
  "forms/fetchFormTemplates",
  fetchFormTemplatesService
);

export const fetchFormTemplate = createAsyncThunk(
  "forms/fetchFormTemplate",
  fetchFormTemplateService
);

export const updateFormTemplate = createAsyncThunk(
  "forms/updateFormTemplate",
  updateFormTemplateService
);
export const deleteFormTemplate = createAsyncThunk(
  "forms/deleteFormTemplate",
  deleteFormTemplateService
);

// REPORTS

export const createForm = createAsyncThunk(
  "forms/createForm",
  createFormService
);

export const fetchForms = createAsyncThunk(
  "forms/fetchForms",
  fetchFormsService
);

export const fetchForm = createAsyncThunk("forms/fetchForm", fetchFormService);

export const updateForm = createAsyncThunk(
  "forms/updateForm",
  updateFormService
);
export const deleteForm = createAsyncThunk(
  "forms/deleteForm",
  deleteFormService
);

const formsSlice = createSlice({
  name: "forms",
  initialState: {
    items: [],
    itemsMap: {},
    selectedFormId: null,
    //
    fetchedFormTemplatesByOrganisationId: {},
    //
    formTemplatesMap: {},
    selectedFormTemplateId: null,
    editedFormTemplate: null,
    newFormTemplate: null,
    isEditingFormTemplate: false,
    //
  },
  reducers: {
    setSelectedFormId: (state, action) => {
      state.selectedFormId = action.payload;
    },
    //
    setSelectedFormTemplateId: (state, action) => {
      state.selectedFormTemplateId = action.payload;
    },
    setNewFormTemplate: (state, action) => {
      state.newFormTemplate = action.payload;
    },
    setEditedFormTemplate: (state, action) => {
      state.editedFormTemplate = action.payload;
    },
    setIsEditingFormTemplate: (state, action) => {
      state.isEditingFormTemplate = action.payload;
    },
  },
  extraReducers: {
    // REPORT TEMPLATES

    [createFormTemplate.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.formTemplatesMap[item.id] = item;
    },
    [fetchFormTemplates.fulfilled]: (state, action) => {
      const {items, organisationId} = action.payload;
      items.forEach((item) => {
        state.formTemplatesMap[item.id] = item;
      });
      state.fetchedFormTemplatesByOrganisationId[organisationId] = items.map(
        (item) => item.id
      );
    },
    [fetchFormTemplate.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.formTemplatesMap[item.id] = item;
    },
    [updateFormTemplate.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.formTemplatesMap[item.id] = item;
    },
    [deleteFormTemplate.fulfilled]: (state, action) => {
      const formTemplateId = action.payload;
      delete state.formTemplatesMap[formTemplateId];
    },

    // REPORTS

    [createForm.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items.push(item);
    },
    [fetchForms.fulfilled]: (state, action) => {
      const {items} = action.payload;
      state.items = items;
    },
    [fetchForm.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items = state.items.filter((i) => i.id !== item.id);
      state.items.push(item);
    },
    [updateForm.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items = state.items.filter((i) => i.id !== item.id);
      state.items.push(item);
    },
    [deleteForm.fulfilled]: (state, action) => {
      const formId = action.payload;
      state.items = state.items.filter((i) => i.id !== formId);
    },
  },
});
export const {
  setSelectedFormId,
  //
  setSelectedFormTemplateId,
  setNewFormTemplate,
  setEditedFormTemplate,
  setIsEditingFormTemplate,
  //
} = formsSlice.actions;

export default formsSlice.reducer;
