import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  setSelectedMaterialCategoryId,
  setSelectedMaterialsClientIds,
  setSelectedRoomIdInMaterials,
} from "../materialsSlice";

import useMaterialsByScene from "../hooks/useMaterialsByScene";
import useNomenclatureByMaterialsGroupId from "../hooks/useNomenclatureByMaterialGroupId";
import useRoomsGroupsWithRooms from "Features/rooms/hooks/useRoomsGroupsWithRooms";
import useSelectedRoomInMaterials from "../hooks/useSelectedRoomInMaterials";

import {Box} from "@mui/material";

import SelectorRoomsGroupContainer from "Features/rooms/components/SelectorRoomsGroupContainer";
import ListRoomsAndMaterials from "./ListRoomsAndMaterials";
import ListMaterials from "./ListMaterials";

import getRoomsAndMaterialsItems from "../utils/getRoomsAndMaterialsItems";
import fromMaterialsDataToClientIds from "../utils/fromMaterialsDataToClientIds";
import getItemsMapByClientId from "Utils/getItemsMapByClientId";
import getCategoriesAndMaterialsItems from "../utils/getCategoriesAndMaterialsItems";
import getFoundCategoriesOrMaterials from "../utils/getFoundCategoriesOrMaterials";

export default function SectionMaterialsByRoom({scene}) {
  const dispatch = useDispatch();

  // data - materials

  const selectedMaterialsGroupId = useSelector(
    (s) => s.materials.selectedMaterialsGroupId
  );

  const materials = useMaterialsByScene(scene, {
    filterByMaterialsGroupId: selectedMaterialsGroupId,
    fetchData: true,
  });

  // data - rooms

  const roomsGroupsWithRooms = useRoomsGroupsWithRooms(scene, {
    filterByScope: true,
    fetchRooms: true,
    roomsWithParents: true,
    roomsWithNum: true,
  });

  const roomsGroups = roomsGroupsWithRooms.map((g) => g.roomsGroup);

  const selectedRoomIdInMaterials = useSelector(
    (s) => s.materials.selectedRoomIdInMaterials
  );

  const room = useSelectedRoomInMaterials();

  // state

  const [selectedRoomsGroupId, setSelectedRoomsGroupId] = useState(null);
  useEffect(() => {
    if (roomsGroupsWithRooms.length > 0) {
      setSelectedRoomsGroupId(roomsGroupsWithRooms[0].roomsGroup.id);
    }
  }, []);

  // helpers - rooms

  const rooms = roomsGroupsWithRooms.find(
    ({roomsGroup, rooms}) => roomsGroup.id === selectedRoomsGroupId
  )?.rooms;

  // data

  const viewVariant = useSelector((s) => s.materials.materialsViewVariant);

  const nomenclature = useNomenclatureByMaterialsGroupId(
    selectedMaterialsGroupId
  );
  const selectedMaterialCategoryId = useSelector(
    (s) => s.materials.selectedMaterialCategoryId
  );
  const selectedMaterialsClientIds = useSelector(
    (s) => s.materials.selectedMaterialsClientIds
  );
  const multipleSelection = useSelector(
    (s) => s.materials.multipleSelectionMaterials
  );

  const searchText = useSelector((s) => s.materials.searchTextMaterials);

  // helpers - rooms and materials

  const roomsAndMaterials = getRoomsAndMaterialsItems(rooms, materials);

  // helpers

  const filteredMaterials = materials;

  const categoriesAndMaterialsItems = getCategoriesAndMaterialsItems({
    nomenclature,
    materials,
  });

  const searchOptions = {
    searchText,
    withParents: true,
  };
  const foundCategoriesAndMaterialsItems = getFoundCategoriesOrMaterials(
    categoriesAndMaterialsItems,
    searchOptions
  );

  // handlers - rooms

  function handleSelectedRoomsGroupIdChange(roomsGroupId) {
    setSelectedRoomsGroupId(roomsGroupId);
  }

  function handleSelectedRoomIdChange(roomId) {
    dispatch(setSelectedRoomIdInMaterials(roomId));
  }

  // handlers

  function handleSelectedMaterialCategoryIdChange(categoryId) {
    dispatch(setSelectedMaterialCategoryId(categoryId));
  }

  function handleNewMaterial(newMaterial) {
    console.log("newMaterial", newMaterial);
  }

  function handleMaterialClick(material) {
    console.log("click on material", material);

    // edge case 1
    if (material?.isCategory) {
      dispatch(setSelectedMaterialCategoryId(material.id));
    }

    // edge case 2 : no material
    if (!material && !multipleSelection) {
      dispatch(setSelectedMaterialsClientIds([]));
    }

    // main
    const clientId = material?.clientId;
    const idIsSelected = selectedMaterialsClientIds?.includes(clientId);
    let newIds = selectedMaterialsClientIds?.filter(
      (materialClientId) => materialClientId !== material?.clientId
    );
    if (multipleSelection) {
      if (!newIds) newIds = [];
      if (material?.isTitle) {
        return;
      } else {
        if (!selectedMaterialsClientIds?.includes(clientId))
          newIds.push(clientId);
      }
    } else {
      newIds = idIsSelected ? [] : [clientId];
    }
    dispatch(setSelectedMaterialsClientIds(newIds));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        height: 1,
        minHeight: 0,
      }}
    >
      <SelectorRoomsGroupContainer
        scene={scene}
        roomsGroups={roomsGroups}
        selectedRoomsGroupId={selectedRoomsGroupId}
        onSelectedRoomsGroupIdChange={handleSelectedRoomsGroupIdChange}
        variant="chips"
      />

      <ListRoomsAndMaterials
        items={roomsAndMaterials}
        selectedRoomId={selectedRoomIdInMaterials}
        onSelectedRoomIdChange={handleSelectedRoomIdChange}
        hideMaterials={true}
      />
    </Box>
  );
}
