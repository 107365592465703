export default function sceneFromFormToState(sceneForm) {
  const scene = {...sceneForm};
  //
  if (scene.organisation) {
    scene.organisationId = scene.organisation.id;
    delete scene.organisation;
  } else {
    scene.organisationId = null;
    delete scene.organisation;
  }
  //
  const addressData = {};
  if (scene.addressL1) addressData.line1 = scene.addressL1;
  if (scene.addressL2) addressData.line2 = scene.addressL2;
  if (scene.addressL3) addressData.line3 = scene.addressL3;
  if (scene.addressL1 || scene.addressL2 || scene.addressL3) {
    scene.addressData = addressData;
  }
  delete scene.addressL1;
  delete scene.addressL2;
  delete scene.addressL3;
  //
  return scene;
}
