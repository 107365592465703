import {Box} from "@mui/material";

import HeaderInSelectionPanelFormTemplate from "./HeaderInSelectionPanelFormTemplate";
import SectionFormTemplateInSelectionPanel from "./SectionFormTemplateInSelectionPanel";
import ActionsFormTemplateInSelectionPanel from "./ActionsFormTemplateInSelectionPanel";

export default function SectionFormTemplateInOrganisationSelectionPanel({
  organisation,
}) {
  // data

  //
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
      }}
    >
      <HeaderInSelectionPanelFormTemplate />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          width: 1,
          overflowY: "auto",
        }}
      >
        <SectionFormTemplateInSelectionPanel />
      </Box>
      <ActionsFormTemplateInSelectionPanel organisation={organisation} />
    </Box>
  );
}
