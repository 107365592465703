import {useRef, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {fetchReports} from "../reportsSlice";

import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

import useAccessToken from "Features/auth/useAccessToken";
export default function useReportsByScene(scene, options) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const syncingMapRef = useRef({});

  const reportsMap = useSelector((s) => s.reports.reportsMap);
  const scope = useSelectedScope();

  useEffect(() => {
    if (scope?.id && accessToken && options?.fetchData) {
      if (!syncingMapRef.current[scope.id]) {
        syncingMapRef.current[scope.id] = true;
        dispatch(fetchReports({scopeId: scope.id, accessToken}));
      }
    }
  }, [scope?.id, accessToken, options?.fetchData]);

  let reports = Object.values(reportsMap);

  console.log("reports v1", reports);
  if (options?.filterByScope) {
    reports = reports.filter((v) => v.scopeId === scope?.id || !v.scopeId);
  }

  if (options?.sortByName) {
    reports = reports.sort((a, b) => a.name.localeCompare(b.name));
  }

  return reports;
}
