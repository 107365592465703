import {useRef, useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";

import {fetchOrganisationNomenclatures} from "../nomenclaturesSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useNomenclaturesByOrganisationId(
  organisationId,
  options
) {
  const syncingMapRef = useRef({});
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const fetchData = options?.fetchData;

  const fetchedNomenclaturesByOrganisationId = useSelector(
    (s) => s.nomenclatures.fetchedNomenclaturesByOrganisationId
  );

  useEffect(() => {
    if (
      accessToken &&
      fetchData &&
      organisationId &&
      !fetchedNomenclaturesByOrganisationId[organisationId]
    ) {
      if (!syncingMapRef.current[organisationId]) {
        syncingMapRef.current[organisationId] = true;
        dispatch(fetchOrganisationNomenclatures({organisationId, accessToken}));
      }
    }
  }, [organisationId, accessToken]);

  const nomenclaturesMap = useSelector(
    (state) => state.nomenclatures.nomenclaturesMap
  );

  const nomenclatures = Object.values(nomenclaturesMap).filter(
    (nomenclature) => nomenclature.organisationId === organisationId
  );

  return nomenclatures;
}
