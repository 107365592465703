import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  //
  fetchOrganisationNomenclatureService,
  fetchOrganisationNomenclaturesService,
  updateOrganisationNomenclatureService,
  postOrganisationNomenclatureService,
  deleteOrganisationNomenclatureService,
} from "./servicesNomenclatures";

//
export const fetchOrganisationNomenclatures = createAsyncThunk(
  "nomenclatures/fetchOrganisationNomenclatures",
  fetchOrganisationNomenclaturesService
);
export const createOrganisationNomenclature = createAsyncThunk(
  "nomenclatures/createOrganisationNomenclature",
  postOrganisationNomenclatureService
);

export const fetchOrganisationNomenclature = createAsyncThunk(
  "nomenclatures/fetchOrganisationNomenclature",
  fetchOrganisationNomenclatureService
);

export const updateOrganisationNomenclature = createAsyncThunk(
  "nomenclatures/updateOrganisationNomenclature",
  updateOrganisationNomenclatureService
);

export const deleteOrganisationNomenclature = createAsyncThunk(
  "nomenclatures/deleteOrganisationNomenclature",
  deleteOrganisationNomenclatureService
);
//

const nomenclaturesSlice = createSlice({
  name: "nomenclatures",
  initialState: {
    fetchedNomenclaturesByOrganisationId: {},
    //
    nomenclaturesMap: {},
    selectedNomenclatureId: null,
    isEditingNomenclature: false,
    editedNomenclature: null,
    newNomenclature: null,
    //
  },
  reducers: {
    setSelectedNomenclatureId: (state, action) => {
      state.selectedNomenclatureId = action.payload;
    },
    //
    setIsEditingNomenclature(state, action) {
      state.isEditingNomenclature = action.payload;
    },
    setEditedNomenclature(state, action) {
      state.editedNomenclature = action.payload;
    },
    setNewNomenclature(state, action) {
      state.newNomenclature = action.payload;
    },
  },
  extraReducers: {
    [createOrganisationNomenclature.fulfilled]: (state, action) => {
      const {item, organisationId} = action.payload;
      state.nomenclaturesMap[item.id] = item;
    },
    [updateOrganisationNomenclature.fulfilled]: (state, action) => {
      const {item, organisationId} = action.payload;
      state.nomenclaturesMap[item.id] = item;
    },
    [fetchOrganisationNomenclatures.fulfilled]: (state, action) => {
      const {items, organisationId} = action.payload;
      items.map((item) => {
        state.nomenclaturesMap[item.id] = item;
      });
      //
      state.fetchedNomenclaturesByOrganisationId[organisationId] = "done";
    },
    [deleteOrganisationNomenclature.fulfilled]: (state, action) => {
      const {nomenclatureId, organisationId} = action.payload;
      delete state.nomenclaturesMap[nomenclatureId];
    },
    //
  },
});
export const {
  setSelectedNomenclatureId,
  setIsEditingNomenclature,
  setEditedNomenclature,
  setNewNomenclature,
} = nomenclaturesSlice.actions;

export default nomenclaturesSlice.reducer;
