import {Route, Routes, BrowserRouter} from "react-router-dom";

import App from "Pages/App";

import PageToolbox from "Features/toolbox/components/PageToolbox";
import PageOrganisations from "Features/organisations/components/PageOrganisations";
import PageOrganisation from "Features/organisations/components/PageOrganisation";

import Test from "Pages/Test";

import Auth from "Pages/Auth";
import Logout from "Pages/Logout";

import Scenes from "Pages/Scenes";

import SceneHome from "Pages/SceneHome";
import PageConfigModules from "Features/navigation/components/PageConfigModules";
//import PageHomeAdmin from "Features/boxhome/components/PageHomeAdmin";
import PageSceneHome from "Features/boxhome/components/PageSceneHome";

import PageInputs from "Features/inputs/components/PageInputs";
import PageRooms from "Features/rooms/components/PageRooms";
import PageSceneLibrary from "Features/library/components/PageSceneLibrary";
import SceneMeasurements from "Pages/SceneMeasurements";
import SceneIssues from "Pages/SceneIssues";
import PageSharedboxes from "Features/sharedboxes/components/PageSharedbox";
import SceneZones from "Pages/SceneZones";
import SceneSectors from "Pages/SceneSectors";
import ScenePhases from "Pages/ScenePhases";
import SceneVersions from "Pages/SceneVersions";
import SceneOnboarding from "Pages/SceneOnboarding";
import SceneRessources from "Pages/SceneRessources";
import SceneElementTypes from "Pages/SceneElementTypes";
import PageRelations from "Features/relations/components/PageRelations";

import SceneMarkers from "Pages/SceneMarkers";
import SharedScene from "Pages/SharedScene";
import Profile from "Pages/Profile";
import PagePackages from "Features/elementPackages/components/PagePackages";
import PageAnnotatedPdf from "Features/annotatedPdf/components/PageAnnotatedPdf";
import PageMaterials from "Features/materials/components/PageMaterials";
import PagePricings from "Features/pricing/components/PagePricings";
import PageBimboxOrder from "Features/ebusiness/components/PageBimboxOrder";
import PageViews from "Features/views/components/PageViews";
import PageView from "Features/views/components/PageView";
import PageReports from "Features/reports/components/PageReports";
import PageReport from "Features/reports/components/PageReport";
import PageSurveys from "Features/surveys/components/PageSurveys";
import PageSamples from "Features/samples/components/PageSamples";
import PageScopes from "Features/scopes/components/PageScopes";
import PageArticles from "Features/articles/components/PageArticles";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<App />}>
          <Route index element={<Scenes />} />
          <Route exact path="/toolbox" element={<PageToolbox />} />
          <Route exact path="/organisations" element={<PageOrganisations />} />
          <Route
            exact
            path="/organisations/:organisationId"
            element={<PageOrganisation />}
          />
          <Route
            exact
            path="/order/:sharedBimboxId"
            element={<PageBimboxOrder />}
          />

          <Route
            exact
            path="/shared/bimbox/:shareLink/viewer"
            element={<SceneHome />}
          />
          <Route exact path="/bimboxes/:sceneId" element={<PageSceneHome />} />
          <Route
            exact
            path="/bimboxes/:sceneId/scopes"
            element={<PageScopes />}
          />
          <Route
            path="/bimboxes/:sceneId/modules"
            element={<PageConfigModules />}
          />
          <Route
            path="/bimboxes/:sceneId/onboarding"
            element={<SceneOnboarding />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/inputs"
            element={<PageInputs />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/markers"
            element={<SceneMarkers />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/zones"
            element={<SceneZones />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/sectors"
            element={<SceneSectors />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/versions"
            element={<SceneVersions />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/rooms"
            element={<PageRooms />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/library"
            element={<PageSceneLibrary />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/phases"
            element={<ScenePhases />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/elementTypes"
            element={<SceneElementTypes />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/relations"
            element={<PageRelations />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/packages"
            element={<PagePackages />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/annotatedPdf/:packageClientId"
            element={<PageAnnotatedPdf />}
          />
          <Route
            path="/bimboxes/:sceneId/ressources"
            element={<SceneRessources />}
          />
          <Route
            path="/bimboxes/:sceneId/articles"
            element={<PageArticles />}
          />
          <Route
            path="/bimboxes/:sceneId/:modelId?/measurements"
            element={<SceneMeasurements />}
          />

          <Route
            exact
            path="/bimboxes/:sceneId/sharedboxes"
            element={<PageSharedboxes />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/views"
            element={<PageViews />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/views/:viewId"
            element={<PageView />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/reports"
            element={<PageReports />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/reports/:reportId"
            element={<PageReport />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/surveys"
            element={<PageSurveys />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/samples"
            element={<PageSamples />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/issues"
            element={<SceneIssues />}
          />
          <Route
            exact
            path="/shared/bimbox/:shareLinkScene"
            element={<SharedScene />}
          />
          <Route exact path="/auth" element={<Auth />} />
          <Route exact path="/logout" element={<Logout />} />
          <Route exact path="/profile" element={<Profile />} />

          <Route exact path="/test" element={<Test />} />
          <Route
            exact
            path="/bimboxes/:sceneId/materials"
            element={<PageMaterials />}
          />
          <Route
            exact
            path="/bimboxes/:sceneId/pricings"
            element={<PagePricings />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
