import {Box} from "@mui/material";

import SectionActionsMaterials from "./SectionActionsMaterials";

export default function SectionLeftPanelActionsMaterials({scene, caplaEditor}) {
  // actions - main

  const actionsMainKeys = ["createMaterialsGroup"];
  const actionsMoreKeys = [
    "renameMaterialsGroup",
    "divider",
    "deleteMaterial",
    "deleteMaterialsGroup",
  ];

  return (
    <Box>
      <SectionActionsMaterials
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
