import {Box, Button} from "@mui/material";

export default function ButtonExtractText({caplaEditor}) {
  // strings

  const label = "Extraire le texte";

  // handlers

  function handleClick() {
    const roomLabel = {
      rect: {x: 300, y: 300, width: 100, height: 100},
      p: 1,
      label: "Room 1",
    };
    caplaEditor.editorPdf.roomLabelsManager.addRoomLabel(roomLabel);
  }

  return (
    <Box>
      <Button onClick={handleClick} variant="contained">
        {label}
      </Button>
    </Box>
  );
}
