import {useSelector, useDispatch} from "react-redux";

import {Box, Paper} from "@mui/material";

import {setToolViewer} from "../viewer3DSlice";
import ButtonFullScreen from "./ButtonFullScreen";
import ToolbarBottomViewer3D from "./ToolbarBottomViewer3D";
import ToolbarBottomLeftViewer3D from "./ToolbarBottomLeftViewer3D";
import ToolbarBottomRightViewer3D from "./ToolbarsBottomRightViewer3D";
import ButtonCloseImageOverlay from "./ButtonCloseImageOverlay";
import ToolHeightsSlider from "./ToolHeightsSlider";
import ProcessMarkerAdd from "./ProcessMarkerAdd";
import ToolContainer from "./ToolContainer";
import ButtonToggle2D3D from "./ButtonToggle2D3D";
import ToolShortcuts from "./ToolShortcuts";

import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";
import ListContainerIssuesInViewer from "Features/issues/components/ListContainerIssuesInViewer";

import useIsFullscreen from "Features/ui/useIsFullscreen";
import ShortcutRight from "Features/shortcut/components/ShortcutRight";
import CardHelperZ from "./CardHelperZ";

import SectionPois from "Features/pois/components/SectionPois";
import ButtonToggleZonesInViewer3d from "Features/zones/components/ButtonToggleZonesInViewer3d";

export default function LayerDefaultDesktop({caplaEditor, scene, viewer}) {
  const dispatch = useDispatch();

  // data

  const imageOverlay = useSelector((s) => s.viewer3D.imageOverlayMode);
  const viewMode = useSelector((state) => state.viewer3D.viewMode);
  const deltaX = Math.floor(
    useSelector((state) => state.viewer3D.multiviewsSliderPositionX)
  );
  const show3D = useSelector((state) => state.viewer3D.show3D);
  const show3DFromPdf = useSelector((s) => s.pdf.show3D);
  const fullscreen = useIsFullscreen();
  const showIssues = useSelector((s) => s.issues.showIssuesInViewer);

  const sceneModule = useSceneModule();
  const pageType = usePageType();

  const selectedRessourceId = useSelector(
    (s) => s.ressources.selectedRessourceId
  );
  const selectedArticleId = useSelector((s) => s.articles.selectedArticleId);

  const selectedTypeId = useSelector(
    (s) => s.elementTypes.selectedElementTypeId
  );
  const selectedTypeIdInMeasurements = useSelector(
    (s) => s.measurements.selectedElementTypeId
  );

  const mode = useSelector((s) => s.viewer3D.mode);
  const tool = useSelector((state) => state.viewer3D.toolViewer);
  const showHelperZ = useSelector((state) => state.viewer3D.pointerMode);
  // const showLeftSelectionInViewer = useSelector(
  //   (s) => s.leftPanel.showLeftSelectionInViewer
  // );

  // const clickedItem = useSelector((s) => s.selection.clickedItem);

  // // effect

  // useEffect(() => {
  //   if (
  //     clickedItem?.type === "MEASUREMENT_ELEMENT" ||
  //     clickedItem?.type === "IMAGE_ENTITY" ||
  //     clickedItem?.type === "IMAGE_PART_ENTITY"
  //   ) {
  //     dispatch(setShowLeftSelectionInViewer(true));
  //   }
  // }, [clickedItem?.type]);

  // helper

  const multiviews = viewMode === "MULTIVIEWS";

  let heightsLeft = 8;
  if (multiviews) {
    heightsLeft = show3D ? deltaX - 10 : deltaX - 24;
  }

  // helper - top

  //let top = fullscreen ? "8px" : "58px";
  let top = "8px";
  if (viewer) top = "8px";

  // helper - show shortcut

  // const showShortcuts = !viewer || !showLeftSelectionInViewer;
  const showShortcuts = true;

  // helper - show bottom left toolbar

  let hideBottomLeft =
    (show3DFromPdf && sceneModule !== "EDIT_MEASUREMENTS") ||
    (["ISSUES", "MARKERS"].includes(sceneModule) && !fullscreen);

  // helper - datasetOverview

  const showDatasetOverview = false;

  // helper - sectionPoi

  const showSectionPoi =
    viewer ||
    (sceneModule === "RESSOURCES" && selectedRessourceId) ||
    (sceneModule === "ARTICLES" && selectedArticleId) ||
    (sceneModule === "ELEMENT_TYPES" && selectedTypeId) ||
    (sceneModule === "EDIT_MEASUREMENTS" && selectedTypeIdInMeasurements);

  // handlers

  function handleToolClose() {
    dispatch(setToolViewer(null));
  }

  return (
    <>
      {showSectionPoi && (
        <Box
          sx={{
            position: "absolute",
            top: "8px",
            left: "72px",
            zIndex: 20,
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <Box sx={{mr: 1}}>
            <ButtonToggleZonesInViewer3d
              caplaEditor={caplaEditor}
              scene={scene}
              viewer={viewer}
            />
          </Box> */}

          <SectionPois
            viewer={viewer}
            scene={scene}
            caplaEditor={caplaEditor}
            inViewer="3D"
          />
        </Box>
      )}

      {showShortcuts && (
        <Box
          sx={{
            position: "absolute",
            top,
            left: "16px",
            zIndex: 20,
          }}
        >
          {!imageOverlay && (
            <ToolShortcuts
              caplaEditor={caplaEditor}
              scene={scene}
              sceneViewer="3D"
              viewer={viewer}
            />
          )}
        </Box>
      )}

      {!imageOverlay &&
        !fullscreen &&
        !viewer && ( // we remove this toggle in the viewer.
          <Box
            sx={{
              position: "absolute",
              top: viewer ? top : "16px",
              zIndex: 20,
              display: "flex",
              alignItems: "center",
              ...(viewer && {left: "50%", transform: "translateX(-50%)"}),
              ...(!viewer && {right: "16px"}),
            }}
          >
            <Paper
              sx={{p: 0.25, bgcolor: "common.caplaBlack", ml: 1.5}}
              elevation={12}
            >
              <ButtonToggle2D3D
                editor3d={caplaEditor?.editor3d}
                viewer={viewer}
              />
            </Paper>
          </Box>
        )}

      <Box
        sx={{position: "absolute", top, right: "8px", zIndex: 50, width: 300}}
      >
        {mode === "PROCESS_ADD_MARKER" && (
          <ProcessMarkerAdd editor3d={caplaEditor?.editor3d} />
        )}
      </Box>
      {!imageOverlay && (
        <Box
          sx={{
            position: "absolute",
            bottom: "16px",
            left: "16px",
            zIndex: 30,
            display: "flex",
            //alignItems: "center",
            flexDirection: "column",
          }}
        >
          {/* {!show3DFromPdf && !viewer && <ButtonFullScreen />} */}
          {/* {!viewer && <Box sx={{width: "8px"}} />} */}

          {showHelperZ && (
            <Box sx={{mb: 1}}>
              <CardHelperZ />
            </Box>
          )}
          {!hideBottomLeft && (
            <ToolbarBottomLeftViewer3D
              editor3d={caplaEditor?.editor3d}
              scene={scene}
            />
          )}
        </Box>
      )}
      {showIssues && !imageOverlay && (
        <Box sx={{position: "absolute", top, left: "8px", zIndex: 30}}>
          <ListContainerIssuesInViewer
            caplaEditor={caplaEditor}
            scene={scene}
            viewer="3D"
          />
        </Box>
      )}
      {/* {multiviews && (
        <Box
          sx={{
            position: "absolute",
            top: (theme) => theme.spacing(16),
            bottom: (theme) => theme.spacing(16),
            left: heightsLeft,
            zIndex: 30,
          }}
          onPointerMove={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ToolHeightsSlider scene={scene} editor3d={caplaEditor?.editor3d} />
        </Box>
      )} */}
      {!imageOverlay && (
        <Box
          sx={{
            position: "absolute",
            bottom: "16px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 30,
            display: "flex",
            alignItems: "center",
          }}
        >
          <ToolbarBottomViewer3D
            caplaEditor={caplaEditor}
            scene={scene}
            viewer={viewer}
          />
        </Box>
      )}
      {!imageOverlay && (
        <Box
          sx={{
            position: "absolute",
            bottom: "16px",
            right: "16px",
            zIndex: 30,
            display: "flex",
            alignItems: "center",
          }}
        >
          <ToolbarBottomRightViewer3D caplaEditor={caplaEditor} />
        </Box>
      )}
      {imageOverlay && (
        <Box
          sx={{
            position: "absolute",
            bottom: "24px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 30,
          }}
        >
          <ButtonCloseImageOverlay editor3d={caplaEditor?.editor3d} />
        </Box>
      )}
      {!imageOverlay && (
        <Box
          sx={{
            position: "absolute",
            top: (theme) => theme.spacing(14),
            right: (theme) => theme.spacing(1),
            width: 300,
            zIndex: 100,
          }}
        >
          <ToolContainer
            tool={tool}
            caplaEditor={caplaEditor}
            onClose={handleToolClose}
            scene={scene}
          />
        </Box>
      )}
      {!imageOverlay && viewer && (
        <ShortcutRight
          caplaEditor={caplaEditor}
          scene={scene}
          viewer={viewer}
        />
      )}
    </>
  );
}
