import {useState, useEffect} from "react";

import {Paper, Box, Typography, IconButton, Dialog} from "@mui/material";
import {Upload, Download} from "@mui/icons-material";

import ContainerFilesSelector from "Features/files/components/ContainerFilesSelector";

import sanitizeString from "Utils/sanitizeString";
import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";
import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";
import getRowsFromFile from "Features/excel/utils/getRowsFromFile";
import getNomenclatureItemsFromSortedArray from "Features/nomenclatures/utils/getNomenclatureItemsFromSortedArray";

import getUpdatedNomenclatureTree from "Features/nomenclatures/utils/getUpdatedNomenclatureTree";

export default function FieldNomenclatureTreeHeader({
  item,
  name,
  value,
  onChange,
}) {
  // strings

  const title = "Nomenclature";

  // state

  const [open, setOpen] = useState(false);

  // handler

  function handleEditClick() {
    setOpen(true);
  }

  async function handleFilesChange(files) {
    try {
      const file = files[0];
      const rows = await getRowsFromFile(file);
      //
      const newNomenclatureTree = getNomenclatureItemsFromSortedArray(rows);
      const oldNomenclatureTree = value?.id ? value : {items: []};
      //
      const updatedNomenclatureTree = getUpdatedNomenclatureTree(
        newNomenclatureTree,
        oldNomenclatureTree
      );
      //
      console.log(
        "updatedNomenclatureTree",
        updatedNomenclatureTree,
        newNomenclatureTree,
        oldNomenclatureTree
      );
      //
      onChange(updatedNomenclatureTree);
      //
      setOpen(false);
    } catch (e) {
      console.error(e);
    }
  }

  async function handleDownloadClick() {
    const fileName = sanitizeString(item.name) + ".xlsx";
    const wb = await createExcelWorkbook({
      template: "NOMENCLATURE",
      data: value,
    });
    downloadExcelFileFromWorkbook(wb, fileName);
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <ContainerFilesSelector
          onFilesChange={handleFilesChange}
          accept=".xlsx"
        />
      </Dialog>
      <Box
        sx={{
          width: 1,
          bgcolor: "background.default",
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            width: 1,
            color: "text.secondary",
            //bgcolor: "background.default",
            px: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{fontSize: 12, fontStyle: "italic"}}>
            {title}
          </Typography>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <IconButton size="small" onClick={handleDownloadClick}>
              <Download fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={handleEditClick}>
              <Upload fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}
