import {useState} from "react";

import {Box, Typography, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import ListRoomsMultipleWithGroups from "./ListRoomsMultipleWithGroups";

export default function SelectorRoomFromTree({
  roomsGroupsWithRooms,
  onClose,
  selectedRoom,
  onSelect,
  scene,
  caplaEditor,
}) {
  //
  console.log("roomsGroupsWithRooms", roomsGroupsWithRooms);
  //
  // string

  const title = "Sélectionnez une pièce";

  // state

  const [selectedRoomsGroupId, setSelectedRoomsGroupId] = useState(
    selectedRoom?.roomsGroupId
  );

  // helpers - rooms and groups

  const roomsGroups = (roomsGroupsWithRooms ?? []).map(
    ({roomsGroup}) => roomsGroup
  );
  const rooms =
    (roomsGroupsWithRooms ?? []).find(
      ({roomsGroup}) => roomsGroup.id === selectedRoomsGroupId
    )?.rooms || [];

  // helpers

  const selectedRoomIds = selectedRoom ? [selectedRoom.id] : [];

  // handlers

  function handleClose() {
    onClose();
  }

  function handleSelectionChange(ids) {
    const id0 = ids?.length > 0 ? ids[0] : null;
    onSelect(id0);
  }

  function handleSelectedRoomsGroupIdChange(id) {
    setSelectedRoomsGroupId(id);
  }

  return (
    <Box sx={{width: 1, height: 1, display: "flex", flexDirection: "column"}}>
      <Box
        sx={{
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {title}
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <Close fontSize="small" />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          minHeight: 0,
        }}
      >
        <ListRoomsMultipleWithGroups
          scene={scene}
          caplaEditor={caplaEditor}
          rooms={rooms}
          roomsGroups={roomsGroups}
          selectedRoomIds={selectedRoomIds}
          onSelectionChange={handleSelectionChange}
          forceSelection={true}
          selectedRoomsGroupId={selectedRoomsGroupId}
          onSelectedRoomsGroupIdChange={handleSelectedRoomsGroupIdChange}
          resetSelectionOnGroupChange={false}
          multipleSelection={false}
          variant="chips"
        />
      </Box>
    </Box>
  );
}
