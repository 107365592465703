function addItemToParent(item, parent) {
  if (parent) {
    if (parent.children) {
      parent.children.push(item);
    } else {
      parent.children = [item];
    }
  }
}

function getRank(item) {
  return item.num.split(".").length;
}

function getItemParent(item, itemMap) {
  let parentNum = [...item.num.split(".")];
  parentNum.pop();
  parentNum = parentNum.join(".");
  return itemMap[parentNum];
}

export default function buildTreeFromItemsWithNum(items) {
  const itemMap = {};
  const tree = [];

  //

  items = items.filter((item) => item.num);

  // build treen

  for (let item of items) {
    const newItem = {...item};
    if (!newItem.label) newItem.label = item.name ?? "-?-";
    if (getRank(item) === 1) {
      itemMap[item.num] = newItem;
      tree.push(newItem);
    } else if (getRank(item) > 1) {
      const parent = getItemParent(item, itemMap);
      addItemToParent(newItem, parent);
      itemMap[item.num] = newItem;
    }
  }
  return tree;
}
