import fromMaterialsDataToClientIds from "./fromMaterialsDataToClientIds";
import getItemsMapByClientId from "Utils/getItemsMapByClientId";

export default function getRoomsAndMaterialsItems(roomsWithNum, materials) {
  // edge cases

  if (!materials || materials.length === 0) return roomsWithNum;

  // helpers

  const materialsByClientId = getItemsMapByClientId(materials);

  // main

  const items = [];

  //
  roomsWithNum?.forEach((room) => {
    items.push({...room, isRoom: true});
    //
    const materialClientIds = fromMaterialsDataToClientIds(room.materialsData);
    materialClientIds?.forEach((materialClientId, index) => {
      const material = materialsByClientId[materialClientId];
      if (material) {
        items.push({
          ...material,
          isMaterial: true,
          num: `${room.num}.${index + 1}`,
        });
      }
    });
  });

  return items;
}
