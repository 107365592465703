import useKvtFormTemplate from "Features/kvform/hooks/useKvtFormTemplate";

import {Box} from "@mui/material";

import KeyValueForm from "Features/kvform/components/KeyValueForm";

export default function FormFormTemplate({formTemplate, onChange}) {
  //

  const template = useKvtFormTemplate();

  // item

  const item = {...formTemplate};
  console.log("item form template", item);

  // handlers

  function handleItemChange(changedItem) {
    const changedFormTemplate = {...formTemplate, ...changedItem};
    onChange(changedFormTemplate);
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        minHeight: 0,
      }}
    >
      <KeyValueForm
        template={template}
        item={item}
        onChange={handleItemChange}
      />
    </Box>
  );
}
