import React, {useState, useEffect} from "react";

import {Grid, Typography, Box} from "@mui/material";
import ListItemsDraggable from "Components/ListItemsDraggable";
export default function FieldChildren({
  name,
  value = [],
  onChange,
  locked,
  sizeKey = 6,
  id,
  options = [],
}) {
  // state

  const initTempValue = value ? value : [];
  const [tempValue, setTempValue] = useState(initTempValue);
  //useEffect(() => setTempValue(initTempValue), [initTempValue]);

  // helpers

  const optionsMap = options.reduce((acc, o) => {
    acc[o[id]] = o;
    return acc;
  }, {});

  const itemChildren = (value ?? []).map((idKey) => {
    return optionsMap[idKey];
  });

  // handlers

  function handleChange() {}
  function handleBlur() {
    if (!locked) onChange(tempValue);
  }

  function handleOrderChange(newIds) {
    onChange(newIds);
  }

  return (
    <Grid
      container
      sx={{
        pr: 1,
        width: 1,
        minWidth: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Grid xs={sizeKey} item>
        <Box
          sx={{
            display: "flex",
            height: 1,
            alignItems: "center",
            bgcolor: "background.default",
            pl: 1,
          }}
        >
          <Typography sx={{fontSize: 13, color: "text.secondary"}} noWrap>
            {name}
          </Typography>
        </Box>
      </Grid>

      <Grid
        xs={12 - sizeKey}
        item
        sx={{
          borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box sx={{bgcolor: "common.white"}}>
          <ListItemsDraggable
            items={itemChildren}
            onOrderChange={handleOrderChange}
            draggable={!locked}
            id={id}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
