import React, {useState} from "react";

import {useSelector} from "react-redux";

import {Box, CircularProgress, IconButton, Typography} from "@mui/material";
import {CloudUpload, Delete, CloudUpload as Upload} from "@mui/icons-material";

import FileSelectorButton from "Features/files/components/FileSelectorButton";

import useAccessToken from "Features/auth/useAccessToken";
import FieldPdfsDialogConfirmUpload from "./FieldPdfsDialogConfirmUpload";
import FieldPdfsContainerPdfs from "./FieldPdfsContainerPdfs";

export default function FieldPdfs({name, value, onChange}) {
  const accessToken = useAccessToken();

  //strings

  const selectPdf = "Depuis mon ordinateur";
  const dragS = "Glisser & déposer des PDFs";
  const addFilesS = "Ajouter des fichiers PDFs";

  // state

  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState();

  const [tempPdfs, setTempPdfs] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  // data

  const scopeId = useSelector((s) => s.scopes.selectedScopeId);

  // handlers

  function handleFilesChange(files) {
    const pdfs = files.map((file) => ({
      name: file.name,
      size: file.size,
      file,
    }));
    setTempPdfs(pdfs);
    setOpenDialog(true);
  }

  function handleClose() {
    setTempPdfs([]);
    setOpenDialog(false);
  }

  function handleConfirm(pdfs) {
    onChange(pdfs);
    setOpenDialog(false);
  }

  // helper

  let showLoader = !loading && !value?.length > 0;

  // handlers - drag & drop

  function handleDragEnter() {
    setHover(true);
  }
  function handleDragLeave() {
    setHover(false);
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("DROP");
    setHover(false);
    try {
      const files = [];
      if (e.dataTransfer.files instanceof FileList) {
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          const item = e.dataTransfer.items[i];
          files.push(item.getAsFile());
        }
        const pdfs = files.map((file) => ({
          name: file.name,
          size: file.size,
          file,
        }));
        setTempPdfs(pdfs);
        setOpenDialog(true);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  // handlers - files

  function handleDelete() {
    onChange(null);
  }

  return (
    <>
      <FieldPdfsDialogConfirmUpload
        open={openDialog}
        onClose={handleClose}
        onConfirm={handleConfirm}
        pdfs={tempPdfs}
      />
      <Box
        sx={{
          width: 1,
          minHeight: 30,
          bgcolor: "common.white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            px: 1,
            py: 0.5,
            bgcolor: "background.default",
            width: 1,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {name}
          </Typography>
        </Box>

        {showLoader && (
          <Box sx={{flex: 1, p: 2, width: 1}}>
            <Box
              sx={{
                bgcolor: "background.default",
                position: "relative",
                width: 1,
                height: 1,
                py: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                sx={{
                  position: "absolute",
                  height: 1,
                  width: 1,
                  border: (theme) => `2px dashed ${theme.palette.divider}`,
                  borderColor: (theme) =>
                    hover ? theme.palette.primary.flash : theme.palette.divider,
                }}
              />
              <CloudUpload color="action" />
              <Typography variant="body2" color="text.secondary" sx={{mt: 1}}>
                {dragS}
              </Typography>
            </Box>
          </Box>
        )}
        {showLoader && (
          <Box
            sx={{
              width: 1,
              display: "flex",
              justifyContent: "left",
              color: "text.secondary",
            }}
          >
            <FileSelectorButton
              onFileChange={handleFilesChange}
              buttonName={selectPdf}
              startIcon="computer"
              buttonVariant="inline"
              buttonColor="inherit"
              multiple={true}
              accept=".pdf"
            />
          </Box>
        )}
        {loading && <CircularProgress />}
        {!showLoader && <FieldPdfsContainerPdfs pdfs={value} />}
      </Box>
    </>
  );
}
