import {useSelector, useDispatch} from "react-redux";

import {setSearchTextRooms} from "../roomsSlice";

import SearchBarObjectsV2 from "Features/search/components/SearchBarObjectsV2";

export default function SearchBarRooms({onFocusChange}) {
  const dispatch = useDispatch();
  // data

  const searchText = useSelector((s) => s.rooms.searchTextRooms);

  // handler

  function handleSearchTextChange(searchText) {
    dispatch(setSearchTextRooms(searchText));
  }

  function handleSearchReset() {
    dispatch(setSearchTextRooms(""));
    onFocusChange(false);
  }

  function handleFocus() {
    onFocusChange(true);
  }

  function handleBlur() {
    onFocusChange(false);
  }

  return (
    <SearchBarObjectsV2
      searchText={searchText}
      onSearchTextChange={handleSearchTextChange}
      onClose={handleSearchReset}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
}
