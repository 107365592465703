import {Box} from "@mui/material";

import useIssuesetsByScene from "Features/issues/useIssuesetsByScene";
import HeaderInShortcutIssues from "Features/issues/components/HeaderInShortcutIssues";
import HeaderInShortcutZones from "Features/zones/components/HeaderInShortcutZones";

export default function HeaderShortcut({caplaEditor, scene, viewer, tool}) {
  const issuesets = useIssuesetsByScene(scene);
  return (
    <Box>
      {tool === "ISSUES" && issuesets.length > 0 && (
        <HeaderInShortcutIssues
          caplaEditor={caplaEditor}
          scene={scene}
          viewer={viewer}
        />
      )}
      {tool === "ZONES" && (
        <HeaderInShortcutZones editor3d={caplaEditor?.editor3d} scene={scene} />
      )}
    </Box>
  );
}
