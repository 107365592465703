import {useSelector, useDispatch} from "react-redux";
import {nanoid, unwrapResult} from "@reduxjs/toolkit";

import {Box, Button, alpha} from "@mui/material";
import {Add} from "@mui/icons-material";

import {createRoom, setSelectedRoomId, setTempRoom} from "../roomsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function ActionsNewRoomInSelectionPanel() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const createS = "Créer la pièce";

  // data

  const tempRoom = useSelector((s) => s.rooms.tempRoom);
  const roomsGroupId = useSelector((s) => s.rooms.selectedRoomsGroupId);

  // handlers

  async function handleCreateClick() {
    try {
      const newRoom = {...tempRoom};
      newRoom.clientId = nanoid();
      newRoom.roomsGroupId = roomsGroupId;
      const result = await dispatch(createRoom({accessToken, room: newRoom}));
      const {item} = unwrapResult(result);
      dispatch(setSelectedRoomId(item.id));
      dispatch(setTempRoom({}));
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateClick}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
