export default function useKvtBlock(data) {
  // data

  const formTemplates = data?.formTemplates ?? [];
  const zones = data?.zones ?? [];

  // form

  const kvt = {
    key: "block",
    groups: [
      {code: "INFO", label: "Info"},
      {code: "DATA", label: "Données"},
    ],
    fields: [
      {
        group: "INFO",
        key: "name",
        name: "Nom",
        type: "text",
      },
      {
        group: "INFO",
        key: "tag",
        name: "Balise",
        type: "text",
      },
      {
        group: "INFO",
        key: "blockType",
        type: "selector",
        name: "Type",
        options: [
          {key: "TEXT", name: "Texte"},
          {key: "FORM", name: "Formulaire"},
          {key: "PDFS", name: "PDFs"},
          {key: "VIEW", name: "Plan"},
        ],
      },
      // {
      //   group: "INFO",
      //   key: "formTemplate",
      //   name: "Modèle de formulaire",
      //   type: "combo",
      //   options: formTemplates,
      //   condition: {
      //     type: "showIfEqual",
      //     key: "blockType",
      //     values: ["FORM"],
      //   },
      // },
      {
        group: "INFO",
        key: "zone",
        name: "Fond de plan",
        type: "combo",
        options: zones,
        condition: {
          type: "showIfEqual",
          key: "blockType",
          values: ["VIEW"],
        },
      },
      {
        group: "DATA",
        key: "data",
        type: "blockData",
      },
    ],
  };

  return kvt;
}
