import {useEffect, useRef} from "react";
import {useSelector} from "react-redux";

import useSelectedReport from "Features/reports/hooks/useSelectedReport";
import useBlocksByReport from "../hooks/useBlocksByReport";

import {Box} from "@mui/material";
import SectionBlockContainer from "./SectionBlockContainer";

export default function ViewerReport() {
  const blockRefs = useRef({});

  // data

  const report = useSelectedReport({fetchData: true});
  const blocks = useBlocksByReport(report, {fetchData: true, sortBlocks: true});

  const selectedBlockId = useSelector((s) => s.blocks.selectedBlockId);

  console.log("selectedBlockId", selectedBlockId, blockRefs.current);

  // effect

  useEffect(() => {
    if (selectedBlockId && blockRefs.current[selectedBlockId]) {
      console.log("scroll to", selectedBlockId);
      blockRefs.current[selectedBlockId].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedBlockId]);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        p: 6,
        "&>*": {mb: 2},
        overflowY: "auto",
      }}
    >
      {blocks.map((block) => {
        const selected = block.id === selectedBlockId;
        return (
          <SectionBlockContainer
            ref={(el) => (blockRefs.current[block.id] = el)}
            key={block.id}
            block={block}
            selected={selected}
          />
        );
      })}
    </Box>
  );
}
