import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {nanoid, unwrapResult} from "@reduxjs/toolkit";

import {
  setIsEditingSample,
  setSelectedSampleId,
  createSample,
  updateSample,
} from "../samplesSlice";

import useSampleInSelectionPanel from "../hooks/useSampleInSelectionPanel";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

export default function ActionsSampleInSelectionPanel() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  // strings

  const createS = "Créer";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const isEditing = useSelector((s) => s.samples.isEditingSample);
  const sample = useSampleInSelectionPanel();
  const scope = useSelectedScope();

  // state

  const [loading, setLoading] = useState(false);

  // helper

  const isNew = !sample?.id;

  // helper - saveLabel

  const saveLabel = isNew ? createS : saveS;

  // helpers - functions

  async function create() {
    if (loading) return;
    try {
      setLoading(true);
      const newSample = {...sample, clientId: nanoid(), scopeId: scope?.id};
      const result = await dispatch(
        createSample({
          accessToken,
          sample: newSample,
        })
      );
      const {item} = unwrapResult(result);
      dispatch(setSelectedSampleId(item?.id));
      setLoading(false);
    } catch (error) {
      console.error("[ActionsSampleInSelectionPanel] error", error);
      setLoading(false);
    }
  }

  async function update() {
    if (loading) return;
    setLoading(true);
    console.log("update sample", sample);
    await dispatch(updateSample({accessToken, updates: sample}));
    dispatch(setIsEditingSample(false));
    setLoading(false);
  }

  // handlers

  function handleCancelClick() {
    dispatch(setIsEditingSample(false));
  }

  async function handleSaveClick() {
    if (isNew) {
      await create();
    } else {
      await update();
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {(isEditing || isNew) && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
}
