export default function useKvtNomenclature() {
  const kvt = {
    key: "nomenclature",
    fields: [
      {
        key: "name",
        name: "Nom de la nomenclature",
        type: "text",
      },
      {
        key: "tree",
        name: "Catégories",
        type: "nomenclatureTree",
      },
    ],
  };

  return kvt;
}
