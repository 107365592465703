export default function getListItemsReports(reports) {
  const items = [];

  const reportsByRef = {};

  reports.sort((a, b) => {
    return b.version - a.version;
  });

  reports.forEach((report) => {
    if (reportsByRef[report.clientReference]) {
      reportsByRef[report.clientReference].push(report);
    } else {
      reportsByRef[report.clientReference] = [report];
    }
  });

  Object.entries(reportsByRef).forEach(([clientReference, reports]) => {
    // report main item
    const mainReport = reports[0];
    const item = {...mainReport, versions: reports};
    items.push(item);
  });

  return items;
}
