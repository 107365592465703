import {useSelector, useDispatch} from "react-redux";

import {setOpenZonesPanel} from "../zonesSlice";

import {Box, Typography, IconButton} from "@mui/material";
import {Image as ZoneIcon, ArrowDropDown as Down} from "@mui/icons-material";
import {lighten} from "@mui/material/styles";

export default function SelectorZoneInTopbarOverview() {
  const dispatch = useDispatch();

  // helper - label

  let label = "Zones";

  // handlers

  function handleClick() {
    dispatch(setOpenZonesPanel(true));
  }

  return (
    <Box
      sx={{
        width: 1,
        height: "26px",
        display: "flex",
        justifyContent: "space-between",
        color: "common.white",
        alignItems: "center",
        bgcolor: (theme) => lighten(theme.palette.common.caplaBlack, 0.2),
        borderRadius: "13px",
        p: 0,
        pl: 1,
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <ZoneIcon sx={{mr: 1, fontSize: 15}} />

      <Typography noWrap sx={{fontSize: 12}} color="inherit">
        {label}
      </Typography>

      <IconButton size="small" onClick={handleClick} color="inherit">
        <Down color="inherit" fontSize="small" />
      </IconButton>
    </Box>
  );
}
