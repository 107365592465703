import getNextNum from "Utils/getNextNum";
import getNextNumWithSameRank from "Utils/getNextNumWithSameRank";

export default function getOtherCategoryFromCategoryWithChildren(category) {
  const lastChild = category.children[category.children.length - 1];
  const otherNum = getNextNumWithSameRank(lastChild.num);
  const otherCategory = {
    id: category.id + "-other",
    isOther: true,
    isCategory: true,
    num: otherNum,
    name: "Autre",
    children: [],
  };
  return otherCategory;
}
