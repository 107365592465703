import React, {useState, useEffect} from "react";

import {Box, Typography, Dialog, Grid, ClickAwayListener} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";
import SelectorAutocomplete from "./SelectorAutocomplete";

import _sortByNum from "Utils/sortByNum";
import getItemRankFromNum from "Utils/getItemRankFromNum";

export default function FieldCombo({
  name,
  value,
  options,
  onChange,
  onNewOption,
  createLabel,
  canCreate,
  locked,
  sizeKey = 12,
  sortByNum = false,
}) {
  // sorted options

  if (sortByNum) {
    options = options
      .map((option) => {
        const rank = getItemRankFromNum(option);
        const name = "___".repeat(rank - 1) + option.name;
        return {...option, name};
      })
      .sort((a, b) => _sortByNum(a.num, b.num));
  }

  // state

  const [open, setOpen] = useState(false);

  // handlers - dialog

  function handleOpen() {
    if (!locked) setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  // handlers - selector

  function handleChange(v) {
    onChange(v);
    setOpen(false);
  }

  return (
    <Box>
      {open ? (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <Box sx={{px: 0.5, display: "flex", alignItems: "center"}}>
            <SelectorAutocomplete
              name={name}
              value={value}
              options={options}
              onChange={handleChange}
              onClose={handleClose}
              onNewOption={onNewOption}
              createLabel={createLabel}
              canCreate={canCreate}
            />
          </Box>
        </ClickAwayListener>
      ) : (
        <Grid
          container
          sx={{
            pr: 1,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Grid
            item
            xs={sizeKey}
            sx={{py: 0.25, pl: 1, bgcolor: "background.default"}}
          >
            <Typography sx={{fontSize: 13, color: "text.secondary"}}>
              {name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12 - sizeKey}
            sx={{
              py: 0.25,
              pl: 1,
              borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                color: locked ? "text.secondary" : "unset",
              }}
              onClick={handleOpen}
            >
              <Typography sx={{fontSize: 13}} noWrap>
                {value?.name}
              </Typography>
              {!locked && <Down fontSize="small" />}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
