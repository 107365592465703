import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import {useSelector} from "react-redux";

export default function useMaterialsGroupsByScene(scene, options) {
  const materialsGroupsMap = useSelector((s) => s.materials.materialsGroupsMap);
  const scope = useSelectedScope();

  let materialsGroups = Object.values(materialsGroupsMap);

  if (scene) {
    materialsGroups = materialsGroups.filter((g) => g.sceneId === scene.id);
  }

  if (options?.filterByScope && scope?.id && scope?.id !== "all") {
    materialsGroups = materialsGroups.filter(
      (g) =>
        !scope?.data?.materialsGroupsIds ||
        scope?.data?.materialsGroupsIds.includes(g.id)
    );
  }

  if (options?.filterByMaterialsGroupId) {
    materialsGroups = materialsGroups.filter(
      (g) => g.id === options.filterByMaterialsGroupId
    );
  }

  if (options.sortByName) {
    materialsGroups = materialsGroups.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  return materialsGroups ?? [];
}
