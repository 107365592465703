import {useSelector, useDispatch} from "react-redux";

import {
  setSelectedMaterialCategoryId,
  setSelectedMaterialsClientIds,
  setSearchTextMaterials,
} from "../materialsSlice";
import {setOpenSelectionPanel} from "Features/selection/selectionSlice";

import useMaterialsByScene from "../hooks/useMaterialsByScene";
import useHandleMaterialClick from "../hooks/useHandleMaterialClick";
import useNomenclatureByMaterialsGroupId from "../hooks/useNomenclatureByMaterialGroupId";

import {Box} from "@mui/material";
import ListMaterials from "./ListMaterials";
import SearchBarObjectsV2 from "Features/search/components/SearchBarObjectsV2";
import getFoundCategoriesOrMaterials from "../utils/getFoundCategoriesOrMaterials";

export default function SectionMaterials({scene}) {
  const dispatch = useDispatch();

  // data

  const selectedMaterialsGroupId = useSelector(
    (s) => s.materials.selectedMaterialsGroupId
  );
  const nomenclature = useNomenclatureByMaterialsGroupId(
    selectedMaterialsGroupId
  );
  const selectedMaterialCategoryId = useSelector(
    (s) => s.materials.selectedMaterialCategoryId
  );
  const selectedMaterialsClientIds = useSelector(
    (s) => s.materials.selectedMaterialsClientIds
  );
  const multipleSelection = useSelector(
    (s) => s.materials.multipleSelectionMaterials
  );

  const materials = useMaterialsByScene(scene, {
    filterByMaterialsGroupId: selectedMaterialsGroupId,
    fetchData: true,
    withRooms: true,
  });

  const searchTextMaterials = useSelector(
    (s) => s.materials.searchTextMaterials
  );

  // data - handler

  const handleMaterialClick = useHandleMaterialClick();

  // helpers

  const filteredMaterials = getFoundCategoriesOrMaterials(materials, {
    searchText: searchTextMaterials,
  });

  // handlers

  function handleSelectedMaterialCategoryIdChange(categoryId) {
    dispatch(setSelectedMaterialCategoryId(categoryId));
  }

  function handleNewMaterial(newMaterial) {
    dispatch(setOpenSelectionPanel(true));
    dispatch(setSelectedMaterialsClientIds([]));
  }

  function handleSearchTextChange(searchText) {
    dispatch(setSearchTextMaterials(searchText));
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <Box sx={{p: 1}}>
        <SearchBarObjectsV2
          searchText={searchTextMaterials}
          onSearchTextChange={handleSearchTextChange}
        />
      </Box>
      <ListMaterials
        materials={filteredMaterials}
        onClick={handleMaterialClick}
        selection={selectedMaterialsClientIds}
        onNewMaterial={handleNewMaterial}
      />
    </Box>
  );
}
