import getItemRankFromNum from "Utils/getItemRankFromNum";

export default function getCategoryOrMaterialItemStyle(item) {
  let bgcolor = "common.white";
  let color = "text.primary";
  let fontWeight = "normal";

  const isCategory = item.isCategory || !item?.materialCategoryId;

  if (isCategory) {
    bgcolor = "background.default";
    const rank = getItemRankFromNum(item);
    if (rank === 1) {
      bgcolor = "grey.500";
    } else if (rank === 2) {
      bgcolor = "grey.400";
    }
  }

  return {bgcolor, color, fontWeight};
}
