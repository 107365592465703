import {useSelector} from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

import SelectorMarkersModel from "Features/markers/components/SelectorMarkersModel";
import ToolbarMarkersMain from "Features/markers/components/ToolbarMarkersMain";
import SectionTitleMarkers from "Features/markers/components/SectionTitleMarkers";
import useIssuesetsInScope from "Features/issues/useIssuesetsInScope";
import Stretch from "Features/ui/components/Stretch";

export default function SceneMarkers() {
  // params

  const {caplaEditor, scene} = useOutletContext();
  const editor3d = caplaEditor?.editor3d;

  // data

  const [_, __, modelsIdsHash] = useIssuesetsInScope();
  const fullscreen = useSelector((s) => s.ui.fullscreen);
  const os = useSelector((s) => s.ui.openSections);

  // helpers - fullscreen hash

  const fullscreenHash = fullscreen ? "1" : "0";

  // helpers - openSections

  const openSections = useSelector((s) => s.ui.openSections);
  const osHash = Object.values(openSections).join("-");

  if (os.outlet)
    return (
      <Box sx={{bgcolor: "common.white", width: 1, height: 1}}>
        <Box sx={{height: "68px"}} />
        <SectionTitleMarkers />
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Box sx={{px: 3, mt: 1, mr: 2, width: "60%", maxWidth: "500px"}}>
            <SelectorMarkersModel scene={scene} editor3d={editor3d} />
          </Box>
        </Box>
        <ToolbarMarkersMain scene={scene} editor3d={editor3d} />
      </Box>
    );
  return <></>;
}
