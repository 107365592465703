import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  fetchBlocksService,
  createBlockService,
  fetchBlockService,
  updateBlockService,
  deleteBlockService,
} from "./servicesBlocks";

// BLOCKS

export const createBlock = createAsyncThunk(
  "blocks/createBlock",
  createBlockService
);

export const fetchBlocks = createAsyncThunk(
  "blocks/fetchBlocks",
  fetchBlocksService
);

export const fetchBlock = createAsyncThunk(
  "blocks/fetchBlock",
  fetchBlockService
);

export const updateBlock = createAsyncThunk(
  "blocks/updateBlock",
  updateBlockService
);
export const deleteBlock = createAsyncThunk(
  "blocks/deleteBlock",
  deleteBlockService
);

const blocksSlice = createSlice({
  name: "blocks",
  initialState: {
    //
    blocksMap: {},
    //
    selectedBlockId: null,
    editedBlock: null,
    isEditingBlock: false,
    newBlock: {},
    formTabBlock: "INFO",
    //
    fetchedBlocksByReportId: {},
    //
    isEditingListBlocks: false,
    //
  },
  reducers: {
    setSelectedBlockId: (state, action) => {
      state.selectedBlockId = action.payload;
    },
    setNewBlock: (state, action) => {
      state.newBlock = action.payload;
    },
    setEditedBlock: (state, action) => {
      state.editedBlock = action.payload;
    },
    setIsEditingBlock: (state, action) => {
      state.isEditingBlock = action.payload;
    },
    setFormTabBlock: (state, action) => {
      state.formTabBlock = action.payload;
    },
    //
    setIsEditingListBlocks: (state, action) => {
      state.isEditingListBlocks = action.payload;
    },
    setTempSortedBlocksIds: (state, action) => {
      state.tempSortedBlocksIds = action.payload;
    },
  },
  extraReducers: {
    // BLOCKS

    [createBlock.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.blocksMap[item.id] = item;
    },
    [fetchBlocks.fulfilled]: (state, action) => {
      const {items, reportId} = action.payload;
      items.forEach((item) => {
        state.blocksMap[item.id] = item;
      });
      state.fetchedBlocksByReportId[reportId] = "done";
    },
    [fetchBlock.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.blocksMap[item.id] = item;
    },
    [updateBlock.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.blocksMap[item.id] = item;
    },
    [deleteBlock.fulfilled]: (state, action) => {
      const blockId = action.payload;
      delete state.blocksMap[blockId];
    },
  },
});
export const {
  setSelectedBlockId,
  setNewBlock,
  setEditedBlock,
  setIsEditingBlock,
  setFormTabBlock,
  //
  setIsEditingListBlocks,
  setTempSortedBlocksIds,
  //
} = blocksSlice.actions;

export default blocksSlice.reducer;
