import {useParams, useLocation} from "react-router-dom";

export default function usePageType() {
  const params = useParams();
  const location = useLocation();
  const path = location.pathname;

  const organisationId = params.organisationId;

  let pageType;
  if (path === "/") {
    pageType = "HOME";
  } else if (path.includes("auth")) {
    pageType = "AUTH";
  } else if (path.includes("toolbox")) {
    pageType = "TOOLBOX";
  } else if (path.includes("organisations")) {
    pageType = organisationId ? "ORGANISATION" : "ORGANISATIONS";
  } else if (path.includes("order")) {
    pageType = "ORDER";
  } else if (path.includes("profile")) {
    pageType = "PROFILE";
  } else if (path.includes("assistantAAA/")) {
    pageType = "ASSISTANT";
  } else if (params.sceneId || params.shareLink) {
    pageType = "SCENE";
  } else if (path === "/bimboxes/new") {
    pageType = "NEW_SCENE";
  } else if (path.includes("/newColoring")) {
    pageType = "NEW_COLORING";
  } else if (path.includes("/colorings/")) {
    pageType = "COLORING";
  } else if (path === "/colorings") {
    pageType = "COLORINGS";
  } else if (path === "/worksites") {
    pageType = "WORKSITES";
  } else if (path.includes("/library")) {
    pageType = "LIBRARY";
  } else if (path.includes("/pricings")) {
    pageType = "PRICINGS";
  }

  return pageType;
}
