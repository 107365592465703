import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import {Button, Box, Paper} from "@mui/material";
import {Launch} from "@mui/icons-material";

import useSelectedScene from "../useSelectedScene";

import useSceneModulePathMap from "Features/navigation/useSceneModulePathMap";
import {setOpenSections} from "Features/ui/uiSlice";

export default function ButtonOpenScene() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // string

  const openS = "Ouvrir la bimbox";

  // data

  const scene = useSelectedScene();
  const pathMap = useSceneModulePathMap({
    sceneId: scene?.id ? scene.id : scene?.clientId,
  });

  // helper

  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // handlers

  function handleClick() {
    const url = pathMap["ONBOARDING"];
    navigate(url);
    dispatch(
      setOpenSections({
        outlet: false,
        fixedViewersBox: true,
        viewer3D: false,
        callToAction: false,
      })
    );
  }

  return (
    <Paper
      elevation={12}
      sx={{
        background,
        color: "common.white",
        height: 36,
        borderRadius: 18,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        px: 1,
        //border: "2px solid white",
      }}
    >
      <Button
        onClick={handleClick}
        color="inherit"
        sx={{height: 36, borderRadius: 18}}
        startIcon={<Launch />}
      >
        {openS}
      </Button>
    </Paper>
  );
}
