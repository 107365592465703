import {useSelector} from "react-redux";
import useSelectedMaterialsGroup from "./useSelectedMaterialsGroup";

export default function useSelectedMaterialsNomenclature() {
  // const id = useSelector((s) => s.materials.selectedMaterialsNomenclatureId);
  const group = useSelectedMaterialsGroup();
  const nomenclatures = useSelector((s) => s.materials.nomenclatures);
  //
  const id = group?.data?.materialsNomenclatureId;
  const nomenclature = nomenclatures?.find((n) => n.id === id);

  return nomenclature;
}
