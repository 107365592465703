export default function stateToRemoteReportTemplate(template) {
  const remoteTemplate = {...template};

  // organisation
  remoteTemplate.organisation = template.organisationId;
  delete remoteTemplate.organisationId;

  // mainFormTemplate
  remoteTemplate.mainFormTemplate = template.mainFormTemplateId;
  delete remoteTemplate.mainFormTemplateId;

  return remoteTemplate;
}
