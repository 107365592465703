// import { StrictMode } from 'react';
import ReactDOM from "react-dom";
import App from "App/App";
import reportWebVitals from "./reportWebVitals";
import {LicenseInfo} from "@mui/x-license-pro";

// MSAL imports
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./authConfig";

// i18n

// eslint-disable-next-line
import i18n from "Features/translations/i18n";

// init DB
// import {initDbPromise} from "Database/db";

// function toDataURL(src, callback, outputFormat) {
//   var img = new Image();
//   img.crossOrigin = "Anonymous";
//   img.onload = function () {
//     var canvas = document.createElement("CANVAS");
//     var ctx = canvas.getContext("2d");
//     var dataURL;
//     canvas.height = this.naturalHeight;
//     canvas.width = this.naturalWidth;
//     ctx.drawImage(this, 0, 0);
//     dataURL = canvas.toDataURL(outputFormat);
//     callback(dataURL);
//   };
//   img.src = src;
// }

// Mui

LicenseInfo.setLicenseKey(
  "80da6241851fed633c95af3e42a38fd8Tz0xMDIyMTcsRT0xNzYzODE2NTE0MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI="
);

// msal

const msalInstance = new PublicClientApplication(msalConfig);

// global variables for Revit data exchange

window.CaplaGlobals = {};
window.RevitToCapla = {};
//window.CaplaToRevit = {};

window.CaplaGlobals.viewpoints = [];

window.RevitToCapla.renderAppFromRevit = function () {
  render("revit");
};

window.RevitToCapla.sendViewpoint = function (revitInput) {
  const {id, image, data} = JSON.parse(revitInput);
  window.CaplaGlobals.viewpoints.push({id, image, data});
  // toDataURL(file, (dataURL) =>
  //   window.CaplaGlobals.viewpoints.push({id, image: dataURL, data})
  // );
};

// window.CaplaToRevit.triggerViewpointCapture = function ({id}) {
//   setTimeout(() => {
//     console.log(
//       "In Revit : window.RevitToCapla.sendViewpoint({id,image,data})"
//     );
//     window.RevitToCapla.sendViewpoint({id, file: revitViewpoint, data: "data"});
//   }, 500);
// };

function render(runningIn) {
  // ReactDOM.render(
  //   <StrictMode>
  //     <App pca={msalInstance} runningIn={runningIn} />
  //   </StrictMode>,
  //   document.getElementById("root")
  // );

  ReactDOM.render(
    <App pca={msalInstance} runningIn={runningIn} />,
    document.getElementById("root")
  );

  // concurrent mode to allow translations + positions...
  // ReactDOM.createRoot(document.getElementById("root")).render(
  //   <App pca={msalInstance} runningIn={runningIn} />
  // );
}

render("browser");

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
