import {useState, useEffect} from "react";

import {Box, Typography, List, ListItemButton} from "@mui/material";
import {ArrowForwardIos as Arrow} from "@mui/icons-material";

import ListMaterials from "./ListMaterials";

import getItemRankFromNum from "Utils/getItemRankFromNum";
import ListMaterialsFromNomenclatureParents from "./ListMaterialsFromNomenclatureParents";
import getItemChildrenFromNum from "Utils/getItemChildrenFromNum";
import addOthersToItemsWithNum from "Utils/addOthersToItemsWithNum";
import sortByNum from "Utils/sortByNum";

import getItemsWithChildrenFromNum from "Utils/getItemsWithChildrenFromNum";
import getMaterialsCategoriesWithMaterialClientIds from "Features/materials/utils/getMaterialCategoriesWithMaterialClientIds";
import getItemsMapById from "Utils/getItemsMapById";
import getParentsNums from "Utils/getParentsNums";

export default function ListMaterialsFromNomenclature({
  materials,
  nomenclature,
  selectedMaterialCategoryId,
  onSelectedMaterialCategoryIdChange,
  variant,
  onClick,
  selectedMaterialsClientIds,
  //
  onNewMaterial,
}) {
  const categories = nomenclature?.tree?.items ?? [];

  const categoriesWithChildren = getItemsWithChildrenFromNum(categories);
  const categoriesWithChildrenAndMaterials =
    getMaterialsCategoriesWithMaterialClientIds(
      categoriesWithChildren,
      materials,
      {pullClientIdsToParents: true}
    );

  const materialsByClientId = materials.reduce((acc, material) => {
    acc[material.clientId] = material;
    return acc;
  }, {});

  const categoriesById = getItemsMapById(categoriesWithChildrenAndMaterials);
  const categoriesByNum = categoriesWithChildrenAndMaterials.reduce(
    (acc, category) => {
      acc[category.num] = category;
      return acc;
    },
    {}
  );

  // state

  const [parents, setParents] = useState([]);

  const showedRank = parents.length + 1;
  const lastParent = parents.length > 0 ? parents[parents.length - 1] : null;

  // init parents based on selectedMaterialCategoryId

  useEffect(() => {
    const selectedCategory = categoriesById[selectedMaterialCategoryId];
    if (selectedCategory) {
      const parentNums = getParentsNums(selectedCategory.num);
      const parents = parentNums.map((num) => categoriesByNum[num]);
      setParents([...parents, selectedCategory]);
    }
  }, [selectedMaterialCategoryId]);

  // helpers - items

  let items = getItemChildrenFromNum(
    lastParent,
    categoriesWithChildrenAndMaterials
  );
  items = items.map((item) => ({...item, isCategory: true}));

  // other item
  const otherItem = {
    id: lastParent?.id ? lastParent.id + "-other" : "other",
    //name: `Autre ${lastParent?.name ? `(${lastParent.name})` : ""}`,
    name: "Autre",
    isOther: true,
    parentId: lastParent?.id,
    isCategory: true,
  };
  if (otherItem.id !== "other") {
    otherItem.materialClientIds = materials
      .filter((m) => m.materialCategoryId === lastParent?.id)
      .map((m) => m.clientId);
  } else {
    otherItem.materialClientIds = materials
      .filter((m) => !m.materialCategoryId || m.materialCategoryId === "other")
      .map((m) => m.clientId);
  }

  if (!lastParent?.isOther && (!lastParent || lastParent?.children?.length > 0))
    items = [...items, otherItem];

  // helpers - show materials

  const lastCategory = categoriesByNum[lastParent?.num] || lastParent; // to get materials uptodate when creating/updating materials // lastParent = to handle the case where id==="other"
  const showMaterials =
    (lastCategory && !lastCategory?.children?.length > 0) ||
    lastParent?.isOther;

  // lastParentMaterials
  let lastParentMaterials = [];
  if (!lastParent?.isOther && lastCategory?.materialClientIds) {
    lastParentMaterials = lastCategory?.materialClientIds.map(
      (materialClientId) => {
        return materialsByClientId[materialClientId];
      }
    );
  } else if (lastParent?.id === "other") {
    lastParentMaterials = materials.filter(
      (m) => !m.materialCategoryId || m.materialCategoryId === "other"
    );
  } else if (lastParent?.isOther) {
    lastParentMaterials = materials.filter(
      (m) => m.materialCategoryId === lastParent.parentId
    );
  }

  // handlers

  function handleClick(item) {
    if (item.isCategory && (!item.isOther || item.id === "other")) {
      onSelectedMaterialCategoryIdChange(item?.id);
    }
    if (!item.isMaterial) {
      setParents([...parents, item]);
      return; // WARNING !! v below onClick will not be called
    }
    if (onClick) {
      onClick(item);
    }
  }

  function handleRootClick() {
    setParents([]);
    onSelectedMaterialCategoryIdChange("other");
  }

  function handleParentClick(parent) {
    const parentIndex = parents.findIndex((p) => p.id === parent.id);
    setParents(parents.slice(0, parentIndex + 1));
    onSelectedMaterialCategoryIdChange(parent.id);
  }

  function handleMaterialClick(material) {
    if (onClick) {
      onClick(material);
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        height: 1,
        flexDirection: "column",
        minHeight: 0,
      }}
    >
      <Box sx={{bgcolor: "background.default"}}>
        <ListMaterialsFromNomenclatureParents
          parents={parents}
          onParentClick={handleParentClick}
          onRootClick={handleRootClick}
        />
      </Box>
      <Box
        sx={{
          width: 1,
          flexGrow: 1,
          overflowY: "auto",
          minHeight: 0,
        }}
      >
        <List dense disablePadding sx={{bgcolor: "common.white"}}>
          {items.map((item) => {
            const selected = selectedMaterialsClientIds?.includes(item.id);
            const showArrow = item.children?.length > 0;
            const counter = item.materialClientIds?.length || "";
            return (
              <ListItemButton
                key={item.id + item.num}
                selected={selected}
                disableGutters
                onClick={() => handleClick(item)}
                divider
              >
                <Box
                  sx={{
                    px: 1,
                    display: "flex",
                    alignItems: "center",
                    width: 1,
                    justifyContent: "space-between",
                    color: "grey.400",
                  }}
                >
                  <Typography
                    sx={{
                      color: "text.primary",
                      fontSize: 12,
                      ...(item.isOther && {
                        fontStyle: "italic",
                        color: "text.secondary",
                      }),
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <Typography sx={{fontSize: 12, mr: 1}}>
                      {counter}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        visibility: showArrow ? "visible" : "hidden",
                      }}
                    >
                      <Arrow fontSize="small" color="inherit" />
                    </Box>
                  </Box>
                </Box>
              </ListItemButton>
            );
          })}
        </List>
        {showMaterials && (
          <ListMaterials
            materials={lastParentMaterials}
            onClick={handleMaterialClick}
            selection={selectedMaterialsClientIds}
            onNewMaterial={onNewMaterial}
          />
        )}
      </Box>
    </Box>
  );
}
