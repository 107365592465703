import {useDispatch, useSelector} from "react-redux";

import {setSelectedEntities} from "../organisationsSlice";

import {Box, List, ListItemButton, Typography} from "@mui/material";

import organisationEntitiesMap from "../data/organisationEntitiesMap";
import ContainerEditableLogo from "./ContainerEditableLogo";

export default function PageOrganisationSectionEntitiesSelector({
  organisation,
}) {
  const dispatch = useDispatch();

  // data

  const entitiesKeys = ["FORM_TEMPLATES", "REPORT_TEMPLATES", "NOMENCLATURES"];

  const selectedEntities = useSelector(
    (state) => state.organisations.selectedEntities
  );

  // handlers

  function handleClick(entityKey) {
    dispatch(setSelectedEntities(entityKey));
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        p: 2,
      }}
    >
      <ContainerEditableLogo organisation={organisation} height={100} />
      <List dense sx={{mt: 1}}>
        {entitiesKeys.map((entityKey) => {
          const selected = entityKey === selectedEntities;
          return (
            <ListItemButton
              selected={selected}
              key={entityKey}
              onClick={() => handleClick(entityKey)}
            >
              <Typography
                variant="body2"
                sx={{...(selected && {fontWeight: "bold"})}}
              >
                {organisationEntitiesMap[entityKey]?.name}
              </Typography>
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
}
