import {useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {setSnackbarMessage} from "Features/ui/uiSlice";
import {setSelectedRoomId} from "../roomsSlice";

import useRooms from "../hooks/useRooms";
import useCreateRoom from "../hooks/useCreateRoom";
import useSelectedRoomsGroup from "../hooks/useSelectedRoomsGroup";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  Typography,
  TextField,
} from "@mui/material";

import ListItemsDraggable from "Components/ListItemsDraggable";
import useAddRoomLabelToPdf from "Features/pdf/hooks/useAddRoomLabelToPdf";

export default function DialogCreateRoom({
  open,
  onClose,
  scene,
  initName,
  roomLabel,
}) {
  const dispatch = useDispatch();

  // string

  const title = "Ajouter une pièce";

  const createS = "Créer";

  // state

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(initName);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setName(initName);
  }, [initName]);

  // data

  const selectedGroup = useSelectedRoomsGroup();
  const selectedRoomParentId = useSelector((s) => s.rooms.selectedRoomParentId);
  const roomsMap = useSelector((s) => s.rooms.roomsMap);
  const roomsByClientId = useRooms({variantByClientId: true});

  // data - function

  const createRoom = useCreateRoom();
  const addRoomLabelToPdf = useAddRoomLabelToPdf();

  // helpers

  const parent = selectedRoomParentId
    ? roomsMap[selectedRoomParentId]
    : selectedGroup;

  const parentChildrenClientIds = parent.childrenClientIds ?? [];

  // helpers - items

  const items = parentChildrenClientIds.map(
    (clientId) => roomsByClientId[clientId]
  );
  const newItem = {clientId: "new", name, isNew: true};
  if (!index) {
    items.push(newItem);
  } else {
    items.splice(index - 1, 0, newItem);
  }

  // helper

  const groupLabel = selectedGroup?.fromScene
    ? selectedGroup.name
    : selectedGroup.name + " ✨";

  // handlers

  function handleClose() {
    onClose();
  }

  function handleOrderChange(newOrder) {
    const index = newOrder.indexOf("new");
    console.log("index", index);
    setIndex(index + 1);
  }

  function handleNewItemNameChange(name) {
    setName(name);
  }

  async function handleSave() {
    const newRoom = {clientId: nanoid(), name, roomsGroupId: selectedGroup.id};
    console.log("[DialogCreateRoom] room", newRoom);
    if (loading) return;
    setLoading(true);
    const room = await createRoom({
      room: newRoom,
      parentId: selectedRoomParentId,
      index,
    });
    setLoading(false);

    // room Label

    if (roomLabel?.bbox) {
      const newRoomLabel = {
        ...roomLabel,
        label: name,
        roomClientId: room.clientId,
      };
      addRoomLabelToPdf(newRoomLabel);
    }

    //
    const message = `${room.name} ajouté`;
    const triggeredAt = Date.now();
    dispatch(setSnackbarMessage({message, triggeredAt}));

    //
    dispatch(setSelectedRoomId(room.id));

    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{border: (theme) => `1px solid ${theme.palette.divider}`}}>
          <Box sx={{bgcolor: "background.default", p: 0.5}}>
            <Typography sx={{fontSize: 13}}>{parent.name}</Typography>
          </Box>
          <Box sx={{width: 1}}>
            <ListItemsDraggable
              items={items}
              onOrderChange={handleOrderChange}
              id="clientId"
              newItemName={name}
              onNewItemNameChange={handleNewItemNameChange}
              draggable={true}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleSave}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
