import {useState} from "react";

import useActionsSamplesMap from "../hooks/useActionsSamplesMap";

import SectionGenericActions from "Components/SectionGenericActions";

//import DialogCreateSamplesGroup from "./DialogCreateSamplesGroup";
//import DialogCreateSample from "./DialogCreateSample";
//import DialogFsEditSamplesGroup from "./DialogFsEditSamplesGroup";
import DialogDeleteSample from "./DialogDeleteSample";
//import DialogImportSamplesFromExcel from "./DialogImportSamplesFromExcel";

export default function SectionActionsSamples({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  const [openDialogCreateSamplesGroup, setOpenDialogCreateSamplesGroup] =
    useState(false);

  const [openDialogDeleteSamplesGroup, setOpenDialogDeleteSamplesGroup] =
    useState(false);
  const [openDialogCreateSample, setOpenDialogCreateSample] = useState(false);
  //
  const [openDialogDeleteSample, setOpenDialogDeleteSample] = useState(false);
  //
  const [
    openDialogImportSamplesFromExcel,
    setOpenDialogImportSamplesFromExcel,
  ] = useState(false);
  //
  const [openDialogEditSamplesGroup, setOpenDialogEditSamplesGroup] =
    useState(false);

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
    createSamplesGroup: () => setOpenDialogCreateSamplesGroup(true),
    deleteSamplesGroup: () => setOpenDialogDeleteSamplesGroup(true),
    createSample: () => setOpenDialogCreateSample(true),
    deleteSample: () => setOpenDialogDeleteSample(true),
    importExcelSamples: () => setOpenDialogImportSamplesFromExcel(true),
    editSamplesGroup: () => setOpenDialogEditSamplesGroup(true),
  };

  // handlers

  function handleClick(actionKey) {
    console.log("trigger", actionKey);
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // handlers - close Dialogs

  function handleCloseDialogCreateSamplesGroup() {
    setOpenDialogCreateSamplesGroup(false);
  }
  function handleCloseDialogCreateSample() {
    setOpenDialogCreateSample(false);
  }

  // data - handlers

  const actionsMap = useActionsSamplesMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {/* {openDialogCreateSample && (
        <DialogCreateSample
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateSample}
          onClose={handleCloseDialogCreateSample}
        />
      )} */}

      {openDialogDeleteSample && (
        <DialogDeleteSample
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogDeleteSample}
          onClose={() => setOpenDialogDeleteSample(false)}
        />
      )}

      {/* {openDialogImportSamplesFromExcel && (
        <DialogImportSamplesFromExcel
          open={openDialogImportSamplesFromExcel}
          onClose={() => {
            setOpenDialogImportSamplesFromExcel(false);
          }}
          scene={scene}
        />
      )} */}
    </>
  );
}
