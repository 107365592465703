import {useSelector} from "react-redux";

import useSelectedSample from "./useSelectedSample";

export default function useSampleInSelectionPanel() {
  //
  const selectedSample = useSelectedSample();
  const editedSample = useSelector((s) => s.samples.editedSample);
  const newSample = useSelector((s) => s.samples.newSample);
  const isEditingSample = useSelector((s) => s.samples.isEditingSample);

  // helper - sample

  let sample = selectedSample ?? newSample;
  if (isEditingSample) sample = editedSample;

  // return

  return sample;
}
