import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  Paper,
  IconButton,
  Menu,
  ListItemButton,
} from "@mui/material";
import {
  Close,
  ArrowDropDown as Down,
  Image,
  ViewStream as Overview,
  DocumentScanner as Report,
} from "@mui/icons-material";

import {setOpenSections, setSelectedFixedViewer} from "Features/ui/uiSlice";
import zoomInOut from "Features/ui/zoomInOut";
import {setIs3dEnabled} from "Features/viewer3D/viewer3DSlice";

export default function TogglePdf3d({caplaEditor}) {
  const dispatch = useDispatch();
  console.log("TogglePdf3d");

  //strings

  const view3DS = "Vue 3D";
  const viewPDF3DS = "PDF et 3D";
  // const viewImageS = "Fonds de plan";
  const closeViewersS = "Fermer les visionneuses";

  // helpers - fixedViewer

  const fixedViewers = [
    {
      key: "PDF",
      icon: (
        <Typography sx={{fontSize: 12, color: "common.white"}}>PDF</Typography>
      ),
      label: "PDF",
    },
    {
      key: "ZONE",
      icon: <Image color="inherit" sx={{fontSize: 16}} />,
      label: "Fond de plan",
    },
    {
      key: "REPORT",
      icon: <Report color="inherit" sx={{fontSize: 16}} />,
      label: "Rapport",
    },
  ];

  // data

  const os = useSelector((s) => s.ui.openSections);
  const selectedMeasurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );
  const selectedFixedViewerKey = useSelector((s) => s.ui.selectedFixedViewer);
  const selectedFixedViewer = fixedViewers.find(
    ({key}) => key === selectedFixedViewerKey
  );

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // helpers

  let mode;
  if (os.fixedViewersBox) mode = "PDF";
  if (os.viewer3D) mode = "3D";
  if (os.fixedViewersBox && os.viewer3D) mode = "MX";
  // if (os.callToAction) mode = "IMAGE";

  // helpers - colors

  // const lightPink = (theme) => lighten(theme.palette.primary.flash, 0.4);
  const lightPink = "secondary.main";

  // handlers

  function handleModeChange(mode) {
    const openSections = {...os};
    if (mode === "PDF") {
      openSections.fixedViewersBox = true;
      openSections.viewer3D = false;
    } else if (mode === "3D") {
      openSections.fixedViewersBox = false;
      openSections.viewer3D = true;
      dispatch(setIs3dEnabled(true));
    } else if (mode === "MX") {
      openSections.fixedViewersBox = true;
      openSections.viewer3D = true;
      dispatch(setIs3dEnabled(true));
    }
    // openSections.callToAction = newMode.includes("IMAGE");
    // if (mode.length === 0) openSections.outlet = true;
    // else openSections.outlet = false;
    dispatch(setOpenSections(openSections));
    // caplaEditor?.setOpenSections(openSections, selectedMeasurementIds);
  }

  function handleDownClick(e) {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  }

  function handleFixedViewerClick(fixedViewer) {
    dispatch(setSelectedFixedViewer(fixedViewer.key));
    setAnchorEl(null);
  }

  function handleCloseViewer() {
    dispatch(
      setOpenSections({
        ...os,
        outlet: true,
      })
    );
  }

  return (
    <>
      <Menu
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        sx={{m: 0, p: 0}}
      >
        {fixedViewers.map((fixedViewer) => {
          const selected = fixedViewer.key === selectedFixedViewerKey;
          return (
            <ListItemButton
              selected={selected}
              sx={{p: 0, m: 0}}
              key={fixedViewer.key}
              onClick={() => handleFixedViewerClick(fixedViewer)}
            >
              <Box sx={{px: 1, py: 0.5, display: "flex", alignItems: "center"}}>
                <Typography variant="body2" sx={{ml: 1}}>
                  {fixedViewer.label}
                </Typography>
              </Box>
            </ListItemButton>
          );
        })}
      </Menu>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: 0.5,
          height: 24,
        }}
      >
        <Paper
          // elevation={12}
          sx={{
            bgcolor: "common.caplaBlack",
            // p: 0.5,
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            color: "divider",
          }}
        >
          <ToggleButtonGroup
            exclusive
            value={mode}
            onChange={(_, v) => handleModeChange(v)}
            size="small"
            sx={{
              height: "24px",
              // bgcolor: "primary.flash",
              // color: "common.white",
            }}
          >
            <ToggleButton
              value="PDF"
              sx={{
                //width: "24px",
                "&.Mui-selected": {
                  bgcolor: lightPink,
                  "&:hover": {
                    bgcolor: lightPink,
                  },
                },
              }}
            >
              <Box sx={{display: "flex", alignItems: "center"}}>
                <Tooltip title={selectedFixedViewer.label}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "common.white",
                    }}
                  >
                    {selectedFixedViewer.icon}
                  </Box>
                </Tooltip>
                <IconButton
                  sx={{color: "common.white", width: 18, height: 18, ml: 1}}
                  onClick={handleDownClick}
                >
                  <Down color="inherit" />
                </IconButton>
              </Box>
            </ToggleButton>

            <ToggleButton
              value="3D"
              sx={{
                "&.Mui-selected": {
                  bgcolor: lightPink,
                  "&:hover": {
                    bgcolor: lightPink,
                  },
                },
              }}
            >
              <Tooltip title={view3DS}>
                <Box sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{fontSize: 12, color: "common.white"}}>
                    3D
                  </Typography>
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value="MX"
              sx={{
                "&.Mui-selected": {
                  bgcolor: lightPink,
                  "&:hover": {
                    bgcolor: lightPink,
                  },
                },
              }}
            >
              <Tooltip title={viewPDF3DS}>
                <Box sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{fontSize: 12, color: "common.white"}}>
                    MX
                  </Typography>
                </Box>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Paper>

        {/* <Tooltip title={closeViewersS}>
        <Box
          sx={{
            ml: 1,
            color: "common.caplaBlack",
            //bgcolor: "common.caplaBlack",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            width: 24,
            height: 24,
          }}
        >
          <IconButton color="inherit" size="small" onClick={handleCloseViewer}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip> */}
      </Box>
    </>
  );
}
