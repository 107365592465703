export default function getRoomsByMaterialClientIdFromRooms(rooms) {
  // edge case 1

  if (!rooms) return {};

  // main
  const roomsByMaterialClientId = {};
  rooms.forEach((room) => {
    const {materialsData} = room;
    materialsData?.forEach((item) => {
      const {materialsClientIds} = item;
      materialsClientIds.forEach((materialClientId) => {
        if (!roomsByMaterialClientId[materialClientId]) {
          roomsByMaterialClientId[materialClientId] = [];
        }
        roomsByMaterialClientId[materialClientId].push(room);
      });
    });
  });
  return roomsByMaterialClientId;
}
