import {Box, Typography} from "@mui/material";

export default function SectionRoomsMaterialsOverview() {
  // strings

  const selectS = "Sélectionnez une pièce";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 1,
        height: 1,
      }}
    >
      <Typography variant="h6" color="text.secondary">
        {selectS}
      </Typography>
    </Box>
  );
}
