import useMeasurementsByRoom from "./useMeasurementsByRoom";
import useRoomsBySceneWithMsts from "Features/rooms/hooks/useRoomsBySceneWithMsts";

import sortByNum from "Features/ressources/utils/sortByNum";

export default function useMeasurementsByRoomForDatagrid(scene, mode) {
  const msByR = useMeasurementsByRoom(scene, mode);
  let rooms = useRoomsBySceneWithMsts(scene);

  // rooms & sectors

  const sectorsAndRooms = [];
  rooms = rooms.sort((a, b) => sortByNum(a.num, b.num));
  rooms.forEach((room) => {
    const sector = sectorsAndRooms.find((s) => s.code === room.sector);
    if (!sector)
      sectorsAndRooms.push({
        id: room.sector,
        code: room.sector,
        type: "sector",
      });
    sectorsAndRooms.push({...room, type: "room"});
  });

  // result

  const result = sectorsAndRooms.map((r) => {
    const mByR = msByR.find((m) => m.roomId === r.id);
    if (r.type === "room") {
      const heights = mByR?.heights ? mByR.heights : [];
      const sumHeights = heights.reduce((prev, ac) => ac + prev, 0);
      const average = heights?.length > 0 ? sumHeights / heights.length : 0;
      return {
        ...r,
        perimeter: mByR?.length.toFixed(1),
        surface: mByR?.area.toFixed(1),
        envelop: mByR?.areaV.toFixed(1),
        height: average.toFixed(2),
      };
    } else {
      return r;
    }
  });

  return result;
}
