// import {useEffect} from "react";
import {useSelector} from "react-redux";

// import {
//   setSelectedMeasurementIds,
//   setSelectedMeasurementId,
// } from "Features/measurements/measurementsSlice";
import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";
import useSelectedRessource from "Features/ressources/hooks/useSelectedRessource";
import useSelectedZone from "Features/zones/hooks/useSelectedZone";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useSelectedMeasurement from "Features/measurements/useSelectedMeasurement";
import useTempMeasurement from "Features/measurements/useTempMeasurement";
import useSelectedElementType from "Features/elementTypes/hooks/useSelectedElementType";
import useSelectedPackage from "Features/elementPackages/useSelectedPackage";
import useSelectedMarker from "Features/markers/hooks/useSelectedMarker";
import useSelectedIssue from "Features/issues/useSelectedIssue";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";
import useSelectedScene from "Features/scenes/useSelectedScene";
import useSelectedInput from "Features/inputs/hooks/useSelectedInput";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useSelectedIssueInModule from "Features/issues/useSelectedIssueInModule";

import useSelectedRoom from "Features/rooms/hooks/useSelectedRoom";
import useSelectedRoomInMaterials from "Features/materials/hooks/useSelectedRoomInMaterials";

import useSelectedSector from "Features/sectors/hooks/useSelectedSector";
import useAnnotatedPdfModel from "Features/annotatedPdf/hooks/useAnnotatedPdfModel";
// import useSceneMeasurements from "Features/measurements/useSceneMeasurements";
import useSelectedMaterial from "Features/materials/hooks/useSelectedMaterial";
import useSelectedSurvey from "Features/surveys/hooks/useSelectedSurvey";
import useSelectedArticle from "Features/articles/hooks/useSelectedArticle";
import useSelectedArticlesGroup from "Features/articles/hooks/useSelectedArticlesGroup";
import useSelectedSample from "Features/samples/hooks/useSelectedSample";
import useSelectedReport from "Features/reports/hooks/useSelectedReport";
import useSelectedBlock from "Features/reportBlocks/hooks/useSelectedBlock";
import useSelectedRoomsGroup from "Features/rooms/hooks/useSelectedRoomsGroup";

// import useSelectedSharedbox from "Features/sharedboxes/hooks/useSelectedSharedbox";

export default function useSelection({
  scene,
  caplaEditor,
  viewer,
  forceSelectionType,
}) {
  // const dispatch = useDispatch();

  let selection = {};

  // strings

  const newSceneS = "Nouvelle Bimbox";
  const newRessourceS = "Nouvel article";
  const newRoomS = "Nouvelle zone";
  const newSectorS = "Nouveau secteur";
  const sharedSceneS = "Bimbox partagée";
  const newSharedboxS = "Nouveau partage";
  const newMaterialS = "Nouveau matériau";
  const nameVoid = "";
  const newPhaseS = "Nouvelle phase";
  const newSurveyS = "Nouvelle visite";
  const newMarkerS = "Nouveau repère";
  const newMeasurementS = "Nouveau repérage";
  const newPackageS = "Nouveau calque de repérage";
  const newSampleS = "Nouveau prélèvement";
  const newReportS = "Nouveau rapport";
  const newBlockS = "Nouveau bloc";

  // data - clicked object

  const clickedObject = useSelector((s) => s.viewer3D.clickedObject);
  // console.log("clickedObject", clickedObject);
  // const clickedType = clickedObject?.type;
  // const clickedId = clickedObject?.entityID;

  // data

  const pageType = usePageType();
  const sceneModule = useSceneModule();

  // data - models

  const models = useSelector((s) => s.viewer3D.models);

  // data - selection

  const selectedMarker = useSelectedMarker();
  const selectedPdfModel = useSelectedPdfModel();
  const tempInput = useSelector((s) => s.inputs.tempInput);
  const annotatedPdfModel = useAnnotatedPdfModel();
  const selectedInput = useSelectedInput();

  // data - sector
  const selectedSector = useSelectedSector(scene);
  const tempSector = useSelector((s) => s.sectors.tempSector);

  // data - room
  const selectedRoomsGroup = useSelectedRoomsGroup();
  const selectedRoom = useSelectedRoom(scene);
  const tempRoom = useSelector((s) => s.rooms.tempRoom);
  const selectedRoomParentId = useSelector((s) => s.rooms.selectedRoomParentId);

  // data - material
  const selectedMaterial = useSelectedMaterial(scene);
  const newMaterial = useSelector((s) => s.materials.tempMaterial);
  const selectedRoomInMaterials = useSelectedRoomInMaterials();
  const materialsViewBy = useSelector((s) => s.materials.materialsViewBy);
  const isCreatingRoomMaterial = useSelector(
    (s) => s.materials.isCreatingRoomMaterial
  );

  // data - sample
  const selectedSample = useSelectedSample();
  const newSample = useSelector((s) => s.samples.newSample);

  // data - scene

  const selectedScene = useSelectedScene();

  // data - home selection

  const homeViewMode = useSelector((s) => s.ui.homeViewMode);

  // data - selected ressource

  const selectedRessource = useSelectedRessource(scene);

  // data - selected article

  const selectedArticle = useSelectedArticle(scene);
  const selectedArticlesGroup = useSelectedArticlesGroup();

  // data - element type

  const selectedElementType = useSelectedElementType(scene);

  // data - selected zone

  let selectedZone = useSelectedZone(scene);
  const zonesMap = useZonesByScene(scene, {variant: "map"});
  let clickedZone;
  if (["IMAGE_ENTITY", "IMAGE_PART_ENTITY"].includes(clickedObject?.type)) {
    const clickedImageModel = models.find(
      (m) => m.id === clickedObject?.modelId
    );
    clickedZone = zonesMap[clickedImageModel?.fromModel?.zoneId];
  }
  if (forceSelectionType === "ZONE" && clickedZone) selectedZone = clickedZone;

  // data - selected package
  const selectedPackage = useSelectedPackage();

  // data - selected issue

  const selectedIssue = useSelectedIssue();
  const selectedIssueInModule = useSelectedIssueInModule();

  // data - selected measurement & types & ressources ()

  const selectedMeasurement = useSelectedMeasurement();
  const tempMeasurement = useTempMeasurement({
    scene,
    editorPdf: caplaEditor?.editorPdf,
  });
  const elementTypes = useElementTypesBySceneProxy(scene);
  const selectedMeasurementElementType = elementTypes.find(
    (t) => t.id === selectedMeasurement?.elementTypeId
  );
  const selectedPhase = useSelector((s) => s.phases.selectedPhase);

  // data - progress

  const selectedProgressListing = null;

  // data - sharedbox

  // const selectedSharedbox = useSelectedSharedbox(scene);
  const selectedSharedbox = useSelector((s) => s.sharedboxes.selectedSharedbox);

  // data - survey

  const selectedSurvey = useSelectedSurvey(scene);

  // data - report

  const selectedReport = useSelectedReport();
  const selectedBlock = useSelectedBlock();

  // effects

  // useEffect(() => {
  //   if (!clickedId) {
  //     // dispatch(setSelectedMeasurementId(null));
  //   } else {
  //     if (clickedType === "MEASUREMENT")
  //       dispatch(setSelectedMeasurementIds([clickedId]));
  //     // dispatch(setSelectedMeasurementId(clickedId));
  //   }
  // }, [clickedId]);

  // SCENE

  if (forceSelectionType) {
    if (forceSelectionType === "SCENE") {
      selection = {
        type: "SCENE",
        item: {...selectedScene, name: selectedScene?.title},
      };
    } else if (forceSelectionType === "MEASUREMENT") {
      selection = {
        type: "MEASUREMENT",
        item: {
          ...selectedMeasurement,
          name: selectedMeasurementElementType?.name,
        },
      };
    } else if (forceSelectionType === "ZONE") {
      selection = {
        type: "ZONE",
        item: {
          ...selectedZone,
        },
      };
    }
  } else if (
    pageType === "HOME" ||
    sceneModule === "ADMIN" ||
    sceneModule === "HOME"
  ) {
    if (scene) {
      selection = {
        type: "SCENE",
        item: {...selectedScene, name: selectedScene?.title},
      };
    } else {
      selection = {
        type: "NEW_SCENE",
        item: {name: newSceneS},
      };
    }
  }
  // SECTORS
  else if (sceneModule === "SECTORS") {
    if (selectedSector) {
      selection = {
        type: "SECTOR",
        item: {...selectedSector},
      };
    } else {
      selection = {
        type: "TEMP_SECTOR",
        item: {
          ...tempSector,
          name: tempSector?.name ? tempSector.name : newSectorS,
        },
      };
    }
  }
  // PHASES
  else if (sceneModule === "PHASES") {
    if (selectedPhase) {
      selection = {
        type: "PHASE",
        item: selectedPhase,
      };
    } else {
      selection = {
        type: "NEW_PHASE",
        item: {name: newPhaseS},
      };
    }
  }

  // ROOMS
  else if (sceneModule === "ROOMS") {
    if (!selectedRoomParentId && !selectedRoom) {
      selection = {
        type: "ROOMS_GROUP",
        item: {...selectedRoomsGroup},
      };
    } else if (selectedRoom) {
      selection = {
        type: "ROOM",
        item: {...selectedRoom},
      };
    } else {
      selection = {
        type: "TEMP_ROOM",
        item: {
          ...tempRoom,
          name: tempRoom?.name ? tempRoom.name : newRoomS,
        },
      };
    }
  }

  // MATERIALS
  else if (sceneModule === "MATERIALS") {
    if (materialsViewBy === "ROOM") {
      if (isCreatingRoomMaterial) {
        selection = {type: "NEW_MATERIAL", item: {name: newMaterialS}};
      } else {
        selection = {
          type: "ROOM_IN_MATERIALS",
          item: selectedRoomInMaterials,
        };
      }
    } else {
      if (selectedMaterial) {
        selection = {
          type: "MATERIAL",
          item: {...selectedMaterial},
        };
      } else {
        selection = {
          type: "NEW_MATERIAL",
          item: {
            ...newMaterial,
            name: newMaterial?.name ? newMaterial.name : newMaterialS,
          },
        };
      }
    }
  }

  // MARKERS
  else if (sceneModule === "MARKERS") {
    if (selectedMarker) {
      selection = {
        type: "MARKER",
        item: {...selectedMarker, name: selectedMarker.description},
      };
    } else {
      selection = {
        type: "NEW_MARKER",
        item: {name: newMarkerS},
      };
    }
  }

  // INPUTS
  else if (sceneModule === "INPUTS") {
    if (selectedInput && selectedPdfModel) {
      selection = {
        type: "PDF_MODEL",
        item: {...selectedPdfModel},
      };
    } else {
      selection = {
        type: "TEMP_INPUT",
        item: {
          ...tempInput,
          name: tempInput?.name ? tempInput.name : "Nouveau fichier",
        },
      };
    }
  }
  // ZONES
  else if (sceneModule === "ZONES") {
    if (selectedZone) {
      selection = {
        type: "ZONE",
        item: selectedZone,
      };
    }
  }
  // PACKAGES
  else if (sceneModule === "PACKAGES") {
    if (selectedPackage) {
      selection = {
        type: "PACKAGE",
        item: {...selectedPackage},
      };
    } else {
      selection = {
        type: "NEW_PACKAGE",
        item: {name: newPackageS},
      };
    }
  }

  // ELEMENT TYPES
  else if (sceneModule === "ELEMENT_TYPES") {
    if (selectedElementType) {
      selection = {
        type: "ELEMENT_TYPE",
        item: {...selectedElementType},
      };
    } else {
      selection = {
        type: "NEW_ELEMENT_TYPE",
        item: {name: "Nouveau type d'élement"},
      };
    }
  }
  // ARTICLES
  else if (sceneModule === "ARTICLES") {
    if (selectedArticle?.id) {
      selection = {
        type: "ARTICLE",
        item: selectedArticle,
      };
    } else if (selectedArticlesGroup) {
      selection = {
        type: "ARTICLES_GROUP",
        item: selectedArticlesGroup,
      };
    }
  }

  // MEASUREMENTS
  else if (sceneModule === "MEASUREMENTS") {
    if (selectedMeasurement) {
      selection = {
        type: "MEASUREMENT",
        item: {
          ...selectedMeasurement,
          name: selectedMeasurementElementType?.name,
        },
      };
    }
  } else if (sceneModule === "EDIT_MEASUREMENTS" || pageType === "COLORING") {
    if (selectedMeasurement) {
      selection = {
        type: "MEASUREMENT",
        item: {
          ...selectedMeasurement,
          name: selectedMeasurementElementType?.name,
        },
      };
    } else {
      selection = {
        type: "TEMP_MEASUREMENT",
        item: {
          ...tempMeasurement,
          name: newMeasurementS,
          // name: tempMeasurement?.elementTypeId
          //   ? tempMeasurement?.codeName
          //   : nameVoid,
        },
      };
    }
  }

  // VIEWER 3D
  else if (sceneModule === "VIEWER_3D") {
    if (selectedZone) {
      selection = {
        type: "ZONE",
        item: selectedZone,
      };
    } else if (selectedMeasurement) {
      selection = {
        type: "MEASUREMENT",
        item: {
          ...selectedMeasurement,
          name: selectedMeasurementElementType?.name,
        },
      };
    }
  }
  // ISSUES
  else if (selectedIssue) {
    selection = {
      type: "ISSUE",
      item: {...selectedIssue, name: selectedIssue.title},
    };
  } else if (selectedIssueInModule) {
    selection = {
      type: "ISSUE",
      item: {...selectedIssueInModule, name: selectedIssueInModule.title},
    };
  } else if (sceneModule === "ISSUES") {
    if (selectedIssue) {
      selection = {
        type: "ISSUE",
        item: {...selectedIssue, name: selectedIssue.title},
      };
    } else {
      selection = {
        type: "NEW_ISSUE",
        item: {name: "New"},
      };
    }
  }
  // PROGRESS
  else if (sceneModule === "PROGRESS") {
    selection = {
      type: "PROGRESS_LISTING",
      item: selectedProgressListing,
    };
  }
  // ANNOTATED PDF
  else if (sceneModule === "ANNOTATED_PDF") {
    selection = {
      type: "ANNOTATED_PDF_MODEL",
      item: annotatedPdfModel,
    };
  }

  // SHARED BOXES
  else if (sceneModule === "SHAREDBOXES") {
    if (selectedSharedbox) {
      selection = {
        type: "SHAREDBOX",
        item: selectedSharedbox,
      };
    } else {
      selection = {
        type: "NEW_SHAREDBOX",
        item: {name: newSharedboxS},
      };
    }
  }
  // SURVEYS
  else if (sceneModule === "SURVEYS") {
    if (selectedSurvey) {
      selection = {
        type: "SURVEY",
        item: selectedSurvey,
      };
    } else {
      selection = {
        type: "NEW_SURVEY",
        item: {name: newSurveyS},
      };
    }
  }

  // SAMPLES
  else if (sceneModule === "SAMPLES") {
    if (selectedSample) {
      selection = {
        type: "SAMPLE",
        item: selectedSample,
      };
    } else {
      selection = {
        type: "NEW_SAMPLE",
        item: {name: newSampleS},
      };
    }
  }

  // REPORTS
  else if (sceneModule === "REPORTS") {
    if (selectedReport) {
      selection = {
        type: "REPORT",
        item: selectedReport,
      };
    } else {
      selection = {
        type: "NEW_REPORT",
        item: {name: newReportS},
      };
    }
  }
  // REPORT

  // REPORTS
  else if (sceneModule === "REPORT") {
    if (selectedBlock) {
      selection = {
        type: "BLOCK",
        item: selectedBlock,
      };
    } else {
      selection = {
        type: "NEW_BLOCK",
        item: {name: newBlockS},
      };
    }
  }

  return selection;
}
