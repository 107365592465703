export default function fromMaterialsDataToClientIds(materialsData) {
  // edge case

  if (!Array.isArray(materialsData) || materialsData?.length === 0) {
    return [];
  }

  // general calse

  return materialsData.reduce((acc, {materialsGroupId, materialsClientIds}) => {
    return [...acc, ...materialsClientIds];
  }, []);
}
