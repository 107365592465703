import getItemsWithChildrenFromNum from "Utils/getItemsWithChildrenFromNum";
import getParentsNums from "Utils/getParentsNums";
import sortByNum from "Utils/sortByNum";
import getFieldsFromItems from "./getFieldsFromItems";
import getItemRankFromNum from "Utils/getItemRankFromNum";

export default function getExcelRowsFromNomenclatureTreeItems(items) {
  if (!items) return [];

  //
  const sortedItems = [...items].sort((a, b) => sortByNum(a.num, b.num));
  const itemsWithChildren = getItemsWithChildrenFromNum(sortedItems);

  //

  const ranksFields = getFieldsFromItems(sortedItems);

  //
  const itemsByNum = items.reduce((acc, item) => {
    acc[item.num] = item;
    return acc;
  }, {});

  const rows = [];
  const processedParentMap = {}; // to check if the parent was already processed or not.

  itemsWithChildren.forEach((item) => {
    if (item?.children?.length > 0) return;

    const itemRank = getItemRankFromNum(item);

    const itemParentNums = getParentsNums(item.num);

    const row = [item.id];

    // manage parents

    if (itemParentNums?.length > 0) {
      itemParentNums.forEach((parentNum, index) => {
        const parent = itemsByNum[parentNum];
        const parentFields = ranksFields[index];
        parentFields.forEach((field) => {
          let parentFieldValue = "";
          if (!processedParentMap[parentNum]) {
            parentFieldValue = parent[field];
            processedParentMap[parentNum] = true;
          }
          row.push(parentFieldValue);
        });
        processedParentMap[parentNum] = true;
      });
    }

    // manage item

    const itemFields = ranksFields[itemRank - 1];
    itemFields.forEach((field) => {
      row.push(item[field]);
    });

    // update rows

    rows.push(row);
  });

  return rows;
}
