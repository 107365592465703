import {Box} from "@mui/material";

export default function SectionListingDetail({
  caplaEditor,
  scene,
  viewer,
  listing,
}) {
  // helpers

  const isNotes = listing?.listingType === "noteset";
  const isPictures = listing?.listingType === "pictureset";

  return <Box sx={{height: 1}}></Box>;
}
