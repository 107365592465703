import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import SectionReportTemplates from "./SectionReportTemplates";
import SectionFormTemplates from "./SectionFormTemplates";
import SectionNomenclatures from "./SectionNomenclatures";

export default function SectionSelectedEntities({organisation}) {
  // data

  const entities = useSelector((state) => state.organisations.selectedEntities);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
      }}
    >
      {entities === "FORM_TEMPLATES" && <SectionFormTemplates />}
      {entities === "REPORT_TEMPLATES" && <SectionReportTemplates />}
      {entities === "NOMENCLATURES" && (
        <SectionNomenclatures organisation={organisation} />
      )}
    </Box>
  );
}
