import {Box} from "@mui/material";

import SectionActionsRooms from "./SectionActionsRooms";

export default function ViewBarRoomsActionsRooms({scene, caplaEditor}) {
  // actions - main

  const actionsMainKeys = [];
  const actionsMoreKeys = [
    "setRoomsViewVariantToList",
    "setRoomsViewVariantToTreeList",
    "setRoomsViewVariantToTree",
    //
    "divider",
    //
    "toggleMultipleSelectionRooms",
  ];

  return (
    <Box sx={{display: "flex", alignItems: "center", width: 1}}>
      <SectionActionsRooms
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
