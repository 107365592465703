import getMaterialCategoryIndex from "./getMaterialCategoryIndex";

export default function getMaterialCode(material, materials) {
  if (!material) return "";

  const type = material.categoryType;
  const code = material.categoryCode;
  //
  const categoryIndex = getMaterialCategoryIndex(material, materials);
  //
  const formattedIndex = String(categoryIndex).padStart(3, "0");
  //
  const part1 = type ? `${type}-` : "";
  const part2 = code ? `${code}-` : "";
  const part3 = formattedIndex ? `${formattedIndex}` : "";
  //
  return `${part1}${part2}${part3}`;
}
