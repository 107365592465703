export default function fromMaterialsClientIdsToData(
  materialsClientIds,
  materialsByClientId
) {
  const dataMap = {};

  materialsClientIds.forEach((clientId) => {
    const material = materialsByClientId[clientId];
    const materialsGroupId = material.materialsGroupId;
    if (dataMap[materialsGroupId]) {
      dataMap[materialsGroupId].push(clientId);
    } else {
      dataMap[materialsGroupId] = [clientId];
    }
  });

  return Object.entries(dataMap).map(
    ([materialsGroupId, materialsClientIds]) => ({
      materialsGroupId,
      materialsClientIds,
    })
  );
}
