import React, {useState, useEffect, useMemo} from "react";

import {
  Box,
  Typography,
  Dialog,
  Grid,
  ClickAwayListener,
  Button,
} from "@mui/material";

import {ArrowDropDown as Down, Add} from "@mui/icons-material";

import ListMaterialsMultipleWithGroups from "Features/materials/components/ListMaterialsMultipleWithGroups";
import ListCategoriesAndMaterials from "Features/materials/components/ListCategoriesAndMaterials";
import ListMaterials from "Features/materials/components/ListMaterials";

import getItemsMapByClientId from "Utils/getItemsMapByClientId";
import fromMaterialsDataToClientIds from "Features/materials/utils/fromMaterialsDataToClientIds";
import fromMaterialsClientIdsToData from "Features/materials/utils/fromMaterialsClientIdsToData";
import getCategoriesAndMaterialsItems from "Features/materials/utils/getCategoriesAndMaterialsItems";
import SelectorMaterialsGroupContainer from "Features/materials/components/SelectorMaterialsGroupContainer";
import SearchBarObjectsV2 from "Features/search/components/SearchBarObjectsV2";
import getFoundCategoriesOrMaterials from "Features/materials/utils/getFoundCategoriesOrMaterials";

export default function FieldMaterialsData({
  name,
  value, // materialsData : [{materialsGroupId, materialsClientIds:[]}]
  materialsGroupsWithNomenclatureAndMaterials,
  onChange,
  onNewData,
  locked,
  sizeKey = 12,
}) {
  // string

  const createMaterialS = "Matériau";
  // helpers

  const onNewMaterial = onNewData?.material;

  // state

  const [selectedMaterialsGroupId, setSelectedMaterialsGroupId] =
    useState(null);

  const [searchTextMaterials, setSearchTextMaterials] = useState("");

  // helpers - materials and groups

  const materialsGroups = (
    materialsGroupsWithNomenclatureAndMaterials ?? []
  ).map(({materialsGroup}) => materialsGroup);

  const nomenclatures = (materialsGroupsWithNomenclatureAndMaterials ?? []).map(
    ({nomenclature}) => nomenclature
  );
  const {materials, nomenclature} =
    (materialsGroupsWithNomenclatureAndMaterials ?? []).find(
      ({materialsGroup}) => materialsGroup.id === selectedMaterialsGroupId
    ) ?? {};

  // effect - init selectedMaterialsGroupId

  const group0 = materialsGroups[0];
  useEffect(() => {
    if (
      (!selectedMaterialsGroupId && materialsGroups?.length > 0) ||
      materialsGroups?.length === 1
    ) {
      setSelectedMaterialsGroupId(group0.id);
    }
  }, [selectedMaterialsGroupId, group0?.id]);

  // helpers - materialsMap

  const materialsByClientId = getItemsMapByClientId(materials);

  // helpers - selectedMaterialsClientIds

  const selectedMaterialsClientIds = fromMaterialsDataToClientIds(value);

  // helpers - categoriesAndMaterialsItems

  const categoriesAndMaterialsItems = useMemo(() => {
    console.log("[compute] getCategoriesAndMaterialsItems");
    return getCategoriesAndMaterialsItems({
      nomenclature,
      materials,
      categoriesWithMaterialsOnly: true,
    });
  }, [nomenclature, materials]);

  // helpers - foundCategoriesAndMaterialsItems

  const foundCategoriesAndMaterialsItems = getFoundCategoriesOrMaterials(
    categoriesAndMaterialsItems,
    {searchText: searchTextMaterials, withParents: true}
  );
  const foundMaterials = getFoundCategoriesOrMaterials(materials, {
    searchText: searchTextMaterials,
  });

  // handlers

  function handleSelectionChange(clientIds) {
    const materialsData = fromMaterialsClientIdsToData(
      clientIds,
      materialsByClientId
    );
    onChange(materialsData);
  }

  function handleMaterialClick(material) {
    const newSelectedMaterialClientIds = selectedMaterialsClientIds.includes(
      material.clientId
    )
      ? selectedMaterialsClientIds.filter((id) => id !== material.clientId)
      : [...selectedMaterialsClientIds, material.clientId];
    const newMaterialsData = fromMaterialsClientIdsToData(
      newSelectedMaterialClientIds,
      materialsByClientId
    );
    onChange(newMaterialsData);
  }

  function handleCreateMaterialClick() {
    onNewData();
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        bgcolor: "background.main",
        minHeight: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {materialsGroups?.length > 1 && (
        <SelectorMaterialsGroupContainer
          selectedMaterialsGroupId={selectedMaterialsGroupId}
          materialsGroups={materialsGroups}
          //materialsGroupsCounters={groupsCounters}
          onSelectedMaterialsGroupIdChange={setSelectedMaterialsGroupId}
          variant="chips"
        />
      )}
      <Box
        sx={{
          p: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "grey.500",
        }}
      >
        <Box sx={{flexGrow: 1}}>
          <SearchBarObjectsV2
            searchText={searchTextMaterials}
            onSearchTextChange={setSearchTextMaterials}
          />
        </Box>
        <Button
          sx={{ml: 2}}
          size="small"
          variant="outlined"
          color="inherit"
          startIcon={<Add />}
          onClick={handleCreateMaterialClick}
        >
          {createMaterialS}
        </Button>
      </Box>

      {/* <ListCategoriesAndMaterials
        items={foundCategoriesAndMaterialsItems}
        //categoriesWithMaterialsOnly={true}
      /> */}

      <ListMaterials
        materials={foundMaterials}
        onClick={handleMaterialClick}
        selection={selectedMaterialsClientIds}
      />

      {/* <ListMaterialsMultipleWithGroups
        nomenclatures={nomenclatures}
        //selectedMaterialCategoryId={selectedMaterialCategoryId}
        //onSelectedMaterialCategoryIdChange={handleMaterialCategoryIdChange}
        materials={materials}
        selectedMaterialsClientIds={selectedMaterialsClientIds}
        onSelectionChange={handleSelectionChange}
        materialsGroups={materialsGroups}
        forceSelection={true}
        selectedMaterialsGroupId={selectedMaterialsGroupId}
        onSelectedMaterialsGroupIdChange={setSelectedMaterialsGroupId}
        resetSelectionOnGroupChange={false}
        multipleSelection={false}
        variant="chips"
        onNewMaterial={onNewMaterial}
      /> */}
    </Box>
  );
}
