import {useDispatch, useSelector} from "react-redux";

import {setOpenZonesPanel, setSelectedZoneId} from "../zonesSlice";

import {Box, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import useZonesByScene from "../hooks/useZonesByScene";
import GridZones from "./GridZones";

export default function SectionSelectorZoneVariantImages({scene}) {
  const dispatch = useDispatch();

  // data

  const zones = useZonesByScene(scene, {filterByScope: true});
  console.log("zones", zones);

  // handlers

  function handleClose() {
    dispatch(setOpenZonesPanel(false));
  }

  function handleZoneClick(zone) {
    console.log("handleZoneClick", zone);
    dispatch(setOpenZonesPanel(false));
    dispatch(setSelectedZoneId(zone.id));
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        bgcolor: "background.default",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{width: 1, display: "flex", justifyContent: "end", p: 1}}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={{flex: 1, width: 1, overflow: "auto"}}>
        <GridZones zones={zones} onClick={handleZoneClick} />
      </Box>
    </Box>
  );
}
