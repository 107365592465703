import getItemRankFromNum from "./getItemRankFromNum";
import getNextNumWithRank1 from "./getNextNumWithRank1";

export default function getItemChildrenFromNum(item, items, options) {
  //
  // options
  const addOthers = options?.addOthers;
  //
  if (!item) return items.filter((i) => getItemRankFromNum(i) === 1);

  const itemNum = item.num;
  const itemRank = getItemRankFromNum(item);

  const children = items.filter((i) => {
    return (
      i?.num &&
      i.num.startsWith(`${itemNum}.`) &&
      i.num !== itemNum &&
      getItemRankFromNum(i) === itemRank + 1
    );
  });

  if (addOthers) {
    const lastChild = children[children.length - 1];
    const lastNum = lastChild?.num;
    const otherNum = getNextNumWithRank1(lastNum);
    const other = {id: item.id, num: otherNum, name: "Autre"};
    children.push(other);
  }

  return children;
}
