import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";

import {
  Box,
  List,
  ListItemButton,
  // ListItemText,
  Typography,
} from "@mui/material";
import {
  ArrowDropDown as Down,
  ArrowDropUp as Up,
  Close,
} from "@mui/icons-material";

import ListItemMaterial from "./ListItemMaterial";
import sortByNum from "Utils/sortByNum";
import SelectorGenericItemWithChips from "Components/SelectorGenericItemWithChips";
import SelectorMaterialsGroup from "./SelectorMaterialsGroup";
import {
  setSelectedMaterialCategoryId,
  setSelectedMaterialsGroupId,
} from "../materialsSlice";

import ListMaterialsFromNomenclature from "./ListMaterialsFromNomenclature";
import ListMaterials from "./ListMaterials";

export default function ListMaterialsMultipleWithGroups({
  scene,
  caplaEditor,
  nomenclatures,
  selectedMaterialCategoryId,
  onSelectedMaterialCategoryIdChange,
  materials = [],
  materialsGroups = [],
  selectedMaterialsClientIds,
  multipleSelection,
  onSelectionChange,
  selectedMaterialsGroupId,
  onSelectedMaterialsGroupIdChange, // {id,name,fromScene}
  resetSelectionOnGroupChange = true,
  forceSelection, // if true, return one default selection
  maxHeightGroups,
  variant = "chips",
  //
  onNewMaterial,
}) {
  const dispatch = useDispatch();
  console.log("materialsGroups", materialsGroups);

  // strings

  const groupS = "Liste de matériaux";
  const groupsS = "Listes";

  // helpers

  const filteredMaterials = materials.filter(
    (r) =>
      !selectedMaterialsGroupId ||
      r.materialsGroupId === selectedMaterialsGroupId
  );

  let selectedMaterialsGroup = materialsGroups.find(
    (g) => g?.id === selectedMaterialsGroupId
  );

  if (!selectedMaterialsGroup)
    selectedMaterialsGroup = {
      id: "undefined",
      name: "Sélectionnez une liste",
    };

  // nomenclature

  const nomenclature = nomenclatures.find(
    (nomenclature) =>
      nomenclature.id === selectedMaterialsGroup?.data?.materialsNomenclatureId
  );

  // init - forceSelection

  useEffect(() => {
    if (
      forceSelection &&
      !selectedMaterialsGroupId &&
      materialsGroups.length > 0
    ) {
      const id0 = materialsGroups[0].id;
      dispatch(setSelectedMaterialsGroupId(id0));
    }
  }, [selectedMaterialsGroupId, materialsGroups.length]);

  // state

  const [openGroups, setOpenGroups] = useState(false);

  // handlers

  function handleGroupClick(group, options) {
    //
    const closeGroupsList = options?.closeGroupsList;
    //
    if (group?.id && selectedMaterialsGroupId !== group?.id) {
      // used to avoid to have one hidden selection...
      if (resetSelectionOnGroupChange) onSelectionChange([]);
    }
    onSelectedMaterialsGroupIdChange(group.id);
    //
    if (closeGroupsList) setOpenGroups(false);
  }

  function handleClick(material) {
    if (material.isCategory) {
      dispatch(setSelectedMaterialCategoryId(material.id));
    }
    console.log("click on material", material);
    const id = material?.id;
    const clientId = material?.clientId;
    const idIsSelected = selectedMaterialsClientIds?.includes(clientId);
    let newIds = selectedMaterialsClientIds?.filter(
      (materialClientId) => materialClientId !== materialClientId
    );
    if (multipleSelection) {
      if (!newIds) newIds = [];
      if (material.isTitle) {
        return;
      } else {
        if (!selectedMaterialsClientIds?.includes(clientId))
          newIds.push(clientId);
      }
    } else {
      newIds = idIsSelected ? [] : [clientId];
    }
    if (onSelectionChange) onSelectionChange(newIds);
  }

  function handleSelectedMaterialCategoryIdChange(id) {
    console.log("[selectedCategoryId]", id);
    if (onSelectedMaterialCategoryIdChange)
      onSelectedMaterialCategoryIdChange(id);
  }

  return (
    <Box
      sx={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: 0}}
    >
      <Box sx={{py: 2}}>
        {variant === "chips" && (
          <SelectorGenericItemWithChips
            items={materialsGroups}
            selectedItem={selectedMaterialsGroup}
            onClick={handleGroupClick}
            maxHeight={maxHeightGroups}
          />
        )}
        {variant === "list" && (
          <Box>
            <Typography
              sx={{
                fontSize: 13,
                color: "secondary.main",
                p: 0.5,
                px: 1,
                visibility: openGroups ? "hidden" : "visible",
              }}
            >
              {groupS}
            </Typography>
            <Box
              sx={{
                p: 1,
                bgcolor: openGroups ? "common.white" : "secondary.main",
              }}
            >
              <Box
                onClick={() => setOpenGroups((open) => !open)}
                sx={{
                  px: 1,
                  py: 0.5,
                  bgcolor: "common.white",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  color: "text.secondary",
                }}
              >
                <Typography sx={{fontSize: 13}}>
                  {!openGroups ? selectedMaterialsGroup?.name : groupsS}
                </Typography>
                {!openGroups ? <Down /> : <Close color="inherit" />}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {!openGroups && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            minHeight: 0,
          }}
        >
          <ListMaterialsFromNomenclature
            nomenclature={nomenclature}
            selectedMaterialCategoryId={selectedMaterialCategoryId}
            onSelectedMaterialCategoryIdChange={
              handleSelectedMaterialCategoryIdChange
            }
            materials={filteredMaterials}
            onClick={handleClick}
            selectedMaterialsClientIds={selectedMaterialsClientIds}
            onNewMaterial={onNewMaterial}
          />
          {/* <ListMaterials
            materials={materials}
            onClick={handleClick}
            selection={selectedMaterialsClientIds}
          /> */}
        </Box>
      )}
      {openGroups && variant === "list" && (
        <Box sx={{overflowY: "auto"}}>
          <SelectorMaterialsGroup
            items={materialsGroups}
            selectedItem={selectedMaterialsGroup}
            onClick={handleGroupClick}
          />
        </Box>
      )}
    </Box>
  );
}
