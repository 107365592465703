import {Box} from "@mui/material";

import useSelection from "../useSelection";

import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";
import useIsDrawing from "Features/measurements/useIsDrawing";
import CardIsDrawing from "Features/measurements/components/CardIsDrawing";
import SectionSceneInSelectionPanel from "Features/scenes/components/SectionSceneInSelectionPanel";
import SectionRessourceInSelectionPanel from "Features/ressources/components/SectionRessourceInSelectionPanel";
import SectionElementTypeInSelectionPanel from "Features/elementTypes/components/SectionElementTypeInSelectionPanel";
import SectionMeasurementInSelectionPanel from "Features/measurements/components/SectionMeasurementInSelectionPanel";
import SectionPackageInSelectionPanel from "Features/elementPackages/components/SectionPackageInSelectionPanel";
import SectionMarkersInSelectionPanel from "Features/markers/components/SectionMarkersInSelectionPanel";
import SectionIssueInSelectionPanel from "Features/issues/components/SectionIssueInSelectionPanel";
import SectionPdfInSelectionPanel from "Features/pdf/components/SectionPdfInSelectionPanel";
import SectionZoneInSelectionPanel from "Features/zones/components/SectionZoneInSelectionPanel";
import SectionHomeSelectionInSelectionPanel from "Features/boxhome/components/SectionHomeSelectionInSelectionPanel";
import SectionViewer3DSelectionInSelectionPanel from "Features/navigation/components/SectionViewer3DSelectionInSelectionPanel";
import SectionTempInputInSelectionPanel from "Features/inputs/components/SectionTempInputInSelectionPanel";
import SectionRoomInSelectionPanel from "Features/rooms/components/SectionRoomInSelectionPanel";
import SectionRoomsGroupInSelectionPanel from "Features/rooms/components/SectionRoomsGroupInSelectionPanel";
import SectionSectorInSelectionPanel from "Features/sectors/components/SectionSectorInSelectionPanel";
import SectionVoidInSelectionPanel from "./SectionVoidInSelectionPanel";
import SectionAnnotatedPdfModelInSelectionPanel from "Features/annotatedPdf/components/SectionAnnotatedPdfModelInSelectionPanel";
import SectionSharedboxInSelectionPanel from "Features/sharedboxes/components/SectionSharedboxInSelectionPanel";
import SectionMaterialInSelectionPanel from "Features/materials/components/SectionMaterialInSelectionPanel";
import SectionRoomMaterialsInSelectionPanel from "Features/materials/components/SectionRoomMaterialsInSelectionPanel";
import SectionPhaseInSelectionPanel from "Features/phases/components/SectionPhaseInSelectionPanel";
import SectionSurveyInSelectionPanel from "Features/surveys/components/SectionSurveyInSelectionPanel";
import SectionArticleInSelectionPanel from "Features/articles/components/SectionArticleInSelectionPanel";
import SectionArticlesGroupInSelectionPanel from "Features/articles/components/SectionArticlesGroupInSelectionPanel";
import SectionSampleInSelectionPanel from "Features/samples/components/SectionSampleInSelectionPanel";
import SectionReportInSelectionPanel from "Features/reports/components/SectionReportInSelectionPanel";
import SectionBlockInSelectionPanel from "Features/reportBlocks/components/SectionBlockInSelectionPanel";
import useSelectionTypeInSelectionPanel from "../useSelectionTypeInSelectionPanel";

export default function SectionSelection({
  caplaEditor,
  scene,
  viewer,
  forceSelectionType,
}) {
  // data

  const type = useSelectionTypeInSelectionPanel({
    scene,
    caplaEditor,
    viewer,
    forceSelectionType,
  });

  return (
    <Box
      sx={{
        width: 1,
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
      }}
    >
      {type === "IS_DRAWING" && (
        <CardIsDrawing editorPdf={caplaEditor?.editorPdf} />
      )}

      {type === "VOID" && <SectionVoidInSelectionPanel />}
      {type === "SCENE" && (
        <SectionSceneInSelectionPanel viewer={viewer} scene={scene} />
      )}
      {type === "HOME" && (
        <SectionHomeSelectionInSelectionPanel
          caplaEditor={caplaEditor}
          scene={scene}
          viewer={viewer}
        />
      )}
      {type === "VIEWER_3D" && (
        <SectionViewer3DSelectionInSelectionPanel
          caplaEditor={caplaEditor}
          scene={scene}
        />
      )}
      {type === "ISSUE" && (
        <SectionIssueInSelectionPanel caplaEditor={caplaEditor} scene={scene} />
      )}
      {type === "MARKER" && <SectionMarkersInSelectionPanel />}
      {type === "ZONE" && (
        <SectionZoneInSelectionPanel caplaEditor={caplaEditor} scene={scene} />
      )}
      {type === "SURVEY" && (
        <SectionSurveyInSelectionPanel
          caplaEditor={caplaEditor}
          scene={scene}
        />
      )}
      {type === "MEASUREMENT" && (
        <SectionMeasurementInSelectionPanel
          caplaEditor={caplaEditor}
          scene={scene}
        />
      )}
      {type === "SECTOR" && (
        <SectionSectorInSelectionPanel
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {type === "ROOM" && <SectionRoomInSelectionPanel scene={scene} />}
      {type === "ROOMS_GROUP" && (
        <SectionRoomsGroupInSelectionPanel scene={scene} />
      )}
      {type === "RESSOURCE" && (
        <SectionRessourceInSelectionPanel
          editorPdf={caplaEditor?.editorPdf}
          scene={scene}
        />
      )}
      {type === "ARTICLE" && (
        <SectionArticleInSelectionPanel
          caplaEditor={caplaEditor}
          scene={scene}
        />
      )}
      {type === "ARTICLES_GROUP" && (
        <SectionArticlesGroupInSelectionPanel
          caplaEditor={caplaEditor}
          scene={scene}
        />
      )}
      {type === "ELEMENT_TYPE" && (
        <SectionElementTypeInSelectionPanel
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {type === "PACKAGE" && (
        <SectionPackageInSelectionPanel
          editor3d={caplaEditor?.editor3d}
          caplaEditor={caplaEditor}
          scene={scene}
        />
      )}

      {/* {sceneModule === "DATA" && selection?.type === "PDF_MODEL" && (
        <SectionPdfInSelectionPanel caplaEditor={caplaEditor} scene={scene} />
      )} */}
      {type === "TEMP_INPUT" && (
        <SectionTempInputInSelectionPanel editor3d={caplaEditor?.editor3d} />
      )}
      {type === "PDF" && (
        <SectionPdfInSelectionPanel caplaEditor={caplaEditor} scene={scene} />
      )}
      {type === "ANNOTATED_PDF" && (
        <SectionAnnotatedPdfModelInSelectionPanel
          editorPdf={caplaEditor?.editorPdf}
          scene={scene}
        />
      )}
      {type === "SHAREDBOX" && (
        <SectionSharedboxInSelectionPanel scene={scene} />
      )}
      {type === "MATERIAL" && <SectionMaterialInSelectionPanel scene={scene} />}
      {type === "ROOM_IN_MATERIALS" && (
        <SectionRoomMaterialsInSelectionPanel scene={scene} />
      )}
      {type === "PHASE" && <SectionPhaseInSelectionPanel scene={scene} />}
      {type === "SAMPLE" && <SectionSampleInSelectionPanel scene={scene} />}
      {type === "REPORT" && <SectionReportInSelectionPanel scene={scene} />}
      {type === "BLOCK" && (
        <SectionBlockInSelectionPanel scene={scene} caplaEditor={caplaEditor} />
      )}
    </Box>
  );
}
