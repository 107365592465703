import getItemsWithChildrenFromNum from "Utils/getItemsWithChildrenFromNum";
import getMaterialsCategoriesWithMaterialClientIds from "Features/materials/utils/getMaterialCategoriesWithMaterialClientIds";
import getOtherCategoryFromCategoryWithChildren from "./getOtherCategoryFromCategoryWithChildren";
import getItemsMapByClientId from "Utils/getItemsMapByClientId";
import sortByNum from "Utils/sortByNum";
import getItemsMapByNum from "Utils/getItemsMapByNum";
import getParentsNums from "Utils/getParentsNums";

export default function getCategoriesAndMaterialsItems({
  nomenclature,
  materials,
  categoriesWithMaterialsOnly,
}) {
  const categories = nomenclature?.tree?.items ?? [];

  const materialsByClientId = getItemsMapByClientId(materials);

  const categoriesWithChildren = getItemsWithChildrenFromNum(categories);

  const categoriesWithChildrenAndMaterialClientIds =
    getMaterialsCategoriesWithMaterialClientIds(
      categoriesWithChildren,
      materials
    );

  let items = [];

  categoriesWithChildrenAndMaterialClientIds.forEach((category) => {
    items.push({...category, isCategory: true});

    // tests
    const categoryHasMaterialsInOther =
      category.materialClientIds?.length > 0 && category.children?.length > 0;
    const categoryHasMaterialsInLeaf =
      category.materialClientIds?.length > 0 && !category.children?.length > 0;

    // case 1 : materials in other
    if (categoryHasMaterialsInOther) {
      const otherCategory = getOtherCategoryFromCategoryWithChildren(category);
      items.push(otherCategory);
      //
      category.materialClientIds.forEach((materialClientId, index) => {
        const material = materialsByClientId[materialClientId];
        if (material) {
          items.push({
            ...material,
            isMaterial: true,
            num: `${otherCategory.num}.${index + 1}`,
          });
        }
      });
    }
    // case 2 : materials in leaf category
    if (categoryHasMaterialsInLeaf) {
      category.materialClientIds.forEach((materialClientId, index) => {
        const material = materialsByClientId[materialClientId];
        if (material) {
          items.push({
            ...material,
            isMaterial: true,
            num: `${category.num}.${index + 1}`,
          });
        }
      });
    }
  });

  // sort

  items.sort((a, b) => sortByNum(a.num, b.num));

  // edge case : categoriesWithMaterialsOnly

  if (categoriesWithMaterialsOnly) {
    const newItems = [];
    //
    const materialNums = items
      .filter((item) => item.isMaterial)
      .map((item) => item.num);
    const processedNumMap = {};
    const itemsByNum = getItemsMapByNum(items);
    //
    materialNums.forEach((num) => {
      const material = itemsByNum[num];
      const parentsNums = getParentsNums(num);
      parentsNums.forEach((parentNum) => {
        if (!processedNumMap[parentNum]) {
          const parent = itemsByNum[parentNum];
          newItems.push(parent);
          processedNumMap[parentNum] = true;
        }
      });
      newItems.push(material);
    });
    items = newItems;
  }
  // return

  return items;
}
