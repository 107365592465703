import {useSelector, useDispatch} from "react-redux";

import {setNomenclatureFormTab} from "Features/nomenclatures/nomenclaturesSlice";

import useKvtNomenclature from "Features/kvform/hooks/useKvtNomenclature";

import {Box, Typography} from "@mui/material";

import KeyValueForm from "Features/kvform/components/KeyValueForm";
import getItemsMapById from "Utils/getItemsMapById";

export default function FormNomenclature({nomenclature, onChange}) {
  const dispatch = useDispatch();
  //

  const template = useKvtNomenclature();

  // item

  const item = {...nomenclature};

  // handlers

  function handleItemChange(changedItem) {
    console.log("FormNomenclature handleItemChange", changedItem);
    const changedNomenclature = {...nomenclature, ...changedItem};

    onChange(changedNomenclature);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: "common.white",
      }}
    >
      <KeyValueForm
        template={template}
        item={item}
        onChange={handleItemChange}
      />
    </Box>
  );
}
