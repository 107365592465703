import itemsWithChildrenFromItemsWithRanks from "Utils/getItemsWithChildrenFromItemsWithRanks";

export default function roomsFromExcelToRoomsToSync(rooms, options) {
  //
  const roomsMap = options.roomsMap;
  const defaultValues = options.defaultValues;
  //
  const roomsToDelete = rooms.filter((room) => room.toDelete);

  const roomsToChange = rooms.filter((room) => !room.toDelete);

  const roomsToChangeWithClientId = [];
  roomsToChange.forEach((room) => {
    if (room.clientId) {
      roomsToChangeWithClientId.push(room);
    } else {
      const roomWithClientId = roomsMap[room.id];
      if (roomWithClientId) {
        roomsToChangeWithClientId.push({
          ...room,
          clientId: roomWithClientId.clientId,
        });
      }
    }
  });
  const roomsWithChildren = itemsWithChildrenFromItemsWithRanks(
    roomsToChangeWithClientId
  );

  const stateRooms = roomsWithChildren.map((room) => {
    const childrenClientIds = room?.children?.map((child) => child.clientId);
    const stateRoom = {...room};
    if (defaultValues.roomsGroupId) {
      stateRoom.roomsGroupId = defaultValues.roomsGroupId;
    }
    if (childrenClientIds) {
      stateRoom.childrenClientIds = childrenClientIds;
      delete stateRoom.children;
    }
    return stateRoom;
  });

  const roomsToCreate = stateRooms.filter((room) => room.toCreate);
  const roomsToUpdate = stateRooms.filter((room) => room.id);

  const rootChildrenClientIds = stateRooms
    .filter((room) => room.rank === 1)
    .map((room) => room.clientId);

  return {roomsToDelete, roomsToCreate, roomsToUpdate, rootChildrenClientIds};
}
