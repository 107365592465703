import React, {useState, useEffect} from "react";

import {Box, Typography, Dialog, Grid, ClickAwayListener} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import SelectorMaterialFromTree from "Features/materials/components/SelectorMaterialFromTree";
import getMaterialsMapFromMaterialsGroupsWithNomenclatureAndMaterials from "Features/materials/utils/getMaterialsMapFromMaterialsGroupsWithNomenclatureAndMaterials";

export default function FieldMaterial({
  name,
  value,
  materialsGroupsWithNomenclatureAndMaterials,
  onChange,
  locked,
  sizeKey = 12,
  treeContainerRef,
}) {
  //
  console.log("field material", value);
  // state

  const [open, setOpen] = useState(false);

  // helpers

  const bbox = treeContainerRef?.current?.getBoundingClientRect();

  // helpers - materialsMap

  const materialsMap =
    getMaterialsMapFromMaterialsGroupsWithNomenclatureAndMaterials(
      materialsGroupsWithNomenclatureAndMaterials
    );

  // helpers - materialName

  const materialRootName = value?.parents?.reduce((acc, parent) => {
    return acc + parent.name + " / ";
  }, "");
  //let materialName = materialRootName + value?.name;
  let materialName = value?.name;
  if (!value) materialName = "Sélectionnez un matériau";

  // handlers - dialog

  function handleOpen() {
    if (!locked) setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  // handlers - selector

  function handleChange(materialId) {
    if (materialId) {
      onChange(materialsMap[materialId]);
    } else {
      onChange(null);
    }

    setOpen(false);
  }

  return (
    <Box>
      {open ? (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <Box
            sx={{
              position: "fixed",
              top: 0,
              bottom: 0,
              left: bbox.left,
              width: bbox.width,
              zIndex: 90,
              //height: 100,
              bgcolor: "background.default",
              //border: (theme) => `1px solid ${theme.palette.primary.main}`,
            }}
          >
            <SelectorMaterialFromTree
              selectedMaterial={value}
              materialsGroupsWithNomenclatureAndMaterials={
                materialsGroupsWithNomenclatureAndMaterials
              }
              onSelect={handleChange}
              onClose={handleClose}
            />
          </Box>
        </ClickAwayListener>
      ) : (
        <Grid
          container
          sx={{
            px: 1,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Grid item xs={sizeKey} sx={{py: 0.25}}>
            <Typography sx={{fontSize: 13}}>{name}</Typography>
          </Grid>
          <Grid
            item
            xs={12 - sizeKey}
            sx={{
              py: 0.25,
              pl: 1,
              borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                color: locked ? "text.secondary" : "unset",
              }}
              onClick={handleOpen}
            >
              <Typography
                sx={{
                  fontSize: 13,
                  ...(!value && {fontStyle: "italic", color: "text.secondary"}),
                }}
              >
                {materialName}
              </Typography>
              {!locked && <Down fontSize="small" />}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
