import useSceneListings from "Features/listings/useSceneListings";

export default function useIssuesetsByScene(sceneId) {
  //console.log("fetch scene listings 31");
  //const listings = useSceneListings(sceneId);
  const listings = [];

  const issuesets = listings.filter((l) => l.listingType === "issueset");

  return issuesets;
}
