import {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";

import {fetchReport} from "../reportsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useSelectedReport(options) {
  const dispatch = useDispatch();
  const syncingMapRef = useRef({});
  const accessToken = useAccessToken();
  const fetchData = options?.fetchData;

  const scopeId = useSelector((s) => s.scopes.selectedScopeId);

  const id = useSelector((s) => s.reports.selectedReportId);
  const map = useSelector((s) => s.reports.reportsMap);
  const report = map[id];

  // effect

  useEffect(() => {
    if (
      id &&
      accessToken &&
      fetchData &&
      !report &&
      syncingMapRef[id] !== true
    ) {
      syncingMapRef.current[id] = true;
      dispatch(fetchReport({accessToken, reportId: id, scopeId}));
    }
  }, [id]);

  // return

  return report;
}
