import {useState} from "react";

import useActionsBlocksMap from "../hooks/useActionsBlocksMap";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogCreateViewBlocks from "./DialogCreateViewBlocks";
import DialogDeleteBlock from "./DialogDeleteBlock";
import DialogValidateReport from "Features/reports/components/DialogValidateReport";

import {Dialog} from "@mui/material";

export default function SectionActionsBlocks({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // data

  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  const [openDialogValidateReport, setOpenDialogValidateReport] =
    useState(false);

  const [openDialogCreateViewBlocks, setOpenDialogCreateViewBlocks] =
    useState(false);

  const [openDialogDeleteBlock, setOpenDialogDeleteBlock] = useState(false);

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
    createViewBlocks: () => setOpenDialogCreateViewBlocks(true),
    deleteBlock: () => setOpenDialogDeleteBlock(true),
    validateReport: () => setOpenDialogValidateReport(true),
  };

  // handlers

  function handleClick(actionKey) {
    console.log("trigger", actionKey);
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // data - handlers

  const actionsMap = useActionsBlocksMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />
      {openDialogValidateReport && (
        <DialogValidateReport
          open={openDialogValidateReport}
          onClose={() => setOpenDialogValidateReport(false)}
        />
      )}
      {openDialogCreateViewBlocks && (
        <DialogCreateViewBlocks
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateViewBlocks}
          onClose={() => setOpenDialogCreateViewBlocks(false)}
        />
      )}
      {openDialogDeleteBlock && (
        <DialogDeleteBlock
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogDeleteBlock}
          onClose={() => setOpenDialogDeleteBlock(false)}
        />
      )}
    </>
  );
}
