import {useSelector} from "react-redux";

export default function useRoomLabels(options) {
  // options

  const pdfModelId = options?.byPdfModelId;
  const currentPageOnly = options?.currentPageOnly;

  //
  let models = useSelector((s) => s.viewer3D.models);
  const currentPage = useSelector((s) => s.viewer3D.pdfCurrentPage);

  // models with roomLabels

  models = models.filter((m) => m.roomLabels);

  if (pdfModelId) {
    models = models.filter((m) => m.id === pdfModelId);
  }

  // roomLabels

  let roomLabels = models.reduce((ac, cur) => {
    return [...ac, ...cur.roomLabels];
  }, []);

  if (currentPageOnly) {
    roomLabels = roomLabels.filter((p) => p === currentPage);
  }

  return roomLabels;
}
