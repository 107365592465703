import useZonesByScene from "Features/zones/hooks/useZonesByScene";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Box,
} from "@mui/material";
import GridZones from "Features/zones/components/GridZones";

export default function DialogCreateViewBlocks({open, onClose, scene}) {
  // strings

  const title = "Ajouter des plans";
  const description = "Choisissez les fonds des plans à utiliser";

  const saveS = "Enregistrer";

  // data

  const zones = useZonesByScene(scene, {filterByScope: true});

  // handlers

  async function handleSave() {
    onClose();
  }

  function handleZoneClick(zone) {
    console.log("zone", zone);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 1}}>
          <Typography variant="body2">{description}</Typography>
        </Box>
        <Box>
          <GridZones zones={zones} onClick={handleZoneClick} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
