import React, {useRef, useEffect, useLayoutEffect, useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import useSceneModule from "Features/navigation/useSceneModule";
import useZonesByScene from "../hooks/useZonesByScene";
import useSelectedZone from "../hooks/useSelectedZone";
import {setSelectedZoneId} from "../zonesSlice";

import {Box, IconButton, Paper} from "@mui/material";
import {Refresh} from "@mui/icons-material";

import SelectorAutocomplete from "Components/SelectorAutocomplete";

import ZonesEditor from "../js/ZonesEditor";
import LayerZoneViewer from "./LayerZoneViewer";
import LayerBlueprintRectangle from "./LayerBlueprintRectangle";

import theme from "Styles/theme";

import getBlueprintBbox from "../utils/getBlueprintBbox";

export default function ViewerZone({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const zonesEditorRef = useRef();

  // strings

  const zonesS = "Fonds de plan";

  // size

  const blueprintStroke = 2;
  const blueprintRatio = 4 / 3;
  const blueprintPadding = 60;
  const blueprintColor = theme.palette.common.caplaBlack;

  const containerWidth = containerRef.current?.getBoundingClientRect()?.width;
  const containerHeight = containerRef.current?.getBoundingClientRect()?.height;

  // state

  const [zonesEditorIsLoaded, setZonesEditorIsLoaded] = useState(false);
  const [blueprintBbox, setBlueprintBbox] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [containerBbox, setContainerBbox] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  // data

  const sceneModule = useSceneModule();
  const zones = useZonesByScene(scene);
  const selectedZone = useSelectedZone(scene);
  const os = useSelector((s) => s.ui.openSections);
  const selectedFixedViewer = useSelector((s) => s.ui.selectedFixedViewer);
  const fixedViewersBoxWidth = useSelector(
    (s) => s.viewer3D.fixedViewersBoxWidth
  );
  const blueprintProps = useSelector((s) => s.zones.blueprintProps) ?? {};
  const showBlueprint = useSelector((s) => s.zones.showBlueprint);

  // data - depth

  const openNavigation = useSelector((s) => s.navigation.open);
  const openSelection = useSelector((s) => s.selection.openSelectionPanel);
  const openLeftPanel = useSelector((s) => s.navigation.openLeftPanel);
  const navPanelSceneModulesWidth = useSelector(
    (s) => s.navigation.navPanelSceneModulesWidth
  );

  // helper

  const url = selectedZone?.imageUrl;
  const zoneImageWidth = selectedZone?.imageSize?.width;
  const zoneImageHeight = selectedZone?.imageSize?.height;

  const showLayer = os.fixedViewersBox && selectedFixedViewer === "ZONE";
  const showBlueprintLayer = showBlueprint && os.fixedViewersBox;

  // handlers

  function handleSelectedZoneChange(zone) {
    dispatch(setSelectedZoneId(zone?.id));
  }

  // init

  function init() {
    zonesEditorRef.current = new ZonesEditor({
      caplaEditor,
      container: "container",
      width: containerWidth,
      height: containerHeight,
      containerElement: containerRef.current,
      blueprintProps,
    });

    setZonesEditorIsLoaded(true);

    caplaEditor.zonesEditor = zonesEditorRef.current;

    window.addEventListener("resize", () => {
      zonesEditorRef.current.updateStageSize();

      if (containerRef.current) {
        //
        const bbox = getBlueprintBbox(
          containerRef.current,
          blueprintRatio,
          blueprintPadding,
          blueprintStroke
        );
        setBlueprintBbox(bbox);
        //
        const containerBbox = containerRef.current.getBoundingClientRect();
        setContainerBbox(containerBbox);
      }
    });
  }

  useEffect(() => {
    if (caplaEditor) {
      init();
      return () => {
        zonesEditorRef.current.clear();
      };
    }
  }, [caplaEditor]);

  useEffect(() => {
    if (url && containerRef.current) {
      zonesEditorRef.current.resetLayers();
      zonesEditorRef.current.addImage({
        url,
        width: zoneImageWidth,
        height: zoneImageHeight,
      });
    }
  }, [url, containerRef.current]);

  // update stage size

  useLayoutEffect(() => {
    setTimeout(() => {
      if (zonesEditorIsLoaded && containerRef.current) {
        zonesEditorRef.current.updateStageSize();
        //
        zonesEditorRef.current.updateStageOnContainerResize();
        //
        const bbox = getBlueprintBbox(
          containerRef.current,
          blueprintRatio,
          blueprintPadding,
          blueprintStroke
        );
        setBlueprintBbox(bbox);
        //
        const containerBbox = containerRef.current.getBoundingClientRect();
        setContainerBbox(containerBbox);
      }
    }, 10); // to get proper canvas resize...necessary when switching MIX<=>..
  }, [
    openNavigation,
    openSelection,
    openLeftPanel,
    navPanelSceneModulesWidth,
    selectedFixedViewer,
    zonesEditorIsLoaded,
    containerRef.current,
    fixedViewersBoxWidth,
    os.viewer3D,
  ]);

  return (
    <Box sx={{width: 1, height: 1, position: "relative"}}>
      {showLayer && <LayerZoneViewer scene={scene} caplaEditor={caplaEditor} />}
      {showBlueprintLayer && (
        <LayerBlueprintRectangle
          containerBbox={containerBbox}
          bbox={blueprintBbox}
          color={blueprintColor}
          stroke={blueprintStroke}
        />
      )}

      <Box
        sx={{
          width: 1,
          height: 1,
          position: "relative",
          bgcolor: "common.white",
          boxSizing: "borderBox",
          overflow: "hidden",
        }}
      >
        <div
          id="container"
          ref={containerRef}
          style={{
            //border: "1px solid black",
            boxSizing: "border-box",
            width: "100%",
            height: "100%",
            //zIndex: 1,
          }}
        ></div>
      </Box>
    </Box>
  );
}
