import {useLayoutEffect} from "react"; // used to load async locales files

// styles
import {ThemeProvider} from "@mui/material/styles";

import {CssBaseline} from "@mui/material";
import theme from "Styles/theme";

// routes
import Router from "Routes/Router";
import {MsalProvider} from "@azure/msal-react";

// redux store
import store from "./store";
import {Provider, useDispatch} from "react-redux";

import {setRunningIn, setMobile} from "Features/ui/uiSlice";

import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function UpdateRunningIn({runningIn}) {
  // used to know if the app is launched from Revit
  const dispatch = useDispatch();
  dispatch(setRunningIn(runningIn));
  return <div />;
}

function UpdateIsMobile() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = !useMediaQuery(theme.breakpoints.up("sm"));
  useLayoutEffect(() => {
    dispatch(setMobile(mobile));
  }, [mobile]);
  return <div />;
}

function App({pca, runningIn}) {
  // render

  console.log(
    "[APP] env",
    process.env.REACT_APP_ENV_TYPE,
    process.env.REACT_APP_USERFLOW_REDIRECT_URI
  );

  return (
    <Provider store={store}>
      <UpdateRunningIn runningIn={runningIn} />
      {/* <StyledEngineProvider injectFirst> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UpdateIsMobile />
        <MsalProvider instance={pca}>
          <Router />
        </MsalProvider>
      </ThemeProvider>
      {/* </StyledEngineProvider> */}
    </Provider>
  );
}

export default App;
