import {client} from "API/capla360";
import remoteToStateFormTemplate from "./utils/remoteToStateFormTemplate";
import stateToRemoteFormTemplate from "./utils/stateToRemoteFormTemplate";

export async function fetchFormTemplatesService({accessToken, organisationId}) {
  const response = await client.get(
    `/organisations/${organisationId}/form_templates/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteFormTemplates = response.data;
  const items = remoteFormTemplates.map(remoteToStateFormTemplate);
  return {items, organisationId};
}

export async function createFormTemplateService({
  accessToken,
  formTemplate,
  organisationId,
}) {
  const newFormTemplate = {...formTemplate, organisation: organisationId};
  console.log("post CREATE REPORT TEMPLATE", newFormTemplate, accessToken);
  if (accessToken && organisationId) {
    const response = await client.post(
      `/organisations/${organisationId}/form_templates/`,
      newFormTemplate,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const newRemoteModel = response.data;
    const newItem = remoteToStateFormTemplate(newRemoteModel);
    console.log("new form created", newItem);
    return {item: newItem};
  } else {
    throw new Error("missing accessToken or organisationId");
  }
}

export async function fetchFormTemplateService({
  accessToken,
  sceneId,
  formTemplateId,
}) {
  const response = await client.get(
    `/scenes/${sceneId}/bimboxformTemplates/${formTemplateId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: {...response.data, sceneId}};
}

export async function updateFormTemplateService({accessToken, formTemplate}) {
  const organisationId = formTemplate.organisationId;
  const updates = stateToRemoteFormTemplate(formTemplate);

  const response = await client.patch(
    `/organisations/${organisationId}/form_templates/${updates.id}/`,
    updates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteFormTemplate = response.data;
  const item = remoteToStateFormTemplate(remoteFormTemplate);

  return {item};
}

export const deleteFormTemplateService = async ({
  accessToken,
  formTemplateId,
  sceneId,
}) => {
  await client.delete(
    `/scenes/${sceneId}/bimboxformTemplates/${formTemplateId}/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return formTemplateId;
};
