import getFoundObjects from "Features/search/utilsSearch/getFoundObjects";
import getParentsNums from "Utils/getParentsNums";

import sortByNum from "Utils/sortByNum";

export default function getFoundCategoriesOrMaterials(
  allCategoriesAndMaterials,
  options
) {
  // options

  const withParents = options.withParents;
  const selectedOnly = options.selectedOnly;

  // edge cases

  if (!allCategoriesAndMaterials) return [];

  // main

  let items = allCategoriesAndMaterials;
  if (selectedOnly) {
    items = allCategoriesAndMaterials.filter((item) => item.selected);
  }

  let foundItems = getFoundObjects(items, {
    searchText: options.searchText,
    searchKeys: ["num", "name"],
  });

  if (withParents) {
    // init
    const foundItemsWithParents = [];
    const itemsByNum = allCategoriesAndMaterials.reduce((ac, item) => {
      ac[item.num] = item;
      return ac;
    }, {});
    const processedNumMap = {};

    // loop
    foundItems.forEach((item) => {
      const parentsNums = getParentsNums(item.num);
      parentsNums.forEach((parentNum) => {
        const parent = itemsByNum[parentNum];
        if (parent && !processedNumMap[parentNum]) {
          foundItemsWithParents.push(parent);
          processedNumMap[parentNum] = true;
        }
      });
      if (!processedNumMap[item.num]) {
        foundItemsWithParents.push(item);
        processedNumMap[item.num] = true;
      }
    });

    // return
    foundItems = foundItemsWithParents;
  }

  // sort

  foundItems = foundItems.sort((a, b) => sortByNum(a.num, b.num));
  //
  return foundItems;
}
