import {useState} from "react";

import {useSelector, useDispatch} from "react-redux";

import {deleteRoomsBatch} from "../roomsSlice";

import DeleteDialog from "Features/ui/components/DeleteDialog";

import useAccessToken from "Features/auth/useAccessToken";

export default function DialogDeleteRooms({open, onClose}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // state

  const [loading, setLoading] = useState(false);

  // data

  const selectedRoomsGroupId = useSelector((s) => s.rooms.selectedRoomsGroupId);
  const selectedRoomIds = useSelector((s) => s.rooms.selectedRoomIds);

  // handler

  function handleClose() {
    onClose();
    setLoading(false);
  }

  async function handleDeleteConfirm() {
    if (loading) return;
    setLoading(true);
    const deleteProps = {
      groupId: selectedRoomsGroupId,
      roomsIds: selectedRoomIds,
      accessToken,
    };
    await dispatch(deleteRoomsBatch(deleteProps));
    setLoading(false);
    onClose();
  }

  return (
    <DeleteDialog
      open={open}
      onCancel={handleClose}
      onConfirm={handleDeleteConfirm}
      ressource={"rooms"}
      loading={loading}
      multipleSelectionCount={selectedRoomIds?.length}
    />
  );
}
