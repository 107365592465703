import React from "react";

import {Box, Checkbox, Grid, Typography} from "@mui/material";

export default function FieldCheckbox({
  name,
  value,
  onChange,
  locked,
  sizeKey = 6,
  horizontal,
  horizontalHeader,
  width,
}) {
  return (
    <Grid
      container
      sx={{
        pr: 1,
        minWidth: horizontal ? width : "unset",
        width: horizontal ? width : "unset",
        borderBottom: horizontal
          ? "unset"
          : (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      {(!horizontal || horizontalHeader) && (
        <Grid item xs={horizontalHeader ? 12 : sizeKey}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: 1,
              pl: 1,
              bgcolor: "background.default",
              color: "text.secondary",
            }}
          >
            <Typography sx={{fontSize: 13}}>{name}</Typography>
          </Box>
        </Grid>
      )}
      {!horizontalHeader && (
        <Grid
          item
          xs={horizontal ? 12 : 12 - sizeKey}
          sx={{borderLeft: (theme) => `1px solid ${theme.palette.divider}`}}
        >
          <Checkbox
            sx={{height: 20, width: 30}}
            size="small"
            checked={value ? true : false}
            onChange={(e) => onChange(e.target.checked)}
            disabled={locked}
          />
        </Grid>
      )}
    </Grid>
  );
}
