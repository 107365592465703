import {useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import {
  createRoomsBatch,
  updateRoomsBatch,
  deleteRoomsBatch,
} from "../roomsSlice";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Button,
} from "@mui/material";

import CardFileSelector from "Features/files/components/CardFileSelector";
import getExcelRowsFromFile from "Features/excel/utils/getExcelRowsFromFile";

import {setSnackbarMessage} from "Features/ui/uiSlice";

import useUpdateRoomsGroup from "../hooks/useUpdateRoomsGroup";

import useSelectedRoomsGroup from "../hooks/useSelectedRoomsGroup";

import roomsFromExcelToRoomsToSync from "../utils/roomsFromExcelToRoomsToSync";
import excelRowToRoom from "../utils/excelRowToRoom";

import useAccessToken from "Features/auth/useAccessToken";

export default function DialogImportRoomsFromExcel({open, onClose, scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const title = "Importez des pièces";

  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  const buttonName = "Choisir un fichier";

  // data

  const roomsMap = useSelector((s) => s.rooms.roomsMap);

  const updateRoomsGroup = useUpdateRoomsGroup(scene);

  const selectedRoomsGroupId = useSelector((s) => s.rooms.selectedRoomsGroupId);
  const selectedRoomsGroup = useSelectedRoomsGroup();

  // state

  const [roomsToSync, setRoomsToSync] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  // handlers

  async function handleFileChange(file) {
    const defaultValues = {roomsGroupId: selectedRoomsGroupId};
    setFile(file);
    const excelRows = await getExcelRowsFromFile(file, 2);
    const roomsFromExcel = [];
    for (let row of excelRows) {
      const room = excelRowToRoom(row);
      roomsFromExcel.push(room);
    }
    console.log("roomsFromExcel", roomsFromExcel);
    const roomsToSync = roomsFromExcelToRoomsToSync(roomsFromExcel, {
      roomsMap,
      defaultValues,
    });
    //
    console.log("rooms to load", roomsToSync);
    //
    setRoomsToSync(roomsToSync);
  }

  function handleCancelClick() {
    onClose();
  }
  async function handleSaveClick() {
    if (loading) return;
    setLoading(true);
    // batchCreate
    const rooms = roomsToSync.roomsToCreate;
    if (rooms?.length > 0) {
      await dispatch(createRoomsBatch({accessToken, rooms}));
    }

    // batchUpdate
    const roomsToUpdate = roomsToSync.roomsToUpdate;
    if (roomsToUpdate?.length > 0) {
      await dispatch(
        updateRoomsBatch({accessToken, updatesBatch: roomsToUpdate})
      );
    }

    // batchDelete
    const roomsToDelete = roomsToSync.roomsToDelete;
    const deleteProps = {
      groupId: selectedRoomsGroupId,
      roomsIds: roomsToDelete.map((a) => a.id),
      accessToken,
    };
    if (roomsToDelete?.length > 0)
      await dispatch(deleteRoomsBatch(deleteProps));

    // update groups
    const childrenClientIds = roomsToSync.rootChildrenClientIds;
    const roomsGroupId = selectedRoomsGroupId;

    const groupUpdates = {
      sceneId: scene?.id,
      id: roomsGroupId,
      childrenClientIds,
    };
    await updateRoomsGroup(groupUpdates);

    setLoading(false);
    const triggeredAt = Date.now();
    const message = `Tableau ${selectedRoomsGroup?.name} mis à jour`;
    dispatch(setSnackbarMessage({message, triggeredAt}));
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 1}}>
          <Box sx={{width: 1, display: "flex", justifyContent: "center", p: 2}}>
            <CardFileSelector
              file={file}
              onFileChange={handleFileChange}
              buttonName={buttonName}
              accept=".xlsx"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button disabled={loading} onClick={handleSaveClick}>
          {saveS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
