export default function getRoomsWithNum(roomsGroup, rooms) {
  //
  //console.log("getRoomsWithNum", roomsGroup, rooms);
  //
  const roomsWithNum = [];
  const processedRoomClientIdMap = {}; // to add rooms without parent at the end.
  let rootNumCount = 0; // to add a num to the rooms without parent at the end.

  // edge case : no roomsGroup children
  if (!roomsGroup?.childrenClientIds) {
    return rooms.map((room, index) => {
      return {...room, num: (index + 1).toString()};
    });
  }

  //
  const roomsByClientId = rooms.reduce((acc, room) => {
    acc[room.clientId] = room;
    return acc;
  }, {});

  //
  let rootItems = [];
  if (roomsGroup?.childrenClientIds?.length > 0) {
    rootItems = roomsGroup.childrenClientIds.map((clientId, index) => {
      const numInt = index + 1;
      rootNumCount = numInt;
      const rootItem = {...roomsByClientId[clientId]};
      rootItem.num = numInt.toString();
      return rootItem;
    });
  }
  //
  function processBrotherItems(brotherItems) {
    brotherItems.forEach((item) => {
      processedRoomClientIdMap[item.clientId] = true;
      roomsWithNum.push(item);
      const childrenClientIds = item.childrenClientIds;
      const parentNum = item.num;
      if (childrenClientIds) {
        const childrenItems = childrenClientIds.map((clientId, index) => {
          const room = {...roomsByClientId[clientId]};
          room.num = parentNum + "." + (index + 1);
          return room;
        });
        processBrotherItems(childrenItems);
      }
    });
    //
  }
  //
  processBrotherItems(rootItems);

  // add rooms without parent at the end (no parent nor group parent)

  rooms.forEach((room) => {
    if (!processedRoomClientIdMap[room.clientId]) {
      rootNumCount++;
      roomsWithNum.push({
        ...room,
        num: rootNumCount.toString(),
        noParent: true, // no parent / not root.
      });
    }
  });
  //
  return roomsWithNum;
}
