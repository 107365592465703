import {useDispatch} from "react-redux";
import {useState, useEffect} from "react";

import {createReport} from "../reportsSlice";

import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import useFormTemplatesByScene from "Features/forms/hooks/useFormTemplatesByScene";
import useReportTemplates from "../hooks/useReportTemplates";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  TextField,
} from "@mui/material";

import SelectorAutocomplete from "Components/SelectorAutocomplete";

import useAccessToken from "Features/auth/useAccessToken";

export default function DialogCreateReport({open, onClose, scene}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();
  const sceneId = scene?.id;
  const organisationId = scene?.organisationId;

  // strings

  const title = "Nouveau rapport";
  const nameS = "Nom du rapport";
  const clientReferenceS = "Référence";
  const reportTemplatesS = "Modèle de rapport";
  const createS = "Créer";

  // data

  const reportTemplates = useReportTemplates(scene, {fetchData: true});
  const formTemplates = useFormTemplatesByScene(scene, {fetchData: true});

  const scope = useSelectedScope();

  // state

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [clientReference, setClientReference] = useState("");
  const [reportTemplate, setReportTemplate] = useState(null);

  // helpers

  const mainFormTemplate = formTemplates?.find(
    (t) => t.id === reportTemplate?.mainFormTemplateId
  );

  console.log("mainFormTemplate", mainFormTemplate, formTemplates);

  // effect

  useEffect(() => {
    if (!name && reportTemplate) {
      setName(reportTemplate.name);
    }
  });

  useEffect(() => {
    if (!clientReference && reportTemplate) {
      const ref = `${scene.clientReference}-${scope.clientReference}`;
      setClientReference(ref);
    }
  }, [reportTemplate?.id]);

  // handlers

  function handleClose() {
    setReportTemplate(null);
    setName("");
    setClientReference("");
    onClose();
  }

  async function handleCreate() {
    const report = {};
    report.scopeId = scope.id;
    report.organisationId = organisationId;
    report.clientReference = clientReference;
    report.version = 1;
    report.versionDescription = "1ère diffusion";
    report.name = name;
    report.reportTemplateId = reportTemplate?.id;
    report.mainFormData = [...mainFormTemplate?.data];

    //
    if (loading) return;
    setLoading(true);
    //
    await dispatch(createReport({accessToken, report}));
    setLoading(false);
    handleClose();
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{width: 400, pt: 3}}>
          <Box sx={{width: 1}}>
            <SelectorAutocomplete
              name={reportTemplatesS}
              value={reportTemplate}
              onChange={setReportTemplate}
              options={reportTemplates}
            />
          </Box>
          <Box sx={{width: 1, mt: 4}}>
            <TextField
              fullWidth
              size="small"
              label={nameS}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box sx={{width: 1, mt: 2}}>
            <TextField
              fullWidth
              size="small"
              label={clientReferenceS}
              value={clientReference}
              onChange={(e) => setClientReference(e.target.value)}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreate}>{createS}</Button>
      </DialogActions>
    </Dialog>
  );
}
