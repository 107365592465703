import getParentNum from "Utils/getParentNum";

export default function getRoomsWithParentIdFromNum(rooms) {
  const roomsByNum = rooms.reduce((ac, room) => {
    ac[room.num] = room;
    return ac;
  }, {});

  const roomsWithParentId = rooms.map((room) => {
    const parentNum = getParentNum(room.num);
    const parent = roomsByNum[parentNum];
    return {...room, parentId: parent?.id};
  });

  return roomsWithParentId;
}
