// output: initial position of the image in stage, centered in the blueprint

export default function getImageDefaultPosition(
  imageSize,
  blueprintBboxInStage
) {
  //
  const imageWidth = imageSize.width;
  const imageHeight = imageSize.height;
  //
  const blueprintWidth = blueprintBboxInStage.width;
  const blueprintHeight = blueprintBboxInStage.height;
  //
  const x = blueprintBboxInStage.x + (blueprintWidth - imageWidth) / 2;
  const y = blueprintBboxInStage.y + (blueprintHeight - imageHeight) / 2;
  //
  return {x, y};
}
