import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  setSelectedMaterialsGroupId,
  updateMaterialsGroup,
} from "../materialsSlice";
import {setSnackbarMessage} from "Features/ui/uiSlice";

import useSelectedMaterialsGroup from "../hooks/useSelectedMaterialsGroup";

import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

import SectionSelectorMaterialsNomenclature from "./SectionSelectorMaterialsNomenclature";

import useAccessToken from "Features/auth/useAccessToken";

export default function DialogRenameMaterialsGroup({
  scene,
  open,
  onClose,
  onCreated,
}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const title = "Renommer liste de matériaux";

  const nameS = "Nom";
  const placeholder = "Nom de la liste de matériaux";

  const updateS = "Mettre à jour";

  // data

  const group = useSelectedMaterialsGroup();

  // state

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  // init - name

  useEffect(() => {
    if (group.name) {
      setName(group.name);
    }
  }, [group?.id]);

  // handlers

  function handleNameChange(e) {
    setName(e.target.value);
  }
  function handleClose() {
    setLoading(false);
    onClose();
  }

  async function handleSave() {
    try {
      if (loading) return;
      setLoading(true);
      //
      const updates = {
        name,
        sceneId: scene?.id,
        id: group.id,
      };
      //
      await dispatch(updateMaterialsGroup({updates, accessToken}));
      //
      setLoading(false);
      //
      const triggeredAt = Date.now();
      const message = "Liste mise à jour";
      dispatch(setSnackbarMessage({triggeredAt, message}));

      onClose();
    } catch (e) {
      console.log("error", e);
    }
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 2}}>
          <TextField
            size="small"
            fullWidth
            placeholder={placeholder}
            sx={{mt: 2}}
            //label={<Typography variant="body2">{nameS}</Typography>}
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            value={name}
            onChange={handleNameChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading || name?.length === 0} onClick={handleSave}>
          {updateS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
