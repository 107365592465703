import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";
import useSelection from "Features/selection/useSelection";

import ActionsNewSceneInSelectionPanel from "Features/scenes/components/ActionsNewSceneInSelectionPanel";
import ActionsSceneInSelectionPanel from "Features/scenes/components/ActionsSceneInSelectionPanel";
import ActionsMarkerInSelectionPanel from "Features/markers/components/ActionsMarkerInSelectionPanel";
import ActionsNewMarkerInSelectionPanel from "Features/markers/components/ActionsNewMarkerInSelectionPanel";
import ActionsElementTypeInSelectionPanel from "Features/elementTypes/components/ActionsElementTypeInSelectionPanel";
import ActionsNewElementTypeInSelectionPanel from "Features/elementTypes/components/ActionsNewElementTypeInSelectionPanel";
import ActionsRessourceInSelectionPanel from "Features/ressources/components/ActionsRessourceInSelectionPanel";
import ActionsNewRessourceInSelectionPanel from "Features/ressources/components/ActionsNewRessourceInSelectionPanel";
import ActionsMeasurementInSelectionPanel from "Features/measurements/components/ActionsMeasurementInSelectionPanel";
import ActionsMeasurementMultiModelsInSelectionPanel from "Features/measurements/components/ActionsMeasurementMultiModelsInSelectionPanel";
import ActionsPackageInSelectionPanel from "Features/elementPackages/components/ActionsPackageInSelectionPanel";
import ActionsIssueInSelectionPanel from "Features/issues/components/ActionsIssueInSelectionPanel";
import ActionsNewIssueInSelectionPanel from "Features/issues/components/ActionsNewIssueInSelectionPanel";

import ActionsNewInputInSelectionPanel from "Features/inputs/components/ActionsNewInputInSelectionPanel";
import ActionsPdfInSelectionPanel from "Features/pdf/components/ActionsPdfInSelectionPanel";
import ActionsRoomsGroupInSelectionPanel from "Features/rooms/components/ActionsRoomsGroupInSelectionPanel";
import ActionsRoomInSelectionPanel from "Features/rooms/components/ActionsRoomInSelectionPanel";
import ActionsNewRoomInSelectionPanel from "Features/rooms/components/ActionsNewRoomInSelectionPanel";
import ActionsSectorInSelectionPanel from "Features/sectors/components/ActionsSectorInSelectionPanel";
import ActionsNewSectorInSelectionPanel from "Features/sectors/components/ActionsNewSectorInSelectionPanel";
import ActionsAnnotatedPdfInSelectionPanel from "Features/annotatedPdf/components/ActionsAnnotatedPdfInSelectionPanel";
import ActionsZoneInSelectionPanel from "Features/zones/components/ActionsZoneInSelectionPanel";

import ActionsSharedboxInSelectionPanel from "Features/sharedboxes/components/ActionsSharedboxInSelectionPanel";
import ActionsNewSharedboxInSelectionPanel from "Features/sharedboxes/components/ActionsNewSharedboxInSelectionPanel";
import ActionsHomeMeasurementInSelectionPanel from "Features/boxhome/components/ActionsHomeMeasurementInSelectionPanel";
import ActionsMaterialInSelectionPanel from "Features/materials/components/ActionsMaterialInSelectionPanel";
import ActionsNewMaterialInSelectionPanel from "Features/materials/components/ActionsNewMaterialInSelectionPanel";
import ActionsNewPhaseInSelectionPanel from "Features/phases/components/ActionsNewPhaseInSelectionPanel";
import ActionsSampleInSelectionPanel from "Features/samples/components/ActionsSampleInSelectionPanel";
import ActionsReportInSelectionPanel from "Features/reports/components/ActionsReportInSelectionPanel";
import ActionsBlockInSelectionPanel from "Features/reportBlocks/components/ActionsBlockInSelectionPanel";
import ActionsRoomMaterialsInSelectionPanel from "Features/materials/components/ActionsRoomMaterialsInSelectionPanel";

export default function SectionSelection({caplaEditor, scene, selection}) {
  // data

  const sceneModule = useSceneModule();
  const pageType = usePageType();
  const isEditing = useSelector((s) => s.measurements.isEditingMeasurement);

  return (
    <Box sx={{width: 1}}>
      {selection?.type === "NEW_SCENE" && <ActionsNewSceneInSelectionPanel />}
      {selection?.type === "SCENE" && <ActionsSceneInSelectionPanel />}
      {selection?.type === "MARKER" && (
        <ActionsMarkerInSelectionPanel editor3d={caplaEditor?.editor3d} />
      )}
      {selection?.type === "NEW_MARKER" && (
        <ActionsNewMarkerInSelectionPanel editor3d={caplaEditor?.editor3d} />
      )}
      {selection?.type === "RESSOURCE" && (
        <ActionsRessourceInSelectionPanel scene={scene} />
      )}
      {selection?.type === "NEW_RESSOURCE" && (
        <ActionsNewRessourceInSelectionPanel scene={scene} />
      )}
      {selection?.type === "ELEMENT_TYPE" && (
        <ActionsElementTypeInSelectionPanel
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {selection?.type === "NEW_ELEMENT_TYPE" && (
        <ActionsNewElementTypeInSelectionPanel scene={scene} />
      )}
      {selection?.type === "MEASUREMENT" &&
        (sceneModule === "EDIT_MEASUREMENTS" ||
          pageType === "COLORING" ||
          isEditing) && (
          <ActionsMeasurementInSelectionPanel
            scene={scene}
            caplaEditor={caplaEditor}
          />
        )}
      {selection?.type === "MEASUREMENT" &&
        sceneModule === "MEASUREMENTS" &&
        !isEditing && (
          <ActionsMeasurementMultiModelsInSelectionPanel scene={scene} />
        )}
      {selection?.type === "MEASUREMENT" && sceneModule === "HOME" && (
        <ActionsHomeMeasurementInSelectionPanel
          caplaEditor={caplaEditor}
          scene={scene}
        />
      )}
      {(selection?.type === "PACKAGE" || selection?.type === "NEW_PACKAGE") && (
        <ActionsPackageInSelectionPanel
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {selection?.type === "ISSUE" && sceneModule !== "HOME" && (
        <ActionsIssueInSelectionPanel />
      )}
      {selection?.type === "NEW_ISSUE" && (
        <ActionsNewIssueInSelectionPanel scene={scene} />
      )}

      {selection?.type === "TEMP_INPUT" && (
        <ActionsNewInputInSelectionPanel editor3d={caplaEditor?.editor3d} />
      )}
      {selection?.type === "PDF_MODEL" && <ActionsPdfInSelectionPanel />}
      {selection?.type === "TEMP_SECTOR" && (
        <ActionsNewSectorInSelectionPanel scene={scene} />
      )}
      {selection?.type === "SECTOR" && (
        <ActionsSectorInSelectionPanel scene={scene} />
      )}
      {selection?.type === "ROOMS_GROUP" && (
        <ActionsRoomsGroupInSelectionPanel scene={scene} />
      )}
      {selection?.type === "TEMP_ROOM" && (
        <ActionsNewRoomInSelectionPanel scene={scene} />
      )}
      {selection?.type === "ROOM" && (
        <ActionsRoomInSelectionPanel scene={scene} />
      )}
      {selection?.type === "ANNOTATED_PDF_MODEL" && (
        <ActionsAnnotatedPdfInSelectionPanel
          caplaEditor={caplaEditor}
          scene={scene}
        />
      )}
      {selection?.type === "ZONE" && (
        <ActionsZoneInSelectionPanel
          editorPdf={caplaEditor?.editorPdf}
          scene={scene}
        />
      )}

      {selection?.type === "NEW_SHAREDBOX" && (
        <ActionsNewSharedboxInSelectionPanel scene={scene} />
      )}
      {selection?.type === "SHAREDBOX" && (
        <ActionsSharedboxInSelectionPanel scene={scene} />
      )}
      {selection?.type === "MATERIAL" && (
        <ActionsMaterialInSelectionPanel scene={scene} />
      )}
      {selection?.type === "NEW_MATERIAL" && (
        <ActionsNewMaterialInSelectionPanel scene={scene} />
      )}
      {selection?.type === "ROOM_IN_MATERIALS" && (
        <ActionsRoomMaterialsInSelectionPanel scene={scene} />
      )}
      {selection?.type === "NEW_PHASE" && (
        <ActionsNewPhaseInSelectionPanel scene={scene} />
      )}
      {(selection?.type === "SAMPLE" || selection?.type === "NEW_SAMPLE") && (
        <ActionsSampleInSelectionPanel scene={scene} />
      )}
      {(selection?.type === "REPORT" || selection?.type === "NEW_REPORT") && (
        <ActionsReportInSelectionPanel scene={scene} />
      )}
      {(selection?.type === "BLOCK" || selection?.type === "NEW_BLOCK") && (
        <ActionsBlockInSelectionPanel scene={scene} />
      )}
    </Box>
  );
}
