import {Box} from "@mui/material";
import {RichTreeView} from "@mui/x-tree-view/RichTreeView";

import buildTreeFromItemsWithNum from "Utils/buildTreeFromItemsWithNum";

export default function TreeNomenclatureItems({
  nomenclatureItems,
  selectedCategoryId,
  onSelectedCategoryIdChange,
}) {
  // helpers

  const tree = buildTreeFromItemsWithNum(nomenclatureItems);

  const selectedItems = selectedCategoryId;

  // handlers

  function handleSelectionChange(event, id) {
    if (id === selectedCategoryId) {
      onSelectedCategoryIdChange(null);
    } else {
      onSelectedCategoryIdChange(id);
    }
  }

  return (
    <RichTreeView
      items={tree}
      multiSelect={false}
      selectedItems={selectedItems}
      onSelectedItemsChange={handleSelectionChange}
    />
  );
}
