import {Box} from "@mui/material";

import SectionActionsSamples from "./SectionActionsSamples";

export default function SectionLeftPanelActionsSamples({scene, caplaEditor}) {
  // actions - main

  const actionsMainKeys = ["exportExcelSamples", "importExcelSamples"];
  const actionsMoreKeys = ["fetchSamples", "divider", "deleteSample"];

  return (
    <Box>
      <SectionActionsSamples
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
