import {useState} from "react";

import {Box} from "@mui/material";

import ViewBarRoomsActionsRooms from "./ViewBarRoomsActionsRooms";
import SearchBarRooms from "./SearchBarRooms";

export default function ViewBarRooms({scene, caplaEditor}) {
  // state

  const [searchBarOnly, setSearchBarOnly] = useState(false);

  // handlers

  function handleFocusChange(focused) {
    setSearchBarOnly(focused);
  }

  return (
    <Box
      sx={{
        width: 1,
        bgcolor: "common.white",
        height: "36px",
        p: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{display: "flex", alignItems: "center", flexGrow: 1, minWidth: 0}}
      >
        <SearchBarRooms onFocusChange={handleFocusChange} />
      </Box>
      {!searchBarOnly && (
        <Box sx={{display: "flex", alignItems: "center", height: 1}}>
          <ViewBarRoomsActionsRooms scene={scene} caplaEditor={caplaEditor} />
        </Box>
      )}
    </Box>
  );
}
