import React, {useRef, useEffect, useState} from "react";

import {Box} from "@mui/material";

import ImageEditor from "../js/ImageEditor";

export default function ViewerImage({src, imageSize}) {
  const containerRef = useRef();
  const imageEditorRef = useRef();

  // state

  const [imageEditorIsLoaded, setImageEditorIsLoaded] = useState(false);

  // init

  function init() {
    const containerElement = containerRef.current;

    imageEditorRef.current = new ImageEditor({
      containerElement,
      src,
      imageSize,
    });

    setImageEditorIsLoaded(true);
  }

  useEffect(() => {
    if (containerRef.current) {
      init();
      return () => {
        imageEditorRef.current.clear();
      };
    }
  }, [containerRef.current]);

  // update stage size

  // useEffect(() => {
  //   if (imageEditorIsLoaded) {
  //     imageEditorRef.current.updateStageSize();
  //   }
  // }, [imageEditorIsLoaded]);

  return (
    <Box sx={{width: 1, height: 1, position: "relative"}}>
      <Box
        sx={{
          width: 1,
          height: 1,
          position: "relative",
          bgcolor: "background.default",
          boxSizing: "borderBox",
          overflow: "hidden",
        }}
      >
        <div
          id="container"
          ref={containerRef}
          style={{
            boxSizing: "border-box",
            width: "100%",
            height: "100%",
          }}
        ></div>
      </Box>
    </Box>
  );
}
