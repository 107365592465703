import {client} from "API/capla360";
import remoteToStateReportTemplate from "./utils/remoteToStateReportTemplate";
import stateToRemoteReportTemplate from "./utils/stateToRemoteReportTemplate";

export async function fetchReportTemplatesService({
  accessToken,
  organisationId,
}) {
  const response = await client.get(
    `/organisations/${organisationId}/report_templates/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteReportTemplates = response.data;
  const items = remoteReportTemplates.map(remoteToStateReportTemplate);
  return {items, organisationId};
}

export async function createReportTemplateService({
  accessToken,
  reportTemplate,
  organisationId,
}) {
  const newReportTemplate = {...reportTemplate, organisation: organisationId};
  console.log("post CREATE REPORT TEMPLATE", newReportTemplate, accessToken);
  if (accessToken && organisationId) {
    const response = await client.post(
      `/organisations/${organisationId}/report_templates/`,
      newReportTemplate,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const newRemoteModel = response.data;
    const newItem = remoteToStateReportTemplate(newRemoteModel);
    console.log("new report created", newItem);
    return {item: newItem};
  } else {
    throw new Error("missing accessToken or organisationId");
  }
}

export async function fetchReportTemplateService({
  accessToken,
  sceneId,
  reportTemplateId,
}) {
  const response = await client.get(
    `/scenes/${sceneId}/bimboxreportTemplates/${reportTemplateId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: {...response.data, sceneId}};
}

export async function updateReportTemplateService({
  accessToken,
  reportTemplate,
}) {
  const organisationId = reportTemplate.organisationId;
  const updates = stateToRemoteReportTemplate(reportTemplate);

  const response = await client.patch(
    `/organisations/${organisationId}/report_templates/${updates.id}/`,
    updates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteReportTemplate = response.data;
  const item = remoteToStateReportTemplate(remoteReportTemplate);

  return {item};
}

export const deleteReportTemplateService = async ({
  accessToken,
  reportTemplateId,
  sceneId,
}) => {
  await client.delete(
    `/scenes/${sceneId}/bimboxreportTemplates/${reportTemplateId}/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return reportTemplateId;
};
