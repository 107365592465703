import applyStyleToRow from "./applyStyleToRow";

export default function fillWorksheetWithRooms({
  worksheet,
  rooms,
  roomsGroup,
  rowStartIndex,
}) {
  // header

  const headerRow = worksheet.getRow(rowStartIndex - 1);
  const headerNames = [...(roomsGroup.data?.rankNames || [])];
  headerNames.push("Etiquette");

  headerNames.forEach((name, index) => {
    headerRow.getCell(index + 2).value = name;
    headerRow.getCell(index + 2).style = headerRow.getCell(1).style;
  });

  // content

  const maxRank = rooms.reduce((acc, room) => {
    const rank = room.num.split(".").length;
    return Math.max(acc, rank);
  }, 0);

  const rowLength = 2 + maxRank;

  rooms.forEach((item, index) => {
    const row = worksheet.getRow(rowStartIndex + index);
    const {id, name, label, num} = item;
    const rank = num.split(".").length;

    row.getCell(1).value = id;
    row.getCell(rank + 1).value = name;
    row.getCell(rowLength).value = label;

    applyStyleToRow({row, item, rowType: "ROOM", rowLength});
  });
}
