export default function getPointerCoordsInStage(pointer, stage) {
  const scale = stage.scaleX();
  //
  //const canvas = this.layerImage.getCanvas()._canvas;
  //const rect = canvas.getBoundingClientRect();
  // Add window scroll positions to account for scrolling
  //const x = rect.left + window.scrollX;
  //const y = rect.top + window.scrollY;
  //const height = rect.height;
  //const width = rect.width;
  //
  //const pointer = {x: windowW / 2, y: windowH / 2};
  //const pointer = {x: x + width / 2, y: -y + height / 2};

  const focusPointTo = {
    x: (pointer.x - stage.x()) / scale,
    y: (pointer.y - stage.y()) / scale,
  };
  return focusPointTo;
}
