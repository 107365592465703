import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {updateRoom} from "Features/rooms/roomsSlice";

import {Box, Button, alpha} from "@mui/material";
import {Save} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";

import {
  setIsEditingRoomMaterials,
  setEditedRoomMaterialsData,
} from "../materialsSlice";
import useSelectedRoomInMaterials from "../hooks/useSelectedRoomInMaterials";

export default function ActionsRoomMaterialsInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const isEditing = useSelector((s) => s.materials.isEditingRoomMaterials);
  const editedRoomMaterialsData = useSelector(
    (s) => s.materials.editedRoomMaterialsData
  );
  const selectedRoomInMaterials = useSelectedRoomInMaterials();

  // state

  const [loading, setLoading] = useState(false);

  // effects

  useEffect(() => {
    return () => setLoading(false);
  }, []);

  // helper - saveLabel

  const saveLabel = saveS;

  // handlers

  function handleCancelClick() {
    dispatch(setIsEditingRoomMaterials(false));
  }

  async function handleSaveClick() {
    try {
      setLoading(true);
      // updates
      const updates = {
        id: selectedRoomInMaterials.id,
        roomsGroupId: selectedRoomInMaterials.roomsGroupId,
        materialsData: editedRoomMaterialsData,
      };
      await dispatch(updateRoom({updates, accessToken}));
      //
      dispatch(setIsEditingRoomMaterials(false));
      dispatch(setEditedRoomMaterialsData(null));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            disabled={loading}
            variant="outlined"
            fullWidth
            onClick={handleCancelClick}
          >
            {cancelS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
            disabled={loading}
          >
            {saveLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
}
