import {useDispatch, useSelector} from "react-redux";
import {useParams, useLocation, useNavigate} from "react-router-dom";

import {setSelectedBlockId} from "../blocksSlice";
import {setTempSortedBlocksIds} from "../blocksSlice";

import {Box} from "@mui/material";
import ListBlocks from "./ListBlocks";

import useBlocksByReport from "../hooks/useBlocksByReport";
import useSelectedReport from "Features/reports/hooks/useSelectedReport";
import ListHeaderBlocks from "./ListHeaderBlocks";

export default function SectionBlocksInLeftPanel({scene}) {
  const dispatch = useDispatch();

  // data

  const report = useSelectedReport({fetchData: true, sortBlocks: true});

  const blocks = useBlocksByReport(report, {
    filterByScope: true,
    sortByName: true,
    fetchData: true,
  });

  const selectedBlockId = useSelector((s) => s.blocks.selectedBlockId);

  // handlers

  function handleClick(block) {
    const blockId = block?.id;
    //
    const blockInSelection = selectedBlockId === blockId;
    //
    if (blockInSelection) {
      dispatch(setSelectedBlockId(null));
    } else {
      dispatch(setSelectedBlockId(blockId));
    }
  }

  function handleOrderChange(blocksIds) {
    console.log("newTempSortedBlocksIds", blocksIds);
    dispatch(setTempSortedBlocksIds(blocksIds));
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        minHeight: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ListHeaderBlocks report={report} />
      <Box
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <ListBlocks
          report={report}
          blocks={blocks}
          selectedBlockId={selectedBlockId}
          onClick={handleClick}
          onOrderChange={handleOrderChange}
        />
      </Box>
    </Box>
  );
}
