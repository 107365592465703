import React, {useState} from "react";

import {Box, Typography, Dialog, Grid, ClickAwayListener} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import SelectorMaterialCategoryFromTree from "Features/materials/components/SelectorMaterialCategoryFromTree";
import getItemsMapById from "Utils/getItemsMapById";

export default function FieldMaterialCategory({
  name,
  value,
  materialsNomenclature,
  onChange,
  locked,
  sizeKey = 12,
  treeContainerRef,
}) {
  //

  // state

  const [open, setOpen] = useState(false);

  // helpers

  const bbox = treeContainerRef?.current?.getBoundingClientRect();

  // helper - other item

  const otherItem = {
    id: "other",
    name: "Autre",
  };

  // helpers - materialCategoriesMap

  const categories = materialsNomenclature?.tree?.items ?? [];
  const categoriesMap = getItemsMapById(categories);

  // helpers - roomName

  const categoryRootName = value?.parents?.reduce((acc, parent) => {
    return acc + parent.name + " / ";
  }, "");

  let categoryName = value?.name;
  if (!value) {
    categoryName = "Sélectionnez une catégorie";
  } else if (value.id === "other") {
    categoryName = "Autre";
  } else if (value.children?.length > 0) {
    categoryName = `${categoryName} / Autre`;
  }

  // handlers - dialog

  function handleOpen() {
    if (!locked) setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  // handlers - selector

  function handleChange(materialCategoryId) {
    if (materialCategoryId) {
      if (materialCategoryId === "other") {
        onChange(otherItem);
      } else {
        onChange(categoriesMap[materialCategoryId]);
      }
    } else {
      onChange(null);
    }

    setOpen(false);
  }

  return (
    <Box>
      {open ? (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <Box
            sx={{
              position: "fixed",
              top: 0,
              bottom: 0,
              left: bbox.left,
              width: bbox.width,
              zIndex: 90,
              //height: 100,
              bgcolor: "background.default",
              //border: (theme) => `1px solid ${theme.palette.primary.main}`,
            }}
          >
            <SelectorMaterialCategoryFromTree
              selectedMaterialCategoryId={value?.id}
              materialsNomenclature={materialsNomenclature}
              onSelect={handleChange}
              onClose={handleClose}
            />
          </Box>
        </ClickAwayListener>
      ) : (
        <Grid
          container
          sx={{
            pr: 1,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Grid
            item
            xs={sizeKey}
            sx={{py: 0.25, pl: 1, bgcolor: "background.default"}}
          >
            <Typography sx={{fontSize: 13, color: "text.secondary"}}>
              {name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12 - sizeKey}
            sx={{
              py: 0.25,
              px: 1,
              borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                color: locked ? "text.secondary" : "unset",
              }}
              onClick={handleOpen}
            >
              <Box>
                <Typography sx={{fontSize: 12, color: "text.secondary"}}>
                  {categoryRootName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 13,
                    color: "text.secondary",
                    fontWeight: "bold",
                  }}
                >
                  {categoryName}
                </Typography>
              </Box>
              {!locked && <Down fontSize="small" />}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
