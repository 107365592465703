import React from "react";

import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useKvtRoomsGroup from "Features/kvform/hooks/useKvtRoomsGroup";

export default function FormRoomsGroup({
  roomsGroup,
  rooms,
  onChange,
  canEdit,
  hiddenFields = [],
}) {
  // template

  const template = useKvtRoomsGroup({rooms});

  // item

  const item = {...roomsGroup};

  const [rankName1, rankName2, rankName3] = roomsGroup.data?.rankNames || [];
  if (rankName1) item.rankName1 = rankName1;
  if (rankName2) item.rankName2 = rankName2;
  if (rankName3) item.rankName3 = rankName3;

  // handlers

  function handleChange(changedRoomsGroup) {
    const newRoomsGroup = {...roomsGroup, ...changedRoomsGroup};
    //
    const {rankName1, rankName2, rankName3} = newRoomsGroup;
    const data = {
      ...newRoomsGroup.data,
      rankNames: [rankName1, rankName2, rankName3].filter((r) => r),
    };
    newRoomsGroup.data = data;
    delete newRoomsGroup.rankName1;
    delete newRoomsGroup.rankName2;
    delete newRoomsGroup.rankName3;
    //

    //

    onChange(newRoomsGroup);
  }

  return roomsGroup ? (
    <KeyValueForm
      item={item}
      template={template}
      onChange={handleChange}
      hiddenFields={hiddenFields}
      canEdit={canEdit}
      sizeKey={4}
    />
  ) : (
    <div />
  );
}
