import {Box} from "@mui/material";

import SectionActionsReports from "./SectionActionsReports";

export default function SectionLeftPanelActionsReports({scene, caplaEditor}) {
  // actions - main

  const actionsMainKeys = ["createReport", "duplicateReport"];
  const actionsMoreKeys = ["debug", "divider", "deleteReport"];

  return (
    <Box>
      <SectionActionsReports
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
