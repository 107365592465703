export default function removeMaterialFromMaterialsData(
  materialClientId,
  materialsGroupId,
  materialsData
) {
  // edge case 1

  if (!materialsData) {
    return [];
  }

  // edge case 2

  if (
    !materialsData.find((item) => item.materialsGroupId === materialsGroupId)
  ) {
    return materialsData;
  }

  // edge case 3
  const item = materialsData.find(
    (item) => item.materialsGroupId === materialsGroupId
  );
  if (
    !item.materialsClientIds.find((clientId) => clientId === materialClientId)
  ) {
    return materialsData;
  }

  // main

  const update = materialsData.map((item) => {
    if (item.materialsGroupId === materialsGroupId) {
      return {
        ...item,
        materialsClientIds: item.materialsClientIds.filter(
          (clientId) => clientId !== materialClientId
        ),
      };
    } else {
      return item;
    }
  });
  return update;
}
