export default function useKvtRoom(data) {
  const rooms = data?.rooms || [];
  const materialsGroupsWithNomenclatureAndMaterials =
    data?.materialsGroupsWithNomenclatureAndMaterials || [];

  const template = {
    key: "kvfRoom",
    groups: [
      {code: "INFO", label: "Info"},
      {code: "MATERIALS", label: "Matériaux"},
    ],
    fields: [
      //{key: "num", type: "text", name: numS},
      {group: "INFO", key: "name", type: "text", name: "Nom"},
      {group: "INFO", key: "tree", type: "section", name: "Arborescence"},
      {
        group: "INFO",
        key: "parentRoom",
        type: "combo",
        name: "Parent",
        options: rooms,
        sortByNum: true,
      },
      {
        group: "INFO",
        key: "childrenClientIds",
        type: "children",
        name: "Enfants",
        options: rooms,
        id: "clientId",
      },
      {group: "INFO", key: "labelSection", type: "section", name: "Etiquette"},
      {group: "INFO", key: "label", type: "text", name: "Etiquette"},
      {
        group: "MATERIALS",
        key: "materialsData",
        type: "materialsData",
        name: "Matériaux",
        materialsGroupsWithNomenclatureAndMaterials,
      },
      //{key: "sector", type: "text", name: sectorS},
      //{key: "surface", type: "number", name: surfaceS},
      //{key: "hsp", type: "number", name: hspS},
      //{key: "type", type: "text", name: typeS},
      //{key: "hsfp", type: "number", name: hsfpS}, => no need : pass by measurements height.
    ],
  };

  return template;
}
