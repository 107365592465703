import React from "react";

import {Box, Paper, Typography} from "@mui/material";

export default function BlockDataKeyValuePairs({keyValuePairs}) {
  return (
    <Box sx={{width: 1, p: 1}}>
      {keyValuePairs.map((pair) => {
        if (pair.type !== "section") {
          return (
            <Box
              key={pair.key}
              sx={{mb: 1, display: "flex", width: 1, alignItems: "start"}}
            >
              <Box sx={{px: 1, width: 150}}>
                <Typography
                  variant="body2"
                  color={pair.value ? "text.secondary" : "error.main"}
                >
                  {pair.name}
                </Typography>
              </Box>
              <Box sx={{flexGrow: 1, px: 1}}>
                <Typography variant="body2" sx={{whiteSpace: "pre-line"}}>
                  {pair.value}
                </Typography>
              </Box>
            </Box>
          );
        } else {
          return (
            <Box
              sx={{
                color: "grey.400",
                width: 1,
                display: "flex",
                justifyContent: "center",
                p: 1,
                mb: 1,
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontStyle: "italic",
                }}
              >
                {pair.name}
              </Typography>
            </Box>
          );
        }
      })}
    </Box>
  );
}
