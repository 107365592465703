export default function stateToRemoteSample(sample) {
  const remoteSample = {...sample};

  // survey
  remoteSample.survey = sample.surveyId;
  delete remoteSample.surveyId;

  // scene
  remoteSample.scene = sample.sceneId;
  delete remoteSample.sceneId;

  // result
  return remoteSample;
}
