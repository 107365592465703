import {useDispatch} from "react-redux";

import {setOpenPanelAutoExtract} from "../pdfSlice";

import {Box, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import ButtonExtractText from "./ButtonExtractText";

export default function PanelAutoExtractFromPdf({caplaEditor}) {
  const dispatch = useDispatch();

  // handlers

  function handleClose() {
    dispatch(setOpenPanelAutoExtract(false));
  }

  return (
    <Box sx={{bgcolor: "common.white", height: 1, width: 1}}>
      <Box sx={{width: 1, display: "flex", justifyContent: "end"}}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
        }}
      >
        <ButtonExtractText caplaEditor={caplaEditor} />
      </Box>
    </Box>
  );
}
