import {useDispatch, useSelector} from "react-redux";

import {setSelectedReportTemplateId} from "Features/reports/reportsSlice";

import useOrganisationReportTemplates from "../hooks/useOrganisationReportTemplates";

import {Box, Typography} from "@mui/material";

import ListReportTemplates from "Features/reports/components/ListReportTemplates";

export default function SectionReportTemplates() {
  const dispatch = useDispatch();

  // string

  const noTemplates = "Aucun modèle";

  // data

  const reportTemplates = useOrganisationReportTemplates();
  const selectedReportTemplateId = useSelector(
    (state) => state.reports.selectedReportTemplateId
  );

  // helpers

  const selection = selectedReportTemplateId
    ? [selectedReportTemplateId]
    : null;

  // helpers

  const isEmpty = reportTemplates.length === 0;

  // handlers

  function handleClick(reportTemplate) {
    if (selectedReportTemplateId === reportTemplate.id) {
      dispatch(setSelectedReportTemplateId(null));
    } else {
      dispatch(setSelectedReportTemplateId(reportTemplate.id));
    }
  }
  return (
    <Box
      sx={{width: 1, p: 2, display: "flex", flexDirection: "column", height: 1}}
    >
      <Box sx={{width: 1, bgcolor: "common.white"}}>
        {isEmpty && (
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>{noTemplates}</Typography>
          </Box>
        )}
        {!isEmpty && (
          <ListReportTemplates
            reportTemplates={reportTemplates}
            onClick={handleClick}
            selection={selection}
          />
        )}
      </Box>
    </Box>
  );
}
