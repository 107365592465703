import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {nanoid, unwrapResult} from "@reduxjs/toolkit";

import {
  setIsEditingReport,
  setSelectedReportId,
  createReport,
  updateReport,
} from "../reportsSlice";

import useReportInSelectionPanel from "../hooks/useReportInSelectionPanel";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

export default function ActionsReportInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const organisationId = scene?.organisationId;

  // strings

  const createS = "Créer";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const isEditing = useSelector((s) => s.reports.isEditingReport);
  const report = useReportInSelectionPanel();
  const scope = useSelectedScope();

  // state

  const [loading, setLoading] = useState(false);

  // helper

  const isNew = !report?.id;

  // helper - saveLabel

  const saveLabel = isNew ? createS : saveS;

  // helpers - functions

  async function create() {
    if (loading) return;
    try {
      setLoading(true);
      const newReport = {
        ...report,
        scopeId: scope?.id,
        organisationId,
        version: 1,
      };
      const result = await dispatch(
        createReport({
          accessToken,
          report: newReport,
        })
      );
      const {item} = unwrapResult(result);
      dispatch(setSelectedReportId(item?.id));
      setLoading(false);
    } catch (error) {
      console.error("[ActionsReportInSelectionPanel] error", error);
      setLoading(false);
    }
  }

  async function update() {
    if (loading) return;
    setLoading(true);
    console.log("update report", report);
    const updates = {...report};
    if (!updates.scopeId) updates.scopeId = scope?.id;
    if (!updates.organisationId) updates.organisationId = organisationId;
    await dispatch(updateReport({accessToken, updates}));
    dispatch(setIsEditingReport(false));
    setLoading(false);
  }

  // handlers

  function handleCancelClick() {
    dispatch(setIsEditingReport(false));
  }

  async function handleSaveClick() {
    if (isNew) {
      await create();
    } else {
      await update();
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {(isEditing || isNew) && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
}
