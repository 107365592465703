import {useSelector, useDispatch} from "react-redux";

import useRooms from "../hooks/useRooms";
import useSelectedRoom from "../hooks/useSelectedRoom";
import {
  setEditedRoom,
  setEditedRoomParentChange,
  setIsEditingRoom,
  setTempRoom,
} from "../roomsSlice";

import {Box} from "@mui/material";

import FormRoom from "./FormRoom";
import useRoomInSelectionPanel from "../hooks/useRoomInSelectionPanel";
import useMaterialsGroupsWithNomenclatureAndMaterials from "Features/materials/hooks/useMaterialsGroupsWithNomenclatureAndMaterials";

export default function SectionRoomInSelectionPanel({scene}) {
  const dispatch = useDispatch();

  // data

  const selectedRoomsGroupId = useSelector((s) => s.rooms.selectedRoomsGroupId);

  const options = {
    filterByRoomsGroupId: selectedRoomsGroupId,
    withNum: true,
    withParentId: true,
  };

  const rooms = useRooms(options);
  const roomInSelectionPanel = useRoomInSelectionPanel(scene);

  const materialsGroupsWithNomenclatureAndMaterials =
    useMaterialsGroupsWithNomenclatureAndMaterials();

  // helper

  const isTemp = !roomInSelectionPanel.id;

  // handlers

  function handleTempRoomChange(room) {
    dispatch(setTempRoom(room));
  }
  function handleEditedRoomChange(room) {
    dispatch(setIsEditingRoom(true));
    dispatch(setEditedRoom(room));
  }

  function handleRoomChange(room) {
    if (isTemp) {
      handleTempRoomChange(room);
    } else {
      handleEditedRoomChange(room);
    }
  }

  function handleParentChange(editedRoomParentChange) {
    dispatch(setEditedRoomParentChange(editedRoomParentChange));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        height: 1,
        minHeight: 0,
      }}
    >
      <FormRoom
        room={roomInSelectionPanel}
        rooms={rooms}
        materialsGroupsWithNomenclatureAndMaterials={
          materialsGroupsWithNomenclatureAndMaterials
        }
        onChange={handleRoomChange}
        onParentChange={handleParentChange}
      />
    </Box>
  );
}
