import {useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import {setIsEditingListBlocks} from "../blocksSlice";
import {updateReport} from "Features/reports/reportsSlice";
import {setTempSortedBlocksIds} from "../blocksSlice";

import useBlocksByReport from "../hooks/useBlocksByReport";

import {Edit} from "@mui/icons-material";
import {Box, IconButton, Button} from "@mui/material";

import useAccessToken from "Features/auth/useAccessToken";

export default function ListHeaderBlocks({report}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const blocks = useBlocksByReport(report, {sortBlocks: true});

  const tempSortedBlocksIds = useSelector((s) => s.blocks.tempSortedBlocksIds);

  const isEditing = useSelector((s) => s.blocks.isEditingListBlocks);

  // state

  const [loading, setLoading] = useState(false);

  // handlers

  function handleEditClick() {
    dispatch(setIsEditingListBlocks(true));
    dispatch(setTempSortedBlocksIds(blocks.map((block) => block.id)));
  }

  async function handleSave() {
    try {
      setLoading(true);
      const updates = {
        id: report.id,
        scopeId: report.scopeId,
        sortedBlocksIds: tempSortedBlocksIds,
      };
      await dispatch(updateReport({accessToken, updates}));
      setLoading(false);
      dispatch(setIsEditingListBlocks(false));
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }

  function handleCancel() {
    dispatch(setIsEditingListBlocks(false));
  }

  return (
    <Box sx={{width: 1}}>
      {isEditing && (
        <Box
          sx={{
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            "& > *": {ml: 1},
            color: "common.white",
            bgcolor: "warning.main",
          }}
        >
          <Button
            disabled={loading}
            size="small"
            color="inherit"
            onClick={handleCancel}
          >
            {cancelS}
          </Button>
          <Button
            disabled={loading}
            size="small"
            color="inherit"
            onClick={handleSave}
          >
            {saveS}
          </Button>
        </Box>
      )}
      {!isEditing && (
        <Box sx={{width: 1, display: "flex", justifyContent: "end", px: 1}}>
          <IconButton size="small" onClick={handleEditClick}>
            <Edit fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
