import {useSelector} from "react-redux";
import getMaterialCode from "../utils/getMaterialCode";
import useMaterialsByScene from "./useMaterialsByScene";
import useRooms from "Features/rooms/hooks/useRooms";

import getRoomsByMaterialClientIdFromRooms from "../utils/getRoomsByMaterialClientIdFromRooms";

export default function useSelectedMaterial(options) {
  // options

  const withRooms = options?.withRooms;

  // main

  const selectedMaterialsGroupId = useSelector(
    (s) => s.materials.selectedMaterialsGroupId
  );
  const materialsMap = useSelector((s) => s.materials.materialsMap);
  const materials = Object.values(materialsMap).filter(
    (m) => m.materialsGroupId === selectedMaterialsGroupId
  );

  const id = useSelector((s) => s.materials.selectedMaterialClientId);

  // rooms

  const rooms = useRooms();

  if (!id) {
    return null;
  } else {
    const selectedMaterial = materialsMap[id];
    const materialCode = getMaterialCode(selectedMaterial, materials);
    let material = {...selectedMaterial, code: materialCode};

    // options
    if (withRooms) {
      const roomsByMaterialClientId =
        getRoomsByMaterialClientIdFromRooms(rooms);
      material = {
        ...material,
        rooms: roomsByMaterialClientId[material.clientId],
      };
    }

    // return

    return material;
  }
}
