import {useSelector} from "react-redux";

import useSelectedMaterial from "./useSelectedMaterial";
import useMaterialsByScene from "./useMaterialsByScene";
import useNomenclatureByMaterialsGroupId from "./useNomenclatureByMaterialGroupId";

import getMaterialCode from "../utils/getMaterialCode";
import useSelectedMaterialCategory from "./useSelectedMaterialCategory";
import getCategoryCodes from "../utils/getCategoryCodes";

export default function useMaterialInSelectionPanel(scene) {
  //
  const groupId = useSelector((s) => s.materials.selectedMaterialsGroupId);
  const materials = useMaterialsByScene(scene, {
    filterByMaterialsGroupId: groupId,
  });
  //
  const selectedMaterial = useSelectedMaterial({withRooms: true});
  const editedMaterial = useSelector((s) => s.materials.editedMaterial);
  const newMaterial = useSelector((s) => s.materials.newMaterial);
  const isEditingMaterial = useSelector((s) => s.materials.isEditingMaterial);

  const isCreatingRoomMaterial = useSelector(
    (s) => s.materials.isCreatingRoomMaterial
  );

  // selected category
  const selectedMaterialsGroupId = useSelector(
    (s) => s.materials.selectedMaterialsGroupId
  );
  const nomenclature = useNomenclatureByMaterialsGroupId(
    selectedMaterialsGroupId
  );
  const selectedMaterialCategoryId = useSelector(
    (s) => s.materials.selectedMaterialCategoryId
  );
  const selectedCategory = useSelectedMaterialCategory();

  // helper - material

  let material = selectedMaterial ?? newMaterial;
  if (isEditingMaterial) material = editedMaterial;
  if (isCreatingRoomMaterial) material = newMaterial;

  // update if necessary
  if (!material.materialCategoryId || !material.id) {
    material = {...material, materialCategoryId: selectedMaterialCategoryId};
  }

  console.log("selectedCategory1", selectedCategory);
  if (!material.id && selectedCategory) {
    // categoryType
    const categoryType = selectedCategory.type;
    if (categoryType) material = {...material, categoryType};
    // categoryCode
    const codes = getCategoryCodes(selectedCategory);
    if (!codes.includes(material.categoryCode)) {
      const categoryCode = codes.length > 0 ? codes[0] : "AU";
      material = {...material, categoryCode};
    }
  }

  if (!material.code || !material.id) {
    const materialCode = getMaterialCode(material, materials);
    material = {...material, code: materialCode};
  }

  // return

  return material;
}
