import {useDispatch} from "react-redux";
import {setOldZoneId} from "../zonesSlice";

import {Box, Paper, IconButton} from "@mui/material";
import {Refresh} from "@mui/icons-material";
import useSelectedZone from "../hooks/useSelectedZone";

export default function ButtonRefreshZoneInViewer({scene, caplaEditor}) {
  const dispatch = useDispatch();
  // data

  const selectedZone = useSelectedZone(scene);
  console.log("selectedZone", selectedZone);

  // helpers

  const url = selectedZone?.imageUrl;
  const width = selectedZone?.imageSize?.width;
  const height = selectedZone?.imageSize?.height;

  // handler

  function handleClick() {
    //caplaEditor.zonesEditor.resetLayers();
    //caplaEditor.zonesEditor.addImage({url, width, height});
    //caplaEditor.zonesEditor.updateStageSize();
    //caplaEditor.zonesEditor.resetStageScale();
    //
    caplaEditor.zonesEditor.initBlueprint();
    //
    //dispatch(setOldZoneId(null));
  }
  return (
    <Box
      sx={{
        p: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IconButton
        sx={{width: 30, height: 30, borderRadius: "4px"}}
        onClick={handleClick}
      >
        <Refresh fontSize="small" />
      </IconButton>
    </Box>
  );
}
