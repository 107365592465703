import formatDate from "Features/surveys/utils/formatDate";

export default function useKvtSample(scene, data) {
  //

  let surveys = data?.surveys || [];
  surveys = surveys.map((survey) => ({
    ...survey,
    name: `[${formatDate(survey.scheduledAt)}] ${survey.name}`,
  }));

  const roomsGroupsWithRooms = data?.roomsGroupsWithRooms || [];
  const materialsGroupsWithNomenclatureAndMaterials =
    data?.materialsGroupsWithNomenclatureAndMaterials || [];

  //

  const template = {
    key: "kvfSample",
    fields: [
      //{key: "imageUrl", type: "image", name: "Photo", height: 200},
      {
        key: "imageBase",
        type: "imageBase64",
        name: "Photo",
        height: 100,
        width: 150,
      },
      {key: "survey", type: "combo", name: "Visite", options: surveys},
      {key: "num", type: "text", name: "Numéro"},
      {key: "room", type: "room", name: "Pièce", roomsGroupsWithRooms},
      {
        key: "material",
        type: "material",
        name: "Matériau",
        materialsGroupsWithNomenclatureAndMaterials,
      },
    ],
  };

  return template;
}
