// import {useDispatch} from "react-redux";

import {Box, Paper} from "@mui/material";

import ButtonScreenshot from "./ButtonScreenshot";
import ToggleMoveTool from "./ToggleMoveTool";
import ToggleRotateTool from "./ToggleRotateTool";
import ToggleMeasureTool from "./ToggleMeasureTool";

// import {setShowIssuesInViewer} from "Features/issues/issuesSlice";
import useSceneModule from "Features/navigation/useSceneModule";
import ToolbarAddIssue from "Features/issues/components/ToolbarAddIssue";
import ToolbarAddMarker from "Features/markers/components/ToolbarAddMarker";
import ButtonDownloadIfc from "Features/ifc/components/ButtonDownloadIfc";

export default function ToolbarBottomViewer3D({caplaEditor, viewer, scene}) {
  // const dispatch = useDispatch();

  // data

  const sceneModule = useSceneModule();

  // helpers - background

  const gradientColor = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  const isFlash = ["ISSUES", "ELEMENTS", "MARKERS", "MEASUREMENTS"].includes(
    sceneModule
  );

  const display = [
    "ISSUES",
    "MARKERS",
    "VIEWER3D",
    "ZONES",
    "MEASUREMENTS",
    "HOME",
    "EDIT_MEASUREMENTS",
    "ELEMENT_TYPES",
    "INPUTS",
    "PROGRESS",
  ].includes(sceneModule);

  const background = isFlash ? gradientColor : "unset";
  const bgcolor = isFlash ? "unset" : "common.caplaBlack";

  // handlers - issue

  // function handleAddIssueClose() {
  //   dispatch(setShowIssuesInViewer(false));
  // }

  return (
    <Paper
      elevation={12}
      sx={{
        background,
        bgcolor,
        p: 0.5,
        borderRadius: "4px",
        display: display ? "flex" : "none",
        alignItems: "center",
        width: "fit-content",
        "&>*:not(:last-child)": {
          mr: 1,
        },
      }}
    >
      {sceneModule === "ISSUES" && (
        <ToolbarAddIssue
          caplaEditor={caplaEditor}
          viewer={viewer}
          scene={scene}
        />
      )}
      {sceneModule === "MARKERS" && (
        <ToolbarAddMarker editor3d={caplaEditor?.editor3d} scene={scene} />
      )}
      {["VIEWER3D", "ZONES", "MEASUREMENTS"].includes(sceneModule) && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "&>*:not(:last-child)": {mr: 1},
          }}
        >
          <ButtonScreenshot caplaEditor={caplaEditor} scene={scene} />
          <ToggleMoveTool />
          <ToggleRotateTool />
          <ToggleMeasureTool />
          {/* <ToggleHideTool /> */}
          <ButtonDownloadIfc scene={scene} />
        </Box>
      )}
      {["HOME"].includes(sceneModule) && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "&>*:not(:last-child)": {mr: 1},
          }}
        >
          <ButtonScreenshot caplaEditor={caplaEditor} scene={scene} />
          <ToggleMeasureTool />
          <ButtonDownloadIfc scene={scene} />
        </Box>
      )}
      {["INPUTS"].includes(sceneModule) && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "&>*:not(:last-child)": {mr: 1},
          }}
        >
          <ButtonScreenshot caplaEditor={caplaEditor} scene={scene} />
          <ToggleMeasureTool />
          <ButtonDownloadIfc scene={scene} />
        </Box>
      )}
      {["EDIT_MEASUREMENTS", "ELEMENT_TYPES"].includes(sceneModule) && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "&>*:not(:last-child)": {mr: 1},
          }}
        >
          <ToggleMeasureTool />
          <ButtonScreenshot caplaEditor={caplaEditor} scene={scene} />
        </Box>
      )}
    </Paper>
  );
}
