import {List, ListItemButton, Box, Typography} from "@mui/material";
import ListItemNewMaterial from "./ListItemNewMaterial";
import getRoomsCountFromRoomsData from "../utils/getRoomsCountFromRoomsData";
import getItemRankFromNum from "Utils/getItemRankFromNum";
import getCategoryOrMaterialItemStyle from "../utils/getCategoryOrMaterialItemStyle";
import getArraysIntersection from "Utils/getArraysIntersection";
import fromMaterialsDataToClientIds from "../utils/fromMaterialsDataToClientIds";

export default function ListRoomsAndMaterials({
  items,
  onMaterialClick,
  selectedMaterialsClientIds,
  onNewMaterial,
  //
  selectedRoomId,
  onSelectedRoomIdChange,
  //
  hideMaterials,
}) {
  // strings

  const noRoomsS = "Pas de pièces";

  // helpers

  if (hideMaterials) items = items?.filter((item) => item.isRoom);

  // handlers

  function handleClick(item) {
    if (item.isMaterial) {
      if (onMaterialClick) onMaterialClick(item);
    } else if (item.isRoom) {
      const selectedId = selectedRoomId === item.id ? null : item.id;
      onSelectedRoomIdChange(selectedId);
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        overflow: "auto",
      }}
    >
      <List sx={{width: 1}} dense disablePadding>
        {items?.map((item) => {
          const selected = selectedRoomId === item.id;
          const isLeaf = item.isRoom && !item.childrenClientIds?.length > 0;
          const rank = getItemRankFromNum(item);
          const materialsCount =
            item.isRoom &&
            fromMaterialsDataToClientIds(item.materialsData).length;
          //
          const fontWeight = isLeaf ? "bold" : "normal";
          const bgcolor = rank === 1 ? "grey.500" : "background.default";
          //
          const showMaterialsCount = isLeaf || (!isLeaf && materialsCount > 0);

          return (
            <ListItemButton
              disableGutters
              divider
              key={item.id}
              selected={selected}
              onClick={() => handleClick(item)}
              sx={{bgcolor, p: 0}}
            >
              <Box
                sx={{
                  px: 1,
                  py: 0.5,
                  width: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{pl: `${rank * 16}px`}}>
                  <Typography sx={{fontSize: 12, fontWeight}}>
                    {item?.name}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: materialsCount ? "text.secondary" : "error.main",
                    visibility: showMaterialsCount ? "visible" : "hidden",
                  }}
                >
                  <Typography sx={{fontSize: 12, color: "inherit"}}>
                    {materialsCount}
                  </Typography>
                </Box>
              </Box>
            </ListItemButton>
          );
        })}
        {/* {onNewMaterial && (
        <ListItemNewMaterial
          onNewMaterial={onNewMaterial}
          variant="selectionPanel"
        />
      )} */}
      </List>
    </Box>
  );
}
