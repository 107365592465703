import {useState} from "react";

import {
  Box,
  Breadcrumbs,
  Menu,
  MenuItem,
  IconButton,
  Typography,
} from "@mui/material";
import {MoreHoriz as More, Home} from "@mui/icons-material";

export default function ListRoomsVariantTreeParents({
  parents,
  onParentClick,
  onRootClick,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // helpers

  const items = [{isRoot: true}];
  const options = [];

  if (parents.length >= 2) {
    items.push({isMore: true});
  }
  //   if (parents.length >= 2) {
  //     items.push(parents[parents.length - 2]);
  //   }
  if (parents.length >= 1) {
    items.push(parents[parents.length - 1]);
  }

  if (parents.length >= 2) {
    const lastIndex = parents.length - 1;
    parents.forEach((parent, index) => {
      if (index <= lastIndex - 1) {
        options.push(parent);
      }
    });
  }

  // handler

  function handleMoreClick(e) {
    setAnchorEl(e.currentTarget);
  }
  function handleCloseMenu() {
    setAnchorEl(null);
  }
  function handleClickOption(parent) {
    onParentClick(parent);
    handleCloseMenu();
  }
  function handleRootClick() {
    onRootClick();
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        px: 1,
        pb: 1,
        bgcolor: "background.default",
      }}
    >
      {items.map((item) => {
        const isMore = item.isMore;
        const isRoot = item.isRoot;
        if (isRoot) {
          return (
            <Box
              key={"root"}
              sx={{display: "flex", alignItems: "center", mr: 0.5}}
            >
              <IconButton onClick={handleRootClick} size="small">
                <Home fontSize="small" />
              </IconButton>
              <Typography>/</Typography>
            </Box>
          );
        } else if (isMore) {
          return (
            <>
              <Box key={"isMore"} sx={{display: "flex", alignItems: "center"}}>
                <IconButton onClick={handleMoreClick} size="small">
                  <More fontSize="small" />
                </IconButton>
                <Typography sx={{mr: 0.5}}>/</Typography>
              </Box>
              <Menu open={open} anchorEl={anchorEl} onClose={handleCloseMenu}>
                {options.map((option) => (
                  <MenuItem
                    key={option.name}
                    onClick={() => handleClickOption(option)}
                  >
                    <Typography sx={{fontSize: 12}}>{option.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </>
          );
        } else {
          return (
            <Box
              key={item.name}
              sx={{display: "flex", alignItems: "center", minWidth: 0}}
            >
              <Typography
                noWrap
                sx={{
                  fontSize: 12,
                  mr: 0.5,
                  fontWeight: "bold",
                  //cursor: "pointer",
                  //":hover": {textDecoration: "underline"},
                }}
                //onClick={() => handleClickOption(item)}
              >
                {item.name}
              </Typography>
              <Typography>/</Typography>
            </Box>
          );
        }
      })}
    </Box>
  );
}
