import {useState} from "react";

import useActionsReportsMap from "../hooks/useActionsReportsMap";
import useSelectedReport from "../hooks/useSelectedReport";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogCreateReport from "./DialogCreateReport";
import DialogDuplicateReport from "./DialogDuplicateReport";
import DialogDeleteReport from "./DialogDeleteReport";

export default function SectionActionsReports({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  const [openDialogCreateReport, setOpenDialogCreateReport] = useState(false);
  const [openDialogEditReport, setOpenDialogEditReport] = useState(false);
  const [openDialogDuplicateReport, setOpenDialogDuplicateReport] =
    useState(false);
  const [openDialogDeleteReport, setOpenDialogDeleteReport] = useState(false);

  // state - selection

  const selectedReport = useSelectedReport(scene);

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
    createReport: () => setOpenDialogCreateReport(true),
    editReport: () => setOpenDialogEditReport(true),
    deleteReport: () => setOpenDialogDeleteReport(true),
    duplicateReport: () => setOpenDialogDuplicateReport(true),
  };

  // handlers

  function handleClick(actionKey) {
    console.log("trigger", actionKey);
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // data - handlers

  const actionsMap = useActionsReportsMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {openDialogCreateReport && (
        <DialogCreateReport
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateReport}
          onClose={() => setOpenDialogCreateReport(false)}
        />
      )}
      {/* {openDialogEditReport && (
        <DialogEditReport
          scene={scene}
          report={selectedReport}
          open={openDialogEditReport}
          onClose={() => setOpenDialogEditReport(false)}
        />
      )} */}
      {openDialogDuplicateReport && (
        <DialogDuplicateReport
          report={selectedReport}
          scene={scene}
          open={openDialogDuplicateReport}
          onClose={() => setOpenDialogDuplicateReport(false)}
        />
      )}
      {openDialogDeleteReport && (
        <DialogDeleteReport
          report={selectedReport}
          scene={scene}
          open={openDialogDeleteReport}
          onClose={() => setOpenDialogDeleteReport(false)}
        />
      )}
    </>
  );
}
