import {useSelector, useDispatch} from "react-redux";

import {setFormTabBlock} from "Features/reportBlocks/blocksSlice";

import useKvtBlock from "Features/kvform/hooks/useKvtBlock";

import {Box} from "@mui/material";

import KeyValueForm from "Features/kvform/components/KeyValueForm";

import getItemsMapById from "Utils/getItemsMapById";

export default function FormBlock({
  caplaEditor,
  block,
  onChange,
  formTemplates,
  zones,
}) {
  const dispatch = useDispatch();

  // data

  const template = useKvtBlock({formTemplates, zones});
  const formTabBlock = useSelector((s) => s.blocks.formTabBlock);

  // helpers

  const formTemplatesById = getItemsMapById(formTemplates);
  const zonesById = getItemsMapById(zones);

  // item

  const item = {...block};
  if (item.blockType === "VIEW")
    item.zone = zonesById[item.data?.viewData?.zoneId];

  // handlers

  function handleItemChange(changedItem) {
    const changedBlock = {...block, ...changedItem};
    console.log("[FormBlock] changedItem", changedItem);
    //
    if (changedBlock.blockType === "VIEW" && changedItem.zone) {
      changedBlock.data = {
        ...changedBlock.data,
        viewData: {
          ...(changedBlock.data?.viewData ?? {}),
          zoneId: changedItem.zone.id,
          zoneImageUrl: changedItem.zone.imageUrl,
          ...(changedItem.data?.viewData?.imageUrl && {
            imageUrl: changedItem.data.viewData.imageUrl,
          }),
        },
      };
    }
    //
    onChange(changedBlock);
  }

  function handleGroupChange(tab) {
    dispatch(setFormTabBlock(tab));
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <KeyValueForm
        caplaEditor={caplaEditor}
        template={template}
        item={item}
        onChange={handleItemChange}
        group={formTabBlock}
        onGroupChange={handleGroupChange}
      />
    </Box>
  );
}
