import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useParams} from "react-router-dom";

import {setSelectedReportId} from "../reportsSlice";
import {newFirstLoadedPage} from "Features/ui/uiSlice";
import {setSelectedFixedViewer} from "Features/ui/uiSlice";
import {setOpenSections} from "Features/ui/uiSlice";
import {setOpenSelectionPanel} from "Features/selection/selectionSlice";

import {Box} from "@mui/material";

export default function PageReport() {
  const dispatch = useDispatch();
  const {reportId} = useParams();

  // data

  const firstLoadedPages = useSelector((s) => s.ui.firstLoadedPages);
  const selectedReportId = useSelector((s) => s.reports.selectedReportId);

  // helpers

  const firstLoaded = firstLoadedPages.includes("REPORT");

  // effect

  useEffect(() => {
    if (!firstLoaded) {
      dispatch(newFirstLoadedPage("REPORT"));
      //
      const os = {
        outlet: false,
        fixedViewersBox: true,
        viewer3D: false,
        callToAction: false,
      };
      dispatch(setOpenSections(os));
      dispatch(setSelectedFixedViewer("REPORT"));
      //
      dispatch(setOpenSelectionPanel(true));
      //
    }
  }, [firstLoaded]);

  useEffect(() => {
    if (selectedReportId !== reportId) {
      dispatch(setSelectedReportId(reportId));
    }
  }, [reportId]);

  return <Box sx={{width: 1, height: 1}}></Box>;
}
