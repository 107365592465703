export default function cleanRoomMaterialsData(materialsData) {
  const cleanMaterialsData = materialsData.map((item) => {
    const cleanMaterialsClientIds = [...new Set(item.materialsClientIds)];
    return {
      ...item,
      materialsClientIds: cleanMaterialsClientIds,
    };
  });

  return cleanMaterialsData;
}
