import {useRef, useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";

import {fetchReportTemplates} from "../reportsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useReportTemplates(scene, options) {
  const dispatch = useDispatch();
  //

  const fetchData = options?.fetchData;

  //

  const organisationId = scene?.organisationId;
  const syncingMapRef = useRef({});
  const accessToken = useAccessToken();

  // data

  const reportTemplatesMap = useSelector((s) => s.reports.reportTemplatesMap);
  const fetchedReportTemplatesByOrganisationId = useSelector(
    (s) => s.reports.fetchedReportTemplatesByOrganisationId
  );

  // effect

  useEffect(() => {
    if (fetchData && organisationId && accessToken) {
      if (!syncingMapRef.current[organisationId]) {
        syncingMapRef.current[organisationId] = true;
        dispatch(fetchReportTemplates({organisationId, accessToken}));
      }
    }
  }, [organisationId, accessToken, fetchData]);

  // helpers

  let reportTemplates = Object.values(reportTemplatesMap).filter(
    (t) => t.organisationId === organisationId
  );

  return reportTemplates;
}
