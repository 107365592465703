import templateGlobalExport from "../assets/template_global_export.xlsx";

import templateRessources from "../assets/template_ressources.xlsx";
import templateRessourcesWithIds from "../assets/template_ressources_with_ids.xlsx";
import templateArticlesWithIds from "../assets/template_articles_with_ids.xlsx";
import templateArticlesWithQties from "../assets/template_articles_qties.xlsx";
import templateMeasurements from "../assets/template_measurements.xlsx";
import templateRooms from "../assets/template_rooms.xlsx";
import templateMaterials from "../assets/template_materials.xlsx";
import templateSectors from "../assets/template_sectors.xlsx";
import templateMeasurementsByRoom from "../assets/template_measurements_by_room.xlsx";
import templateMeasurementsByType from "../assets/template_measurements_by_type.xlsx";
import templateElementTypesWithIds from "../assets/template_element_types_with_ids.xlsx";
import templateTypeRessourceMap from "../assets/template_type_ressource_map.xlsx";
import templateSubjectTypeMap from "../assets/template_subject_type_map.xlsx";
import templateTypesRessources from "../assets/types-articles-template.xlsx";
import templateRessourcesTypes from "../assets/articles-types-template.xlsx";
import templateRessourcesPU from "../assets/articles-pu-template.xlsx";
import templateNomenclature from "../assets/template_nomenclature.xlsx";

export default function getTemplateUrl(template) {
  const templateMap = {
    ALL: templateGlobalExport,
    RESSOURCES: templateRessources,
    RESSOURCES_WITH_IDS: templateRessourcesWithIds,
    ARTICLES_WITH_IDS: templateArticlesWithIds,
    ARTICLES_WITH_QTIES: templateArticlesWithQties,
    ELEMENT_TYPES_WITH_IDS: templateElementTypesWithIds,
    TYPE_RESSOURCE_MAP: templateTypeRessourceMap,
    MEASUREMENTS: templateMeasurements,
    MEASUREMENTS_BY_ROOM: templateMeasurementsByRoom,
    MEASUREMENTS_BY_TYPE: templateMeasurementsByType,
    ROOMS: templateRooms,
    SECTORS: templateSectors,
    SUBJECT_TYPE_MAP: templateSubjectTypeMap,
    MATERIALS: templateMaterials,
    ELEMENT_TYPES_RESSOURCES: templateTypesRessources,
    RESSOURCES_ELEMENT_TYPES: templateRessourcesTypes,
    RESSOURCES_PU: templateRessourcesPU,
    NOMENCLATURE: templateNomenclature,
  };
  return templateMap[template];
}
