import {useDispatch, useSelector} from "react-redux";

import {Add, ContentCopy as Duplicate, Delete} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import {fetchReportsByClientReferenceService} from "../servicesReports";
import useSelectedReport from "./useSelectedReport";

export default function useActionsReportsMap({scene, caplaEditor}) {
  const accessToken = useAccessToken();

  // data

  const selectedReportId = useSelector((s) => s.reports.selectedReportId);
  const selectedReport = useSelectedReport();

  // handlers

  async function handleDebug() {
    const organisationId = scene?.id;
    const clientReference = selectedReport?.clientReference;
    const result = await fetchReportsByClientReferenceService({
      accessToken,
      organisationId,
      clientReference,
    });
    console.log("[debug] result", result);
  }
  // actions map

  const actionsMap = {
    createReport: {
      icon: <Add fontSize="small" />,
      name: "Rapport",
      label: "Créer un rapport",
    },
    duplicateReport: {
      icon: <Duplicate fontSize="small" />,
      name: "Dupliquer",
      label: "Créer une nouvelle version",
      disabled: !selectedReportId,
    },
    deleteReport: {
      icon: <Delete fontSize="small" />,
      name: "Supprimer",
      label: "Supprimer le rapport",
      disabled: !selectedReportId,
    },
    debug: {
      name: "Debug",
      label: "Debug",
      handler: handleDebug,
    },
  };

  return actionsMap;
}
