import {client} from "API/capla360";

export async function fetchFormsService({accessToken, sceneId}) {
  const response = await client.get(`/scenes/${sceneId}/bimboxforms/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const sharedForms = response.data;
  const forms = sharedForms.map((r) => {
    return {...r, sceneId: r.scene};
  });
  return {items: forms, sceneId};
}

export async function createFormService({accessToken, form}) {
  const newForm = {...form};
  const sceneId = form.sceneId;
  delete newForm.scene;
  newForm.scene = form.sceneId;
  const response = await client.post(
    `scenes/${sceneId}/bimboxforms/`,
    newForm,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: {...response.data, sceneId}};
}

export async function fetchFormService({accessToken, sceneId, formId}) {
  const response = await client.get(
    `/scenes/${sceneId}/bimboxforms/${formId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: {...response.data, sceneId}};
}

export async function updateFormService({
  accessToken,
  formId,
  sceneId,
  updates,
}) {
  const response = await client.put(
    `/scenes/${sceneId}/bimboxforms/${formId}/`,
    updates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const updatedForm = response.data;
  return {item: {...updatedForm, sceneId: updatedForm.scene}};
}

export const deleteFormService = async ({accessToken, formId, sceneId}) => {
  await client.delete(`/scenes/${sceneId}/bimboxforms/${formId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return formId;
};
