import {IconButton, Tooltip, Box} from "@mui/material";
import {Download} from "@mui/icons-material";

import ifcExport from "Features/ifc/ifcExport";
import useSceneMeasurements from "Features/measurements/useSceneMeasurements";
import {useSelector} from "react-redux";

export default function ButtonDownloadIfc({scene}) {
  // strings

  const tipString = "IFC";

  // data

  //const measurements = useSceneMeasurements(scene);
  const measurements = useSelector(
    (s) => s.measurements.measurementsShowedInViewer3dWithoutShortcut
  );

  // handlers

  function handleDownloadClick() {
    console.log("ifc export measurements", measurements);
    ifcExport({scene, measurements});
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "common.white",
      }}
    >
      <Tooltip title={tipString}>
        <IconButton
          onClick={handleDownloadClick}
          color="inherit"
          sx={{height: 30, width: 30, borderRadius: 0}}
        >
          <Download fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
