import getItemsMapByClientId from "Utils/getItemsMapByClientId";

export default function cleanRoomsChildrenClientIds(rooms) {
  const roomsByClientId = getItemsMapByClientId(rooms);
  //
  const roomsUpdates = [];
  //
  rooms.forEach((room) => {
    const newChildrenClientIds = [];
    room.childrenClientIds?.forEach((clientId) => {
      if (roomsByClientId[clientId]) {
        newChildrenClientIds.push(clientId);
      }
    });
    if (
      room.childrenClientIds &&
      newChildrenClientIds.length !== room.childrenClientIds.length
    ) {
      roomsUpdates.push({
        id: room.id,
        roomsGroupId: room.roomsGroupId,
        childrenClientIds: newChildrenClientIds,
      });
    }
  });
  return roomsUpdates;
}
