import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {setOpenDialogFsCreateSample} from "Features/samples/samplesSlice";

import {
  createMaterialsGroupService,
  createMaterialService,
  fetchMaterialsGroupsService,
  fetchMaterialsService,
  updateMaterialsGroupService,
  updateMaterialService,
  deleteMaterialsGroupService,
  deleteMaterialService,
} from "./services";

import {fetchOrganisationNomenclaturesService} from "Features/organisations/services";
import {setMultipleSelection} from "Features/viewer3D/viewer3DSlice";
import {setEditedRoom, setSelectedRoomId} from "Features/rooms/roomsSlice";

// REMOTE

export const createMaterialsGroup = createAsyncThunk(
  "materials/createMaterialsGroup",
  createMaterialsGroupService
);
export const createMaterial = createAsyncThunk(
  "materials/createMaterial",
  createMaterialService
);
export const fetchOrganisationNomenclatures = createAsyncThunk(
  "materials/fetchOrganisationNomenclatures",
  fetchOrganisationNomenclaturesService
);
export const fetchMaterialsGroups = createAsyncThunk(
  "materials/fetchMaterialsGroups",
  fetchMaterialsGroupsService
);
export const fetchMaterials = createAsyncThunk(
  "materials/fetchMaterials",
  fetchMaterialsService
);
export const updateMaterialsGroup = createAsyncThunk(
  "materials/updateMaterialsGroup",
  updateMaterialsGroupService
);
export const updateMaterial = createAsyncThunk(
  "materials/updateMaterial",
  updateMaterialService
);
export const deleteMaterialsGroup = createAsyncThunk(
  "materials/deleteMaterialsGroup",
  deleteMaterialsGroupService
);
export const deleteMaterial = createAsyncThunk(
  "materials/deleteMaterial",
  deleteMaterialService
);

const materialsInitialState = {
  //
  viewMode: "LIST", // IMAGES
  //
  nomenclatures: [],
  //
  fetchedMaterialsByGroupId: {},
  //
  materialsGroupsMap: {},
  materialsMap: {},
  //
  newMaterial: {},
  editedMaterial: {},
  isEditingMaterial: false,
  //
  materialsNomenclatures: [],
  selectedMaterialsNomenclatureId: null,
  selectedMaterialCategoryId: null,
  //
  isSelectingMaterialsGroup: false,
  selectedMaterialsGroupId: null,
  selectedMaterialClientId: null,
  selectedMaterialsClientIds: [],
  //
  formTabMaterial: "INFO",
  //
  materialsViewVariant: "TREE_LIST", // "TREE_LIST", "LIST"
  materialsViewBy: "ROOM", // "ROOM", "CATEGORY", "MATERIAL"
  searchTextMaterials: "",
  //
  selectedRoomIdInMaterials: null,
  isEditingRoomMaterials: false,
  editedRoomMaterialsData: [],
  isCreatingRoomMaterial: false,
  selectedRoomMaterialsClientIds: [],
  copiedMaterialsData: [],
  //
  openDialogFsMaterialsSettings: false,
  openDialogFsSelectorMaterial: false,
  openDialogFsSelectorMaterialCategory: false,
};

export const materialsSlice = createSlice({
  name: "materials",
  initialState: materialsInitialState,
  reducers: {
    setFormTabMaterial: (state, action) => {
      state.formTabMaterial = action.payload;
    },
    //
    setSearchTextMaterials: (state, action) => {
      state.searchTextMaterials = action.payload;
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    },
    //
    setIsSelectingMaterialsGroup: (state, action) => {
      state.isSelectingMaterialsGroup = action.payload;
    },
    setSelectedMaterialsGroupId: (state, action) => {
      state.selectedMaterialsGroupId = action.payload;
    },
    //
    setMultipleSelectionMaterials: (state, action) => {
      state.multipleSelectionMaterials = action.payload;
    },
    setSelectedMaterialClientId: (state, action) => {
      const id = action.payload;
      state.selectedMaterialClientId = id;
      if (!id) state.selectedMaterialsClientIds = [];
    },
    setSelectedMaterialsClientIds: (state, action) => {
      const ids = action.payload;
      const id0 = ids.length >= 1 ? ids[0] : null;
      state.selectedMaterialsClientIds = ids;
      state.selectedMaterialClientId = id0;
    },
    setSelectedMaterialCategoryId: (state, action) => {
      state.selectedMaterialCategoryId = action.payload;
    },
    //
    addMaterial: (state, action) => {
      const material = action.payload;
      state.materialsMap[material.clientId] = material;
    },
    //
    setOpenDialogFsMaterialsSettings: (state, action) => {
      state.openDialogFsMaterialsSettings = action.payload;
    },
    setSelectedMaterialsNomenclatureId: (state, action) => {
      state.selectedMaterialsNomenclatureId = action.payload;
    },
    setOpenDialogFsSelectorMaterial: (state, action) => {
      state.openDialogFsSelectorMaterial = action.payload;
    },
    setOpenDialogFsSelectorMaterialCategory: (state, action) => {
      state.openDialogFsSelectorMaterialCategory = action.payload;
    },
    //
    setNewMaterial: (state, action) => {
      state.newMaterial = action.payload;
    },
    setEditedMaterial: (state, action) => {
      state.editedMaterial = action.payload;
    },
    setIsEditingMaterial: (state, action) => {
      state.isEditingMaterial = action.payload;
    },
    //
    setMaterialsViewVariant: (state, action) => {
      state.materialsViewVariant = action.payload;
    },
    setMaterialsViewBy: (state, action) => {
      state.materialsViewBy = action.payload;
    },
    //
    setSelectedRoomIdInMaterials: (state, action) => {
      state.selectedRoomIdInMaterials = action.payload;
    },
    setIsEditingRoomMaterials: (state, action) => {
      state.isEditingRoomMaterials = action.payload;
    },
    setEditedRoomMaterialsData: (state, action) => {
      state.editedRoomMaterialsData = action.payload;
    },
    setIsCreatingRoomMaterial: (state, action) => {
      state.isCreatingRoomMaterial = action.payload;
    },
    setSelectedRoomMaterialsClientIds: (state, action) => {
      state.selectedRoomMaterialsClientIds = action.payload;
    },
    setCopiedMaterialsData: (state, action) => {
      state.copiedMaterialsData = action.payload;
    },
  },
  extraReducers: {
    // REMOTE

    [createMaterialsGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.materialsGroupsMap[item.id] = item;
    },
    [createMaterial.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.materialsMap[item.clientId] = item;
    },
    [fetchOrganisationNomenclatures.fulfilled]: (state, action) => {
      const {nomenclatures} = action.payload;
      state.nomenclatures = nomenclatures;
    },
    [fetchMaterialsGroups.fulfilled]: (state, action) => {
      const {items} = action.payload;
      console.log("debugafa state fetchMaterialsGroups", items);
      items.forEach((item) => {
        state.materialsGroupsMap[item?.id] = item;
      });
    },
    [fetchMaterials.fulfilled]: (state, action) => {
      const {items, materialsGroupId} = action.payload;
      items.forEach((item) => {
        state.materialsMap[item.clientId] = item;
      });
      state.fetchedMaterialsByGroupId[materialsGroupId] = true;
    },

    [updateMaterialsGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.materialsGroupsMap[item.id] = item;
    },
    [updateMaterial.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.materialsMap[item.clientId] = item;
    },
    [deleteMaterialsGroup.fulfilled]: (state, action) => {
      const {materialsGroupId} = action.payload;
      delete state.materialsGroupsMap[materialsGroupId];
    },
    [deleteMaterial.fulfilled]: (state, action) => {
      const {materialClientId} = action.payload;
      delete state.materialsMap[materialClientId];
    },

    // EXTERNAL

    "scopes/fetchRemoteScopeData/fulfilled": (state, action) => {
      const {data, scopeId} = action.payload;
      const materialsGroups =
        data.materialsGroups?.length > 0 ? data.materialsGroups : [];
      console.log("[STATE MATERIALS] fetchRemoteScopeData", scopeId, data);
      materialsGroups.forEach((materialsGroup) => {
        state.materialsGroupsMap[materialsGroup.id] = materialsGroup;
        if (materialsGroup.materials?.length > 0) {
          materialsGroup.materials.forEach((material) => {
            state.materialsMap[material.clientId] = material;
          });
        }
      });
    },
    "comments/addComment/fulfilled": (state, action) => {
      const comment = action.payload;
      if (comment.materialsClientIds) {
        comment.materialsClientIds.forEach((materialClientId) => {
          const oldMaterial = state.materialsMap[materialClientId];
          const oldCommentIds = oldMaterial?.commentsClientIds;
          //
          const newCommentsClientIds = oldCommentIds
            ? [...oldCommentIds, comment.clientId]
            : [comment.clientId];
          //
          state.materialsMap[materialClientId] = {
            ...oldMaterial,
            commentsClientIds: newCommentsClientIds,
          };
        });
      }
    },
  },
});

export const {
  //
  setFormTabMaterial,
  setSearchTextMaterials,
  setViewMode,
  //
  setMultipleSelectionMaterials,
  setIsSelectingMaterialsGroup,
  setSelectedMaterialsGroupId,
  setSelectedMaterialsNomenclatureId,
  setSelectedMaterialClientId,
  setSelectedMaterialsClientIds,
  setSelectedMaterialCategoryId,
  //
  addMaterial,
  //
  setOpenDialogFsMaterialsSettings,
  setOpenDialogFsSelectorMaterialCategory,
  setOpenDialogFsSelectorMaterial,
  //
  setNewMaterial,
  setEditedMaterial,
  setIsEditingMaterial,
  //
  setMaterialsViewBy,
  setMaterialsViewVariant,
  //
  setSelectedRoomIdInMaterials,
  setIsEditingRoomMaterials,
  setEditedRoomMaterialsData,
  setIsCreatingRoomMaterial,
  setSelectedRoomMaterialsClientIds,
  setCopiedMaterialsData,
} = materialsSlice.actions;

export default materialsSlice.reducer;
