const roomsFromTemplates = [
  {
    title: "Niveaux",
    items: ["R-2", "R-1", "RDC", "R+1", "R+2", "R+3", "R+4", "R+5"],
  },
  {
    title: "Pièces",
    items: [
      "Entrée",
      "Couloir",
      "Séjour",
      "Cuisine",
      "Séjour/Cuisine",
      "Chambre 1",
      "Chambre 2",
      "Chambre 3",
      "WC",
      "Salle de bain",
    ],
  },
  {
    title: "Façades",
    items: ["Façade Nord", "Façade Sud", "Façade Est", "Façade Ouest"],
  },
];

export default roomsFromTemplates;
