import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Button,
  Box,
  Typography,
  LinearProgress,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import {Add, PhotoSizeSelectSmall as Select, Info} from "@mui/icons-material";

import DialogCreateZone from "./DialogCreateZone";

import {setMode} from "Features/viewer3D/viewer3DSlice";
// import {setOpenDialogCreateZone} from "../zonesSlice";

//import gif from "Assets/drawZoneGif.gif";

export default function ButtonCreateZone({caplaEditor, scene, show}) {
  const dispatch = useDispatch();

  // strings

  const buttonString = "Fond de plan";
  const helperString = "Dessinez un rectangle";
  const descriptionS =
    "Délimitez la zone du plan où les repérages seront effectués.";

  // data

  const mode = useSelector((s) => s.viewer3D.mode);

  // state

  const [open, setOpen] = useState(false);

  // helpers

  const isCreating = mode === "CREATE_PDF_ZONE";

  // handlers

  function handleCreateClick() {
    console.log("clickAA", caplaEditor?.editorPdf);
    dispatch(setMode("CREATE_PDF_ZONE"));
    caplaEditor?.editorPdf?.annotationsManager?.setCreatingZone(true);
    caplaEditor?.editorPdf?.triggerCreateRectangle();
  }

  function handleAnnotationChanged(annotations, action) {
    console.log("[button create zone] annotation change", action);
    // we disable the tools when the annotation is added.
    if (action === "add" && annotations[0].getCustomData("annotationId")) {
      console.log("[button create zone], active handler");
      caplaEditor?.editorPdf.activePanTool();
    }
  }

  // init

  useEffect(() => {
    if (caplaEditor?.editorPdf) {
      caplaEditor?.editorPdf.setAnnotationChangedHandler(
        handleAnnotationChanged
      );
    }
  }, [caplaEditor?.editorPdf]);

  return (
    <Box sx={{color: "common.white"}}>
      <DialogCreateZone scene={scene} caplaEditor={caplaEditor} />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Typography sx={{py: 2}}>{descriptionS}</Typography>
          {/* <img src={gif} width={450} height="auto" /> */}
        </DialogContent>
      </Dialog>

      {!isCreating ? (
        <Box sx={{display: "flex"}}>
          <Button
            size="small"
            variant="outlined"
            onClick={handleCreateClick}
            startIcon={<Add />}
            color="inherit"
          >
            {buttonString}
          </Button>
          <IconButton
            sx={{ml: 1}}
            color="inherit"
            size="small"
            onClick={() => setOpen(true)}
          >
            <Info fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <Box
          sx={{
            px: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Select />
          <Typography
            noWrap
            sx={{fontSize: "12px", ml: 1, width: "fit-content"}}
          >
            {helperString}
          </Typography>

          <LinearProgress
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              left: 0,
            }}
          />
        </Box>
      )}
    </Box>
  );
}
