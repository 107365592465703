import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Box} from "@mui/material";

import DeleteDialog from "Features/ui/components/DeleteDialog";

import useDeleteNomenclature from "../hooks/useDeleteNomenclature";
import useSelectedNomenclature from "../hooks/useSelectedNomenclature";

export default function DialogDeleteReport({scene, open, onClose}) {
  // state

  const [loading, setLoading] = useState(false);

  // data

  const selectedNomenclature = useSelectedNomenclature(scene);
  const deleteNomenclature = useDeleteNomenclature();

  // handler

  function handleClose() {
    onClose();
    setLoading(false);
  }

  async function handleDeleteConfirm() {
    console.log("handleDeleteConfirm");
    if (loading) return;
    setLoading(true);
    await deleteNomenclature(selectedNomenclature);
    setLoading(false);
    onClose();
  }

  return (
    <DeleteDialog
      open={open}
      onCancel={handleClose}
      onConfirm={handleDeleteConfirm}
      ressource="nomenclature"
      loading={loading}
    />
  );
}
