import {useDispatch} from "react-redux";
import {deleteReport} from "../reportsSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useDeleteReport() {
  //
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const deleteS = async (report) => {
    const reportId = report.id;
    const scopeId = report.scopeId;
    if ((reportId, scopeId, accessToken)) {
      await dispatch(deleteReport({reportId, scopeId, accessToken}));
    }
  };

  return deleteS;
}
