import {useSelector} from "react-redux";

import useSelectedReport from "./useSelectedReport";

export default function useReportInSelectionPanel() {
  //
  const selectedReport = useSelectedReport();
  const editedReport = useSelector((s) => s.reports.editedReport);
  const newReport = useSelector((s) => s.reports.newReport);
  const isEditingReport = useSelector((s) => s.reports.isEditingReport);

  // helper - report

  let report = selectedReport ?? newReport;
  if (isEditingReport) report = editedReport;

  // return

  return report;
}
