import {useDispatch, useSelector} from "react-redux";

import {setSelectedNomenclatureId} from "Features/nomenclatures/nomenclaturesSlice";

import useNomenclaturesByOrganisationId from "Features/nomenclatures/hooks/useNomenclaturesByOrganisationId";

import {Box, Typography} from "@mui/material";

import ListNomenclatures from "Features/nomenclatures/components/ListNomenclatures";

export default function SectionNomenclatures({organisation}) {
  const dispatch = useDispatch();

  // string

  const noNomenclatures = "Aucune nomenclature";

  // data

  const nomenclatures = useNomenclaturesByOrganisationId(organisation?.id, {
    fetchData: true,
  });
  const selectedNomenclatureId = useSelector(
    (state) => state.nomenclatures.selectedNomenclatureId
  );

  // helpers

  const selection = selectedNomenclatureId ? [selectedNomenclatureId] : null;

  // helpers

  const isEmpty = nomenclatures.length === 0;

  // handlers

  function handleClick(nomenclature) {
    if (selectedNomenclatureId === nomenclature.id) {
      dispatch(setSelectedNomenclatureId(null));
    } else {
      dispatch(setSelectedNomenclatureId(nomenclature.id));
    }
  }
  return (
    <Box
      sx={{width: 1, p: 2, display: "flex", flexDirection: "column", height: 1}}
    >
      <Box sx={{width: 1, bgcolor: "common.white"}}>
        {isEmpty && (
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>{noNomenclatures}</Typography>
          </Box>
        )}
        {!isEmpty && (
          <ListNomenclatures
            nomenclatures={nomenclatures}
            onClick={handleClick}
            selectedNomenclatureId={selectedNomenclatureId}
          />
        )}
      </Box>
    </Box>
  );
}
