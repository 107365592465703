import {useEffect, useRef} from "react";

import {useSelector, useDispatch} from "react-redux";
import useRooms from "Features/rooms/hooks/useRooms";

import {fetchMaterials} from "../materialsSlice";

import useMaterialsCategories from "./useMaterialsCategories";
import useSamples from "Features/samples/hooks/useSamples";

import getSamplesByMaterial from "Features/samples/utils/getSamplesByMaterial";
import getItemsMapById from "Utils/getItemsMapById";
import getMaterialCode from "../utils/getMaterialCode";

import useAccessToken from "Features/auth/useAccessToken";
import getRoomsByMaterialClientIdFromRooms from "../utils/getRoomsByMaterialClientIdFromRooms";

export default function useMaterialsByScene(scene, options) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();
  const syncingMapRef = useRef({});

  // options
  const filterByMaterialsGroupId = options?.filterByMaterialsGroupId;
  const fetchData = options?.fetchData;
  //
  const withRooms = options?.withRooms;
  const withCategories = options?.withCategories;
  const withSamples = options?.withSamples;
  const withComments = options?.withComments;

  // data
  const fetchedMaterialsByGroupId = useSelector(
    (s) => s.materials.fetchedMaterialsByGroupId
  );
  const materialsMap = useSelector((s) => s.materials.materialsMap);

  // data

  const rooms = useRooms({fetchData: true});

  // effect - fetch data

  const groupId = options?.filterByMaterialGroupId;

  useEffect(() => {
    if (accessToken && fetchData && groupId) {
      if (
        !fetchedMaterialsByGroupId[groupId] &&
        syncingMapRef.current[groupId] !== true
      ) {
        syncingMapRef.current[groupId] = true;
        dispatch(fetchMaterials({materialsGroupId: groupId, accessToken}));
      }
    }
  }, [accessToken, fetchData, groupId]);

  //
  const categories = useMaterialsCategories();
  const samples = useSamples({filterByScope: true});
  const commentsMap = useSelector((s) => s.comments.commentsMap);
  //
  let materials = Object.values(materialsMap);
  //

  // material code
  materials = materials.map((material) => ({
    ...material,
    code: getMaterialCode(material, materials),
  }));

  if (filterByMaterialsGroupId) {
    materials = materials.filter(
      (m) => m.materialsGroupId === filterByMaterialsGroupId
    );
  }

  if (withCategories) {
    const categoriesById = getItemsMapById(categories);
    materials = materials.map((m) => ({
      ...m,
      category: categoriesById[m.materialCategoryId],
    }));
  }

  if (withSamples) {
    const samplesByMat = getSamplesByMaterial(samples);
    materials = materials.map((material) => ({
      ...material,
      samples: samplesByMat[material.clientId],
    }));
  }

  if (withComments) {
    materials = materials.map((material) => {
      if (material.commentsClientIds) {
        const comments = material.commentsClientIds.map(
          (clientId) => commentsMap[clientId]
        );
        return {...material, comments};
      } else {
        return material;
      }
    });
  }

  if (withRooms) {
    const roomsByMaterialClientId = getRoomsByMaterialClientIdFromRooms(rooms);
    materials = materials.map((material) => ({
      ...material,
      rooms: roomsByMaterialClientId[material.clientId],
    }));
  }
  return materials;
}
