import getItemsMapById from "Utils/getItemsMapById";
import getItemsWithChildrenFromNum from "Utils/getItemsWithChildrenFromNum";
import getParentsNums from "Utils/getParentsNums";

export default function getUpdatedNomenclatureTree(newTree, oldTree) {
  const newTreeWithChildren = getItemsWithChildrenFromNum(newTree.items);

  // maps
  const newTreeItemsMap = getItemsMapById(newTree.items);
  const oldTreeItemsMap = getItemsMapById(oldTree?.items ?? []);

  // edge case : new tree

  const isNewTree = !oldTree?.items?.length > 0;
  if (isNewTree) return newTree;

  //

  const newTreeItemsMapByNum = newTree.items.reduce((acc, item) => {
    acc[item.num] = item;
    return acc;
  }, {});
  const oldTreeItemsMapByNum = (oldTree?.items ?? []).reduce((acc, item) => {
    acc[item.num] = item;
    return acc;
  }, {});

  // processed items

  const processedNewItemsMap = {}; // by new num

  // updatedItems

  const updatedItems = [];

  newTreeWithChildren.forEach((newItem) => {
    if (!newItem?.children?.length > 0) {
      const oldItem = oldTreeItemsMap[newItem.id];
      if (oldItem) {
        const newItemParentNums = getParentsNums(newItem.num);
        const oldParentItemNums = getParentsNums(oldItem.num);
        newItemParentNums.forEach((parentNum, index) => {
          const newParentItem = newTreeItemsMapByNum[parentNum];
          if (!processedNewItemsMap[newParentItem.num]) {
            const oldParentItemNum = oldParentItemNums[index];
            const oldParentItem = oldTreeItemsMapByNum[oldParentItemNum];
            let updatedParentItem;
            if (oldParentItem) {
              updatedParentItem = {
                ...newParentItem,
                id: oldParentItem.id,
              };
            } else {
              updatedParentItem = {...newParentItem};
            }
            updatedItems.push(updatedParentItem);
            processedNewItemsMap[newParentItem.num] = true;
          }
        });
        updatedItems.push(newItem);
      } else {
        console.log("newItem in tree", newItem);
        updatedItems.push(newItem);
      }
    }
  });

  return {...newTree, items: updatedItems};
}
