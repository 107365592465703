import {useDispatch} from "react-redux";
import {
  fetchOrganisationNomenclatures,
  setSelectedOrganisationId,
} from "../organisationsSlice";

import {List, ListItemButton, Typography} from "@mui/material";

import useAccessToken from "Features/auth/useAccessToken";
import {useNavigate} from "react-router-dom";

export default function ListOrganisations({organisations}) {
  const accessToken = useAccessToken();
  const navigate = useNavigate();

  //
  const dispatch = useDispatch();

  function handleClick(organisation) {
    dispatch(setSelectedOrganisationId(organisation.id));
    dispatch(
      fetchOrganisationNomenclatures({
        accessToken,
        organisationId: organisation.id,
      })
    );
  }

  function handleLinkClick(organisation) {
    navigate(`organisations/${organisation.id}`);
  }
  return (
    <List>
      {organisations.map((organisation) => (
        <ListItemButton
          key={organisation.id}
          divider
          onClick={() => handleClick(organisation)}
        >
          <Typography
            onClick={() => handleLinkClick(organisation)}
            sx={{cursor: "pointer", ":hover": {textDecoration: "underline"}}}
          >
            {organisation.name}
          </Typography>
        </ListItemButton>
      ))}
    </List>
  );
}
