import {useNavigate} from "react-router-dom";

import {Box, Button} from "@mui/material";
import {ArrowBackIos as Back} from "@mui/icons-material";

import ProfileButton from "Features/profile/components/ButtonProfile";

export default function PageOrganisationTopBar() {
  const navigate = useNavigate();

  // string

  const label = "Mes bimboxes";

  // handlers

  function handleGoToScene() {
    navigate("/");
  }
  return (
    <Box
      sx={{
        width: 1,
        height: "42px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: "secondary.light",
        color: "common.white",
      }}
    >
      <Button color="inherit" startIcon={<Back />} onClick={handleGoToScene}>
        {label}
      </Button>
      <ProfileButton />
    </Box>
  );
}
