import {useSelector} from "react-redux";

import useSelectedNomenclature from "./useSelectedNomenclature";

export default function useNomenclatureInSelectionPanel() {
  //
  const selectedNomenclature = useSelectedNomenclature();
  const editedNomenclature = useSelector(
    (s) => s.nomenclatures.editedNomenclature
  );
  const newNomenclature = useSelector((s) => s.nomenclatures.newNomenclature);
  const isEditingNomenclature = useSelector(
    (s) => s.nomenclatures.isEditingNomenclature
  );

  // helper - nomenclature

  let nomenclature = selectedNomenclature ?? newNomenclature;
  if (isEditingNomenclature) nomenclature = editedNomenclature;

  // return

  return nomenclature;
}
