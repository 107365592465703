import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";

import {useNavigate} from "react-router-dom";

import {Box, Paper, Typography, Icon} from "@mui/material";
import useSceneModulePathMap from "Features/navigation/useSceneModulePathMap";

export default function SectionTitle({sceneModule, scene}) {
  const navigate = useNavigate();

  // data

  const icon = useSceneModuleIconMap()[sceneModule];
  const name = useSceneModuleNameMap()[sceneModule];
  const path = useSceneModulePathMap({sceneId: scene?.id})[sceneModule];

  // handlers

  function handleClick() {
    navigate(path);
  }
  // render

  return (
    <Box sx={{p: 1}}>
      <Paper
        elevation={6}
        sx={{
          p: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mr: 1,
            color: "action",
          }}
        >
          {icon}
        </Box>
        <Typography
          onClick={handleClick}
          sx={{":hover": {textDecoration: "underline", cursor: "pointer"}}}
        >
          {name}
        </Typography>
      </Paper>
    </Box>
  );
}
