import {useRef, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {fetchBlocks} from "../blocksSlice";

import useSelectedReport from "Features/reports/hooks/useSelectedReport";

import useAccessToken from "Features/auth/useAccessToken";

export default function useBlocksByReport(report, options) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const syncingMapRef = useRef({});

  const blocksMap = useSelector((s) => s.blocks.blocksMap);

  useEffect(() => {
    if (report?.id && accessToken && options?.fetchData) {
      if (!syncingMapRef.current[report.id]) {
        syncingMapRef.current[report.id] = true;
        dispatch(fetchBlocks({reportId: report.id, accessToken}));
      }
    }
  }, [report?.id, accessToken, options?.fetchData]);

  let blocks = Object.values(blocksMap);

  blocks = blocks.filter((v) => v.reportId === report?.id);

  if (options?.sortByName) {
    blocks = blocks.sort((a, b) => a.name.localeCompare(b.name));
  }

  if (options?.sortBlocks) {
    const blocksIds = blocks.map((block) => block.id);
    let sortedBlocksIds = report?.sortedBlocksIds || [];
    //
    if (sortedBlocksIds.length !== blocksIds.length) {
      sortedBlocksIds = blocksIds;
    }
    //
    blocks = sortedBlocksIds.map((id) => blocksMap[id]);
  }

  return blocks;
}
