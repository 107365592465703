import {Box} from "@mui/material";

export default function LayerBlueprintRectangle({
  containerBbox,
  bbox,
  color,
  stroke = 1,
}) {
  //
  console.log("bbox", bbox);
  //
  const verticalPanelWidth =
    containerBbox.x + containerBbox.width - bbox.x - bbox.width;
  //
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          zIndex: 1,
          left: containerBbox.x,
          top: containerBbox.y,
          height: bbox.y - containerBbox.y,
          width: containerBbox.width,
          bgcolor: color,
          opacity: 0.5,
          boxSizing: "border-box",
        }}
      />
      <Box
        sx={{
          position: "fixed",
          zIndex: 1,
          left: bbox.x + bbox.width,
          top: bbox.y,
          height: bbox.height,
          width: verticalPanelWidth,
          bgcolor: color,
          opacity: 0.5,
          boxSizing: "border-box",
        }}
      />
      <Box
        sx={{
          position: "fixed",
          zIndex: 1,
          left: containerBbox.x,
          top: bbox.y + bbox.height,
          height: containerBbox.y + containerBbox.height - bbox.y - bbox.height,
          width: containerBbox.width,
          bgcolor: color,
          opacity: 0.5,
          boxSizing: "border-box",
        }}
      />
      <Box
        sx={{
          position: "fixed",
          zIndex: 1,
          left: containerBbox.x,
          top: bbox.y,
          height: bbox.height,
          width: bbox.x - containerBbox.x,
          bgcolor: color,
          opacity: 0.5,
          boxSizing: "border-box",
        }}
      />

      <Box
        sx={{
          position: "fixed",
          zIndex: 90,
          left: bbox.x,
          top: bbox.y,
          height: bbox.height,
          borderLeft: `${stroke}px solid ${color}`,
          boxSizing: "border-box",
        }}
      />

      <Box
        sx={{
          position: "fixed",
          zIndex: 90,
          left: bbox.x,
          top: bbox.y,
          width: bbox.width,
          borderTop: `${stroke}px solid ${color}`,
          boxSizing: "border-box",
        }}
      />
      <Box
        sx={{
          position: "fixed",
          zIndex: 90,
          left: bbox.x + bbox.width,
          top: bbox.y,
          height: bbox.height,
          borderLeft: `${stroke}px solid ${color}`,
          boxSizing: "border-box",
        }}
      />
      <Box
        sx={{
          position: "fixed",
          zIndex: 90,
          left: bbox.x,
          top: bbox.y + bbox.height,
          width: bbox.width,
          borderBottom: `${stroke}px solid ${color}`,
          boxSizing: "border-box",
        }}
      />
    </>
  );
}
