export default function remoteToStateFormTemplate(template) {
  const stateTemplate = {...template};

  // organisation
  stateTemplate.organisationId = template.organisation;
  delete stateTemplate.organisation;

  //
  return stateTemplate;
}
