import {useSelector} from "react-redux";
import useSelectedRoomInMaterials from "./useSelectedRoomInMaterials";

export default function useRoomMaterialsDataInSelectionPanel() {
  const isEditingRoomMaterials = useSelector(
    (s) => s.materials.isEditingRoomMaterials
  );
  const selectedRoomInMaterials = useSelectedRoomInMaterials();
  const editedRoomMaterialsData = useSelector(
    (s) => s.materials.editedRoomMaterialsData
  );
  //
  if (isEditingRoomMaterials) {
    return editedRoomMaterialsData;
  } else {
    return selectedRoomInMaterials?.materialsData;
  }
}
