import {Box} from "@mui/material";

import SectionActionsRooms from "./SectionActionsRooms";

export default function SectionLeftPanelActionsRooms({scene, caplaEditor}) {
  // actions - main

  const actionsMainKeys = [
    "createRoomsGroup",
    "createRoom",
    "editRoomsGroup",
    "exportExcelRooms",
    "importExcelRooms",
    "autoExtract",
  ];
  const actionsMoreKeys = [
    "createRoomsFromTemplate",
    "divider",
    //
    "fetchData",
    //
    "divider",
    "cleanRoomsRelations",
    //
    "divider",
    "deleteRoomsGroup",
    "deleteRooms",
    "deleteAllRooms",
  ];

  return (
    <Box>
      <SectionActionsRooms
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
