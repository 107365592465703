import {List, ListItemButton, Typography} from "@mui/material";

export default function ListReportTemplates({
  reportTemplates,
  onClick,
  selection,
}) {
  // handlers

  function handleClick(reportTemplate) {
    console.log("reportTemplate", reportTemplate);
    onClick(reportTemplate);
  }

  return (
    <List dense sx={{width: 1}}>
      {reportTemplates.map((reportTemplate) => {
        const selected = selection?.includes(reportTemplate.id);
        return (
          <ListItemButton
            key={reportTemplate.id}
            onClick={() => handleClick(reportTemplate)}
            selected={selected}
          >
            <Typography>{reportTemplate.name}</Typography>
          </ListItemButton>
        );
      })}
    </List>
  );
}
