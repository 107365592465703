import {useSelector, useDispatch} from "react-redux";

import {setReportFormTab} from "Features/reports/reportsSlice";

import useKvtReport from "Features/kvform/hooks/useKvtReport";

import {Box, Typography} from "@mui/material";

import KeyValueForm from "Features/kvform/components/KeyValueForm";
import getItemsMapById from "Utils/getItemsMapById";

export default function FormReport({report, onChange, reportTemplates}) {
  const dispatch = useDispatch();
  //

  const data = {
    reportTemplates: reportTemplates,
  };
  const template = useKvtReport(data);

  // data

  const group = useSelector((s) => s.reports.reportFormTab);

  // helpers

  const reportTemplatesById = getItemsMapById(reportTemplates);

  // item

  const item = {...report};
  // reportTemplate
  const reportTemplate = reportTemplatesById[report?.reportTemplateId];
  item.reportTemplate = reportTemplate;

  // handlers

  function handleItemChange(changedItem) {
    console.log("FormReport handleItemChange", changedItem);
    const changedReport = {...report, ...changedItem};
    //
    if (changedItem.reportTemplate) {
      changedReport.reportTemplateId = changedItem.reportTemplate?.id;
      delete changedReport.reportTemplate;
    }
    onChange(changedReport);
  }

  function handleGroupChange(group) {
    dispatch(setReportFormTab(group));
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <KeyValueForm
        template={template}
        item={item}
        onChange={handleItemChange}
        group={group}
        onGroupChange={handleGroupChange}
      />
      {/* <Box sx={{p: 3}}>
        <Typography variant="body2" color="text.secondary">{`org. #${
          report?.organisationId ?? "-?-"
        }`}</Typography>
      </Box> */}
    </Box>
  );
}
