import {Box, Typography, Paper} from "@mui/material";

import KeyValueForm from "Features/kvform/components/KeyValueForm";

export default function FieldNomenclatureTreeCategory({category, onChange}) {
  const selectS = "Sélectionnez une catégorie";

  // data

  const kvt = {
    key: "category",
    fields: [
      {
        key: "name",
        name: "Nom",
        type: "text",
      },
      {key: "code", name: "Code", type: "text"},
      {key: "type", name: "Type", type: "text"},
    ],
  };

  // handler

  function handleCategoryChange(newCategory) {
    onChange(newCategory);
  }

  return (
    <Box
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        p: 2,
        bgcolor: "background.default",
      }}
    >
      <Paper sx={{bgcolor: "common.white"}}>
        {category && (
          <KeyValueForm
            sizeKey={2}
            template={kvt}
            item={category}
            onChange={handleCategoryChange}
          />
        )}
        {!category && (
          <Box sx={{p: 1}}>
            <Typography variant="body2" color="text.secondary">
              {selectS}
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
