import {useEffect} from "react";
import {useParams, Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {Box} from "@mui/material";

import BoxResizable from "Components/BoxResizable";

import {
  // setIsLoaded,
  // setScenesDialog,
  setViewerWidth,
  setFixedViewersBoxWidth,
  setIsViewerFullScreen,
  setCanUpdateModels,
  updateScenesStatus,
  fetchStoredModel,
  fetchStoredModels,
  setIs3dEnabled,
} from "Features/viewer3D/viewer3DSlice";
// import {
//   // setOpenSelectionPanel,
//   setSelectionPanelWidth,
// } from "Features/selection/selectionSlice";
import {
  setOpen,
  // setOpenLeftPanel,
  // setLeftPanelWidth,
} from "Features/navigation/navigationSlice";
import {setSelectedFixedViewer} from "Features/ui/uiSlice";
// import useSceneIsLoaded from "Features/scenes/useSceneIsLoaded";
// import useScenes from "Features/scenes/useScenes";
import useScene from "Features/scenes/useScene";
import useSectionDims from "Features/ui/useSectionDims";
// import useSharedRemoteScene from "Features/scenes/useSharedRemoteScene";

import PanelSelection from "Features/selection/components/PanelSelection";
import AppTopBar from "Features/navigation/components/AppTopBar";
import Stretch from "Features/ui/components/Stretch";
//import ScenesDialog from "Features/scenes/components/ScenesDialog";
import Viewer3DModeEditor from "Features/viewer3D/components/Viewer3DModeEditor";
import PdfViewer from "Features/pdf/components/SectionPdfViewer";
//import MapViewer from "Features/map/components/MapViewer";
import ViewerZone from "Features/zones/components/ViewerZone";
import ViewerReport from "Features/reportBlocks/components/ViewerReport";
import PageToolbox from "Features/toolbox/components/PageToolbox";
import PageOrganisations from "Features/organisations/components/PageOrganisations";
import PageOrganisation from "Features/organisations/components/PageOrganisation";
import PageBimboxOrder from "Features/ebusiness/components/PageBimboxOrder";
// import TogglePdf3d from "Features/navigation/components/TogglePdf3d";
//import BoxCard from "./SharedScene/components/BoxCard";
import SectionCallToActionSelectDataItem from "Features/ui/components/SectionCallToActionSelectDataItem";
import PageDownloadingScene from "Features/scenes/components/PageDownloadingScene";
import useMeasurementsModelsInitialScope from "Features/measurements/useMeasurementsModelsInitialScope";
import usePageType from "Features/navigation/usePageType";

import useSceneModule from "Features/navigation/useSceneModule";
import DialogCreateElementTypeFast from "Features/elementTypes/components/DialogCreateElementTypeFast";
import DialogFsCreateType from "Features/elementTypor/components/DialogFsCreateType";
import DialogSelectorElementTypes from "Features/elementTypes/components/DialogSelectorElementTypes";

import LayerOverviewerDialogsFs from "Features/overviewer/components/LayerOverviewerDialogsFs";
//import ButtonSetViewToEdit from "Features/pdf/components/ButtonSetViewToEdit";
import useSelectedScene from "Features/scenes/useSelectedScene";
//import ButtonBestViews from "Features/ui/components/ButtonBestViews";
// import ButtonToggleGridView from "Features/ui/components/ButtonToggleGridView";
import ButtonToggleSelection from "Features/selection/components/ButtonToggleSelection";
import NavPanelSceneModule from "Features/navigation/components/NavPanelSceneModules";

import SectionLeftPanel from "Features/leftPanel/components/SectionLeftPanel";
import useIsFullscreen from "Features/ui/useIsFullscreen";
import useIs3dEnabled from "Features/navigation/useIs3dEnabled";

import useUpdateDefaultVersionForMeasurements from "Features/versions/hooks/useUpdateDefaultVersionForMeasurements";
import useFetchScopeByScene from "Features/scopes/hooks/useFetchScopeByScene";
import useInitScopeSelection from "Features/overviewer/hooks/useInitScopeSelection";
import useInitFetchElementTypesGroups from "Features/overviewer/hooks/useInitFetchElementTypesGroups";
import useInitFetchRessourcesGroups from "Features/overviewer/hooks/useInitFetchRessourcesGroups";
import useInitFetchRoomsGroups from "Features/rooms/hooks/useInitFetchRoomsGroups";

import useInitFetchRemoteSceneOverview from "Features/overviewer/hooks/useInitFetchRemoteSceneOverview";
import useInitFetchRemoteSceneLight from "Features/overviewer/hooks/useInitFetchRemoteSceneLight";
import useSceneFromStateAndUrl from "Features/scenes/useSceneFromStateAndUrl";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";

import useOpenDialogFsOpenScope from "Features/overviewer/hooks/useOpenDialogFsOpenScope";
import useOpenDialogFsLoadingScope from "Features/overviewer/hooks/useOpenDialogFsLoadingScope";

import useInitAndUpdateRelations from "Features/relations/hooks/useInitAndUpdateRelations";
import useUpdatePackageIdsInMainScope from "Features/overviewer/hooks/useUpdatePackageIdsInMainScope";
import TopBarOverview from "Features/navigation/components/TopBarOverview";
import useUpdateMeasurementsDataManager from "Features/overviewer/hooks/useUpdateMeasurementsDataManager";
import useUpdateMeasurementsShowedInViewers from "Features/measurements/useUpdateMeasurementsShowedInViewers";
import useUpdateRestoreSession from "Features/navigation/useUpdateRestoreSession";
import useAutoFetchSceneModelsInScope from "Features/overviewer/hooks/useAutoFetchSceneModelsInScope";
import useAutoDetectModelsToLoad from "Features/overviewer/hooks/useAutoDetectModelsToLoad";
import useAutoLoadModelsInViewers from "Features/overviewer/hooks/useAutoLoadModelsInViewers";
import useUpdateInitialEntitiesSelectionByScope from "Features/overviewer/hooks/useUpdateInitialEntitiesSelectionByScope";

import SnackbarGeneric from "Features/ui/components/SnackbarGeneric";
import useAutoUpdateRessourceExtractedText from "Features/pdf/hooks/useAutoUpdateRessourceExtractedText";
import useAutoSyncModels from "Features/overviewer/hooks/useAutoSyncModels";
import useInitFetchArticlesGroups from "Features/overviewer/hooks/useInitFetchArticlesGroups";
import useAutoSetScopeParam from "Features/overviewer/hooks/useAutoSetScopeParam";
import useAutoUpdateShowedModules from "Features/navigation/useAutoUpdateShowedModules";
import useInitFetchUserOrganisationNomenclatures from "Features/materials/hooks/useInitFetchUserOrganisationNomenclatures";
import useInitFetchMaterialsGroups from "Features/materials/hooks/useInitFetchMaterialsGroups";
import useInitFetchSurveys from "Features/surveys/hooks/useInitFetchSurveys";

export default function AppDesktop({caplaEditor}) {
  const dispatch = useDispatch();

  // params

  const params = useParams();
  const isStagingEnv = useIsStagingEnv();

  // data

  const is3dEnabled = useIs3dEnabled();

  const dims = useSectionDims();
  const sceneModule = useSceneModule();
  const mainSection = useSelector((s) => s.viewer3D.mainSection);
  // const openLeftPanel = useSelector((s) => s.navigation.openLeftPanel); // section used in the viewer to display the list of ressources

  const sceneV1 = useScene(params.sceneId, caplaEditor);
  const selectedScene = useSelectedScene(caplaEditor);

  const sceneV2 = useSceneFromStateAndUrl();
  const scene = isStagingEnv ? sceneV2 : sceneV1;

  // const sharedRemoteScene = useSharedRemoteScene({shareLink: params.shareLink});

  // const openNavigation = useSelector((s) => s.navigation.open);
  const showDataPreview = useSelector((s) => s.viewer3D.showDataPreview);

  // const show3D = useSelector((s) => s.pdf.show3D); // used to handle pdf sizing when 3D on.
  const fullscreen = useIsFullscreen();

  const scenesStatus = useSelector((state) => state.viewer3D.scenesStatus);
  const sceneStatus = scenesStatus[scene?.clientId];

  // const scenesInitialLoadingStatus = useSelector(
  //   (s) => s.scenes.scenesInitialLoadingStatus
  // );

  const os = useSelector((s) => s.ui.openSections);
  const selectedFixedViewer = useSelector((s) => s.ui.selectedFixedViewer);

  const space = false; // used to display space & multiscenes

  const remoteDataOnly = useSelector((s) => s.sync.remoteDataOnly);

  const pageType = usePageType();

  const openSearchPanel = useSelector((s) => s.pdf.openSearchPanel);
  const imageOverlay = useSelector((s) => s.viewer3D.imageOverlayMode);

  // helper

  const isScreenshotViewer = mainSection === "SCREENSHOTVIEWER";

  // helpers

  const graphicsIsLeft = !os.outlet && os.callToAction;

  // helpers • top

  const top = fullscreen ? "8px" : "68px";

  // helpers • active scene

  const isHome = Object.keys(params).length === 0;
  // const pageWithScenes = params.sceneId || params.worksiteId;

  const isShared = Boolean(params.shareLink);

  let activeScene = scene;
  // if (isShared) activeScene = sharedRemoteScene;

  // helpers - show ButtonToggleSelection

  // const showButtonToggleSelection =
  //   pageType === "SCENE" &&
  //   !imageOverlay &&
  //   !isScreenshotViewer &&
  //   !["ADMIN", "LIBRARY", "PRICINGS", "RELATIONS"].includes(sceneModule) &&
  //   !openSearchPanel;

  // helpers - pdf3d

  const isPdf3d = os.fixedViewersBox && os.viewer3D;

  // helpers - showSelectionPanel

  const showSelectionPanel =
    pageType === "SCENE" && !["MODULES"].includes(sceneModule);

  // effect - initial measurements scope

  useMeasurementsModelsInitialScope(activeScene);

  // effects - can update models & viewer3D

  useEffect(() => {
    if (isShared) {
      dispatch(setCanUpdateModels(false));
    } else {
      dispatch(setCanUpdateModels(true));
      dispatch(setIs3dEnabled(true)); // enable 3D in the studio app.
    }
  }, [isShared]);

  // handlers

  function handleWidthChange(width) {
    dispatch(setViewerWidth(width));
    if (showDataPreview && os.outlet) dispatch(setFixedViewersBoxWidth(width));
  }

  function handleFixedViewersWidthChange(width) {
    dispatch(setFixedViewersBoxWidth(width));
    if (showDataPreview && os.outlet) dispatch(setViewerWidth(width));
    if (isPdf3d) dispatch(setViewerWidth(1 - width));
  }

  function handleCallToActionWidthChange() {
    // dispatch(setFixedViewersBoxWidth(1 - width));
    // dispatch(setViewerWidth(1 - width));
  }

  // effects

  useEffect(() => {
    if (isHome) {
      dispatch(setIsViewerFullScreen(false));
    }
  }, [isHome]);

  /*
   * SCENE & MODELS PRE-LOADING
   */

  // preload models in scene

  async function fetchClientModels(sceneClientId) {
    const result = await dispatch(fetchStoredModels(sceneClientId, false));
    const {models} = unwrapResult(result);
    for (let model of models) {
      if (model.enabled) await dispatch(fetchStoredModel(model.id));
    }
    dispatch(updateScenesStatus({sceneClientId, status: "preloaded"})); // scene's models in state
  }

  useEffect(() => {
    if (
      !space &&
      !isShared &&
      sceneStatus === "idle" &&
      scene?.clientId &&
      (!remoteDataOnly || !scene?.id)
    ) {
      fetchClientModels(scene.clientId); // preload all the models in the store.
    }
  }, [scene?.clientId, sceneStatus, space, remoteDataOnly, isShared]);

  /*
   * COLORING PAGES
   */
  useEffect(() => {
    if (pageType === "NEW_COLORING") dispatch(setOpen(false)); // open : navigationPanel
    if (pageType === "COLORING") dispatch(setOpen(false));
  }, [pageType]);

  /*
   * WORKSITES & FIXED VIEWER
   */
  useEffect(() => {
    if (pageType === "WORKSITES") {
      dispatch(setSelectedFixedViewer("MAP"));
    } else {
      dispatch(setSelectedFixedViewer("PDF"));
    }
  }, [pageType]);

  // auto sync

  console.time("debugtime useAuto");

  useAutoSyncModels(activeScene);

  // updates with hooks

  useUpdatePackageIdsInMainScope(activeScene); // large impact on performance.

  useUpdateDefaultVersionForMeasurements(scene);
  useFetchScopeByScene(activeScene);

  useInitScopeSelection(activeScene);
  useUpdateRestoreSession();

  useInitFetchRemoteSceneOverview();
  useInitFetchRemoteSceneLight();

  useInitFetchUserOrganisationNomenclatures();

  useInitFetchElementTypesGroups();
  useInitFetchRessourcesGroups();
  useInitFetchRoomsGroups(activeScene);
  useInitFetchMaterialsGroups(activeScene);
  useInitFetchArticlesGroups();
  useInitFetchSurveys(activeScene);

  useUpdateMeasurementsShowedInViewers(activeScene);
  useUpdateMeasurementsDataManager(caplaEditor);

  useOpenDialogFsLoadingScope();

  // auto

  useAutoUpdateShowedModules(activeScene);

  // auto fetching models

  useAutoFetchSceneModelsInScope(activeScene);
  useAutoDetectModelsToLoad();
  useAutoLoadModelsInViewers(caplaEditor);

  // change scope

  useUpdateInitialEntitiesSelectionByScope();
  useAutoSetScopeParam();

  // super dangerous updates !!!

  useInitAndUpdateRelations(activeScene);

  // extractedText

  useAutoUpdateRessourceExtractedText(activeScene);

  console.timeEnd("debugtime useAuto");

  // render

  if (pageType === "TOOLBOX") {
    return <PageToolbox />;
  } else if (pageType === "ORDER") {
    return <PageBimboxOrder />;
  } else if (pageType === "ORGANISATIONS") {
    return <PageOrganisations />;
  } else if (pageType === "ORGANISATION") {
    return <PageOrganisation />;
  } else
    return (
      <Stretch>
        <LayerOverviewerDialogsFs scene={activeScene} />
        <DialogCreateElementTypeFast scene={scene} />
        <DialogFsCreateType scene={activeScene} caplaEditor={caplaEditor} />
        <DialogSelectorElementTypes
          scene={activeScene}
          caplaEditor={caplaEditor}
        />
        <SnackbarGeneric />

        {/* {(activeScene || isHome || pageType === "NEW_COLORING") &&
        !["LIBRARY"].includes(sceneModule) &&
        !isScreenshotViewer && (
          <ButtonToggleGridView viewer={isShared} caplaEditor={caplaEditor} />
        )} */}

        <Box
          sx={{
            width: 1,
            height: 1,
            overflow: "hidden",
            display: "flex",
            boxSizing: "border-box",
            //border: (theme) => `8px solid ${theme.palette.primary.flash}`,
          }}
        >
          <NavPanelSceneModule
            scene={activeScene}
            viewer={isShared}
            caplaEditor={caplaEditor}
          />
          <SectionLeftPanel
            caplaEditor={caplaEditor}
            scene={activeScene}
            viewer={isShared}
          />
          <Box
            sx={{
              flexGrow: 1,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              height: 1, // (for flexDirection = row)
              overflow: "hidden",
              position: "relative",
            }}
          >
            {!isHome && true && (
              <Box sx={{bgcolor: "common.white", width: 1}}>
                <TopBarOverview scene={activeScene} caplaEditor={caplaEditor} />
              </Box>
            )}
            <Box
              sx={{
                flexGrow: 1,
                position: "relative",
                display: "flex",
                //flexDirection: "column",
                height: 1, // (for flexDirection = row)
                overflow: "hidden",
                position: "relative",
              }}
            >
              {(isHome || activeScene) && (
                <AppTopBar
                  caplaEditor={caplaEditor}
                  scene={activeScene}
                  viewer={isShared}
                />
              )}

              {/* {showButtonToggleSelection && (
                <Box
                  sx={{
                    position: "absolute",
                    right: "0px",
                    top,
                    zIndex: 90,
                    display: "flex",
                    "&>*:not(:last-child)": {mr: 1},
                  }}
                >
                  <ButtonToggleSelection />
                </Box>
              )} */}

              <Box
                sx={{
                  flexGrow: 1,
                  width: 1,
                  position: "relative",
                  display: "flex",
                  boxSizing: "border-box",
                  minWidth: 0,
                  minHeight: 0,
                }}
              >
                <Box
                  sx={{
                    flexGrow: dims?.showOutlet ? 1 : 0,
                    width: dims?.showOutlet ? "unset" : 0,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                    display: "flex",
                    minWidth: 0,
                    minHeight: 0,
                    overflow: "hidden",
                  }}
                >
                  {activeScene || isHome || pageType === "NEW_COLORING" ? (
                    <Outlet
                      context={{
                        caplaEditor: caplaEditor,
                        scene: activeScene,
                        viewer: isShared,
                      }}
                    />
                  ) : (
                    <PageDownloadingScene scene={scene} />
                  )}
                </Box>

                {/* <Box
              sx={{
                height: 1,
                flexGrow: dims.showFixedViewersBox ? 1 : 0,
                width: dims.showFixedViewersBox ? "unset" : 0,
                minWidth: 0,
                bgcolor: "red",
              }}
            >
              <PdfViewer scene={activeScene} caplaEditor={caplaEditor} viewer={isShared}/>
            </Box> */}

                {
                  <BoxResizable
                    // width={dims.viewer3DWidth}
                    width={dims.fixedViewersBoxWidth}
                    //width={0.5}
                    onChange={handleFixedViewersWidthChange}
                    isWidthInPercent={true}
                    left={graphicsIsLeft || isPdf3d ? false : true}
                    right={graphicsIsLeft || isPdf3d ? true : false}
                    //disabled={openNavigation}
                    //auto={show3D}
                    hide={!os.fixedViewersBoxWidth}
                    viewer={isShared}
                  >
                    {/* {selectedFixedViewer === "PDF" &&
                      pageType !== "TOOLBOX" && (
                        <PdfViewer
                          scene={activeScene}
                          viewer={isShared}
                          caplaEditor={caplaEditor}
                        />
                      )} */}
                    {pageType !== "TOOLBOX" && true && (
                      <PdfViewer
                        scene={activeScene}
                        viewer={isShared}
                        caplaEditor={caplaEditor}
                        hidden={selectedFixedViewer !== "PDF"}
                      />
                    )}
                    {/* {selectedFixedViewer === "MAP" && (
                      <MapViewer
                        scene={activeScene}
                        caplaEditor={caplaEditor}
                      />
                    )} */}
                    {selectedFixedViewer === "ZONE" && (
                      <Box
                        sx={{
                          //position: "absolute",
                          //top: 0,
                          //bottom: 0,
                          //left: 0,
                          //right: 0,
                          //zIndex: 50,
                          width: 1,
                          height: 1,
                        }}
                      >
                        <ViewerZone caplaEditor={caplaEditor} scene={scene} />
                      </Box>
                    )}
                    {selectedFixedViewer === "REPORT" && (
                      <Box
                        sx={{
                          width: 1,
                          height: 1,
                        }}
                      >
                        <ViewerReport caplaEditor={caplaEditor} scene={scene} />
                      </Box>
                    )}
                  </BoxResizable>
                }

                {
                  <BoxResizable
                    width={dims.viewer3DWidth}
                    //width={0.5}
                    onChange={handleWidthChange}
                    isWidthInPercent={true}
                    left={graphicsIsLeft ? false : true}
                    right={graphicsIsLeft ? true : false}
                    disabled={isPdf3d}
                    hide={!os.viewer3D || !is3dEnabled}
                    viewer={isShared}
                  >
                    <Viewer3DModeEditor
                      scene={activeScene}
                      viewer={isShared}
                      caplaEditor={caplaEditor}
                    />
                  </BoxResizable>
                }

                <BoxResizable
                  width={dims.callToActionWidth}
                  isWidthInPercent={true}
                  onChange={handleCallToActionWidthChange}
                  left={graphicsIsLeft ? false : true}
                  right={false}
                  disabled={graphicsIsLeft}
                  hide={os.callToAction}
                  viewer={isShared}
                >
                  {Boolean(dims.callToActionWidth) && (
                    <SectionCallToActionSelectDataItem
                      caplaEditor={caplaEditor}
                      scene={activeScene}
                      viewer={isShared}
                    />
                  )}
                </BoxResizable>

                {/* {showOutlet || (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    zIndex: 10,
                  }}
                >
                  <Outlet />
                </Box>
              )} */}
              </Box>
            </Box>
          </Box>

          {showSelectionPanel && (
            <PanelSelection
              scene={pageType === "HOME" ? selectedScene : activeScene}
              //scene={activeScene}
              caplaEditor={caplaEditor}
              viewer={isShared}
            />
          )}
        </Box>
      </Stretch>
    );
}
