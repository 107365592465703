export default function getOtherItemFromMaterialCategory(materialCategory) {
  const otherItem = {
    id: materialCategory.id + "-other",
    name: `Autre (${materialCategory.name})`,
    isOther: true,
    parentId: materialCategory.id,
    isCategory: true,
  };
  return otherItem;
}
