import {Box, Typography} from "@mui/material";
import {ArrowDropDown as Down, Close} from "@mui/icons-material";

import SelectorMaterialsGroup from "./SelectorMaterialsGroup";
import SelectorGenericItemWithChips from "Components/SelectorGenericItemWithChips";
import getItemsMapById from "Utils/getItemsMapById";

export default function SelectorMaterialsGroupContainer({
  variant,
  selectedMaterialsGroupId,
  materialsGroups,
  materialsGroupsCounters,
  onSelectedMaterialsGroupIdChange,
  isSelectingMaterialsGroup,
  onIsSelectingMaterialsGroupChange,
}) {
  // strings

  const groupS = "Liste de matériaux";
  const groupsS = "Listes";

  // helpers

  const groupsMap = getItemsMapById(materialsGroups);
  const selectedMaterialsGroup = groupsMap[selectedMaterialsGroupId];

  // handlers

  function handleGroupClick(group) {
    onSelectedMaterialsGroupIdChange(group.id);
  }

  function handleToggleSelectGroup() {
    onIsSelectingMaterialsGroupChange(!isSelectingMaterialsGroup);
  }

  return (
    <>
      <Box sx={{py: 2}}>
        {variant === "chips" && (
          <SelectorGenericItemWithChips
            items={materialsGroups}
            selectedItem={selectedMaterialsGroup}
            onClick={handleGroupClick}
            counters={materialsGroupsCounters}
          />
        )}
        {variant === "list" && (
          <Box>
            <Typography
              sx={{
                fontSize: 13,
                color: "secondary.main",
                p: 0.5,
                px: 1,
                visibility: isSelectingMaterialsGroup ? "hidden" : "visible",
              }}
            >
              {groupS}
            </Typography>
            <Box
              sx={{
                p: 1,
                bgcolor: isSelectingMaterialsGroup
                  ? "common.white"
                  : "secondary.main",
              }}
            >
              <Box
                onClick={handleToggleSelectGroup}
                sx={{
                  px: 1,
                  py: 0.5,
                  bgcolor: "common.white",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  color: "text.secondary",
                }}
              >
                <Typography sx={{fontSize: 13}}>
                  {!isSelectingMaterialsGroup
                    ? selectedMaterialsGroup?.name
                    : groupsS}
                </Typography>
                {!isSelectingMaterialsGroup ? (
                  <Down />
                ) : (
                  <Close color="inherit" />
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {isSelectingMaterialsGroup && variant === "list" && (
        <Box
          sx={{
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
          }}
        >
          <SelectorMaterialsGroup
            items={materialsGroups}
            selectedItem={selectedMaterialsGroup}
            onClick={handleGroupClick}
          />
        </Box>
      )}
    </>
  );
}
