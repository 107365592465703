import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

export default function DialogValidateReport({open, onClose}) {
  // strings

  const title = "Valider le rapport";

  const description = "Une nouvelle version du rapport sera créée";

  const saveS = "Valider";

  // handlers

  function handleSave() {
    window.location.href = `mailto:?subject=Nouveau rapport&body=Une nouvelle version du rapport sera créée`;
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <p>{description}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
