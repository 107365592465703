import React, {useState} from "react";

import {Box, Checkbox, Grid, Typography, Menu, MenuItem} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import DialogSelectorColor from "../../../Components/DialogSelectorColor";

export default function FieldSelector({
  name,
  value,
  onChange,
  options,
  locked,
  sizeKey = 12,
  horizontal = false,
  horizontalHeader = false,
  width,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // helpers

  let optionName = "";
  if (value) optionName = options.find((o) => o.key === value)?.name;

  // handlers

  function handleOpen(e) {
    if (!locked) setAnchorEl(e.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  // menu

  function handleChange(key) {
    onChange(key);
    setAnchorEl(null);
  }

  return (
    <>
      <Grid
        container
        sx={{
          pr: 1,
          borderBottom: horizontal
            ? "unset"
            : (theme) => `1px solid ${theme.palette.divider}`,
          width: horizontal ? width : 1,
          minWidth: horizontal ? width : "unset",
        }}
      >
        {(!horizontal || horizontalHeader) && (
          <Grid
            item
            xs={horizontalHeader ? 12 : sizeKey}
            sx={{bgcolor: "background.default", pl: 1, py: 0.2}}
          >
            <Typography sx={{fontSize: 13, color: "text.secondary"}}>
              {name}
            </Typography>
          </Grid>
        )}
        {!horizontalHeader && (
          <Grid
            item
            xs={horizontal ? 12 : 12 - sizeKey}
            sx={{
              borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
              px: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: 1,
                height: 1,
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
                color: locked ? "text.secondary" : "unset",
              }}
              onClick={handleOpen}
            >
              <Typography sx={{fontSize: 13}}>{optionName}</Typography>
              {!locked && options?.length > 1 && <Down fontSize="small" />}
            </Box>
          </Grid>
        )}
      </Grid>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {options.map((option) => {
          return (
            <MenuItem key={option.key} onClick={() => handleChange(option.key)}>
              <Typography variant="body2">{option?.name}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
