export default function remoteToStateSurvey(survey) {
  const _survey = {...survey};

  // scene
  _survey.sceneId = survey.scene;
  delete _survey.scene;

  // scope
  _survey.scopeId = survey.scope;
  delete _survey.scope;

  //
  return _survey;
}
