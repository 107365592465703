import {Box} from "@mui/material";

import SectionActionsBlocks from "./SectionActionsBlocks";

export default function SectionLeftPanelActionsBlocks({scene, caplaEditor}) {
  // actions - main

  const actionsMainKeys = ["validateReport", "createViewBlocks"];
  const actionsMoreKeys = ["cleanReport", "divider", "deleteBlock"];

  return (
    <Box>
      <SectionActionsBlocks
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
