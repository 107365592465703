import {saveBlob, urlToBlob} from "Features/files/utils";
import {
  createImageAsync,
  cropImage,
  getImageSize,
} from "Features/images/imageUtils";
import Konva from "konva";
import theme from "Styles/theme";

import MarkersManager from "./MarkersManager";

import markerToMarkerNodeProps from "../utils/markerToMarkerNodeProps";
import getBlueprintBbox from "../utils/getBlueprintBbox";
import getBboxInStage from "./utilsZonesEditor.js/getBboxInStage";
import getStageDefaultScale from "./utilsZonesEditor.js/getStageDefaultScale";
import getImageDefaultPosition from "./utilsZonesEditor.js/getImageDefaultPosition";
import getPointerCoordsInStage from "./utilsZonesEditor.js/getPointerCoordsInStage";

export default class ZonesEditor {
  containerElement;

  stage;
  layer;
  width;
  height;

  strokeWidth;
  color;

  painting;
  writting;
  onWritting;
  onTextPosition;
  mode;

  lastAnnotation;
  lastTextAnnotation;
  lastTextarea;
  annotations;
  selection;

  imageNode;
  blueprintNode;

  prevContainerBbox; // used to updated stage on container resize.
  prevStageScale; // used to updated stage on container resize.
  prevImageNodePosition; // used to updated stage on container resize.

  constructor({
    caplaEditor,
    container,
    width,
    height,
    onWritting,
    onTextPosition,
    onModeChange,
    containerElement,
    blueprintProps,
  }) {
    this.caplaEditor = caplaEditor;
    this.containerElement = containerElement;

    this.prevContainerBbox = containerElement.getBoundingClientRect();

    this.stage = new Konva.Stage({
      container, // container string  ("container")
      width,
      height,
      draggable: true,
    });

    // layer

    this.layerDefault = new Konva.Layer({zIndex: 1});
    this.layerImage = new Konva.Layer({zIndex: 5});
    this.layerPolylines = new Konva.Layer();
    this.layerAnchors = new Konva.Layer();
    this.layerZones = new Konva.Layer({zIndex: 4});
    this.layerDifferences = new Konva.Layer({zIndex: 6});
    this.layerPattern = new Konva.Layer();
    this.layerMarkers = new Konva.Layer({zIndex: 7});

    this.stage.add(this.layerDefault);
    this.stage.add(this.layerImage);
    this.stage.add(this.layerPolylines);
    this.stage.add(this.layerAnchors);
    this.stage.add(this.layerZones);
    this.stage.add(this.layerDifferences);
    this.stage.add(this.layerPattern);
    this.stage.add(this.layerMarkers);

    // blueprint

    this.blueprintBboxInStage = {x: 0, y: 0, width: 0, height: 0};
    this.blueprintProps = blueprintProps;
    this.addBlueprintNode();
    this.updateBlueprintBboxes();

    //

    this.url = null;
    this.imageNode = null;
    this.polylineNodes = [];
    this.anchorNodes = [];

    this.polylines = [];

    this.width = width;
    this.height = height;
    this.imageSize = {};

    this.scaleBy = 1.1;
    this.scale = 1;

    this.imageScale = 1;
    this.offsetX = 0;
    this.offsetY = 0;

    this.mode = "FREE";
    this.painting = false;
    this.writting = false;

    this.isPickingColorFromCanvas = false;

    this.onWritting = onWritting;
    this.onTextPosition = onTextPosition;
    this.onModeChange = onModeChange;

    this.color = theme.palette.primary.main;
    this.strokeWidth = 4;

    this.annotations = [];

    this.addListeners();

    this.firstImageWasAdded = false; // to avoid zoom reset when changing image url.

    //
    this.imageSizeRef = {width: 200, height: 100};
    this.positionRef = {x: 0, y: 0, z: 0}; // ref used to compute position of several images.
    this.canvasSize = {width: 300, height: 150}; // used to compute canvas size with several images.

    // difference

    this.imageOldNode = null;
    this.imageDiffNode = null;
    this.bbox = null;

    // pattern recognition

    this.patternRectNode = null;

    // zones

    this.firstZoneNode = null;

    // managers

    this.markersManager = new MarkersManager({zonesEditor: this});
  }

  /*
   * setters
   */

  setFirstImageWasAdded(bool) {
    this.firstImageWasAdded = bool;
  }

  /*
   * blueprint
   */

  updateBlueprintBboxes() {
    //
    const containerBbox = this.containerElement.getBoundingClientRect();

    // in window
    const {aspectRatio, padding, stroke} = this.blueprintProps;
    const blueprintBbox = getBlueprintBbox(
      this.containerElement,
      aspectRatio,
      padding,
      stroke
    );
    // in container
    this.blueprintBboxInContainer = {...blueprintBbox};
    this.blueprintBboxInContainer.x = blueprintBbox.x - containerBbox.x;
    this.blueprintBboxInContainer.y = blueprintBbox.y - containerBbox.y;

    // in stage
    this.blueprintBboxInStage = getBboxInStage(
      this.blueprintBboxInContainer,
      this.stage
    );
  }

  updateBlueprintNode() {
    this.updateBlueprintBboxes();
    const {x, y, width, height} = this.blueprintBboxInStage;
    this.blueprintNode.x(x);
    this.blueprintNode.y(y);
    this.blueprintNode.width(width);
    this.blueprintNode.height(height);
  }

  resetBlueprintNode() {
    this.blueprintNode.x(0);
    this.blueprintNode.y(0);
    this.blueprintNode.width(0);
    this.blueprintNode.height(0);
  }

  addBlueprintNode() {
    const blueprintNode = new Konva.Rect({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      fill: "white",
      stroke: "black",
      strokeWidth: 1,
    });
    this.layerDefault.add(blueprintNode);
    this.layerDefault.draw();
    //
    this.blueprintNode = blueprintNode;
  }

  initBlueprint() {
    this.stage.position({x: 0, y: 0});

    // first set stage scale
    const defaultScale = getStageDefaultScale(
      this.imageSize,
      this.blueprintBboxInContainer
    );
    this.setStageScale(defaultScale);

    // then refresh blueprint bbox
    this.updateBlueprintBboxes();

    // then set image position
    const imageDefaultPosition = getImageDefaultPosition(
      this.imageSize,
      this.blueprintBboxInStage
    );
    this.setImageNodePosition(imageDefaultPosition);
  }

  /*
   * layers
   */

  resetLayers() {
    this.clearLayerDifferences();
    this.clearLayerImage();
    this.destroyZoneLayerContent();
    this.setFirstImageWasAdded(true);
    this.setFirstImageWasAdded(false);
  }

  /*
   * debug
   */

  addCircle({x, y}) {
    var circle = new Konva.Circle({
      x: x,
      y: y,
      radius: 5 / this.stage.scaleX(),
      fill: "red",
      stroke: "black",
      strokeWidth: 1,
    });
    this.layerMarkers.add(circle);
    this.layerMarkers.draw();
  }

  /*
   * image
   */

  async addImageNode({url, offsetX, offsetY, width, height, layer, scale}) {
    const imageObj = new Image();
    return new Promise((resolve, reject) => {
      imageObj.onload = () => {
        try {
          const image = new Konva.Image({
            image: imageObj,
            x: offsetX,
            y: offsetY,
            width, // Use provided width for consistency
            height, // Use provided height for consistency
            scaleX: scale ?? 1,
            scaleY: scale ?? 1,
          });
          layer.add(image);
          //layer.draw();
          this.stage.draw();
          resolve(image); // Resolve with the Konva.Image object
        } catch (error) {
          reject(error); // Reject with any errors during creation or drawing
        }
      };

      imageObj.onerror = reject; // Reject on image loading errors

      imageObj.crossOrigin = "Anonymous"; // Set cross-origin for potential CORS issues
      imageObj.src = url;
    });
  }

  setImageNodePosition({x, y}) {
    this.imageNode.x(x);
    this.imageNode.y(y);
    this.prevImageNodePosition = {x, y};
  }

  async addImage({url, width, height}) {
    this.clearLayerImage();

    if (!url) return;
    if (this.imageNode) {
      this.imageNode.destroy();
      this.imageNode = null;
    }
    const imageSize = {width, height};
    this.imageSize = imageSize;

    const imageObj = new Image();
    imageObj.onload = () => {
      const image = new Konva.Image({
        image: imageObj,
      });
      this.imageNode = image;
      if (!this.firstImageWasAdded) {
        console.log("FirstImageWasAdded");
        this.initBlueprint();
        //this.stage.scaleX(1 / this.imageScale);
        //this.stage.scaleY(1 / this.imageScale);
        this.setFirstImageWasAdded(true);
      }

      if (this.layerImage.getChildren().length === 0) {
        this.layerImage.add(image);
        this.layerImage.draw();
      }
    };
    imageObj.crossOrigin = "Anonymous";
    imageObj.src = url;

    this.url = url;
  }

  getImageCropByBlueprint() {
    this.updateBlueprintBboxes();
    const {x, y, width, height} = this.blueprintBboxInStage;
    const cropX = x - this.imageNode.x();
    const cropY = y - this.imageNode.y();
    const cropWidth = width;
    const cropHeight = height;
    return {x: cropX, y: cropY, width: cropWidth, height: cropHeight};
  }

  async getImageFileCropByBlueprint() {
    const {x, y, width, height} = this.getImageCropByBlueprint();
    const cropFile = await cropImage(this.url, x, y, width, height);
    return cropFile;
  }

  async downloadImageCropByBlueprint() {
    const {x, y, width, height} = this.getImageCropByBlueprint();
    console.log("crop", x, y, width, height);
    const cropFile = await cropImage(this.url, x, y, width, height);
    saveBlob(cropFile, cropFile.name);
  }

  draw() {
    this.layerImage.draw();
  }

  clear() {
    this.stage.clear();
    this.stage.clearCache();
    if (this.lastTextarea) document.body.removeChild(this.lastTextarea);
  }

  getImageNodeFromLayerImage() {
    // used to avoid 2 creations. Bug with the useEffect of init.
    const children = this.layerImage.getChildren();
    return children[0];
  }

  hideImage() {
    const imageNode = this.getImageNodeFromLayerImage();
    if (imageNode) imageNode.hide();
  }

  showImage() {
    const imageNode = this.getImageNodeFromLayerImage();
    if (imageNode) imageNode.show();
  }

  setImageOpacity(opacity) {
    const imageNode = this.getImageNodeFromLayerImage();
    if (imageNode) imageNode.opacity(opacity / 100);
  }
  clearLayerImage() {
    const children = this.layerImage.getChildren();
    for (let i = 0; i < children.length; i++) {
      children[i].destroy();
    }
  }

  /*
   * zones
   */
  drawZones() {
    this.layerZones.draw();
  }

  getCanvasSizeFromZones(zones) {
    let topLeftZone;
    let bottomRightZone;
    return {width: 3000, height: 1000};
  }

  destroyZoneLayerContent() {
    const children = this.layerZones.getChildren();
    for (let i = 0; i < children.length; i++) {
      children[i].destroy();
    }
  }

  async downloadZoneImage(zone) {
    const url = await this.getZoneUrl(zone);
    const blob = await urlToBlob(url, `${zone.name}.png`);
    saveBlob(blob);
  }
  async getImageData({
    width,
    height,
    imageUrl,
    imageWidth,
    imageHeight,
    scale,
    deltaImage,
  }) {
    // width and height are the container dims.
    const canvas = document.createElement("canvas");
    const image = await createImageAsync(imageUrl);
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, width, height);
    ctx.drawImage(
      image,
      0,
      0,
      imageWidth,
      imageHeight,
      deltaImage.x,
      deltaImage.y,
      imageWidth * scale,
      imageHeight * scale
    );
    //
    return ctx.getImageData(0, 0, width, height);
  }

  async getOldAndNewImageData() {
    const bbox = this.getCombinedBoundingBox(this.imageNode, this.imageOldNode);
    const width = bbox.width;
    const height = bbox.height;
    const deltaNew = bbox.deltaImage1;
    const deltaOld = bbox.deltaImage2;
    const imageUrlOld = this.imageOldNode.image().src;
    const imageUrlNew = this.imageNode.image().src;
    const scaleNew = 1;
    const scaleOld = this.imageOldNode.scaleX();
    const imageNewWidth = this.imageNode.width();
    const imageNewHeight = this.imageNode.height();
    const imageOldWidth = this.imageOldNode.width();
    const imageOldHeight = this.imageOldNode.height();
    const imageDataNew = await this.getImageData({
      width,
      height,
      imageUrl: imageUrlNew,
      deltaImage: deltaNew,
      imageWidth: imageNewWidth,
      imageHeight: imageNewHeight,
      scale: scaleNew,
    });
    const imageDataOld = await this.getImageData({
      width,
      height,
      imageUrl: imageUrlOld,
      deltaImage: deltaOld,
      imageWidth: imageOldWidth,
      imageHeight: imageOldHeight,
      scale: scaleOld,
    });
    return {imageDataNew, imageDataOld};
  }

  async getImageDataFromZone(zone) {
    const canvas = document.createElement("canvas");

    const image = await createImageAsync(zone.imageUrl);

    const width = zone.imageSize.width;
    const height = zone.imageSize.height;

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0);

    return ctx.getImageData(0, 0, width, height);
  }

  async getZoneImageData(zone) {
    const canvas = document.createElement("canvas");

    const image = await createImageAsync(zone.imageUrl);

    const width = zone.imageSize.width;
    const height = zone.imageSize.height;

    const widthMeter = zone.width * zone.scale;
    const pxByMeter = zone.imageSize.width / widthMeter;

    let offsetX =
      (zone.position.x - this.positionRef.x) * pxByMeter -
      (zone.imageSize.width - this.imageSizeRef.width) / 2;
    let offsetY =
      (zone.position.z - this.positionRef.z) * pxByMeter -
      (zone.imageSize.height - this.imageSizeRef.height) / 2;

    //canvas.width = width + offsetX;
    //canvas.height = height + offsetY;
    canvas.width = this.canvasSize.width;
    canvas.height = this.canvasSize.height;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, offsetX, offsetY);

    return ctx.getImageData(
      0,
      0,
      this.canvasSize.width,
      this.canvasSize.height
    );
  }

  async getZoneUrl(zone) {
    const canvas = document.createElement("canvas");

    const image = await createImageAsync(zone.imageUrl);

    const width = zone.imageSize.width;
    const height = zone.imageSize.height;

    const widthMeter = zone.width * zone.scale;
    const pxByMeter = zone.imageSize.width / widthMeter;

    let offsetX = (zone.position.x - this.positionRef.x) * pxByMeter;
    let offsetY = (zone.position.z - this.positionRef.z) * pxByMeter;

    //canvas.width = width + offsetX;
    //canvas.height = height + offsetY;
    canvas.width = this.canvasSize.width;
    canvas.height = this.canvasSize.height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, offsetX, offsetY);

    return canvas.toDataURL();
  }

  addZone(zone, options) {
    const positionRef = options?.positionRef;

    const positionZone = zone.position;

    let offsetX = 0;
    let offsetY = 0;

    const widthMeter = zone.width * zone.scale;
    const pxByMeter = zone.imageSize.width / widthMeter;

    if (positionRef) {
      offsetX =
        (positionZone.x - positionRef.x) * pxByMeter -
        (zone.imageSize.width - this.imageSizeRef.width) / 2;
      offsetY =
        (positionZone.z - positionRef.z) * pxByMeter -
        (zone.imageSize.height - this.imageSizeRef.height) / 2;
      console.log("add zone with offset", offsetX, offsetY, zone);
    }

    const imageObj = new Image();
    imageObj.onload = () => {
      const image = new Konva.Image({
        image: imageObj,
        x: offsetX,
        y: offsetY,
        //scaleX: 1 / this.imageScale,
        //scaleY: 1 / this.imageScale,
        //width: this.width,
        //height: this.height,
      });
      if (!this.firstZoneNode) this.firstZoneNode = image;
      this.layerZones.add(image);
      this.layerZones.draw();
    };
    imageObj.crossOrigin = "Anonymous";
    imageObj.src = zone.imageUrl;
  }

  addZones(zones) {
    this.canvasSize = this.getCanvasSizeFromZones(zones);
    if (!zones?.length > 0) return;
    const zoneRef = zones[0];
    const positionRef = zoneRef.position;
    this.positionRef = positionRef;
    this.imageSizeRef = zoneRef.imageSize;
    //
    this.destroyZoneLayerContent();
    if (this.imageNode) this.imageNode.destroy();
    //
    zones.forEach((zone) => this.addZone(zone, {positionRef}));
  }

  /*
   * image diff
   */

  getCombinedBoundingBox(imageNode1, imageNode2) {
    // Get absolute positions of each image node relative to the stage
    // const image1AbsPos = imageNode1.getAbsolutePosition();
    // const image2AbsPos = imageNode2.getAbsolutePosition();

    const image1AbsPos = {x: imageNode1.x(), y: imageNode1.y()};
    const image2AbsPos = {x: imageNode2.x(), y: imageNode2.y()};

    const stageAbsPos = this.stage.getAbsolutePosition();

    const scale1 = imageNode1.getScale().x;
    const scale2 = imageNode2.getScale().x;
    console.log("scales", scale1, scale2);

    // Get scaled dimensions of each image node
    const image1Width = imageNode1.getWidth() * scale1;
    const image1Height = imageNode1.getHeight() * scale1;
    const image2Width = imageNode2.getWidth() * scale2;
    const image2Height = imageNode2.getHeight() * scale2;

    // Calculate scaled minimum x and minimum y for the bounding box
    const minX = Math.min(image1AbsPos.x * scale1, image2AbsPos.x * scale2);
    const minY = Math.min(image1AbsPos.y * scale1, image2AbsPos.y * scale2);

    // Calculate scaled maximum x and maximum y for the bounding box
    const maxX = Math.max(
      (image1AbsPos.x + image1Width) * scale1,
      (image2AbsPos.x + image2Width) * scale2
    );
    const maxY = Math.max(
      (image1AbsPos.y + image1Height) * scale1,
      (image2AbsPos.y + image2Height) * scale2
    );

    // Calculate width and height of the bounding box
    const boundingBoxWidth = maxX - minX;
    const boundingBoxHeight = maxY - minY;

    // Return the top-left coordinates of the bounding box
    const bbox = {
      x: minX,
      y: minY,
      width: boundingBoxWidth,
      height: boundingBoxHeight,
      deltaImage1: {x: image1AbsPos.x - minX, y: image1AbsPos.y - minY},
      deltaImage2: {x: image2AbsPos.x - minX, y: image2AbsPos.y - minY},
      stageAbsPos: {x: stageAbsPos.x, y: stageAbsPos.y},
    };
    console.log("bbox", bbox);
    this.bbox = bbox;
    return bbox;
  }

  clearLayerDifferences() {
    const children = this.layerDifferences.getChildren();
    for (let i = 0; i < children.length; i++) {
      children[i].destroy();
    }
  }
  async setImageDiff(imageDiff) {
    this.imageDiffNode = await this.addImageNode({
      url: imageDiff.url,
      offsetX: this.bbox.x,
      offsetY: this.bbox.y,
      layer: this.layerDifferences,
    });
  }

  showImageDiff() {
    if (this.imageDiffNode) this.imageDiffNode.show();
  }
  hideImageDiff() {
    if (this.imageDiffNode) this.imageDiffNode.hide();
  }
  setImageDiffOpacity(opacity) {
    if (this.imageDiffNode) this.imageDiffNode.opacity(opacity / 100);
  }

  async setImageOld(imageOld) {
    this.destroyZoneLayerContent();
    console.log("[setImageOld]", imageOld);
    this.imageOldNode = await this.addImageNode({
      url: imageOld.url,
      offsetX: imageOld.offsetX,
      offsetY: imageOld.offsetY,
      layer: this.layerZones,
      scale: imageOld.scale,
    });
  }

  showImageOld() {
    if (this.imageOldNode) this.imageOldNode.show();
  }
  hideImageOld() {
    if (this.imageOldNode) this.imageOldNode.hide();
  }
  setImageOldOpacity(opacity) {
    if (this.imageOldNode) this.imageOldNode.opacity(opacity / 100);
  }

  /*
   * stage
   */

  setStageScale(scale) {
    this.stage.scale({x: scale, y: scale});
    this.prevStageScale = scale;
  }

  _downloadImage(img) {
    const link = document.createElement("a");
    link.href = img.src;
    link.download = "image.png";
    link.click();
  }
  downloadStageImage() {
    const visibleRect = this.stage.getClientRect({skipTransform: false});
    const _visibleRect = this.stage.getClientRect({skipTransform: true});

    const offsetX = -visibleRect.left;
    const offsetY = -visibleRect.top;
    const width = visibleRect.width / this.stage.scaleX;
    const height = visibleRect.height / this.stage.scaleY;

    const _offsetX = -_visibleRect.left;
    const _offsetY = -_visibleRect.top;
    const _width = _visibleRect.width;
    const _height = _visibleRect.height;

    this.stage.toImage({
      x: _offsetX,
      y: _offsetY,
      width: _width,
      height: _height,
      pixelRatio: 2,
      imageSmoothingEnabled: false,
      quality: 1,
      callback: this._downloadImage,
    });
  }

  downloadBlueprint() {
    // add background
    this.updateBlueprintNode();

    // get image
    const {x, y, width, height} = this.blueprintBboxInContainer;
    this.stage.toImage({
      x,
      y,
      width,
      height,
      callback: this._downloadImage,
    });

    // reset
    this.resetBlueprintNode();
  }

  updateStageSize() {
    const container = this.stage.container();
    const width = container.getBoundingClientRect().width;
    const height = container.getBoundingClientRect().height;
    console.log("dims", width, height);
    this.stage.width(width);
    this.stage.height(height);
  }

  // update stage position & scale when container changes
  // rule : keep center of image at the center of the blueprint
  updateStageOnContainerResize() {
    try {
      //
      this.updateBlueprintBboxes();
      //
      const containerBbox = this.containerElement.getBoundingClientRect();
      //
      const deltaScale = containerBbox.width / this.prevContainerBbox.width;

      // delta X and Y : based on center of blueprint.

      const prevWidth = this.prevContainerBbox.width;
      const prevHeight = this.prevContainerBbox.height;
      const prevCenterPointer = {x: prevWidth / 2, y: prevHeight / 2};
      const prevCenterPoint = getPointerCoordsInStage(
        prevCenterPointer,
        this.stage
      );

      //
      const newScale = this.prevStageScale * deltaScale;

      // change stage scale

      this.setStageScale(newScale);

      // compute new center

      const newWidth = containerBbox.width;
      const newHeight = containerBbox.height;
      const centerPointer = {x: newWidth / 2, y: newHeight / 2};
      const centerPoint = getPointerCoordsInStage(centerPointer, this.stage);

      // delta X - Y
      const deltaX = centerPoint.x - prevCenterPoint.x;
      const deltaY = centerPoint.y - prevCenterPoint.y;

      const newPositionX = this.prevImageNodePosition.x + deltaX;
      const newPositionY = this.prevImageNodePosition.y + deltaY;

      // change image position
      this.setImageNodePosition({x: newPositionX, y: newPositionY});
      //
      this.prevContainerBbox = containerBbox;
    } catch (e) {
      console.log("error", e);
    }
  }

  resetStageScale() {
    this.stage.scaleX(1 / this.imageScale);
    this.stage.scaleY(1 / this.imageScale);
    this.stage.position({x: 0, y: 0});
  }

  resizeNodes() {
    this.markersManager.resizeNodes();
  }

  addListeners() {
    this.stage.on("wheel", (e) => {
      e.evt.preventDefault();
      var oldScale = this.stage.scaleX();

      var pointer = this.stage.getPointerPosition();

      var mousePointTo = {
        x: (pointer.x - this.stage.x()) / oldScale,
        y: (pointer.y - this.stage.y()) / oldScale,
      };

      var newScale =
        e.evt.deltaY < 0 ? oldScale * this.scaleBy : oldScale / this.scaleBy;
      this.scale = newScale;

      this.setStageScale(newScale);

      var newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      this.stage.position(newPos);
      this.stage.batchDraw();

      this.resizeNodes();
    });
  }

  /*
   * caplaEditor
   */

  resetEditor() {
    this.setFirstImageWasAdded(false);
    this.url = null;
  }

  enableCrosshair() {
    this.stage.container().style.cursor = "crosshair";
  }
  disableCrosshair() {
    this.stage.container().style.cursor = "pointer";
  }

  /*
   * recognition
   */

  addPatternRect() {
    const width = 100;
    const height = 100;
    const centerX = this.stage.width() / 2;
    const centerY = this.stage.height() / 2;

    // Create the rectangle with initial dimensions and position
    const rect = new Konva.Rect({
      x: centerX - width / 2,
      y: centerY - height / 2,
      width: width,
      height: height,
      fill: "gray", // Set a fill color for the rectangle
      stroke: "black", // Set a stroke color for the rectangle outline
      strokeWidth: 2, // Set the stroke width for the rectangle outline
      opacity: 0.5,
      draggable: true,
    });

    // Create a transformer instance
    const transformer = new Konva.Transformer({
      node: rect,
      // Enable all transform handles (rotates by default)
      enabledAnchors: [
        "top-left",
        "top-right",
        "bottom-left",
        "bottom-right",
        "top-center",
        "middle-right",
        "bottom-center",
        "middle-left",
      ],
      // Disable rotation for a rectangle (optional)
      rotateEnabled: false, // Adjust this line to enable rotation if desired
    });

    // Add the rectangle and transformer to the stage
    this.layerPattern.add(rect);
    this.layerPattern.add(transformer);

    // Draw the stage to display the rectangle and transformer
    this.layerPattern.draw();
    this.patternRectNode = rect;
  }

  getPatternRectImageData = () => {
    // Get the stage canvas context
    const rectNode = this.patternRectNode;
    if (!rectNode) {
      console.log("no rect node");
      return;
    }

    // Get rectangle position and size relative to the stage
    //const {x, y, width, height} = rectNode.getClientRect();
    const x = rectNode.attrs.x;
    const y = rectNode.attrs.y;
    const scaleX = rectNode.attrs.scaleX;
    const scaleY = rectNode.attrs.scaleY;
    const height = rectNode.attrs.height * scaleY;
    const width = rectNode.attrs.width * scaleX;

    // Create a temporary canvas to capture the image data
    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = width;
    tempCanvas.height = height;
    const tempCtx = tempCanvas.getContext("2d");

    // Copy the relevant portion of the stage canvas onto the temporary canvas
    tempCtx.drawImage(
      this.imageNode.image(),
      x - this.imageNode.x(),
      y - this.imageNode.y(),
      width,
      height,
      0,
      0,
      width,
      height
    );

    // Get the image data from the temporary canvas
    const imageData = tempCtx.getImageData(0, 0, width, height);

    // Release the temporary canvas resources
    tempCanvas.remove(); // Or use tempCanvas.width = 0; tempCanvas.height = 0; for potential reuse

    return imageData;
  };

  getZoneImageImageData() {
    const imageNode = this.imageNode;
    // Check if the node is an image node
    if (!imageNode.nodeType === "Image") {
      throw new Error("getNodeImageData: node is not an Image node");
    }

    const canvas = imageNode.getCanvas(); // Get the canvas associated with the image node

    // Get context and image data from the canvas
    const context = canvas.getContext("2d");
    const image = imageNode.getImage();
    const imageData = context.createImageData(image.width, image.height);

    // Draw the image onto the context to populate the image data
    context.drawImage(image, 0, 0);

    return imageData;
  }

  downloadPattern() {
    const patternData = this.getPatternRectImageData();

    const canvas1 = document.createElement("canvas");
    canvas1.width = patternData.width;
    canvas1.height = patternData.height;
    const ctx1 = canvas1.getContext("2d");
    ctx1.putImageData(patternData, 0, 0);
    const dataURL1 = canvas1.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = dataURL1;
    link.download = "pattern.png";
    link.click();
  }

  async setImageRecognition({url, width, height}) {
    this.imageRecognitionNode = await this.addImageNode({
      url: url,
      offsetX: this.imageNode.x(),
      offsetY: this.imageNode.y(),
      layer: this.layerPattern,
      width,
      height,
      scale: 1,
    });
  }

  // MARKERS

  addMarkers(markers) {
    const markersProps = markers.map((marker) =>
      markerToMarkerNodeProps({marker, imageNode: this.imageNode})
    );
    this.markersManager.addMarkers(markersProps, {init: true});
  }
}
