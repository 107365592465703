import {useDispatch} from "react-redux";

import useRooms from "../hooks/useRooms";

import {
  setEditedRoomsGroup,
  setIsEditingRoomsGroup,
  setNewRoomsGroup,
} from "../roomsSlice";

import {Box} from "@mui/material";

import FormRoomsGroup from "./FormRoomsGroup";
import useRoomsGroupInSelectionPanel from "../hooks/useRoomsGroupInSelectionPanel";

export default function SectionRoomsGroupInSelectionPanel({scene}) {
  const dispatch = useDispatch();

  // data

  const roomsGroup = useRoomsGroupInSelectionPanel(scene);

  const rooms = useRooms({filterByRoomsGroupId: roomsGroup?.id});

  // helper

  const isNew = !roomsGroup?.id;

  // handlers

  function handleNewRoomsGroupChange(roomsGroup) {
    dispatch(setNewRoomsGroup(roomsGroup));
  }
  function handleEditedRoomsGroupChange(roomsGroup) {
    console.log("Edited rooms group", roomsGroup);
    dispatch(setIsEditingRoomsGroup(true));
    dispatch(setEditedRoomsGroup(roomsGroup));
  }

  function handleRoomsGroupChange(roomsGroup) {
    if (isNew) {
      handleNewRoomsGroupChange(roomsGroup);
    } else {
      handleEditedRoomsGroupChange(roomsGroup);
    }
  }
  return (
    <Box sx={{pt: 4}}>
      <Box sx={{borderTop: (theme) => `1px solid ${theme.palette.divider}`}}>
        <FormRoomsGroup
          rooms={rooms}
          roomsGroup={roomsGroup}
          onChange={handleRoomsGroupChange}
        />
      </Box>
    </Box>
  );
}
