import {useState, useEffect} from "react";
import {useSelector} from "react-redux";

import {setExtractedText} from "Features/pdf/pdfSlice";
import DialogCreateRoom from "./DialogCreateRoom";
import getBboxFromRects from "Features/pdf/utils/getBboxFromRects";

export default function LayerDialogCreateRoomInPdfViewer() {
  // state

  const [initName, setInitName] = useState(null);
  const open = initName?.length > 0;

  // data

  const extractedText = useSelector((s) => s.pdf.extractedText);
  console.log("extractedText", extractedText);

  const text = extractedText?.text;
  const extractedAt = extractedText?.extractedAt;
  const rects = extractedText?.rects;

  //

  const roomLabel = {
    pdfModelId: extractedText?.pdfModelId,
    label: text,
    p: 1,
    bbox: getBboxFromRects(rects),
  };

  // effect

  useEffect(() => {
    if (text) {
      setInitName(text);
    }
  }, [extractedAt]);

  // handlers

  function handleClose() {
    setInitName(null);
  }

  return (
    <>
      {open && (
        <DialogCreateRoom
          open={open}
          onClose={handleClose}
          initName={initName}
          roomLabel={roomLabel}
        />
      )}
    </>
  );
}
