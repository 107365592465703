import {useSelector} from "react-redux";

import useSelectedRoom from "./useSelectedRoom";

export default function useRoomInSelectionPanel() {
  //
  const selectedRoom = useSelectedRoom({withParentId: true, withNum: true});
  const editedRoom = useSelector((s) => s.rooms.editedRoom);
  const newRoom = useSelector((s) => s.rooms.tempRoom);
  const isEditingRoom = useSelector((s) => s.rooms.isEditingRoom);

  // helper - room

  let room = selectedRoom ?? newRoom;
  if (isEditingRoom) room = editedRoom;

  // return

  return room;
}
