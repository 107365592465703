import {useDispatch} from "react-redux";
import {fetchSurveys} from "../surveysSlice";
import {useEffect} from "react";

import useAccessToken from "Features/auth/useAccessToken";

export default function useInitFetchSurveys(scene) {
  const sceneId = scene?.id;
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (sceneId && accessToken) {
      dispatch(fetchSurveys({accessToken, sceneId}));
    }
  }, [sceneId, accessToken]);
}
