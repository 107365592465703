import React, {useState, useEffect} from "react";

import {Grid, Typography, InputBase, Box} from "@mui/material";
export default function FieldText({
  name,
  value,
  onChange,
  multiline,
  locked,
  sizeKey = 12,
  horizontal = false,
  horizontalHeader = true,
  width = 100,
  placeholder,
}) {
  // state

  const initTempValue = value ? value : "";
  const [tempValue, setTempValue] = useState(initTempValue);
  useEffect(() => setTempValue(initTempValue), [initTempValue]);

  // handlers

  function handleInputChange(e) {
    let value = e.target.value;
    //value = value.replace(",", ".");
    if (!locked) setTempValue(value);
  }

  function handleBlur() {
    if (!locked) onChange(tempValue);
  }

  return (
    <Grid
      container
      sx={{
        pr: 1,
        width: horizontal ? `${width}px` : 1,
        minWidth: horizontal ? `${width}px` : 1,
        borderBottom: horizontal
          ? "unset"
          : (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      {(!horizontal || horizontalHeader) && (
        <Grid xs={horizontalHeader ? 12 : sizeKey} item>
          <Box
            sx={{
              display: "flex",
              height: 1,
              alignItems: "center",
              bgcolor: "background.default",
              pl: 1,
            }}
          >
            <Typography sx={{fontSize: 13, color: "text.secondary"}} noWrap>
              {name}
            </Typography>
          </Box>
        </Grid>
      )}
      {!horizontalHeader && (
        <Grid
          xs={horizontal ? 12 : 12 - sizeKey}
          item
          sx={{
            borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
            px: 1,
          }}
        >
          <InputBase
            value={tempValue}
            multiline={multiline}
            onChange={handleInputChange}
            onBlur={handleBlur}
            placeholder={placeholder}
            sx={{
              width: 1,
              fontSize: 13,
              whiteSpace: "pre-line",
              py: 0,
              "& .MuiInputBase-input": {p: 0},
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}
