import {Paper, Box} from "@mui/material";

import KeyValueForm from "./KeyValueForm";
import FieldFormDataHeader from "./FieldFormDataHeader";

function valueToFormItem(value) {
  if (!value) return {};
  return value.reduce((acc, field) => {
    acc[field.key] = field.value;
    return acc;
  }, {});
}

export default function FieldFormData({item, name, value, onChange}) {
  // helper

  const fields = value ?? [];

  const kvt = {
    key: "report",
    fields,
  };

  // handler

  function handleItemChange(newItem) {
    const newValue = value.map((field) => {
      const newField = {...field};
      newField.value = newItem[field.key];
      return newField;
    });
    onChange(newValue);
  }

  function handleValueChange(newValue) {
    onChange(newValue);
  }

  return (
    <Box
      sx={{
        width: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <FieldFormDataHeader
        name={name}
        value={value}
        onChange={handleValueChange}
      />
      <Box sx={{width: 1}}>
        <KeyValueForm
          sizeKey={5}
          template={kvt}
          item={valueToFormItem(value)}
          onChange={handleItemChange}
        />
      </Box>
    </Box>
  );
}
