import addMaterialToMaterialsData from "./addMaterialToMaterialsData";

export default function addMaterialsDataToMaterialsData(
  materialsDataToAdd,
  materialsData
) {
  let update = materialsData;
  materialsDataToAdd.forEach((item) => {
    const {materialsClientIds, materialsGroupId} = item;
    materialsClientIds.forEach((materialClientId) => {
      update = addMaterialToMaterialsData(
        materialClientId,
        materialsGroupId,
        update
      );
    });
  });
  return update;
}
