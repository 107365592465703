import {useSelector} from "react-redux";

import {IconButton, Box, Typography} from "@mui/material";
import {Add} from "@mui/icons-material";

import useTakeEditorScreenshot from "Features/viewer3D/useTakeEditorScreenshot";
import usePageType from "Features/navigation/usePageType";

export default function HeaderInShortcutIssues({scene, caplaEditor, viewer}) {
  // strings

  const issuesS = "Notes";

  // data

  const pageType = usePageType();
  const selectedIssuesListingId = useSelector(
    (s) => s.issues.selectedIssuesListingId
  );

  const takeScreenshot = useTakeEditorScreenshot({
    caplaEditor,
    scene,
    target: {listingType: "issueset", listingId: selectedIssuesListingId},
  });

  // helper - edit

  const userCanEdit = pageType !== "COLORING" && !viewer;

  // handlers

  function handleClick() {
    takeScreenshot();
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        pl: 1,
      }}
    >
      <Box sx={{display: "flex", alignItems: "center", mt: 2}}>
        <Typography variant="h6" sx={{fontWeight: "bold"}}>
          {issuesS}
        </Typography>
        {userCanEdit && (
          <Box
            sx={{
              ml: 2,
              height: 24,
              width: 24,
              borderRadius: 12,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "primary.flash",
              color: "common.white",
            }}
          >
            <IconButton size="small" onClick={handleClick} color="inherit">
              <Add fontSize="small" color="inherit" />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
}
