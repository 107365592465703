export default function useKvtReportTemplate(data) {
  //
  const formTemplates = data?.formTemplates || [];

  const kvt = {
    key: "reportTemplate",
    fields: [
      {
        key: "name",
        name: "Nom du modèle",
        type: "text",
      },
      // {
      //   key: "wordUrl",
      //   name: "Modèle Word",
      //   type: "text",
      // },
      {
        key: "mainFormTemplate",
        name: "Formulaire principal",
        type: "combo",
        options: formTemplates,
      },
    ],
  };

  return kvt;
}
