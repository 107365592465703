import {useDispatch, useSelector} from "react-redux";

import {updateReport} from "Features/reports/reportsSlice";

import useSelectedBlock from "./useSelectedBlock";
import useSelectedReport from "Features/reports/hooks/useSelectedReport";

import {
  Add,
  ContentCopy as Duplicate,
  Delete,
  AddPhotoAlternate as AddViewsIcon,
  Task as ValidateIcon,
} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";

export default function useActionsBlocksMap({scene, caplaEditor}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // data

  const selectedBlockId = useSelector((s) => s.blocks.selectedBlockId);
  const selectedBlock = useSelectedBlock();
  const blocksMap = useSelector((s) => s.blocks.blocksMap);
  const selectedReport = useSelectedReport();

  // handlers

  async function handleDebug() {
    console.log("[debug] result");
  }

  async function handleCleanReport() {
    console.log("report", selectedReport);
    let sortedBlocksIds = selectedReport.sortedBlocksIds ?? [];
    sortedBlocksIds = sortedBlocksIds.filter((id) => blocksMap[id]);
    if (sortedBlocksIds.length > 0) {
      const updates = {
        id: selectedReport?.id,
        scopeId: selectedReport?.scopeId,
        sortedBlocksIds,
      };
      await dispatch(updateReport({accessToken, updates}));
    }
  }
  // actions map

  const actionsMap = {
    validateReport: {
      icon: <ValidateIcon fontSize="small" />,
      name: "Valider",
      label: "Valider le rapport",
    },
    createBlock: {
      icon: <Add fontSize="small" />,
      name: "Bloc",
      label: "Ajouter un bloc",
    },
    createViewBlocks: {
      icon: <AddViewsIcon fontSize="small" />,
      name: "Plans",
      label: "Ajouter des plans",
    },

    deleteBlock: {
      icon: <Delete fontSize="small" />,
      name: "Supprimer",
      label: "Supprimer la section",
      disabled: !selectedBlockId,
    },
    debug: {
      name: "Debug",
      label: "Debug",
      handler: handleDebug,
    },
    cleanReport: {
      label: "Nettoyer le rapport",
      handler: handleCleanReport,
    },
  };

  return actionsMap;
}
