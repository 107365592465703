import {useLocation, useParams} from "react-router-dom";

import usePageType from "./usePageType";

export default function useSceneModule() {
  const {pathname} = useLocation();
  const {scenarioId, modelId, viewId, reportId} = useParams();
  const isEditMode = modelId ? true : false;
  const pageType = usePageType();

  if (pageType !== "SCENE") return;

  let sceneModule = "HOME"; // for shared scene, sceneModule === SHARED
  if (pathname.includes("shared/bimbox")) {
    sceneModule = "SHARED";
  } else if (pathname.includes("build")) {
    sceneModule = "BUILD";
  } else if (pathname.includes("modules")) {
    sceneModule = "MODULES";
  } else if (pathname.includes("admin")) {
    sceneModule = "ADMIN";
  } else if (pathname.includes("inputs")) {
    sceneModule = "INPUTS";
  } else if (pathname.includes("datasets")) {
    sceneModule = "DATASETS";
  } else if (pathname.includes("data")) {
    sceneModule = "DATA";
  } else if (pathname.includes("nomenclature")) {
    sceneModule = "NOMENCLATURE";
  } else if (pathname.includes("zones")) {
    sceneModule = "ZONES";
  } else if (pathname.includes("sectors")) {
    sceneModule = "SECTORS";
  } else if (pathname.includes("rooms")) {
    sceneModule = "ROOMS";
  } else if (pathname.includes("phases")) {
    sceneModule = "PHASES";
  } else if (pathname.includes("versions")) {
    sceneModule = "VERSIONS";
  } else if (pathname.includes("markers")) {
    sceneModule = "MARKERS";
  } else if (
    (pathname.includes("measurements") && isEditMode) ||
    pageType === "COLORING"
  ) {
    sceneModule = "EDIT_MEASUREMENTS";
  } else if (pathname.includes("measurements")) {
    sceneModule = "MEASUREMENTS";
  } else if (pathname.includes("elementTypes")) {
    sceneModule = "ELEMENT_TYPES";
  } else if (pathname.includes("ressources")) {
    sceneModule = "RESSOURCES";
  } else if (pathname.includes("relations")) {
    sceneModule = "RELATIONS";
  } else if (pathname.includes("annotatedPdf")) {
    sceneModule = "ANNOTATED_PDF";
  } else if (pathname.includes("packages")) {
    sceneModule = "PACKAGES";
  } else if (pathname.includes("quantities")) {
    sceneModule = "QUANTITIES";
  } else if (pathname.includes("notes")) {
    sceneModule = "NOTES";
  } else if (pathname.includes("issuesets")) {
    sceneModule = "ISSUESETS";
  } else if (pathname.includes("issues")) {
    sceneModule = "ISSUES";
  } else if (pathname.includes("viewerPdf")) {
    sceneModule = "VIEWER_PDF";
  } else if (pathname.includes("viewer3D")) {
    sceneModule = "VIEWER_3D";
  } else if (pathname.includes("ifc")) {
    sceneModule = "IFC";
  } else if (pathname.includes("progress")) {
    sceneModule = "PROGRESS";
  } else if (pathname.includes("sharedboxes")) {
    sceneModule = "SHAREDBOXES";
  } else if (pathname.includes("library")) {
    sceneModule = "LIBRARY";
  } else if (pathname.includes("materials")) {
    sceneModule = "MATERIALS";
  } else if (pathname.includes("pricings")) {
    sceneModule = "PRICINGS";
  } else if (pathname.includes("onboarding")) {
    sceneModule = "ONBOARDING";
  } else if (pathname.includes("views") && viewId) {
    sceneModule = "VIEW";
  } else if (pathname.includes("views")) {
    sceneModule = "VIEWS";
  } else if (pathname.includes("reports")) {
    if (reportId) {
      sceneModule = "REPORT";
    } else {
      sceneModule = "REPORTS";
    }
  } else if (pathname.includes("surveys")) {
    sceneModule = "SURVEYS";
  } else if (pathname.includes("samples")) {
    sceneModule = "SAMPLES";
  } else if (pathname.includes("scopes")) {
    sceneModule = "SCOPES";
  } else if (pathname.includes("articles")) {
    sceneModule = "ARTICLES";
  }
  return sceneModule;
}
