import {useSelector} from "react-redux";

import useRoomLabels from "./useRoomLabels";

import useSelectedRoomsGroup from "./useSelectedRoomsGroup";

import getRoomsWithNum from "../utils/getRoomsWithNum";
import getRoomsWithParentIdFromNum from "../utils/getRoomsWithParentIdFromNum";
import getFoundRooms from "../utils/getFoundRooms";
import getRoomsWithLabels from "../utils/getRoomsWithLabels";

export default function useRooms(options) {
  //
  const roomsMap = useSelector((s) => s.rooms.roomsMap);
  const roomsGroup = useSelectedRoomsGroup();
  const searchText = useSelector((s) => s.rooms.searchTextRooms);
  const roomLabels = useRoomLabels();

  //

  let rooms = roomsMap ? Object.values(roomsMap) : [];

  //
  if (options?.filterByRoomsGroupId) {
    rooms = rooms.filter(
      (r) => r.roomsGroupId === options.filterByRoomsGroupId
    );
  }

  if (options?.withRoomLabels) {
    rooms = getRoomsWithLabels(rooms, roomLabels);
  }
  //
  if (options?.withNum || options?.withParentId) {
    rooms = getRoomsWithNum(roomsGroup, rooms);
  }

  //
  if (options?.withParentId) {
    rooms = getRoomsWithParentIdFromNum(rooms);
  }
  //
  if (options?.filterByClientIds) {
    const roomsByClientIds = rooms.reduce((acc, r) => {
      acc[r.clientId] = r;
      return acc;
    }, {});
    rooms = options.filterByClientIds.map((id) => roomsByClientIds[id]);
  }

  // SEARCH
  if (options?.applySearch) {
    const searchOptions = {
      searchText,
      searchKeys: ["name"],
      withParents: true,
    };
    rooms = getFoundRooms(rooms, searchOptions);
  }

  // VARIANT
  if (options?.variantByClientId) {
    rooms = rooms.reduce((acc, room) => {
      acc[room.clientId] = room;
      return acc;
    }, {});
  }

  return rooms;
}
