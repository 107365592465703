import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {Draggable} from "react-beautiful-dnd";

import {List, ListItem, Box, Typography, TextField} from "@mui/material";
import {DragIndicator as Handle} from "@mui/icons-material";

export default function ListItemsDraggable({
  items,
  onOrderChange,
  draggable,
  id,
  newItemName,
  onNewItemNameChange,
}) {
  items = items.filter((item) => item && item[id]);

  // helpers

  const sortedIds = items.map((i) => i[id]);

  const hasNew = sortedIds.includes("new");

  // handlers

  function handleDragEnd(result) {
    const {destination, source, draggableId} = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const newIds = [...sortedIds];
    newIds.splice(source.index, 1);
    newIds.splice(destination.index, 0, draggableId);

    onOrderChange(newIds);
  }

  function handleNewItemNameChange(e) {
    onNewItemNameChange(e.target.value);
  }

  return (
    <Box sx={{width: 1, bgcolor: "background.default"}}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="1">
          {(provided, _) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <List dense disablePadding>
                {items.map((item, index) => {
                  const disableDrag = !draggable || (hasNew && !item.isNew);
                  return (
                    <Draggable
                      key={item[id]}
                      draggableId={item[id]}
                      index={index}
                      isDragDisabled={disableDrag}
                    >
                      {(provided, _) => (
                        <Box
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          sx={{
                            width: 1,
                            borderBottom: (theme) =>
                              `1px solid ${theme.palette.divider}`,
                          }}
                        >
                          <ListItem key={item.id} sx={{p: 0, m: 0, width: 1}}>
                            <Box
                              sx={{
                                bgcolor: "common.white",
                                border: "4px",
                                px: 1,
                                py: 0.5,
                                display: "flex",
                                alignItems: "center",
                                width: 1,
                              }}
                            >
                              {!disableDrag && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    ml: -1,
                                  }}
                                >
                                  <Handle fontSize="small" color="action" />
                                </Box>
                              )}
                              {item.isNew && (
                                <TextField
                                  size="small"
                                  value={newItemName}
                                  onChange={handleNewItemNameChange}
                                />
                              )}
                              {!item.isNew && (
                                <Typography variant="body2">
                                  {item.name}
                                </Typography>
                              )}
                            </Box>
                          </ListItem>
                        </Box>
                      )}
                    </Draggable>
                  );
                })}
              </List>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}
