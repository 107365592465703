import getParentsNums from "Utils/getParentsNums";

export default function getRoomsWithParentsFromNum(roomsWithNum) {
  const roomsByNum = roomsWithNum.reduce((acc, room) => {
    acc[room.num] = room;
    return acc;
  }, {});

  const roomsWithParents = roomsWithNum.map((room) => {
    const parentsNums = getParentsNums(room.num);
    let parents = parentsNums.map((num) => roomsByNum[num]);

    return {
      ...room,
      parents,
    };
  });

  return roomsWithParents;
}
