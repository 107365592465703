import getItemRankFromNum from "Utils/getItemRankFromNum";
import sortByNum from "Utils/sortByNum";

export default function getExcelHeaderFromNomenclatureTree(nomenclatureTree) {
  if (!nomenclatureTree) return [];

  const rankNames = nomenclatureTree.rankNames;
  const fieldNames = nomenclatureTree.fieldNames ?? [];
  const fieldKeys = nomenclatureTree.fields ?? [];

  const row1 = []; // ["cat1","","cat2"]
  const row2 = []; // ["name","code","name"]
  const row3 = []; // ["Catégorie 1", "Ouvrage ou composants", "Matériaux ou Produits"], name of the category or one field.

  const sortedItems = [...(nomenclatureTree?.items ?? [])].sort((a, b) =>
    sortByNum(a.num, b.num)
  );

  const rankProcessedMap = {};
  sortedItems.forEach((item) => {
    const rank = getItemRankFromNum(item);
    if (!rankProcessedMap[rank]) {
      rankProcessedMap[rank] = true;
      const fields = Object.keys(item).filter(
        (key) => key !== "num" && key !== "id" && key !== "name"
      );
      let sortedFields = fields.sort((a, b) => a.localeCompare(b));
      if (item.name) sortedFields = ["name", ...sortedFields];
      sortedFields.forEach((field, index) => {
        // row1
        row1.push(`Catégorie ${rank}`);

        // row2
        row2.push(field);

        // row3
        const fieldIndex = fieldKeys[rank - 1].indexOf(field);
        const fieldName =
          (fieldNames[rank - 1] && fieldNames[rank - 1][fieldIndex]) ?? "-";
        row3.push(fieldName);
      });
    }
  });

  return [row1, row2, row3];
}
