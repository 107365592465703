import {useSelector, useDispatch} from "react-redux";

import {
  setNewFormTemplate,
  setEditedFormTemplate,
  setIsEditingFormTemplate,
} from "../formsSlice";

import useSelectedFormTemplate from "../hooks/useSelectedFormTemplate";
import useFormTemplateInSelectionPanel from "../hooks/useFormTemplateInSelectionPanel";

import {Box} from "@mui/material";

import FormFormTemplate from "./FormFormTemplate";

export default function SectionFormTemplateInSelectionPanel() {
  const dispatch = useDispatch();
  // data

  const formTemplate = useFormTemplateInSelectionPanel();

  // helpers

  const isNew = !formTemplate?.id;

  // handlers

  function handleChange(changedFormTemplate) {
    if (isNew) {
      dispatch(setNewFormTemplate(changedFormTemplate));
    } else {
      dispatch(setEditedFormTemplate(changedFormTemplate));
      dispatch(setIsEditingFormTemplate(true));
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
        bgcolor: "common.white",
      }}
    >
      <FormFormTemplate formTemplate={formTemplate} onChange={handleChange} />
    </Box>
  );
}
