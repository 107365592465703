import {useState, useEffect} from "react";

import {Box, IconButton, Paper, Dialog} from "@mui/material";
import {Close, Edit, Fullscreen} from "@mui/icons-material";
import ViewerImage from "./ViewerImage";

import {getImageSize} from "../imageUtils";

export default function DialogImageViewer({
  src,
  imageSize,
  scale = 3,
  open,
  onClose,
}) {
  console.log("[DialogImageViewer] src", src, imageSize);

  // state

  const [width, setWidth] = useState(imageSize?.width ?? 0);
  const [height, setHeight] = useState(imageSize?.height ?? 0);

  // effect

  async function updateImageSize() {
    const _imageSize = await getImageSize({fileURL: src});
    setWidth(_imageSize?.width ?? 300);
    setHeight(_imageSize?.height ?? 200);
  }
  useEffect(() => {
    if (!imageSize && src) {
      updateImageSize();
    } else if (imageSize) {
      setWidth(imageSize?.width ?? 300);
      setHeight(imageSize?.height ?? 200);
    }
  }, [src]);

  function handleClose() {
    onClose();
  }

  return (
    <>
      {open && width && height && (
        <Dialog open={open} onClose={handleClose} maxWidth={false}>
          <Box
            sx={{
              //width: 1,
              //height: 1,
              position: "relative",
              p: 0,
              borderRadius: "4px",
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{position: "absolute", right: "8px", top: "8px"}}
            >
              <Close />
            </IconButton>
            <Paper
              elevation={12}
              sx={{
                width: Math.min(width * scale, 800),
                height: Math.min(height * scale, 600),
              }}
            >
              <ViewerImage src={src} imageSize={{width, height}} />
            </Paper>
          </Box>
        </Dialog>
      )}
    </>
  );
}
