import {useSelector, useDispatch} from "react-redux";

import {
  setNewReportTemplate,
  setEditedReportTemplate,
  setIsEditingReportTemplate,
} from "../reportsSlice";

import useSelectedReportTemplate from "../hooks/useSelectedReportTemplate";
import useReportTemplateInSelectionPanel from "../hooks/useReportTemplateInSelectionPanel";

import FormReportTemplate from "./FormReportTemplate";
import useOrganisationFormTemplates from "Features/organisations/hooks/useOrganisationFormTemplates";

export default function SectionReportTemplateInSelectionPanel() {
  const dispatch = useDispatch();
  // data

  const reportTemplate = useReportTemplateInSelectionPanel();
  const formTemplates = useOrganisationFormTemplates({fetchData: true});

  // helpers

  const isNew = !reportTemplate?.id;

  // handlers

  function handleChange(changedReportTemplate) {
    console.log("changedReportTemplate", changedReportTemplate);
    if (isNew) {
      dispatch(setNewReportTemplate(changedReportTemplate));
    } else {
      dispatch(setEditedReportTemplate(changedReportTemplate));
      dispatch(setIsEditingReportTemplate(true));
    }
  }

  return (
    <FormReportTemplate
      reportTemplate={reportTemplate}
      onChange={handleChange}
      formTemplates={formTemplates}
    />
  );
}
