import {Box, Typography} from "@mui/material";

import {getFileSizeString} from "Features/files/utils";

export default function FieldPdfsContainerPdfs({pdfs}) {
  // strings

  const emptyS = "Aucun PDF";

  if (!pdfs) {
    return (
      <Box>
        <Typography>{emptyS}</Typography>
      </Box>
    );
  } else {
    return (
      <Box sx={{width: 1}}>
        {pdfs.map((pdf) => {
          const sizeS = getFileSizeString(pdf.size);
          return (
            <Box
              sx={{
                px: 1,
                py: 0.5,
                width: 1,
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{fontSize: 12}}>{pdf.name}</Typography>
              <Typography
                sx={{fontSize: 12, fontStyle: "italic"}}
                color="text.secondary"
                variant="body2"
              >
                {sizeS}
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  }
}
