export default function getRoomsMapFromRoomsGroupsWithRooms(
  roomsGroupsWithRooms,
  options
) {
  if (!roomsGroupsWithRooms) return {};
  //
  return roomsGroupsWithRooms.reduce((acc, roomsGroupWithRooms) => {
    roomsGroupWithRooms.rooms.forEach((room) => {
      const key = options?.byClientId ? room.clientId : room.id;
      acc[key] = room;
    });
    return acc;
  }, {});
}
