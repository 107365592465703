import {Box} from "@mui/material";
import FieldTextMultiline from "./FieldTextMultiline";
import FieldFormData from "./FieldFormData";
import FieldViewData from "./FieldViewData";

export default function FieldBlockData({caplaEditor, item, value, onChange}) {
  const blockType = item.blockType;
  const blockName = item.name;

  console.log("FieldBlockData", blockType, blockName, value);

  // handlers

  function handleTextChange(text) {
    const newValue = value ? {...value, text} : {text};
    onChange(newValue);
  }

  function handleKeyValuePairsChange(keyValuePairs) {
    const newValue = value ? {...value, keyValuePairs} : {keyValuePairs};
    onChange(newValue);
  }

  function handleViewDataChange(viewData) {
    const newValue = value ? {...value, viewData} : {viewData};
    onChange(newValue);
  }

  return (
    <Box>
      {blockType === "TEXT" && (
        <FieldTextMultiline
          name={blockName}
          value={value?.text}
          onChange={handleTextChange}
        />
      )}
      {blockType === "FORM" && (
        <FieldFormData
          name={blockName}
          value={value?.keyValuePairs}
          onChange={handleKeyValuePairsChange}
        />
      )}
      {blockType === "VIEW" && (
        <FieldViewData
          caplaEditor={caplaEditor}
          item={item}
          name={blockName}
          value={value?.viewData}
          onChange={handleViewDataChange}
        />
      )}
    </Box>
  );
}
