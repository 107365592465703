import {Box, Button, Typography} from "@mui/material";

import SearchBarObjectsV2 from "Features/search/components/SearchBarObjectsV2";

export default function FieldRoomsIdsViewBar({
  searchText,
  onSearchTextChange,
  showAllRooms,
  onShowAllRoomsChange,
}) {
  // strings

  const showS = "Afficher toutes les pièces";
  const hideS = "Pièces sélectionnées";

  // helpers

  const buttonLabel = showAllRooms ? hideS : showS;

  // handlers

  function handleSearchTextChange(searchText) {
    onSearchTextChange(searchText);
  }

  function handleSearchReset() {
    onSearchTextChange("");
  }

  function handleClick() {
    onShowAllRoomsChange(!showAllRooms);
  }

  return (
    <Box
      sx={{
        p: 1,
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <SearchBarObjectsV2
        searchText={searchText}
        onSearchTextChange={handleSearchTextChange}
        onClose={handleSearchReset}
        //onBlur={handleBlur}
        //onFocus={handleFocus}
      />
      <Box sx={{color: "text.secondary"}}>
        <Button size="small" color="inherit" onClick={handleClick}>
          <Typography sx={{fontSize: 12}} noWrap>
            {buttonLabel}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
