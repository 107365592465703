import {useRef, useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";

import getRoomsWithNum from "../utils/getRoomsWithNum";

import useRoomsGroups from "./useRoomsGroups";
import {fetchRooms} from "../roomsSlice";
import getRoomsWithParentsFromNum from "../utils/getRoomsWithParentsFromNum";

import useAccessToken from "Features/auth/useAccessToken";

export default function useRoomsGroupsWithRooms(scene, options) {
  //
  console.log("options", options);
  //
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  //
  const roomsGroupsWithRooms = [];
  //
  const syncingRoomsByGroupIdRef = useRef({});
  //
  const filterByScope = options?.filterByScope;
  const fetchRoomsOption = options?.fetchRooms;
  const roomsWithParents = options?.roomsWithParents;
  const roomsWithNum = options?.roomsWithNum;
  //
  const roomsGroups = useRoomsGroups(scene, {
    filterByScope,
    sortByName: true,
  });
  const fetchedRoomsByGroupId = useSelector(
    (s) => s.rooms.fetchedRoomsByGroupId
  );
  //
  const roomsGroupsHash = roomsGroups.reduce((hash, group) => {
    hash = hash + group.id;
    return hash;
  }, "");

  // init - fetch rooms

  useEffect(() => {
    roomsGroups.forEach((group) => {
      const roomsWereFetched = fetchedRoomsByGroupId[group.id];
      const isSyncingRooms = syncingRoomsByGroupIdRef.current[group.id];
      if (
        accessToken &&
        fetchRoomsOption &&
        !roomsWereFetched &&
        !isSyncingRooms
      ) {
        syncingRoomsByGroupIdRef.current[group.id] = true;
        dispatch(fetchRooms({roomsGroupId: group.id, accessToken}));
      }
    });
  }, [roomsGroupsHash, accessToken, fetchRooms]);

  //
  const roomsMap = useSelector((s) => s.rooms.roomsMap);
  //
  let rooms = roomsMap ? Object.values(roomsMap) : [];
  //
  roomsGroups.forEach((group) => {
    let groupRooms = rooms.filter((r) => r.roomsGroupId === group.id);
    //
    if (roomsWithNum || roomsWithParents) {
      groupRooms = getRoomsWithNum(group, groupRooms);
    }
    //
    if (roomsWithParents) {
      groupRooms = getRoomsWithParentsFromNum(groupRooms);
    }
    //
    const groupWithRooms = {roomsGroup: group, rooms: groupRooms};
    roomsGroupsWithRooms.push(groupWithRooms);
  });
  //
  return roomsGroupsWithRooms;
}
