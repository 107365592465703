export default function addMaterialToMaterialsData(
  materialClientId,
  materialsGroupId,
  materialsData
) {
  // edge case 1

  if (!materialsData) {
    return [{materialsGroupId, materialsClientIds: [materialClientId]}];
  }

  // edge case 2

  if (
    !materialsData.find((item) => item.materialsGroupId === materialsGroupId)
  ) {
    return [
      ...materialsData,
      {materialsGroupId, materialsClientIds: [materialClientId]},
    ];
  }

  // main

  const update = materialsData.map((item) => {
    if (item.materialsGroupId === materialsGroupId) {
      return {
        ...item,
        materialsClientIds: [
          ...new Set([...item.materialsClientIds, materialClientId]),
        ],
      };
    } else {
      return item;
    }
  });
  return update;
}
