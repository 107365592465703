import getMaterialCategoriesWithParentsFromNum from "./getMaterialCategoriesWithParentsFromNum";
import getItemsWithChildrenFromNum from "Utils/getItemsWithChildrenFromNum";

export default function getCategoriesByIdFromNomenclature(
  nomenclature,
  options
) {
  let categories = nomenclature?.tree?.items ?? [];
  //
  if (options?.withChildren) {
    categories = getItemsWithChildrenFromNum(categories);
  }

  if (options?.withParents) {
    categories = getMaterialCategoriesWithParentsFromNum(categories);
  }

  //
  const categoriesById = categories.reduce((acc, category) => {
    acc[category.id] = category;
    return acc;
  }, {});

  //
  if (options?.addRootOtherItem) {
    const rootOtherItem = {
      id: "other",
      name: "Autre",
    };
    categoriesById["other"] = rootOtherItem;
  }

  //
  return categoriesById;
}
