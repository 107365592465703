import {useSelector, useDispatch} from "react-redux";

import {setShowedModules} from "../navigationSlice";

import {Link as RouterLink} from "react-router-dom";

import {Box, Paper, Icon, Typography, Link, IconButton} from "@mui/material";
import {lighten} from "@mui/material/styles";
import theme from "Styles/theme";
import {PushPin as Pin} from "@mui/icons-material";

import useSceneModuleNameMap from "../useSceneModuleNameMap";
import useSceneModuleIconMap from "../useSceneModuleIconMap";
import useSceneModulePathMap from "../useSceneModulePathMap";

import enabledModulesByModeMap from "../data/enabledModulesByModeMap";

export default function ListItemButtonModule({moduleKey, scene}) {
  const dispatch = useDispatch();
  const sceneId = scene?.id ? scene.id : scene?.clientId;

  // data

  const nameMap = useSceneModuleNameMap();
  const iconMap = useSceneModuleIconMap();
  const pathMap = useSceneModulePathMap({sceneId});

  const showedModules = useSelector((s) => s.navigation.showedModules);

  // helpers

  const name = nameMap[moduleKey];
  const icon = iconMap[moduleKey];
  const path = pathMap[moduleKey];
  const pinned = showedModules?.includes(moduleKey);

  // helper - enabled

  const mode = scene?.bimboxMode ? scene.bimboxMode : "BASIC";
  const enabledModules = enabledModulesByModeMap[mode];
  const enabled = enabledModules?.includes(moduleKey) || mode === "EXPERT";

  // helper - overviewed

  const modeForOverview = useSelector((s) => s.navigation.modeForOverview);
  const modulesForOverview = enabledModulesByModeMap[modeForOverview];
  const overviewed =
    modulesForOverview?.includes(moduleKey) || modeForOverview === "EXPERT";

  // helpers - bgcolor

  let bgcolor =
    enabled && (modeForOverview === mode || !modeForOverview)
      ? "grey.700"
      : "common.caplaBlack";
  if (mode !== modeForOverview && overviewed)
    bgcolor = lighten(theme.palette.common.caplaBlack, 0.05);

  let color =
    (modeForOverview === mode || !modeForOverview) && enabled
      ? "common.white"
      : "grey.700";
  if (modeForOverview !== mode && overviewed) color = "common.white";

  // handlers

  function handlePinClick() {
    const newShowedModules = showedModules.filter((m) => m !== moduleKey);
    if (!pinned) newShowedModules.push(moduleKey);
    dispatch(setShowedModules(newShowedModules));
  }

  return (
    <Box sx={{p: 1, minWith: 0}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          bgcolor,
          color: "common.white",
          borderRadius: "4px",
          minWidth: 0,
          p: 1,
          pr: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color,
          }}
        >
          <Box
            sx={{
              display: "flex",
              aligItems: "center",
              justifyContent: "center",
              mr: 1,
              height: 20,
              width: 20,
              borderRadius: "50%",
              bgcolor: pinned ? "common.caplaBlack" : "unset",
            }}
          >
            <IconButton
              onClick={handlePinClick}
              size="small"
              sx={{
                color:
                  pinned && enabled && mode === modeForOverview
                    ? "common.white"
                    : "grey.600",
                transform: pinned ? "unset" : "rotate(30deg)",
              }}
            >
              {<Pin color="inherit" sx={{fontSize: 12}} />}
            </IconButton>
          </Box>
          <Icon
            sx={{
              mr: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon}
          </Icon>
        </Box>
        {enabled && (
          <Link
            component={RouterLink}
            to={path}
            color="inherit"
            underline="none"
          >
            <Typography
              noWrap
              sx={{
                color,
              }}
            >
              {name}
            </Typography>
          </Link>
        )}
        {!enabled && (
          <Typography
            noWrap
            sx={{
              color,
            }}
          >
            {name}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
