export default function cleanRoomsGroupChildrenClientIds(roomsGroup, rooms) {
  const roomsByClientId = rooms.reduce((acc, r) => {
    acc[r.clientId] = r;
    return acc;
  }, {});
  //
  const clientIdsToRemove = [];
  const newChildrenClientIds = [];

  roomsGroup.childrenClientIds.forEach((clientId) => {
    if (roomsByClientId[clientId]) {
      newChildrenClientIds.push(clientId);
    } else {
      clientIdsToRemove.push(clientId);
    }
  });
  return {idsToRemove: clientIdsToRemove, newIds: newChildrenClientIds};
}
