// handler : used in materials list.
// key features : multi selection & category selection.

import {useDispatch, useSelector} from "react-redux";

import {
  setSelectedMaterialCategoryId,
  setSelectedMaterialsClientIds,
} from "../materialsSlice";

export default function useHandleMaterialClick() {
  const dispatch = useDispatch();

  // data

  const selectedMaterialsClientIds = useSelector(
    (s) => s.materials.selectedMaterialsClientIds
  );
  const multipleSelection = useSelector(
    (s) => s.materials.multipleSelectionMaterials
  );

  // return

  return (material) => {
    if (material?.isCategory) {
      dispatch(setSelectedMaterialCategoryId(material?.id));
    }
    console.log("click on material", material);

    const clientId = material?.clientId;
    const idIsSelected = selectedMaterialsClientIds?.includes(clientId);
    let newIds = selectedMaterialsClientIds?.filter(
      (materialClientId) => materialClientId !== materialClientId
    );
    if (multipleSelection) {
      if (!newIds) newIds = [];
      if (material.isTitle) {
        return;
      } else {
        if (!selectedMaterialsClientIds?.includes(clientId))
          newIds.push(clientId);
      }
    } else {
      newIds = idIsSelected ? [] : [clientId];
    }
    dispatch(setSelectedMaterialsClientIds(newIds));
  };
}
