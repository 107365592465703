import {useState} from "react";

import {Box, CircularProgress, IconButton, Typography} from "@mui/material";
import {CloudUpload, Delete} from "@mui/icons-material";
import {ArrowDropDown as Down, ArrowDropUp as Up} from "@mui/icons-material";

import {isImageFile} from "Features/images/imageUtils";

import FileSelectorButton from "Features/files/components/FileSelectorButton";
import useAccessToken from "Features/auth/useAccessToken";
import {uploadFileService} from "Features/files/services";

export default function FieldEditableImage({
  name,
  value,
  onChange,
  container,
  readOnly,
  blobPrefix,
  scene,
  height = 160,
}) {
  const accessToken = useAccessToken();
  const sceneId = scene?.id;

  //strings

  const selectImageString = "Depuis mon ordinateur";
  const dragS = "Glisser & déposer une image";

  // state

  const [loading, setLoading] = useState(false);
  const [tempValue, setTempValue] = useState(null);
  const [hover, setHover] = useState();
  const [expand, setExpand] = useState(true);

  // helper

  let showedValue = value ? value : tempValue;
  if (loading) showedValue = tempValue;

  // handlers - drag & drop

  function handleDragEnter() {
    setHover(true);
  }
  function handleDragLeave() {
    setHover(false);
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("DROP");
    setHover(false);
    try {
      let file = e.dataTransfer.items[0].getAsFile();
      if (isImageFile(file)) {
        handleFileChange(file);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  // handlers - files

  async function handleFileChange(file) {
    setLoading(true);
    setTempValue(URL.createObjectURL(file));

    const blobName = blobPrefix ?? "" + file.size + "_" + file.name;

    const value = await uploadFileService({
      file,
      sceneId,
      accessToken,
      container,
      blobName,
    });
    console.log("new image in storage", value);
    onChange(value);
    setTempValue(null);
    setLoading(false);
  }

  function handleDelete() {
    onChange(null);
  }
  return (
    <Box sx={{display: "flex", flexDirection: "column", width: 1}}>
      <Box
        sx={{
          //bgcolor: "background.default",
          width: 1,
          pl: 1,
          pr: 0.5,
          borderBottom: (theme) => `2px solid ${theme.palette.divider}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{fontSize: 12}} noWrap>
          {name}
        </Typography>
        <IconButton size="small" onClick={() => setExpand(!expand)}>
          {!expand ? <Down fontSize="small" /> : <Up fontSize="small" />}
        </IconButton>
      </Box>
      {expand && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height,
            width: 1,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          {readOnly && (
            <Box
              sx={{
                width: 1,
                height: 1,
                minHeight: 0,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundImage: `url(${showedValue})`,
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                position: "relative",
              }}
            />
          )}
          {!readOnly && (
            <Box
              sx={{
                width: 1,
                height: 1,
                minHeight: 0,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundImage: `url(${showedValue})`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              {!value && !loading && (
                <Box sx={{flex: 1, p: 2, width: 1}}>
                  <Box
                    sx={{
                      bgcolor: "background.default",
                      position: "relative",
                      width: 1,
                      height: 1,
                      py: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      sx={{
                        position: "absolute",
                        height: 1,
                        width: 1,
                        border: (theme) =>
                          `2px dashed ${theme.palette.divider}`,
                        borderColor: (theme) =>
                          hover
                            ? theme.palette.primary.flash
                            : theme.palette.divider,
                      }}
                    />
                    <CloudUpload color="action" />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{mt: 1}}
                    >
                      {dragS}
                    </Typography>
                  </Box>
                </Box>
              )}
              {!value && !loading && (
                <Box
                  sx={{
                    width: 1,
                    display: "flex",
                    justifyContent: "left",
                    color: "text.secondary",
                    p: 0.5,
                  }}
                >
                  <FileSelectorButton
                    onFileChange={handleFileChange}
                    buttonName={selectImageString}
                    startIcon="computer"
                    buttonVariant="inline"
                    buttonColor="inherit"
                  />
                </Box>
              )}
              {loading && <CircularProgress />}
              {value && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    bgcolor: "white",
                    //border: (theme) => `1px solid ${theme.palette.primary.main}`,
                    position: "absolute",
                    bottom: 4,
                    right: 4,
                    zIndex: 100,
                  }}
                >
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={handleDelete}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
