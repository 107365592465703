import {useSelector} from "react-redux";

import useSelectedRoomsGroup from "./useSelectedRoomsGroup";

export default function useRoomsGroupInSelectionPanel(scene) {
  //
  const selectedRoomsGroup = useSelectedRoomsGroup();
  const editedRoomsGroup = useSelector((s) => s.rooms.editedRoomsGroup);
  const newRoomsGroup = useSelector((s) => s.rooms.newRoomsGroup);
  const isEditingRoomsGroup = useSelector((s) => s.rooms.isEditingRoomsGroup);

  // helper - roomsGroup

  let roomsGroup = selectedRoomsGroup ?? newRoomsGroup;
  if (isEditingRoomsGroup) roomsGroup = editedRoomsGroup;

  // return

  return roomsGroup;
}
