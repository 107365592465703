import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  setSelectedMaterialsGroupId,
  createMaterialsGroup,
} from "../materialsSlice";
import {setSnackbarMessage} from "Features/ui/uiSlice";

import useCreateMaterialsGroup from "../hooks/useCreateMaterialsGroup";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import SectionSelectorMaterialsNomenclature from "./SectionSelectorMaterialsNomenclature";

export default function DialogCreateMaterialsGroup({
  scene,
  open,
  onClose,
  onCreated,
}) {
  const dispatch = useDispatch();

  // data

  const selectedScopeId = useSelector((s) => s.scopes.selectedScopeId);

  const options = {fromScopeId: selectedScopeId};
  const create = useCreateMaterialsGroup(options);
  const nomenclatures = useSelector((s) => s.materials.nomenclatures);
  const scope = useSelectedScope(scene);

  // strings

  const title = "Nouvelle liste de matériaux";

  const nameS = "Nom";
  const placeholder = "Nom de la liste de matériaux";
  const nomenclatureS = "Classification";

  const createS = "Créer";

  // state

  const [name, setName] = useState("");
  const [nomenclatureId, setNomenclatureId] = useState();
  const [loading, setLoading] = useState(false);

  // init - name

  const selectedNomenclature = nomenclatures?.find(
    (n) => n.id === nomenclatureId
  );
  const scopeName = scope?.name ?? "";

  useEffect(() => {
    if (selectedNomenclature?.name) {
      setName(`${scopeName} - ${selectedNomenclature.name}`);
    }
  }, [nomenclatureId]);

  // handlers

  function handleNameChange(e) {
    setName(e.target.value);
  }
  function handleClose() {
    setLoading(false);
    onClose();
  }

  function handleNomenclatureChange(nomenclatureId) {
    setNomenclatureId(nomenclatureId);
  }

  async function handleSave() {
    try {
      if (loading) return;
      setLoading(true);
      //
      const newGroup = {
        name,
        sceneId: scene?.id,
        data: {materialsNomenclatureId: nomenclatureId},
      };
      //
      const item = await create(newGroup);
      //
      setLoading(false);
      //
      setName("");
      setNomenclatureId();

      //
      if (item) {
        dispatch(setSelectedMaterialsGroupId(item?.id));
        //
        const triggeredAt = Date.now();
        const message = "Nouvelle arborescence créée";
        dispatch(setSnackbarMessage({triggeredAt, message}));
        //
        if (onCreated) onCreated(item);
      }

      onClose();
    } catch (e) {
      console.log("error", e);
    }
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <SectionSelectorMaterialsNomenclature
          selectedNomenclatureId={nomenclatureId}
          onSelectionChange={handleNomenclatureChange}
          nomenclatures={nomenclatures}
        />
        <TextField
          size="small"
          fullWidth
          placeholder={placeholder}
          sx={{mt: 2}}
          //label={<Typography variant="body2">{nameS}</Typography>}
          InputProps={{
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
          value={name}
          onChange={handleNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading || name?.length === 0} onClick={handleSave}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
