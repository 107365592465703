import {useSelector, useDispatch} from "react-redux";

import {setOpenSelectionPanel} from "../selectionSlice";

import useSceneModule from "Features/navigation/useSceneModule";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";

import {Box, Typography, IconButton} from "@mui/material";
import {ArrowForwardIos as Arrow} from "@mui/icons-material";
import ButtonToggleSelection from "./ButtonToggleSelection";

export default function ContainerModeSelector({mode, onModeChange}) {
  const dispatch = useDispatch();

  // data

  const sceneModule = useSceneModule();
  const sceneModuleName = useSceneModuleNameMap()[sceneModule];

  const modes = [
    {code: "SCENE_MODULE", label: sceneModuleName},
    {code: "SELECTION", label: "Sélection"},
  ];

  const openPanel = useSelector((s) => s.selection.openSelectionPanel);

  // handlers

  function handleArrowClick() {
    dispatch(setOpenSelectionPanel(!openPanel));
  }

  return (
    <Box
      sx={{
        width: 1,
        bgcolor: "common.caplaBlack",
        display: "flex",
        alignItems: "center",
        color: "grey.500",
        justifyContent: "space-between",
        height: "36px",
      }}
    >
      {/* <IconButton size="small" onClick={handleArrowClick} color="inherit">
        <Arrow fontSize="small" color="inherit" />
      </IconButton> */}
      <ButtonToggleSelection />
      <Box
        sx={{
          width: 1,
          display: "flex",
          alignItems: "center",
          color: "grey.500",
          p: 1,
          justifyContent: "space-around",
        }}
      >
        {modes.map((m) => {
          const selected = m.code === mode;

          return (
            <Typography
              key={m.code}
              variant="body2"
              sx={{
                cursor: "pointer",
                ...(selected && {color: "common.white", fontWeight: "bold"}),
              }}
              onClick={() => onModeChange(m.code)}
            >
              {m.label}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
}
