import {useSelector, useDispatch} from "react-redux";

import {
  setNewNomenclature,
  setEditedNomenclature,
  setIsEditingNomenclature,
} from "../nomenclaturesSlice";

import useNomenclatureInSelectionPanel from "../hooks/useNomenclatureInSelectionPanel";

import FormNomenclature from "./FormNomenclature";

export default function SectionNomenclatureInSelectionPanel({scene}) {
  const dispatch = useDispatch();

  // data

  const nomenclature = useNomenclatureInSelectionPanel();

  // helpers

  const isNew = !nomenclature?.id;

  // handlers

  function handleChange(changedNomenclature) {
    if (isNew) {
      dispatch(setNewNomenclature(changedNomenclature));
    } else {
      dispatch(setEditedNomenclature(changedNomenclature));
      dispatch(setIsEditingNomenclature(true));
    }
  }

  return (
    <FormNomenclature nomenclature={nomenclature} onChange={handleChange} />
  );
}
