import React from "react";

import {Box, Paper, Typography} from "@mui/material";

export default function BlockDataText({text}) {
  // helper

  const label = text ?? "Aucun contenu";
  return (
    <Box sx={{width: 1, p: 1}}>
      <Typography
        variant="body2"
        sx={{whiteSpace: "pre-line", ...(!text && {color: "error.main"})}}
      >
        {label}
      </Typography>
    </Box>
  );
}
