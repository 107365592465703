import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {
  setIsEditingReportTemplate,
  setSelectedReportTemplateId,
  createReportTemplate,
  updateReportTemplate,
} from "../reportsSlice";

import useReportTemplateInSelectionPanel from "../hooks/useReportTemplateInSelectionPanel";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";

export default function ActionsReportTemplateInSelectionPanel({organisation}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  // strings

  const createS = "Créer";
  const saveS = "Enregistrer";
  const editS = "Editer";
  const cancelS = "Annuler";

  // data

  const isEditing = useSelector((s) => s.reports.isEditingReportTemplate);
  const reportTemplate = useReportTemplateInSelectionPanel();

  // state

  const [loading, setLoading] = useState(false);

  // helper

  const isNew = !reportTemplate?.id;

  // helper - saveLabel

  const saveLabel = isNew ? createS : saveS;

  // helpers - functions

  async function createTemplate() {
    if (loading) return;
    try {
      setLoading(true);
      const {item} = await dispatch(
        createReportTemplate({
          accessToken,
          reportTemplate,
          organisationId: organisation?.id,
        })
      );
      dispatch(setSelectedReportTemplateId(item.id));
      setLoading(false);
    } catch (error) {
      console.error("[ActionsReportTemplateInSelectionPanel] error", error);
      setLoading(false);
    }
  }

  async function updateTemplate() {
    if (loading) return;
    setLoading(true);
    await dispatch(updateReportTemplate({accessToken, reportTemplate}));
    dispatch(setIsEditingReportTemplate(false));
    setLoading(false);
  }

  // handlers

  function handleCancelClick() {
    dispatch(setIsEditingReportTemplate(false));
  }

  async function handleSaveClick() {
    if (isNew) {
      await createTemplate();
    } else {
      await updateTemplate();
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {(isEditing || isNew) && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
}
