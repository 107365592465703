import getItemsMapById from "Utils/getItemsMapById";
import useRooms from "./useRooms";
import {useSelector} from "react-redux";

export default function useSelectedRoom(options) {
  const rooms = useRooms(options);
  const roomsMap = getItemsMapById(rooms);
  const selectedRoomId = useSelector((s) => s.rooms.selectedRoomId);
  return roomsMap[selectedRoomId];
}
