import {useEffect} from "react";
import {useParams} from "react-router-dom";
import useAccessToken from "Features/auth/useAccessToken";
import {useDispatch} from "react-redux";

import {fetchOrganisation} from "../organisationsSlice";
import {fetchReportTemplates} from "Features/reports/reportsSlice";
import {fetchFormTemplates} from "Features/forms/formsSlice";

export default function useInitFetchOrganisation() {
  const {organisationId} = useParams();
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (accessToken && organisationId) {
      dispatch(fetchOrganisation({accessToken, organisationId}));
      dispatch(fetchReportTemplates({accessToken, organisationId}));
      dispatch(fetchFormTemplates({accessToken, organisationId}));
    }
  }, [accessToken, organisationId]);
}
