import {Box, Typography} from "@mui/material";

import ContainerEditableLogo from "./ContainerEditableLogo";
import ContainerEditableEmailDomain from "./ContainerEditableEmailDomain";
import ContainerEditableNomenclatures from "./ContainerEditableNomenclatures";

import useSelectedOrganisation from "../hooks/useSelectedOrganisation";
import ContainerEditableBimboxModes from "./ContainerEditableBimboxModes";
import ContainerEditableApiKey from "./ContainerEditableApiKey";

export default function SectionSelectedOrganisation() {
  //data

  const organisation = useSelectedOrganisation();

  // helper

  const display = organisation ? "flex" : "none";

  return (
    <Box
      sx={{
        display,
        flexDirection: "column",
        width: 1,
        height: 1,
        minHeight: 0,
      }}
    >
      <Box sx={{p: 1, width: 1, display: "flex"}}>
        <Typography>{organisation?.name}</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 1,
            mt: 1,
            "&>*": {
              mb: 2,
            },
          }}
        >
          <Box sx={{p: 1, width: 1, display: "flex"}}>
            <ContainerEditableLogo organisation={organisation} />
          </Box>

          <Box sx={{p: 1, width: 1, minHeight: 0, display: "flex"}}>
            <ContainerEditableEmailDomain organisation={organisation} />
          </Box>
          <ContainerEditableNomenclatures organisation={organisation} />
          <ContainerEditableBimboxModes organisation={organisation} />
          <ContainerEditableApiKey organisation={organisation} />
        </Box>
      </Box>
    </Box>
  );
}
