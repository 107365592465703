import {useDispatch, useSelector} from "react-redux";

import {setSelectedFormTemplateId} from "Features/forms/formsSlice";

import useOrganisationFormTemplates from "../hooks/useOrganisationFormTemplates";

import {Box, Typography} from "@mui/material";

import ListFormTemplates from "Features/forms/components/ListFormTemplates";

export default function SectionFormTemplates() {
  const dispatch = useDispatch();

  // string

  const noTemplates = "Aucun modèle";

  // data

  const formTemplates = useOrganisationFormTemplates();
  const selectedFormTemplateId = useSelector(
    (state) => state.forms.selectedFormTemplateId
  );

  // helpers

  const selection = selectedFormTemplateId ? [selectedFormTemplateId] : null;

  // helpers

  const isEmpty = formTemplates.length === 0;

  // handlers

  function handleClick(formTemplate) {
    if (selectedFormTemplateId === formTemplate.id) {
      dispatch(setSelectedFormTemplateId(null));
    } else {
      dispatch(setSelectedFormTemplateId(formTemplate.id));
    }
  }
  return (
    <Box
      sx={{width: 1, p: 2, display: "flex", flexDirection: "column", height: 1}}
    >
      <Box sx={{width: 1, bgcolor: "common.white"}}>
        {isEmpty && (
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>{noTemplates}</Typography>
          </Box>
        )}
        {!isEmpty && (
          <ListFormTemplates
            formTemplates={formTemplates}
            onClick={handleClick}
            selection={selection}
          />
        )}
      </Box>
    </Box>
  );
}
