import {useEffect, useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import useRooms from "../hooks/useRooms";

import {
  setSelectedRoomIds,
  setSelectedRoomId,
  setSelectedRoomParentId,
} from "../roomsSlice";

import {setSelectedRoomsGroupId} from "../roomsSlice";
import {fetchRooms} from "../roomsSlice";

import {Box, Typography} from "@mui/material";

import ListRoomsMultipleWithGroups from "./ListRoomsMultipleWithGroups";

import useRoomsGroups from "../hooks/useRoomsGroups";
import useAccessToken from "Features/auth/useAccessToken";

export default function SectionRoomsInLeftPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // string

  const noGroupS = "Aucune arborescence de pièces";

  // data

  const roomsGroups = useRoomsGroups(scene, {
    sortByName: true,
    filterByScope: true,
  });

  const selectedRoomIds = useSelector((s) => s.rooms.selectedRoomIds);
  const selectedRoomsGroupId = useSelector((s) => s.rooms.selectedRoomsGroupId);

  const rooms = useRooms({
    filterByRoomsGroupId: selectedRoomsGroupId,
    withParentId: true,
    withNum: true,
    applySearch: true,
    withRoomLabels: true,
  });

  const roomsViewVariant = useSelector((s) => s.rooms.roomsViewVariant);
  const multipleSelection = useSelector((s) => s.rooms.multipleSelectionRooms);

  // init

  useEffect(() => {
    if (accessToken && selectedRoomsGroupId) {
      dispatch(fetchRooms({accessToken, roomsGroupId: selectedRoomsGroupId}));
    }
  }, [accessToken, selectedRoomsGroupId]);

  // handlers

  function handleSelectionChange(ids) {
    dispatch(setSelectedRoomIds(ids));
    if (ids.length >= 1) {
      dispatch(setSelectedRoomId(ids[0]));
    } else {
      dispatch(setSelectedRoomId(null));
    }
  }

  function handleSelectedRoomsGroupIdChange(id) {
    dispatch(setSelectedRoomsGroupId(id));
  }

  function handleSelectedRoomParentIdChange(id) {
    dispatch(setSelectedRoomParentId(id));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: 0,
        justifyItems: "strech",
      }}
    >
      {roomsGroups.length > 0 ? (
        <ListRoomsMultipleWithGroups
          scene={scene}
          caplaEditor={caplaEditor}
          rooms={rooms}
          selectedRoomIds={selectedRoomIds}
          onSelectionChange={handleSelectionChange}
          onSelectedRoomParentIdChange={handleSelectedRoomParentIdChange}
          roomsGroups={roomsGroups}
          forceSelection={true}
          selectedRoomsGroupId={selectedRoomsGroupId}
          onSelectedRoomsGroupIdChange={handleSelectedRoomsGroupIdChange}
          multipleSelection={multipleSelection}
          variant="list"
          variantRooms={roomsViewVariant}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {noGroupS}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
