import {useState} from "react";
import {Paper, Box} from "@mui/material";

import FieldNomenclatureTreeHeader from "./FieldNomenclatureTreeHeader";
import TreeNomenclatureItems from "Features/nomenclatures/components/TreeNomenclatureItems";
import FieldNomenclatureTreeCategory from "./FieldNomenclatureTreeCategory";
import getItemsMapById from "Utils/getItemsMapById";

export default function FieldNomenclatureTree({item, name, value, onChange}) {
  // state

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  // helper

  const tree = value ?? [];

  const categories = tree?.items ? tree.items : [];

  const categoriesIds = getItemsMapById(categories);

  const selectedCategory = selectedCategoryId
    ? categoriesIds[selectedCategoryId]
    : null;

  // handler

  function handleSelectedCategoryIdChange(newCategoryId) {
    console.log("selectedCategoryId", newCategoryId);
    setSelectedCategoryId(newCategoryId);
  }

  function handleItemChange(newItem) {
    const newValue = value.map((field) => {
      const newField = {...field};
      newField.value = newItem[field.key];
      return newField;
    });
    onChange(newValue);
  }

  function handleValueChange(newValue) {
    onChange(newValue);
  }

  function handleCategoryChange(newCategory) {
    console.log("newCategory", newCategory);
    const newItems = tree.items.map((category) => {
      if (category.id === newCategory.id) {
        return newCategory;
      } else {
        return category;
      }
    });
    const newTree = {...tree, items: newItems};
    onChange(newTree);
  }

  return (
    <Box
      sx={{
        width: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <FieldNomenclatureTreeHeader
        item={item}
        name={name}
        value={value}
        onChange={handleValueChange}
      />
      <FieldNomenclatureTreeCategory
        category={selectedCategory}
        onChange={handleCategoryChange}
      />
      <Box sx={{width: 1}}>
        <TreeNomenclatureItems
          nomenclatureItems={tree?.items ?? []}
          selectedCategoryId={selectedCategoryId}
          onSelectedCategoryIdChange={handleSelectedCategoryIdChange}
        />
      </Box>
    </Box>
  );
}
