export default function getParentsNums(num) {
  if (!num) return [];
  const parentsNums = [];
  const numArray = num.split(".");
  if (numArray.length === 1) {
    return parentsNums;
  } else {
    for (let i = 1; i < numArray.length; i++) {
      parentsNums.push(numArray.slice(0, i).join("."));
    }
    return parentsNums;
  }
}
