import React, {useState} from "react";

import {useNavigate, useLocation} from "react-router";

import {Box} from "@mui/material";

import Stretch from "Features/ui/components/Stretch";

import Landing from "../components/Landing";

export default function DesktopLayout({scene}) {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  // local states

  // helpers

  // handlers

  function handleSceneOpenClick() {
    navigate(`${pathname}/viewer`);
  }

  return (
    <Box>
      <Stretch>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
          }}
        >
          <Landing scene={scene} onSceneOpenClick={handleSceneOpenClick} />
        </Box>
      </Stretch>
    </Box>
  );
}
