import {useState} from "react";

import useActionsNomenclatureMap from "../hooks/useActionsNomenclatureMap";
import useSelectedNomenclature from "../hooks/useSelectedNomenclature";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogDeleteNomenclature from "./DialogDeleteNomenclature";

export default function SectionActionsNomenclature({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  const [openDialogDeleteNomenclature, setOpenDialogDeleteNomenclature] =
    useState(false);

  // state - selection

  const selectedNomenclature = useSelectedNomenclature();

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
    deleteNomenclature: () => setOpenDialogDeleteNomenclature(true),
  };

  // handlers

  function handleClick(actionKey) {
    console.log("trigger", actionKey);
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // data - handlers

  const actionsMap = useActionsNomenclatureMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />
      {openDialogDeleteNomenclature && (
        <DialogDeleteNomenclature
          nomenclature={selectedNomenclature}
          scene={scene}
          open={openDialogDeleteNomenclature}
          onClose={() => setOpenDialogDeleteNomenclature(false)}
        />
      )}
    </>
  );
}
