import React, {useState, useEffect} from "react";

import {Box, Typography, Dialog, Grid, ClickAwayListener} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import SelectorRoomFromTree from "Features/rooms/components/SelectorRoomFromTree";
import getRoomsMapFromRoomsGroupsWithRooms from "Features/rooms/utils/getRoomsMapFromRoomsGroupsWithRooms";

export default function FieldRoom({
  name,
  value,
  roomsGroupsWithRooms,
  onChange,
  locked,
  sizeKey = 12,
  treeContainerRef,
}) {
  // state

  const [open, setOpen] = useState(false);

  // helpers

  const bbox = treeContainerRef?.current?.getBoundingClientRect();

  // helpers - roomsMap

  const roomsMap = getRoomsMapFromRoomsGroupsWithRooms(roomsGroupsWithRooms);

  // helpers - roomName

  const roomRootName = value?.parents?.reduce((acc, parent) => {
    return acc + parent.name + " / ";
  }, "");
  let roomName = roomRootName + value?.name;
  if (!value) roomName = "Sélectionnez une pièce";

  // handlers - dialog

  function handleOpen() {
    if (!locked) setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  // handlers - selector

  function handleChange(roomId) {
    if (roomId) {
      onChange(roomsMap[roomId]);
    } else {
      onChange(null);
    }

    setOpen(false);
  }

  return (
    <Box>
      {open ? (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <Box
            sx={{
              position: "fixed",
              top: 0,
              bottom: 0,
              left: bbox.left,
              width: bbox.width,
              zIndex: 90,
              //height: 100,
              bgcolor: "background.default",
              //border: (theme) => `1px solid ${theme.palette.primary.main}`,
            }}
          >
            <SelectorRoomFromTree
              selectedRoom={value}
              roomsGroupsWithRooms={roomsGroupsWithRooms}
              onSelect={handleChange}
              onClose={handleClose}
            />
          </Box>
        </ClickAwayListener>
      ) : (
        <Grid
          container
          sx={{
            px: 1,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Grid item xs={sizeKey} sx={{py: 0.25}}>
            <Typography sx={{fontSize: 13}}>{name}</Typography>
          </Grid>
          <Grid
            item
            xs={12 - sizeKey}
            sx={{
              py: 0.25,
              pl: 1,
              borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                color: locked ? "text.secondary" : "unset",
              }}
              onClick={handleOpen}
            >
              <Typography
                sx={{
                  fontSize: 13,
                  ...(!value && {fontStyle: "italic", color: "text.secondary"}),
                }}
              >
                {roomName}
              </Typography>
              {!locked && <Down fontSize="small" />}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
