import {useSelector} from "react-redux";

import useSelectedFormTemplate from "./useSelectedFormTemplate";

export default function useFormTemplateInSelectionPanel() {
  //
  const selectedFormTemplate = useSelectedFormTemplate();
  const editedFormTemplate = useSelector((s) => s.forms.editedFormTemplate);
  const newFormTemplate = useSelector((s) => s.forms.newFormTemplate);
  const isEditingFormTemplate = useSelector(
    (s) => s.forms.isEditingFormTemplate
  );

  // helper - formTemplate

  let formTemplate = selectedFormTemplate ?? newFormTemplate;
  if (isEditingFormTemplate) formTemplate = editedFormTemplate;

  // return

  return formTemplate;
}
