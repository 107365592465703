import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  fetchReportTemplatesService,
  createReportTemplateService,
  fetchReportTemplateService,
  updateReportTemplateService,
  deleteReportTemplateService,
} from "./servicesReportTemplates";
import {
  fetchReportsService,
  createReportService,
  fetchReportService,
  updateReportService,
  deleteReportService,
} from "./servicesReports";

// TEMPLATE REPORTS

export const createReportTemplate = createAsyncThunk(
  "reports/createReportTemplate",
  createReportTemplateService
);

export const fetchReportTemplates = createAsyncThunk(
  "reports/fetchReportTemplates",
  fetchReportTemplatesService
);

export const fetchReportTemplate = createAsyncThunk(
  "reports/fetchReportTemplate",
  fetchReportTemplateService
);

export const updateReportTemplate = createAsyncThunk(
  "reports/updateReportTemplate",
  updateReportTemplateService
);
export const deleteReportTemplate = createAsyncThunk(
  "reports/deleteReportTemplate",
  deleteReportTemplateService
);

// REPORTS

export const createReport = createAsyncThunk(
  "reports/createReport",
  createReportService
);

export const fetchReports = createAsyncThunk(
  "reports/fetchReports",
  fetchReportsService
);

export const fetchReport = createAsyncThunk(
  "reports/fetchReport",
  fetchReportService
);

export const updateReport = createAsyncThunk(
  "reports/updateReport",
  updateReportService
);
export const deleteReport = createAsyncThunk(
  "reports/deleteReport",
  deleteReportService
);

const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    //
    reportsMap: {},
    selectedReportId: null,
    editedReport: null,
    isEditingReport: false,
    newReport: null,
    reportFormTab: "INFO",
    //
    fetchedReportTemplatesByOrganizationId: {},
    fetchedReportsByScopeId: {},
    //
    reportTemplatesMap: {},
    selectedReportTemplateId: null,
    editedReportTemplate: null,
    newReportTemplate: null,
    isEditingReportTemplate: false,
    //
  },
  reducers: {
    setSelectedReportId: (state, action) => {
      state.selectedReportId = action.payload;
    },
    setNewReport: (state, action) => {
      state.newReport = action.payload;
    },
    setEditedReport: (state, action) => {
      state.editedReport = action.payload;
    },
    setIsEditingReport: (state, action) => {
      state.isEditingReport = action.payload;
    },
    //
    setReportFormTab: (state, action) => {
      state.reportFormTab = action.payload;
    },
    setSelectedReportTemplateId: (state, action) => {
      state.selectedReportTemplateId = action.payload;
    },
    setNewReportTemplate: (state, action) => {
      state.newReportTemplate = action.payload;
    },
    setEditedReportTemplate: (state, action) => {
      state.editedReportTemplate = action.payload;
    },
    setIsEditingReportTemplate: (state, action) => {
      state.isEditingReportTemplate = action.payload;
    },
  },
  extraReducers: {
    // REPORT TEMPLATES

    [createReportTemplate.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.reportTemplatesMap[item.id] = item;
    },
    [fetchReportTemplates.fulfilled]: (state, action) => {
      const {items, organisationId} = action.payload;
      items.forEach((item) => {
        state.reportTemplatesMap[item.id] = item;
      });
      state.fetchedReportTemplatesByOrganizationId[organisationId] = "done";
    },
    [fetchReportTemplate.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.reportTemplatesMap[item.id] = item;
    },
    [updateReportTemplate.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.reportTemplatesMap[item.id] = item;
    },
    [deleteReportTemplate.fulfilled]: (state, action) => {
      const reportTemplateId = action.payload;
      delete state.reportTemplatesMap[reportTemplateId];
    },

    // REPORTS

    [createReport.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.reportsMap[item.id] = item;
    },
    [fetchReports.fulfilled]: (state, action) => {
      const {items, scopeId} = action.payload;
      items.forEach((item) => {
        state.reportsMap[item.id] = item;
      });
      state.fetchedReportsByScopeId[scopeId] = "done";
    },
    [fetchReport.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.reportsMap[item.id] = item;
    },
    [updateReport.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.reportsMap[item.id] = item;
    },
    [deleteReport.fulfilled]: (state, action) => {
      const reportId = action.payload;
      delete state.reportsMap[reportId];
    },
  },
});
export const {
  setSelectedReportId,
  setNewReport,
  setEditedReport,
  setIsEditingReport,
  setReportFormTab,
  //
  setSelectedReportTemplateId,
  setNewReportTemplate,
  setEditedReportTemplate,
  setIsEditingReportTemplate,
  //
} = reportsSlice.actions;

export default reportsSlice.reducer;
