import {useDispatch, useSelector} from "react-redux";

import {setSelectedSampleId} from "../samplesSlice";

import useSurveysWithSamples from "../hooks/useSurveysWithSamples";
import useSamples from "../hooks/useSamples";

import {Box, List, ListItemButton, Typography} from "@mui/material";

export default function SectionSamplesInLeftPanel({scene}) {
  const dispatch = useDispatch();

  // data

  const selectedSampleId = useSelector((s) => s.samples.selectedSampleId);

  const surveysWithSamples = useSurveysWithSamples(scene, {
    fetchSamples: true,
    filterByScope: true,
    sortByScheduledAt: true,
  });

  const samples = useSamples({
    filterByScope: true,
    sortByNum: true,
  });

  // handlers

  function handleClick(sample) {
    const id = sample?.id === selectedSampleId ? null : sample?.id;
    dispatch(setSelectedSampleId(id));
  }

  return (
    <List>
      {samples.map((sample) => {
        const selected = sample.id === selectedSampleId;

        return (
          <ListItemButton
            key={sample.id}
            selected={selected}
            onClick={() => handleClick(sample)}
          >
            <Box>
              <Typography>{sample.num}</Typography>
            </Box>
          </ListItemButton>
        );
      })}
    </List>
  );
}
