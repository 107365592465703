import {useSelector} from "react-redux";

import {Box, Typography} from "@mui/material";
import {
  // SelectAll,
  // Layers,
  // Interests as Types,
  // Room as Markers,
  Image as Zones,
  // ContentCopy as Models,
  //Elevator as Elevations,
  VerticalSplit as Elevations,
  // ViewInAr as Scenes,
  // Square as Elements,
  // CalendarToday,
  Error as Issues,
  // ContentCut as Cut,
  // Widgets as Measurements,
  Category as ElementTypes,
  ViewTimeline as Phases,
  Update as Versions,
} from "@mui/icons-material";

import ButtonTool from "./ButtonTool";
import ButtonZoomOut from "../../ButtonZoomOut";

// import useSceneModule from "Features/navigation/useSceneModule";
import useElevationsFromSceneSectors from "Features/sectors/hooks/useElevationsFromSceneSectors";
// import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import useIssuesetsByScene from "Features/issues/useIssuesetsByScene";
import ButtonRefreshPdfInViewer from "Features/pdf/components/ButtonRefreshPdfInViewer";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
// import useSceneMeasurements from "Features/measurements/useSceneMeasurements";
// import useMeasurementsFiltersAndShortcuts from "Features/measurements/useMeasurementsFilters";
// import useSceneMeasurementsInScope from "Features/measurements/useSceneMeasurementsInScope";
import usePhasesByScene from "Features/phases/hooks/usePhasesByScene";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";
import useVersionsByScene from "Features/versions/hooks/useVersionsByScene";

export default function HeaderTools({
  tool,
  toolViewer3D,
  onChange,
  // space,
  sceneViewer,
  viewer,
  scene,
  caplaEditor,
}) {
  // data

  const nameMap = useSceneModuleNameMap();
  // const os = useSelector((s) => s.ui.openSections);

  // strings

  // const markersString = "Markers";
  // const storeysString = "Storeys";
  // const typesIfcString = "IFC Types";
  const elementTypesString = "Types";
  const zonesString = nameMap["ZONES"];
  const phasesString = "Phases";
  // const measurementsString = "Elements";
  // const modelsString = "Models";
  // const scenesString = "Bimboxes";
  // const elementsString = "Elements";
  // const measurementsByDayString = "Elements built at";
  const elevationsString = "Secteurs";
  const issuesString = "Notes";
  // const clippingPlanesString = "Clipping planes";
  // const selectionS = "Sélection";
  const versionsString = "Versions";

  // data

  const viewMode = useSelector((s) => s.viewer3D.viewMode);

  // const models = useSelector((s) => s.viewer3D.models)
  //   .filter((m) => m.sceneClientId === scene?.clientId)
  //   .filter((m) => m.enabled);

  // const ifcModels = models.filter((m) => m.type === "IFC");
  // const sceneModule = useSceneModule();

  const elevations = useElevationsFromSceneSectors(scene);
  const elementTypes = useElementTypesBySceneProxy(scene);
  const zones = useZonesByScene(scene);
  const phases = usePhasesByScene(scene);
  // const measurements = useSceneMeasurements(scene);
  const versions = useVersionsByScene(scene);

  // const [filters] = useMeasurementsFiltersAndShortcuts(scene);

  // const selectedModelId = useSelector((s) => s.viewer3D.selectedModelId);

  // const mode = useMeasurementsScopeMode(scene, viewer);

  // const measurementsInScope = useSceneMeasurementsInScope(scene, mode);

  const issuesets = useIssuesetsByScene(scene);

  // helpers - count

  // const elementTypesCount = filters?.elementTypes?.length;
  // const builtAtsCount = filters?.builtAts?.length;
  // const modelsCount = models.filter(
  //   (m) => ["IFC", "MEASUREMENTS"].includes(m.type) && !m.hidden
  // ).length;

  // helpers

  // const selectedModel = models.find((m) => m.id === selectedModelId);

  const modePDF = sceneViewer === "PDF";
  // const mode3D =
  //   sceneViewer !== "PDF" && (viewMode === "3D" || sceneViewer === "3D");
  const mode3D = sceneViewer === "3D";
  const mode2D = viewMode === "MULTIVIEWS";

  // helpers

  // const hide3DShortcuts =
  //   os.viewer3D && os.fixedViewersBox && (mode3D);
  const hide3DShortcuts = false;

  // // helpers - show ifc filters

  // const showIfcShortcuts = ifcModels.length > 0 && sceneModule === "IFC";

  // // helpers - showMeasurementsShortcuts

  // const showMeasurementsShortcutsInPdf =
  //   !viewer &&
  //   modePDF &&
  //   measurements.length > 0 &&
  //   !((sceneModule === "HOME" || viewer) && selectedModel?.type === "PDF");

  // // helprs - showBuiltsAt

  // const showBuiltAts =
  //   measurementsInScope.filter((m) => m.builtAt).length > 0 &&
  //   !["INPUTS"].includes(sceneModule);

  // helpers - tool

  const tools = [
    // {
    //   code: "SCENES",
    //   icon: <Scenes />,
    //   label: scenesString,
    //   visible: mode3D && space,
    // },
    {
      code: "VERSIONS",
      icon: <Versions />,
      label: versionsString,
      visible: versions.length > 0,
      count: 1,
    },
    {
      code: "ELEVATIONS",
      icon: <Elevations />,
      label: elevationsString,
      visible: !modePDF && elevations.length > 0,
      count: 1,
    },
    {
      code: "PHASES",
      icon: <Phases />,
      label: phasesString,
      count: phases?.length,
      visible: phases.length > 0,
    },
    // {
    //   code: "SELECTION",
    //   icon: <SelectAll />,
    //   label: selectionS,
    //   visible: mode3D && !viewer,
    //   count: 1,
    // },
    {
      code: "ZONES",
      icon: <Zones />,
      label: zonesString,
      visible: zones?.length > 0 && (mode3D || mode2D),
      count: zones?.length,
    },
    // {
    //   code: "MODELS",
    //   icon: <Models />,
    //   count: modelsCount,
    //   label: modelsString,
    //   visible: false && (mode3D || mode2D) & (models.length > 0),
    // },
    {
      code: "ELEMENT_TYPES",
      icon: <ElementTypes />,
      count: 1,
      label: elementTypesString,
      visible: !modePDF && elementTypes.length > 0,
    },
    // {
    //   code: "MEASUREMENTS_BUILT_AT",
    //   icon: <CalendarToday />,
    //   count: builtAtsCount,
    //   label: measurementsByDayString,
    //   visible: !modePDF && measurements?.length > 0 && showBuiltAts,
    // },
    // {
    //   code: "STOREYS",
    //   icon: <Layers />,
    //   label: storeysString,
    //   visible: showIfcShortcuts,
    // },
    // {
    //   code: "TYPES",
    //   icon: <Types />,
    //   label: typesIfcString,
    //   visible: showIfcShortcuts,
    // },
    // {
    //   code: "CLIPPING_PLANES",
    //   icon: <Cut />,
    //   label: clippingPlanesString,
    // },
    // {
    //   code: "MARKERS",
    //   icon: <Markers />,
    //   label: markersString,
    //   visible: false && mode3D && !viewer,
    // },
    {
      code: "ZONES_IN_PDF",
      icon: <Zones />,
      label: zonesString,
      visible: zones?.length > 0 && modePDF,
      count: zones?.length,
    },
    // {
    //   code: "PDF_MEASUREMENTS",
    //   icon: <Measurements />,
    //   label: measurementsString,
    //   visible: false && showMeasurementsShortcutsInPdf,
    // },
    {
      code: "PDF_ELEMENT_TYPES",
      icon: <ElementTypes />,
      count: 1,
      label: elementTypesString,
      visible: modePDF,
    },
    // {
    //   code: "PDF_ELEMENTS",
    //   icon: <Elements sx={{fontSize: "12px"}} />,
    //   label: elementsString,
    // },
    // {
    //   code: "PDF_MARKERS",
    //   icon: <Markers />,
    //   label: markersString,
    // },
    // {
    //   code: "PDF_MEASUREMENTS_BUILT_AT",
    //   icon: <CalendarToday />,
    //   label: measurementsByDayString,
    //   count: builtAtsCount,
    //   visible: showMeasurementsShortcutsInPdf && showBuiltAts,
    // },
    {
      code: "ISSUES",
      icon: <Issues />,
      label: issuesString,
      hideLabel: true,
      count: 1,
      visible: (mode3D || mode2D) && issuesets.length > 0 && !viewer,
    },
  ];

  // helper - selected tool

  let selectedTool = tools.find((t) => t.code === tool);
  if (sceneViewer !== "PDF") selectedTool = toolViewer3D;

  // helper - offset

  let offset = 0;

  // handlers

  function handleButtonClick(code) {
    const wasSelected = code === tool;
    onChange(wasSelected ? null : code);
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: offset,
          alignItems: "center",
          "&>*:not(:last-child)": {
            mb: 1,
          },
        }}
      >
        {!modePDF && (
          <Box sx={{}}>
            <ButtonZoomOut
              editor3d={caplaEditor?.editor3d}
              location="shortcuts"
            />
          </Box>
        )}
        {(viewer || !hide3DShortcuts) &&
          tools
            .filter((t) => t.visible)
            .map(({code, icon, label, count}) => {
              const selected = code === tool || code === toolViewer3D;
              return (
                <ButtonTool
                  icon={icon}
                  label={label}
                  key={code}
                  selected={selected}
                  onClick={() => handleButtonClick(code)}
                  count={count}
                />
              );
            })}
        {modePDF && (
          <Box sx={{}}>
            <ButtonRefreshPdfInViewer editorPdf={caplaEditor?.editorPdf} />
          </Box>
        )}
      </Box>
      {/* {selectedTool && !selectedTool?.hideLabel && (
        <Typography variant="h6" sx={{mt: 2}}>
          <b>{selectedTool.label}</b>
        </Typography>
      )} */}
    </Box>
  );
}
