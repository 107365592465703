import {useState, useEffect} from "react";

import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  styled,
  InputBase,
  IconButton,
  Button,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import {lighten} from "@mui/material/styles";

import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";
import CheckboxConnectNewScene from "./CheckboxConnectNewScene";
import useCreateScene from "../useCreateScene";
import {useIsAuthenticated} from "@azure/msal-react";
import useProfile from "Features/profile/useProfile";

import ContainerLoadingBimbox from "Features/overviewer/components/ContainerLoadingBimbox";
import {ArrowBack} from "@mui/icons-material";
import SelectorBimboxModes from "Features/navigation/components/SelectorBimboxModes";

const Input = styled(InputBase)(({theme}) => ({
  fontSize: theme.typography.body1.fontSize,
  width: 300,
}));

export default function CreateSceneDialog({open, onClose}) {
  const {t} = useTranslation(["scenes", "common"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  // strings

  const titleString = t("scenes:create.dialog.title");
  const placeholderString = t("scenes:create.dialog.placeholder");
  //const createString = capitalizeStart(t("common:create"));
  const createString = "Créer la bimbox";
  const clientReferenceStr = "Réf. du dossier";

  // local state

  const [title, setTitle] = useState("");
  const [clientReference, setClientReference] = useState("");
  const [bimboxMode, setBimboxMode] = useState("BASIC");

  // data

  const connectSceneOnCreation = useSelector(
    (s) => s.scenes.connectSceneOnCreation
  );

  const createNewScene = useCreateScene();

  const {data: profile} = useProfile();

  // validation

  const validation = title.length > 0;

  // helpers

  let modeOptions = profile?.bimboxModes ?? ["BASIC", "STANDARD", "PRO"];
  if (!modeOptions.includes("BASIC")) modeOptions = ["BASIC", ...modeOptions];

  useEffect(() => {
    if (profile?.bimboxModes?.length > 0) {
      setBimboxMode(profile.bimboxModes[0]);
    }
  }, [profile?.organisationId]);

  // handlers

  function handleBimboxModeChange(modes) {
    if (modes.length > 0) {
      setBimboxMode(modes[0]);
    }
  }

  async function handleCreateClick() {
    //
    const options = {
      connectSceneOnCreation,
      openWhenCreated: true,
      elementTypes: [],
    };

    createNewScene({title, clientReference, bimboxMode}, options);
  }
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "common.caplaBlack",
          width: 1,
          height: 1,
        }}
      >
        <Paper
          sx={{
            position: "relative",
            width: 400,
            bgcolor: (theme) => lighten(theme.palette.common.caplaBlack, 0),
          }}
          elevation={6}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              color: "grey.700",
              zIndex: 10,
            }}
          >
            <IconButton onClick={() => onClose()} color="inherit">
              <ArrowBack />
            </IconButton>
          </Box>
          <ContainerLoadingBimbox />
          <SelectorBimboxModes
            selection={[bimboxMode]}
            options={modeOptions}
            onChange={handleBimboxModeChange}
          />
          <Box sx={{p: 3, color: "common.white"}}>
            <Input
              autoFocus
              placeholder={placeholderString}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{
                color: "common.white",
                fontSize: (theme) => theme.typography.h6.fontSize,
              }}
            />
          </Box>

          <Box
            sx={{
              width: 1,
              px: 4,
              visibility: validation ? "visible" : "hidden",
            }}
          >
            <Box
              sx={{
                width: 1,
                borderRadius: "4px",
                bgcolor: "primary.flash",
                display: "flex",
                alignItems: "center",
                color: "white",
              }}
            >
              <Button
                fullWidth
                color="inherit"
                disabled={!validation}
                onClick={handleCreateClick}
              >
                {createString}
              </Button>
            </Box>
            {isAuthenticated && (
              <Box sx={{color: "common.white"}}>
                <CheckboxConnectNewScene />
              </Box>
            )}
          </Box>

          {profile?.organisationId && (
            <Box sx={{display: "flex", alignItems: "center", mt: 2, p: 1}}>
              {profile?.logoUrl && (
                <Box sx={{mr: 1}}>
                  <Box sx={{height: "40px", borderRadius: "8px"}}>
                    <img
                      width="auto"
                      height="100%"
                      src={profile.logoUrl}
                      style={{borderRadius: "8px"}}
                    />
                  </Box>
                </Box>
              )}
              <Box>
                <TextField
                  size="small"
                  placeholder={clientReferenceStr}
                  value={clientReference}
                  onChange={(e) => setClientReference(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography sx={{color: "common.white"}}>#</Typography>
                      </InputAdornment>
                    ),
                    sx: {
                      fontSize: 13,
                      color: "common.white",
                    },
                  }}
                />
              </Box>
            </Box>
          )}
        </Paper>
      </Box>
    </Dialog>
  );
}
