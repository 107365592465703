import getParentsNums from "Utils/getParentsNums";

export default function getMaterialCategoriesWithParentsFromNum(
  materialCategoriesWithNum
) {
  const materialCategoriesByNum = materialCategoriesWithNum.reduce(
    (acc, materialCategory) => {
      acc[materialCategory.num] = materialCategory;
      return acc;
    },
    {}
  );

  const materialCategoriesWithParents = materialCategoriesWithNum.map(
    (materialCategory) => {
      const parentsNums = getParentsNums(materialCategory.num);
      let parents = parentsNums.map((num) => materialCategoriesByNum[num]);

      return {
        ...materialCategory,
        parents,
      };
    }
  );

  return materialCategoriesWithParents;
}
