import {useState, useEffect} from "react";

import {
  Box,
  Dialog,
  Typography,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";

import {uploadFileService} from "Features/files/services";
import useAccessToken from "Features/auth/useAccessToken";
import FieldPdfs from "./FieldPdfs";
import FieldPdfsContainerPdfs from "./FieldPdfsContainerPdfs";

export default function FieldPdfsDialogConfirmUpload({
  scene,
  open,
  onClose,
  onConfirm,
  pdfs,
}) {
  const accessToken = useAccessToken();
  const sceneId = scene?.id;

  // strings

  const title = "Ajout de PDFs";
  const description = "Voulez-vous vraiment ajouter ces PDFs ?";
  const cancelS = "Annuler";
  const confirmS = "Confirmer";

  // state

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) setLoading(false);
    return () => setLoading(false);
  }, [open]);

  // helpers - functions

  async function uploadPdf(pdf) {
    return await uploadFileService({
      accessToken,
      file: pdf,
      sceneId,
      container: "scene-files",
    });
  }
  // handlers

  function handleClose() {
    onClose();
  }

  async function handleConfirm() {
    setLoading(true);
    const pdfUrls = await Promise.all(
      pdfs.map(async (pdf) => await uploadPdf(pdf))
    );
    console.log("pdfUrls", pdfUrls);
    const newPdfs = pdfUrls.map((url, index) => ({
      url,
      name: pdfs[index].name,
      size: pdfs[index].size,
    }));
    setLoading(false);
    onConfirm(newPdfs);
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{description}</Typography>
        <Box sx={{p: 1}}>
          <FieldPdfsContainerPdfs pdfs={pdfs} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          {cancelS}
        </Button>
        <Button onClick={handleConfirm} disabled={loading}>
          {confirmS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
