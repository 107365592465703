import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import useNomenclatureByMaterialsGroupId from "../hooks/useNomenclatureByMaterialGroupId";
import useMaterialsByScene from "../hooks/useMaterialsByScene";
import useSelectedRoomInMaterials from "../hooks/useSelectedRoomInMaterials";
import useMaterialsGroupsWithNomenclatureAndMaterials from "../hooks/useMaterialsGroupsWithNomenclatureAndMaterials";

import {
  setEditedRoomMaterialsData,
  setIsCreatingRoomMaterial,
  setIsEditingRoomMaterials,
  setSelectedMaterialsGroupId,
  setSelectedRoomMaterialsClientIds,
} from "../materialsSlice";

import {Box, Typography} from "@mui/material";

import ListCategoriesAndMaterials from "./ListCategoriesAndMaterials";
import ListMaterials from "./ListMaterials";
import SectionSelectionPanelActionsRoomMaterials from "./SectionSelectionPanelActionsRoomMaterials";
import FieldMaterialsData from "Features/kvform/components/FieldMaterialsData";

import getCategoriesAndMaterialsItems from "../utils/getCategoriesAndMaterialsItems";
import getItemsMapByClientId from "Utils/getItemsMapByClientId";
import getRoomsAndMaterialsItems from "../utils/getRoomsAndMaterialsItems";
import fromMaterialsDataToClientIds from "../utils/fromMaterialsDataToClientIds";

import SearchBarObjectsV2 from "Features/search/components/SearchBarObjectsV2";

import getFoundCategoriesOrMaterials from "../utils/getFoundCategoriesOrMaterials";
import useRoomMaterialsDataInSelectionPanel from "../hooks/useRoomMaterialsDataInSelectionPanel";
import {setEditedRoom} from "Features/rooms/roomsSlice";
import SectionRoomsMaterialsOverview from "./SectionRoomsMaterialsOverview";

export default function SectionRoomMaterialsInSelectionPanel({scene}) {
  const dispatch = useDispatch();

  // strings

  const noMaterialS = "Aucun matériau";

  // data - room

  const isEditingRoomMaterials = useSelector(
    (s) => s.materials.isEditingRoomMaterials
  );

  const room = useSelectedRoomInMaterials();

  const materialsData = useRoomMaterialsDataInSelectionPanel();

  const selectedRoomMaterialsClientIds = useSelector(
    (s) => s.materials.selectedRoomMaterialsClientIds
  );

  // data - materials

  const selectedMaterialsGroupId = useSelector(
    (s) => s.materials.selectedMaterialsGroupId
  );
  const nomenclature = useNomenclatureByMaterialsGroupId(
    selectedMaterialsGroupId
  );
  const selectedMaterialCategoryId = useSelector(
    (s) => s.materials.selectedMaterialCategoryId
  );
  const materials = useMaterialsByScene(scene, {
    filterByMaterialsGroupId: selectedMaterialsGroupId,
    fetchData: true,
  });

  // data - field materials data

  const materialsGroupsWithNomenclatureAndMaterials =
    useMaterialsGroupsWithNomenclatureAndMaterials(scene, {
      filterByScope: true,
      filterByMaterialsGroupId: selectedMaterialsGroupId,
    });

  // state

  const [searchTextMaterials, setSearchTextMaterials] = useState("");

  // effect - init selectedMaterialsGroupId

  const groups = materialsGroupsWithNomenclatureAndMaterials.map(
    (g) => g.materialsGroup
  );
  useEffect(() => {
    if (!selectedMaterialsGroupId && groups?.length > 0) {
      dispatch(setSelectedMaterialsGroupId(groups[0].id));
    }
  }, [selectedMaterialsGroupId, groups?.length]);

  // helper - show overview

  const showOverview = !Boolean(room);

  // helpers - rooms and materials

  const materialsByClientId = getItemsMapByClientId(materials);
  const roomMaterialsClientIds = fromMaterialsDataToClientIds(
    room?.materialsData
  );
  const roomMaterials = roomMaterialsClientIds.map(
    (clientId) => materialsByClientId[clientId]
  );

  const noMaterials = !roomMaterialsClientIds?.length > 0;

  // helpers - categoriesAndMaterialsItems

  const categoriesAndMaterialsItems = getCategoriesAndMaterialsItems({
    nomenclature,
    materials,
    //categoriesWithMaterialsOnly: true,
  });

  // helpers - foundInAllMaterials

  const foundInAllMaterials = getFoundCategoriesOrMaterials(materials, {
    searchText: searchTextMaterials,
  });

  // handlers

  function handleAddMaterialClick() {
    dispatch(setIsEditingRoomMaterials(true));
  }

  function handleCreateMaterialClick() {
    dispatch(setIsCreatingRoomMaterial(true));
  }

  function handleRoomMaterialsDataChange(materialsData) {
    console.log("handleRoomMaterialsDataChange", materialsData);
    dispatch(setEditedRoomMaterialsData(materialsData));
  }

  function handleRoomMaterialClick(material) {
    console.log("material", material);
    const newClientIds = selectedRoomMaterialsClientIds.filter(
      (clientId) => clientId !== material.clientId
    );
    if (!selectedRoomMaterialsClientIds.includes(material.clientId)) {
      newClientIds.push(material.clientId);
    }
    dispatch(setSelectedRoomMaterialsClientIds(newClientIds));
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: 1,
        width: 1,
        minHeight: 0,
        bgcolor: "background.default",
      }}
    >
      {showOverview && <SectionRoomsMaterialsOverview />}

      {!isEditingRoomMaterials && !showOverview && (
        <Box
          sx={{width: 1, height: 1, display: "flex", flexDirection: "column"}}
        >
          <SectionSelectionPanelActionsRoomMaterials scene={scene} />
          <Box
            sx={{
              width: 1,
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            {!noMaterials && (
              <ListMaterials
                materials={roomMaterials}
                onAddMaterialClick={handleAddMaterialClick}
                onClick={handleRoomMaterialClick}
                selection={selectedRoomMaterialsClientIds}
              />
            )}
            {noMaterials && (
              <Box
                sx={{
                  display: "flex",
                  width: 1,
                  height: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  {noMaterialS}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}

      {isEditingRoomMaterials && !showOverview && (
        <Box
          sx={{width: 1, height: 1, display: "flex", flexDirection: "column"}}
        >
          <FieldMaterialsData
            value={materialsData}
            onChange={handleRoomMaterialsDataChange}
            materialsGroupsWithNomenclatureAndMaterials={
              materialsGroupsWithNomenclatureAndMaterials
            }
            onNewData={handleCreateMaterialClick}
          />
        </Box>
      )}
    </Box>
  );
}
