import {useState} from "react";

import {Box, Typography, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import ListMaterialsMultipleWithGroups from "./ListMaterialsMultipleWithGroups";

export default function SelectorMaterialFromTree({
  materialsGroupsWithNomenclatureAndMaterials,
  onClose,
  selectedMaterial,
  onSelect,
  scene,
  caplaEditor,
}) {
  //
  console.log(
    "materialsGroupsWithNomenclatureAndMaterials",
    materialsGroupsWithNomenclatureAndMaterials
  );
  //
  // string

  const title = "Sélectionnez un matériau";

  // state

  const [selectedMaterialsGroupId, setSelectedMaterialsGroupId] = useState(
    selectedMaterial?.materialsGroupId
  );

  // helpers - materials and groups

  const materialsGroups = (
    materialsGroupsWithNomenclatureAndMaterials ?? []
  ).map(({materialsGroup}) => materialsGroup);
  const nomenclatures = (materialsGroupsWithNomenclatureAndMaterials ?? []).map(
    ({nomenclature}) => nomenclature
  );
  const materials =
    (materialsGroupsWithNomenclatureAndMaterials ?? []).find(
      ({materialsGroup}) => materialsGroup.id === selectedMaterialsGroupId
    )?.materials || [];

  // helpers

  const selectedMaterialIds = selectedMaterial ? [selectedMaterial.id] : [];

  // handlers

  function handleClose() {
    onClose();
  }

  function handleSelectionChange(ids) {
    const id0 = ids?.length > 0 ? ids[0] : null;
    onSelect(id0);
  }

  function handleSelectedMaterialsGroupIdChange(id) {
    setSelectedMaterialsGroupId(id);
  }

  return (
    <Box sx={{width: 1, height: 1, display: "flex", flexDirection: "column"}}>
      <Box
        sx={{
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {title}
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <Close fontSize="small" />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          minHeight: 0,
        }}
      >
        <ListMaterialsMultipleWithGroups
          scene={scene}
          caplaEditor={caplaEditor}
          nomenclatures={nomenclatures}
          materials={materials}
          materialsGroups={materialsGroups}
          selectedMaterialIds={selectedMaterialIds}
          onSelectionChange={handleSelectionChange}
          forceSelection={true}
          selectedMaterialsGroupId={selectedMaterialsGroupId}
          onSelectedMaterialsGroupIdChange={
            handleSelectedMaterialsGroupIdChange
          }
          resetSelectionOnGroupChange={false}
          multipleSelection={false}
          variant="chips"
        />
      </Box>
    </Box>
  );
}
