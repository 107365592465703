import {useDispatch} from "react-redux";
import {deleteOrganisationNomenclature} from "../nomenclaturesSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useDeleteNomenclature() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const deleteNomenclature = async (nomenclature) => {
    const nomenclatureId = nomenclature.id;
    const organisationId = nomenclature.organisationId;
    dispatch(
      deleteOrganisationNomenclature({
        nomenclatureId,
        organisationId,
        accessToken,
      })
    );
  };

  return deleteNomenclature;
}
