import {useSelector, useDispatch} from "react-redux";

import {
  setSelectedMaterialCategoryId,
  setSelectedMaterialsClientIds,
} from "../materialsSlice";

import useMaterialsByScene from "../hooks/useMaterialsByScene";
import useNomenclatureByMaterialsGroupId from "../hooks/useNomenclatureByMaterialGroupId";

import {Box} from "@mui/material";

import ListMaterialsFromNomenclature from "./ListMaterialsFromNomenclature";
import ListCategoriesAndMaterials from "./ListCategoriesAndMaterials";

import getCategoriesAndMaterialsItems from "../utils/getCategoriesAndMaterialsItems";
import getFoundCategoriesOrMaterials from "../utils/getFoundCategoriesOrMaterials";

export default function SectionMaterialsByCategory({scene}) {
  const dispatch = useDispatch();

  // data

  const viewVariant = useSelector((s) => s.materials.materialsViewVariant);

  const selectedMaterialsGroupId = useSelector(
    (s) => s.materials.selectedMaterialsGroupId
  );
  const nomenclature = useNomenclatureByMaterialsGroupId(
    selectedMaterialsGroupId
  );
  const selectedMaterialCategoryId = useSelector(
    (s) => s.materials.selectedMaterialCategoryId
  );
  const selectedMaterialsClientIds = useSelector(
    (s) => s.materials.selectedMaterialsClientIds
  );
  const multipleSelection = useSelector(
    (s) => s.materials.multipleSelectionMaterials
  );

  const materials = useMaterialsByScene(scene, {
    filterByMaterialsGroupId: selectedMaterialsGroupId,
    fetchData: true,
  });

  const searchText = useSelector((s) => s.materials.searchTextMaterials);

  // helpers

  const filteredMaterials = materials;

  const categoriesAndMaterialsItems = getCategoriesAndMaterialsItems({
    nomenclature,
    materials,
  });

  const searchOptions = {
    searchText,
    withParents: true,
  };
  const foundCategoriesAndMaterialsItems = getFoundCategoriesOrMaterials(
    categoriesAndMaterialsItems,
    searchOptions
  );

  // handlers

  function handleSelectedMaterialCategoryIdChange(categoryId) {
    dispatch(setSelectedMaterialCategoryId(categoryId));
  }

  function handleNewMaterial(newMaterial) {
    console.log("newMaterial", newMaterial);
  }

  function handleMaterialClick(material) {
    console.log("click on material", material);

    // edge case 1
    if (material?.isCategory) {
      dispatch(setSelectedMaterialCategoryId(material.id));
    }

    // edge case 2 : no material
    if (!material && !multipleSelection) {
      dispatch(setSelectedMaterialsClientIds([]));
    }

    // main
    const clientId = material?.clientId;
    const idIsSelected = selectedMaterialsClientIds?.includes(clientId);
    let newIds = selectedMaterialsClientIds?.filter(
      (materialClientId) => materialClientId !== material?.clientId
    );
    if (multipleSelection) {
      if (!newIds) newIds = [];
      if (material?.isTitle) {
        return;
      } else {
        if (!selectedMaterialsClientIds?.includes(clientId))
          newIds.push(clientId);
      }
    } else {
      newIds = idIsSelected ? [] : [clientId];
    }
    dispatch(setSelectedMaterialsClientIds(newIds));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        height: 1,
        minHeight: 0,
      }}
    >
      <Box sx={{p: 1}}></Box>
      {viewVariant === "TREE_LIST" && (
        <ListMaterialsFromNomenclature
          nomenclature={nomenclature}
          selectedMaterialCategoryId={selectedMaterialCategoryId}
          onSelectedMaterialCategoryIdChange={
            handleSelectedMaterialCategoryIdChange
          }
          materials={filteredMaterials}
          onClick={handleMaterialClick}
          selectedMaterialsClientIds={selectedMaterialsClientIds}
          onNewMaterial={handleNewMaterial}
        />
      )}
      {viewVariant === "LIST" && (
        <ListCategoriesAndMaterials
          items={foundCategoriesAndMaterialsItems}
          //
          selectedMaterialCategoryId={selectedMaterialCategoryId}
          onSelectedMaterialCategoryIdChange={
            handleSelectedMaterialCategoryIdChange
          }
          //
          onMaterialClick={handleMaterialClick}
          selectedMaterialsClientIds={selectedMaterialsClientIds}
        />
      )}
    </Box>
  );
}
