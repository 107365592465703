import removeMaterialFromMaterialsData from "Features/materials/utils/removeMaterialFromMaterialData";
import addMaterialToMaterialsData from "Features/materials/utils/addMaterialToMaterialsData";

export default function getRoomsUpdatesFromMaterialWithRoomsUpdate(
  targetMaterial,
  originMaterial
) {
  const originRooms = originMaterial.rooms ?? [];
  const targetRooms = targetMaterial.rooms ?? [];

  const materialClientId = targetMaterial.clientId;
  const materialsGroupId = targetMaterial.materialsGroupId;

  const roomsToRemoveMaterial = originRooms.filter((room) => {
    return !targetRooms.find(
      (targetRoom) => targetRoom.clientId === room.clientId
    );
  });

  const roomsToAddMaterial = targetRooms.filter((room) => {
    return !originRooms.find(
      (originRoom) => originRoom.clientId === room.clientId
    );
  });

  const roomsUpdates = [];

  roomsToRemoveMaterial.forEach((room) => {
    const roomUpdate = {
      id: room.id,
      roomsGroupId: room.roomsGroupId,
      materialsData: removeMaterialFromMaterialsData(
        materialClientId,
        materialsGroupId,
        room.materialsData
      ),
    };
    roomsUpdates.push(roomUpdate);
  });

  roomsToAddMaterial.forEach((room) => {
    const roomUpdate = {
      id: room.id,
      roomsGroupId: room.roomsGroupId,
      materialsData: addMaterialToMaterialsData(
        materialClientId,
        materialsGroupId,
        room.materialsData
      ),
    };
    roomsUpdates.push(roomUpdate);
  });

  return roomsUpdates;
}
