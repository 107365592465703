import {List, ListItemButton, Box, Typography} from "@mui/material";
import ListItemNewMaterial from "./ListItemNewMaterial";
import SearchBarObjectsV2 from "Features/search/components/SearchBarObjectsV2";

import getRoomsCountFromRoomsData from "../utils/getRoomsCountFromRoomsData";
import getItemRankFromNum from "Utils/getItemRankFromNum";
import getCategoryOrMaterialItemStyle from "../utils/getCategoryOrMaterialItemStyle";
import getArraysIntersection from "Utils/getArraysIntersection";

export default function ListMaterialCategories({
  materialCategories,
  selection,
  onClick,
}) {
  // strings

  // helpers

  // handlers

  function handleClick(e, category) {
    e.stopPropagation();
    console.log("click on category", category);
    onClick(category);
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        overflow: "auto",
      }}
    >
      <List sx={{width: 1}} dense disablePadding>
        {materialCategories.map((category) => {
          //const selected = selection?.includes(material?.clientId);
          //const roomsCount = getRoomsCountFromRoomsData(material?.roomsData);
          //const noRooms = !roomsCount;
          // const roomsCountLabel = `${roomsCount} pièce${
          //   roomsCount > 1 ? "s" : ""
          // }`;
          const rank = getItemRankFromNum(category);
          let {bgcolor, color, fontWeight} =
            getCategoryOrMaterialItemStyle(category);
          const selected = selection?.includes(category.id);
          return (
            <ListItemButton
              disableGutters
              divider
              key={category.id}
              selected={selected}
              onClick={(e) => handleClick(e, category)}
              sx={{bgcolor, color, p: 0}}
            >
              <Box sx={{px: 1, py: 0.5, width: 1}}>
                <Box sx={{pl: `${rank * 16}px`}}>
                  <Typography sx={{fontSize: 12, fontWeight}}>
                    {category?.name}
                  </Typography>
                </Box>

                {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{fontSize: 12, color: "text.secondary"}}>
                  {material?.code}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 12,
                    color: noRooms ? "error.main" : "text.secondary",
                  }}
                >
                  {noRooms ? noRoomsS : roomsCountLabel}
                </Typography>
              </Box> */}
              </Box>
            </ListItemButton>
          );
        })}
        {/* {onNewMaterial && (
        <ListItemNewMaterial
          onNewMaterial={onNewMaterial}
          variant="selectionPanel"
        />
      )} */}
      </List>
    </Box>
  );
}
