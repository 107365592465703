import {List, ListItemButton, Typography} from "@mui/material";

export default function ListNomenclatures({
  nomenclatures,
  onClick,
  selectedNomenclatureId,
}) {
  // handlers

  function handleClick(nomenclature) {
    console.log("nomenclature", nomenclature);
    onClick(nomenclature);
  }

  return (
    <List dense sx={{width: 1}}>
      {nomenclatures.map((nomenclature) => {
        const selected = selectedNomenclatureId === nomenclature.id;
        return (
          <ListItemButton
            selected={selected}
            key={nomenclature.id}
            onClick={() => handleClick(nomenclature)}
          >
            <Typography>{nomenclature.name}</Typography>
          </ListItemButton>
        );
      })}
    </List>
  );
}
