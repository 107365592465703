import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import useMaterialsGroupsByScene from "../hooks/useMaterialsGroupsByScene";
import useSelectedMaterialsGroup from "../hooks/useSelectedMaterialsGroup";

import {
  setIsSelectingMaterialsGroup,
  setSelectedMaterialsClientIds,
  setSelectedMaterialsGroupId,
} from "../materialsSlice";

import {Box, Typography} from "@mui/material";
import {ArrowDropDown as Down, Close} from "@mui/icons-material";

import SelectorMaterialsGroup from "./SelectorMaterialsGroup";
import SelectorGenericItemWithChips from "Components/SelectorGenericItemWithChips";

export default function SectionSelectorMaterialsGroup({
  scene,
  variant,
  forceSelection,
}) {
  const dispatch = useDispatch();

  // strings

  const groupS = "Liste de matériaux";
  const groupsS = "Listes";

  // options

  const resetSelectionOnGroupChange = true;

  // data

  const materialsGroups = useMaterialsGroupsByScene(scene, {
    filterByScope: true,
    sortByName: true,
  });
  const selectedMaterialsGroup = useSelectedMaterialsGroup();

  const isSelectingMaterialsGroup = useSelector(
    (s) => s.materials.isSelectingMaterialsGroup
  );

  // effect

  useEffect(() => {
    if (
      forceSelection &&
      !selectedMaterialsGroup?.id &&
      materialsGroups.length > 0
    ) {
      const id0 = materialsGroups[0].id;
      dispatch(setSelectedMaterialsGroupId(id0));
    }
  }, [selectedMaterialsGroup?.id, materialsGroups.length]);

  // handlers

  function handleGroupClick(group, options) {
    //
    if (group?.id && selectedMaterialsGroup?.id !== group?.id) {
      // used to avoid to have one hidden selection...
      if (resetSelectionOnGroupChange)
        dispatch(setSelectedMaterialsClientIds([]));
      dispatch(setSelectedMaterialsGroupId(group.id));
    }
    //
    dispatch(setIsSelectingMaterialsGroup(false));
  }

  function handleToggleSelectGroup() {
    dispatch(setIsSelectingMaterialsGroup(!isSelectingMaterialsGroup));
  }

  return (
    <>
      <Box sx={{py: 2}}>
        {variant === "chips" && (
          <SelectorGenericItemWithChips
            items={materialsGroups}
            selectedItem={selectedMaterialsGroup}
            onClick={handleGroupClick}
          />
        )}
        {variant === "list" && (
          <Box>
            <Typography
              sx={{
                fontSize: 13,
                color: "secondary.main",
                p: 0.5,
                px: 1,
                visibility: isSelectingMaterialsGroup ? "hidden" : "visible",
              }}
            >
              {groupS}
            </Typography>
            <Box
              sx={{
                p: 1,
                bgcolor: isSelectingMaterialsGroup
                  ? "common.white"
                  : "secondary.main",
              }}
            >
              <Box
                onClick={handleToggleSelectGroup}
                sx={{
                  px: 1,
                  py: 0.5,
                  bgcolor: "common.white",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  color: "text.secondary",
                }}
              >
                <Typography sx={{fontSize: 13}}>
                  {!isSelectingMaterialsGroup
                    ? selectedMaterialsGroup?.name
                    : groupsS}
                </Typography>
                {!isSelectingMaterialsGroup ? (
                  <Down />
                ) : (
                  <Close color="inherit" />
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {isSelectingMaterialsGroup && variant === "list" && (
        <Box
          sx={{
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
          }}
        >
          <SelectorMaterialsGroup
            items={materialsGroups}
            selectedItem={selectedMaterialsGroup}
            onClick={handleGroupClick}
          />
        </Box>
      )}
    </>
  );
}
