export default function getBlueprintBbox(
  domElement,
  ratio,
  padding,
  stroke = 1
) {
  if (!domElement) {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
  }

  const {x, y, width, height} = domElement.getBoundingClientRect();

  console.log("container bbox", x, y, width.toFixed(0), height.toFixed(0));

  const elementRatio = width / height;
  let newWidth, newHeight;

  if (elementRatio < ratio) {
    newWidth = width - 2 * padding;
    newHeight = newWidth / ratio;
  } else {
    newHeight = height - 2 * padding;
    newWidth = newHeight * ratio;
  }

  const newX = x + (width - newWidth) / 2;
  const newY = y + (height - newHeight) / 2;

  return {
    x: newX,
    y: newY,
    width: newWidth - 2 * stroke,
    height: newHeight - 2 * stroke,
  };
}
