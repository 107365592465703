import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import PdfWebViewer from "./PdfWebViewer";
import PdfWebViewerModeEditor from "./PdfWebViewerModeEditor";

import SectionLoadingPdf from "./SectionLoadingPdf";
import SectionNoInputAddInput from "./SectionNoInputAddInput";
import ScreenHelperPdfViewer from "./ScreenHelperPdfViewer";
// import usePdfModelCandidatesInViewer from "../usePdfModelCandidatesInViewer";
import useSelectedPdfModel from "../useSelectedPdfModel";
import usePdfModelUrl from "../usePdfModelUrl";

// import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";
import SectionScreenshotViewer from "Features/viewer3D/components/SectionScreenshotViewer";
import useInputsByScene from "Features/inputs/hooks/useInputsByScene";

export default function SectionPdfViewer({scene, viewer, caplaEditor, hidden}) {
  // const dispatch = useDispatch();

  // init

  const selectedPdfModel = useSelectedPdfModel();

  const url = usePdfModelUrl(selectedPdfModel, viewer);

  console.log("[DEBUG42]", selectedPdfModel);

  // const pdfModelIdLoaded = useSelector((s) => s.pdf.pdfModelIdLoaded);
  const showPdfSelector = useSelector((s) => s.pdf.showPdfSelector);

  // data

  const mainSection = useSelector((s) => s.viewer3D.mainSection);
  // const viewMode = useSelector((s) => s.viewer3D.viewMode); // viewMode : "PDF", "3D"... not uptodate.
  const inputs = useInputsByScene(scene);

  const os = useSelector((s) => s.ui.openSections);

  const showHelper = useSelector((s) => s.pdf.showScreenPdfViewerHelper);

  // const [pdfModelCandidatesInViewer, pdfModelCandidatesInViewerHash] =
  //   usePdfModelCandidatesInViewer(scene);

  const screenshot = useSelector((s) => s.viewer3D.screenshot);

  // state

  const initModel = selectedPdfModel;
  const [model, setModel] = useState(initModel); // used to fix bug : selectedModel.url change from undefined <> id, causing infinite loop in pdfviewer loader component.

  // helpers

  const noPdf = !selectedPdfModel?.id;
  const noPdfInputs =
    inputs.filter((model) => model.type === "PDF").length === 0;

  // helpers

  // const pdfIsLoading =
  //   !pdfModelIdLoaded || pdfModelIdLoaded !== selectedPdfModel?.id;

  // helper screenshot

  const screenshotFromPDF = screenshot?.options?.fromViewMode === "PDF";

  // helper display file selector

  const displayFileSelector =
    !showHelper &&
    !noPdfInputs &&
    showPdfSelector &&
    !selectedPdfModel &&
    os.fixedViewersBox;

  // effects

  useEffect(() => {
    if (initModel && initModel.id !== model?.id && initModel.url) {
      setModel(initModel);
    }
  }, [initModel?.id, initModel?.url]);

  // // effect - pdf in viewer
  // useEffect(() => {
  //   if (viewer && !selectedPdfModel?.id) {
  //     if (!pdfModelCandidatesInViewer.includes(model?.id)) {
  //       const c0 = pdfModelCandidatesInViewer[0];
  //       if (c0) {
  //         dispatch(setSelectedPdfModelId(c0));
  //       }
  //     }
  //   }
  // }, [
  //   viewer,
  //   scene?.id,
  //   pdfModelCandidatesInViewerHash,
  //   model?.id,
  //   selectedPdfModel?.id,
  // ]);

  return (
    <Box
      sx={{
        width: hidden ? 0 : 1,
        height: 1,
        position: "relative",
        borderRight: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      {showHelper && viewer && <ScreenHelperPdfViewer />}
      <Box
        sx={{
          width: 1,
          height: 1,
          boxSizing: "border-box",
          position: "relative",
        }}
      >
        <PdfWebViewerModeEditor
          model={model}
          caplaEditor={caplaEditor}
          isPDF={
            selectedPdfModel?.type === "PDF" ||
            selectedPdfModel?.type === "ANNOTATED_PDF" ||
            selectedPdfModel?.type === "TEMP_PDF"
          }
          scene={scene}
          viewer={viewer}
        />
      </Box>

      {noPdfInputs && os.fixedViewersBox && (
        <Box
          sx={{
            boxSizing: "border-box",
            //border: "2px solid black",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            bgcolor: "background.default",
            zIndex: 30,
          }}
        >
          <SectionNoInputAddInput editor3d={caplaEditor?.editor3d} />
        </Box>
      )}

      {/* {false && pdfIsLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            bgcolor: (theme) => alpha(theme.palette.common.caplaBlack, 0.9),
            zIndex: 70,
            p: 6,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "common.white",
          }}
        >
          <LinearProgress color="inherit" sx={{width: 1}} />
        </Box>
      )} */}

      {!url && os.fixedViewersBox && !noPdf && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            bgcolor: "common.white",
            zIndex: 60,
          }}
        >
          <SectionLoadingPdf pdfModel={selectedPdfModel} />
        </Box>
      )}

      {mainSection && screenshotFromPDF && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            bgcolor: "common.white",
            zIndex: 60,
          }}
        >
          {mainSection === "SCREENSHOTVIEWER" &&
            os.fixedViewersBox && ( // to avoid clash with ifc screenshot
              <SectionScreenshotViewer
                scene={scene}
                editor3d={caplaEditor?.editor3d}
                viewer={viewer}
              />
            )}
        </Box>
      )}
    </Box>
  );
}
