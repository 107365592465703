// used to compute annotation Rect props from quads

export default function getBboxFromRects(rects) {
  // edge case
  if (!rects || rects.length === 0) return {x: 0, y: 0, width: 0, height: 0};

  //
  const minX1 = Math.min(...rects.map((rect) => rect.x1));
  const minY1 = Math.min(...rects.map((rect) => rect.y1));
  const maxX2 = Math.max(...rects.map((rect) => rect.x2));
  const maxY2 = Math.max(...rects.map((rect) => rect.y2));
  //
  return {
    x: minX1,
    y: minY1,
    width: maxX2 - minX1,
    height: maxY2 - minY1,
  };
}
