import {useEffect} from "react";
import {useSelector} from "react-redux";

import useRoomLabels from "Features/rooms/hooks/useRoomLabels";

export default function useAutoUpdateRoomLabelsPdfAnnotations(caplaEditor) {
  const roomLabelsUpdatedAt = useSelector((s) => s.rooms.roomLabelsUpdatedAt);
  const pdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);

  const roomLabels = useRoomLabels({pdfModelId});

  const roomLabelsManager = caplaEditor?.editorPdf?.roomLabelsManager;

  console.log("roomLabels", roomLabels);

  useEffect(() => {
    if (roomLabelsManager) {
      roomLabelsManager.initRoomLabelsAnnotations(roomLabels);
    }
  }, [roomLabelsManager, pdfModelId, roomLabelsUpdatedAt]);
}
