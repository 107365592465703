import {useDispatch, useSelector} from "react-redux";

import {Add, ContentCopy as Duplicate, Delete} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";

export default function useActionsNomenclatureMap({scene, caplaEditor}) {
  const accessToken = useAccessToken();

  // data

  const selectedNomenclatureId = useSelector(
    (s) => s.nomenclatures.selectedNomenclatureId
  );

  // handlers

  async function handleDebug() {}
  // actions map

  const actionsMap = {
    deleteNomenclature: {
      icon: <Delete fontSize="small" />,
      name: "Supprimer",
      label: "Supprimer la nomenclature",
      disabled: !selectedNomenclatureId,
    },
    debug: {
      name: "Debug",
      label: "Debug",
      handler: handleDebug,
    },
  };

  return actionsMap;
}
