import {useRef, useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";

import useMaterialsGroupsByScene from "./useMaterialsGroupsByScene";

import {fetchMaterials} from "../materialsSlice";

import useAccessToken from "Features/auth/useAccessToken";
import getMaterialCode from "../utils/getMaterialCode";

export default function useMaterialsGroupsWithNomenclatureAndMaterials(
  scene,
  options
) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const syncingMaterialsByGroupIdRef = useRef({});

  //

  const filterByScope = options?.filterByScope;
  const filterByMaterialsGroupId = options?.filterByMaterialsGroupId;
  const fetchMaterialsOption = options?.fetchMaterials;

  //

  const materialsGroupsWithNomenclatureAndMaterials = [];

  //

  const allNomenclatures = useSelector((s) => s.materials.nomenclatures);

  //
  const materialsGroups = useMaterialsGroupsByScene(scene, {
    filterByScope,
    sortByName: true,
    filterByMaterialsGroupId,
  });

  //

  const fetchedMaterialsByGroupId = useSelector(
    (s) => s.materials.fetchedMaterialsByGroupId
  );
  //
  const materialsGroupsHash = materialsGroups.reduce((hash, group) => {
    hash = hash + group.id;
    return hash;
  }, "");

  // init - fetch materials

  useEffect(() => {
    materialsGroups.forEach((group) => {
      const materialsWereFetched = fetchedMaterialsByGroupId[group.id];
      const isSyncingMaterials = syncingMaterialsByGroupIdRef.current[group.id];
      if (
        accessToken &&
        fetchMaterialsOption &&
        !materialsWereFetched &&
        !isSyncingMaterials
      ) {
        syncingMaterialsByGroupIdRef.current[group.id] = true;
        dispatch(fetchMaterials({materialsGroupId: group.id, accessToken}));
      }
    });
  }, [materialsGroupsHash, accessToken, fetchMaterials]);

  //
  const materialsMap = useSelector((s) => s.materials.materialsMap);
  //
  let materials = materialsMap ? Object.values(materialsMap) : [];
  //
  materialsGroups.forEach((group) => {
    let groupMaterials = materials.filter(
      (r) => r.materialsGroupId === group.id
    );
    groupMaterials = groupMaterials.map((material) => ({
      ...material,
      code: getMaterialCode(material, groupMaterials),
    }));
    //
    // if (materialsWithNum || materialsWithParents) {
    //   groupMaterials = getMaterialsWithNum(group, groupMaterials);
    // }
    // //
    // if (materialsWithParents) {
    //   groupMaterials = getMaterialsWithParentsFromNum(groupMaterials);
    // }
    //
    const groupWithMaterials = {
      materialsGroup: group,
      materials: groupMaterials,
      nomenclature: allNomenclatures.find(
        (n) => n.id === group.data?.materialsNomenclatureId
      ),
    };
    materialsGroupsWithNomenclatureAndMaterials.push(groupWithMaterials);
  });
  //
  return materialsGroupsWithNomenclatureAndMaterials;
}
