import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  setIsEditingRoom,
  setEditedRoom,
  setEditedRoomParentChange,
} from "../roomsSlice";
import {updateRoom, updateRoomsGroup} from "../roomsSlice";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import useSelectedRoomsGroup from "../hooks/useSelectedRoomsGroup";

export default function ActionsRoomInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const isEditing = useSelector((s) => s.rooms.isEditingRoom);
  const editedRoom = useSelector((s) => s.rooms.editedRoom);
  const editedRoomParentChange = useSelector(
    (s) => s.rooms.editedRoomParentChange
  );
  const roomsMap = useSelector((s) => s.rooms.roomsMap);
  const selectedRoomsGroup = useSelectedRoomsGroup();

  // state

  const [loading, setLoading] = useState(false);

  // handlers

  function handleCancelClick() {
    dispatch(setIsEditingRoom(false));
  }

  async function handleSaveClick() {
    setLoading(true);
    // parent change
    if (editedRoomParentChange) {
      const {targetParentRoomId, originParentRoomId, roomClientId} =
        editedRoomParentChange;

      let originIsGroup = !Boolean(originParentRoomId);
      let targetIsGroup = !Boolean(targetParentRoomId);

      const origin = originIsGroup
        ? selectedRoomsGroup
        : roomsMap[originParentRoomId];
      const target = targetIsGroup
        ? selectedRoomsGroup
        : roomsMap[targetParentRoomId];

      console.log("origin and target", origin, target);

      const originChildrenClientIds = (origin?.childrenClientIds ?? []).filter(
        (id) => id !== roomClientId
      );
      const targetChildrenClientIds = [
        ...(target?.childrenClientIds ?? []),
        roomClientId,
      ];

      const originUpdates = {
        id: origin?.id,
        childrenClientIds: originChildrenClientIds,
        sceneId: scene?.id,
        roomsGroupId: selectedRoomsGroup.id,
      };

      const targetUpdates = {
        id: target?.id,
        childrenClientIds: targetChildrenClientIds,
        sceneId: scene?.id,
        roomsGroupId: selectedRoomsGroup.id,
      };

      //

      if (originIsGroup) {
        await dispatch(updateRoomsGroup({accessToken, updates: originUpdates}));
      } else {
        await dispatch(updateRoom({accessToken, updates: originUpdates}));
      }

      if (targetIsGroup) {
        await dispatch(updateRoomsGroup({accessToken, updates: targetUpdates}));
      } else {
        await dispatch(updateRoom({accessToken, updates: targetUpdates}));
      }

      //
      dispatch(setEditedRoomParentChange(null));
    }
    // room
    const updatedRoom = {...editedRoom};
    await dispatch(updateRoom({accessToken, updates: updatedRoom}));
    setLoading(false);
    dispatch(setIsEditingRoom(false));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            disabled={loading}
            variant="outlined"
            fullWidth
            onClick={handleCancelClick}
          >
            {cancelS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            disabled={loading}
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
