import {useRef, useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";

import {fetchFormTemplates} from "../formsSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useFormTemplatesByScene(scene, options) {
  const syncingMapRef = useRef({});
  const dispatch = useDispatch();
  const organisationId = scene?.organisationId;
  const accessToken = useAccessToken();

  const fetchData = options?.fetchData;

  const fetchedFormTemplatesByOrganisationId = useSelector(
    (s) => s.forms.fetchedFormTemplatesByOrganisationId
  );

  useEffect(() => {
    if (
      accessToken &&
      fetchData &&
      organisationId &&
      !fetchedFormTemplatesByOrganisationId[organisationId]
    ) {
      if (!syncingMapRef.current[organisationId]) {
        syncingMapRef.current[organisationId] = true;
        dispatch(fetchFormTemplates({organisationId, accessToken}));
      }
    }
  }, [organisationId, accessToken]);

  const formTemplatesMap = useSelector((state) => state.forms.formTemplatesMap);

  const formTemplates = Object.values(formTemplatesMap).filter(
    (formTemplate) => formTemplate.organisationId === organisationId
  );

  return formTemplates;
}
