import {useSelector, useDispatch} from "react-redux";

import {Box, Paper, Typography, IconButton} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import IconModuleInLeftPanel from "Features/ui/components/IconModuleInLeftPanel";
import useSelectedReport from "../hooks/useSelectedReport";

export default function HeaderInLeftPanelReport({scene}) {
  const dispatch = useDispatch();

  // data

  const selectedReport = useSelectedReport();

  // handlers

  function handleClick() {}

  return (
    <Box sx={{p: 1}}>
      <Paper
        elevation={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: (theme) => theme.spacing(5),
          px: 1,
        }}
      >
        <IconModuleInLeftPanel scene={scene} />
        <Typography>{selectedReport?.name}</Typography>
        <Box
          sx={{display: "flex", alignItems: "center", justifyContent: "center"}}
        >
          <IconButton onClick={handleClick}>
            <Down />
          </IconButton>
        </Box>
      </Paper>
    </Box>
  );
}
