import {useDispatch, useSelector} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {createRoom, updateRoom, updateRoomsGroup} from "../roomsSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useCreateRooms() {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const roomsMap = useSelector((s) => s.rooms.roomsMap);
  const roomsGroupsMap = useSelector((s) => s.rooms.roomsGroupsMap);

  const create = async ({rooms, parentId}) => {
    console.log("useCreateRooms", rooms, parentId);
    // edge case

    if (!rooms) return;

    // helpers

    const roomsClientIds = rooms.map((room) => room.clientId);
    const roomsGroupId = rooms[0].roomsGroupId;

    //
    if (parentId) {
      const parentRoom = roomsMap[parentId];
      let childrenClientIds = [...(parentRoom.childrenClientIds || [])];
      childrenClientIds.push(...roomsClientIds);

      const parentUpdates = {
        id: parentId,
        roomsGroupId: parentRoom.roomsGroupId,
        childrenClientIds,
      };
      await dispatch(updateRoom({accessToken, updates: parentUpdates}));
    } else {
      const roomsGroup = roomsGroupsMap[roomsGroupId];
      const childrenClientIds = [...(roomsGroup.childrenClientIds || [])];
      childrenClientIds.push(...roomsClientIds);

      const groupUpdates = {
        id: roomsGroupId,
        sceneId: roomsGroup.sceneId,
        childrenClientIds,
      };
      await dispatch(updateRoomsGroup({accessToken, updates: groupUpdates}));
    }
    //
    const result = await Promise.all(
      rooms.map(async (room) => await dispatch(createRoom({accessToken, room})))
    );
    const items = result.map((r) => unwrapResult(r).item);
    return items;
  };

  return create;
}
