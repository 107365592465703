import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import SectionFormTemplateInOrganisationSelectionPanel from "Features/forms/components/SectionFormTemplateInOrganisationSelectionPanel";
import SectionReportTemplateInOrganisationSelectionPanel from "Features/reports/components/SectionReportTemplateInOrganisationSelectionPanel";
import SectionNomenclatureInOrganisationSelectionPanel from "Features/nomenclatures/components/SectionNomenclatureInOrganisationSelectionPanel";

export default function PageOrganisationSectionSelection({organisation}) {
  // data

  const entities = useSelector((state) => state.organisations.selectedEntities);

  //
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
        minHeight: 0,
      }}
    >
      {entities === "FORM_TEMPLATES" && (
        <SectionFormTemplateInOrganisationSelectionPanel
          organisation={organisation}
        />
      )}
      {entities === "REPORT_TEMPLATES" && (
        <SectionReportTemplateInOrganisationSelectionPanel
          organisation={organisation}
        />
      )}
      {entities === "NOMENCLATURES" && (
        <SectionNomenclatureInOrganisationSelectionPanel
          organisation={organisation}
        />
      )}
    </Box>
  );
}
