import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {nanoid, unwrapResult} from "@reduxjs/toolkit";

import {
  setIsEditingBlock,
  setSelectedBlockId,
  createBlock,
  updateBlock,
} from "../blocksSlice";

import useBlockInSelectionPanel from "../hooks/useBlockInSelectionPanel";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import {setSelectedFixedViewer} from "Features/ui/uiSlice";

export default function ActionsBlockInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const selectedReportId = useSelector((s) => s.reports.selectedReportId);

  // strings

  const createS = "Créer";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const isEditing = useSelector((s) => s.blocks.isEditingBlock);
  const block = useBlockInSelectionPanel();

  // state

  const [loading, setLoading] = useState(false);

  // effects

  useEffect(() => {
    return () => setLoading(false);
  }, []);

  // helper

  const isNew = !block?.id;

  // helper - saveLabel

  const saveLabel = isNew ? createS : saveS;

  // helpers - functions

  async function create() {
    if (loading) return;
    try {
      setLoading(true);
      const newBlock = {
        ...block,
        reportId: selectedReportId,
      };
      console.log("create block", newBlock);
      const result = await dispatch(
        createBlock({
          accessToken,
          block: newBlock,
        })
      );
      const {item} = unwrapResult(result);
      dispatch(setSelectedBlockId(item?.id));
      setLoading(false);
    } catch (error) {
      console.error("[ActionsBlockInSelectionPanel] error", error);
      setLoading(false);
    }
  }

  async function update() {
    if (loading) return;
    setLoading(true);
    console.log("update block", block);
    const updates = {...block};
    await dispatch(updateBlock({accessToken, updates}));
    dispatch(setIsEditingBlock(false));
    setLoading(false);

    // restore ui
    dispatch(setSelectedFixedViewer("REPORT"));
  }

  // handlers

  function handleCancelClick() {
    dispatch(setIsEditingBlock(false));
  }

  async function handleSaveClick() {
    if (isNew) {
      await create();
    } else {
      await update();
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {(isEditing || isNew) && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
}
