import {useDispatch, useSelector} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {createRoom, updateRoom, updateRoomsGroup} from "../roomsSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useCreateRoom() {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();
  const roomsMap = useSelector((s) => s.rooms.roomsMap);
  const roomsGroupsMap = useSelector((s) => s.rooms.roomsGroupsMap);

  const create = async ({room, parentId, index}) => {
    // index : 1 = 1st position
    //
    if (parentId) {
      const parentRoom = roomsMap[parentId];
      let childrenClientIds = [...(parentRoom.childrenClientIds || [])];
      if (index) {
        childrenClientIds.splice(index - 1, 0, room.clientId);
      } else {
        childrenClientIds.push(room.clientId);
      }

      const updates = {
        id: parentId,
        roomsGroupId: parentRoom.roomsGroupId,
        childrenClientIds,
      };
      await dispatch(updateRoom({accessToken, updates}));
    } else {
      const roomsGroup = roomsGroupsMap[room.roomsGroupId];
      const childrenClientIds = [...(roomsGroup.childrenClientIds || [])];
      if (index) {
        childrenClientIds.splice(index - 1, 0, room.clientId);
      } else {
        childrenClientIds.push(room.clientId);
      }
      const updates = {
        id: room.roomsGroupId,
        sceneId: roomsGroup.sceneId,
        childrenClientIds,
      };
      await dispatch(updateRoomsGroup({accessToken, updates}));
    }
    //
    const result = await dispatch(createRoom({accessToken, room}));
    const {item} = unwrapResult(result);
    return item;
  };

  return create;
}
