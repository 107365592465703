import {Box, Typography} from "@mui/material";

export default function GridZones({zones, selectedZoneId, onClick}) {
  // state

  const width = 240;
  const height = (width * 3) / 4;

  return (
    <Box
      sx={{
        width: 1,
        //height: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {zones.map((zone) => {
        return (
          <Box
            key={zone.id}
            sx={{
              p: 2,
              minWidth: 0,
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
            onClick={() => onClick(zone)}
          >
            <Box
              sx={{
                width,
                height,
                bgcolor: "common.white",
                ":hover": {
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                },
              }}
            >
              <img
                src={zone.imageUrl}
                style={{width: "100%", height: "100%", objectFit: "cover"}}
              />
            </Box>
            <Box sx={{p: 1}}>
              <Typography variant="body2" color="text.secondary">
                {zone.name}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
