import useKvtReportTemplate from "Features/kvform/hooks/useKvtReportTemplate";

import {Box} from "@mui/material";

import KeyValueForm from "Features/kvform/components/KeyValueForm";

import getItemsMapById from "Utils/getItemsMapById";

export default function FormReportTemplate({
  reportTemplate,
  onChange,
  formTemplates,
}) {
  const template = useKvtReportTemplate({formTemplates});

  // helpers

  const formTemplatesById = getItemsMapById(formTemplates);

  // item

  const item = {...reportTemplate};
  // main_form_template
  item.mainFormTemplate = formTemplatesById[reportTemplate?.mainFormTemplateId];
  delete item.mainFormTemplateId;

  // handlers

  function handleItemChange(changedItem) {
    const changedReportTemplate = {...reportTemplate, ...changedItem};
    // main_form_template
    changedReportTemplate.mainFormTemplateId = changedItem.mainFormTemplate?.id;
    delete changedReportTemplate.mainFormTemplate;
    //
    onChange(changedReportTemplate);
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <KeyValueForm
        template={template}
        item={item}
        onChange={handleItemChange}
      />
    </Box>
  );
}
