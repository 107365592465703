import {useState} from "react";
import {Box} from "@mui/material";

import DeleteDialog from "Features/ui/components/DeleteDialog";
import useSelectedBlock from "../hooks/useSelectedBlock";
import useDeleteBlock from "../hooks/useDeleteBlock";

export default function DialogDeleteBlock({scene, open, onClose}) {
  // state

  const [loading, setLoading] = useState(false);

  // data

  const selectedBlock = useSelectedBlock();
  const deleteBlock = useDeleteBlock();

  // handler

  function handleClose() {
    onClose();
    setLoading(false);
  }

  async function handleDeleteConfirm() {
    if (loading) return;
    setLoading(true);
    await deleteBlock(selectedBlock);
    setLoading(false);
    onClose();
  }

  return (
    <DeleteDialog
      open={open}
      onCancel={handleClose}
      onConfirm={handleDeleteConfirm}
      ressource="block"
      loading={loading}
    />
  );
}
