import React, {useState, useEffect} from "react";

import {Box, Typography, Dialog, Grid, ClickAwayListener} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import SelectorRooms from "Features/rooms/components/SelectorRooms";
import getRoomsMapFromRoomsGroupsWithRooms from "Features/rooms/utils/getRoomsMapFromRoomsGroupsWithRooms";
import fromRoomsDataToRoomsIds from "Features/rooms/utils/fromRoomsDataToRoomsIds";
import fromRoomsIdsToRoomsData from "Features/rooms/utils/fromRoomsIdsToRoomsData";

import ListRooms from "Features/rooms/components/ListRooms";

import ViewBarRooms from "Features/rooms/components/ViewBarRooms";
import SelectorRoomsGroupContainer from "Features/rooms/components/SelectorRoomsGroupContainer";
import FieldRoomsIdsViewBar from "./FieldRoomsIdsViewBar";
import getFoundRooms from "Features/rooms/utils/getFoundRooms";
import getRoomsGroupIdRoomsWithMaterialCountMap from "Features/rooms/utils/getRoomsGroupIdRoomsWithMaterialCountMap";
import getRoomsGroupsMaterialsCountMap from "Features/rooms/utils/getRoomsGroupsMaterialsCountMap";

export default function FieldRoomsIds({
  item,
  name,
  value,
  roomsGroupsWithRooms,
  onChange,
  locked,
  sizeKey = 12,
  treeContainerRef,
}) {
  // state

  const [open, setOpen] = useState(false);
  const [selectedRoomsGroupId, setSelectedRoomsGroupId] = useState(null);
  const [showAllRooms, setShowAllRooms] = useState(false);
  const [searchText, setSearchText] = useState("");

  // helpers - selection

  const selection = value;

  // helpers - roomsMap

  const roomsMap = getRoomsMapFromRoomsGroupsWithRooms(roomsGroupsWithRooms);
  const allRooms = Object.values(roomsMap);

  // helpers

  const roomsGroups = roomsGroupsWithRooms.map(({roomsGroup}) => roomsGroup);

  const rooms =
    roomsGroupsWithRooms.find(
      ({roomsGroup}) => roomsGroup.id === selectedRoomsGroupId
    )?.rooms ?? [];

  const roomsWithSelection = rooms.map((room) => {
    const selected = selection.includes(room.id);
    return {...room, selected};
  });

  const filteredRooms = getFoundRooms(roomsWithSelection, {
    searchText,
    withParents: true,
    selectedOnly: !showAllRooms,
  });

  // helpers - roomsGroupsCounter

  const groupsCounters = getRoomsGroupsMaterialsCountMap(allRooms, [item]);
  const groupIdWithMaxMaterials = Object.entries(groupsCounters).reduce(
    (acc, [groupId, count]) => {
      return count > acc[1] ? [groupId, count] : acc;
    },
    [null, 0]
  )[0];

  // helpers - roomsLabel

  const count = selection ? selection.length : 0;
  const roomsLabel =
    count > 1
      ? `${count} pièces`
      : count === 1
      ? "1 pièce"
      : "Sélectionnez des pièces";

  // effect - init selectedRoomsGroupId

  useEffect(() => {
    if (!selectedRoomsGroupId) {
      setSelectedRoomsGroupId(groupIdWithMaxMaterials);
    }
  }, [selectedRoomsGroupId, groupIdWithMaxMaterials]);
  // handlers

  function handleSelectedRoomsGroupIdChange(id) {
    setSelectedRoomsGroupId(id);
  }

  // handlers - selector

  function handleChange(roomsIds) {
    onChange(roomsIds);
    //
    setOpen(false);
  }

  function handleRoomClick(room) {
    const roomWasSelected = selection.includes(room.id);
    let newSelection = selection.filter((id) => id !== room.id);
    if (!roomWasSelected) newSelection.push(room.id);
    console.log("room click", room, "newSelection", newSelection);
    onChange(newSelection);
  }

  function handleSelectedRoomParentIdChange(parentId) {
    console.log("parentId", parentId);
  }

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        display: "flex",
        flexDirection: "column",
        width: 1,
        height: 1,
        minHeight: 0,
      }}
    >
      <SelectorRoomsGroupContainer
        selectedRoomsGroupId={selectedRoomsGroupId}
        roomsGroups={roomsGroups}
        roomsGroupsCounters={groupsCounters}
        onSelectedRoomsGroupIdChange={handleSelectedRoomsGroupIdChange}
        variant="chips"
      />
      <FieldRoomsIdsViewBar
        searchText={searchText}
        onSearchTextChange={setSearchText}
        showAllRooms={showAllRooms}
        onShowAllRoomsChange={setShowAllRooms}
      />
      <Box
        sx={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          minHeight: 0,
        }}
      >
        <ListRooms
          rooms={filteredRooms}
          selection={selection}
          onClick={handleRoomClick}
          onSelectedRoomParentIdChange={handleSelectedRoomParentIdChange}
        />
      </Box>
    </Box>
  );
}
