import {useState} from "react";

import {Box, CircularProgress, IconButton, Typography} from "@mui/material";
import {CloudUpload, Delete} from "@mui/icons-material";
import {ArrowDropDown as Down, ArrowDropUp as Up} from "@mui/icons-material";

import {isImageFile} from "Features/images/imageUtils";

import FileSelectorButton from "Features/files/components/FileSelectorButton";
import ContainerImageBase64 from "Features/images/components/ContainerImageBase64";

import {Jimp} from "jimp";
import {blobToArrayBuffer} from "Features/files/utils";

export default function FieldImageBase64({
  name,
  value,
  onChange,
  height,
  width,
}) {
  // state

  const [expand, setExpand] = useState(true);

  // handlers

  function handleChange(imageBase64) {
    onChange(imageBase64);
  }
  function handleDelete() {
    onChange(null);
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box
        sx={{
          bgcolor: "background.default",
          width: 1,
          pl: 1,
          pr: 0.5,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{fontSize: 12, color: "text.secondary"}} noWrap>
          {name}
        </Typography>
        <IconButton size="small" onClick={() => setExpand(!expand)}>
          {!expand ? <Down fontSize="small" /> : <Up fontSize="small" />}
        </IconButton>
      </Box>
      {expand && (
        <ContainerImageBase64
          imageBase64={value}
          height={height}
          width={width}
          onChange={handleChange}
          onDelete={handleDelete}
        />
      )}
    </Box>
  );
}
