import getViewportRectangle from "./getViewportRectangle";

export default async function extractTextFromPdf(webViewer, options) {
  //

  const fromViewportRegion = options?.fromViewportRegion;

  //

  const {x, y, width, height} = getViewportRectangle(webViewer);

  //

  const {PDFNet, documentViewer} = webViewer.Core;

  //

  const doc = documentViewer.getDocument();
  const currentPage = documentViewer.getCurrentPage();

  const text = await doc.loadPageText(currentPage);
}
