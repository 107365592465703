import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import {useSelector} from "react-redux";

export default function useSamples(options) {
  const samplesMap = useSelector((s) => s.samples.samplesMap);
  const scope = useSelectedScope();

  let samples = Object.values(samplesMap);

  if (options?.filterByScope) {
    samples = samples.filter(
      (s) => s.scopeId === scope?.id || s.scopeId === "all"
    );
  }

  if (options?.sortByNum) {
    samples = samples.sort((a, b) => a.num.localeCompare(b.num));
  }

  return samples;
}
