import {Box} from "@mui/material";

import HeaderInSelectionPanelNomenclature from "./HeaderInSelectionPanelNomenclature";
import SectionNomenclatureInSelectionPanel from "./SectionNomenclatureInSelectionPanel";
import ActionsNomenclatureInSelectionPanel from "./ActionsNomenclatureInSelectionPanel";
import SectionActionsNomenclature from "./SectionActionsNomenclature";

export default function SectionNomenclatureInOrganisationSelectionPanel({
  organisation,
}) {
  // data

  const actionsMainKeys = ["deleteNomenclature"];
  const actionsMoreKeys = [];

  //
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
      }}
    >
      <HeaderInSelectionPanelNomenclature />

      <Box sx={{width: 1}}>
        <SectionActionsNomenclature
          actionsMainKeys={actionsMainKeys}
          actionsMoreKeys={actionsMoreKeys}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
          overflow: "auto",
        }}
      >
        <SectionNomenclatureInSelectionPanel />
      </Box>
      <ActionsNomenclatureInSelectionPanel organisation={organisation} />
    </Box>
  );
}
