import {nanoid} from "@reduxjs/toolkit";

export default function excelRowToRoom(row) {
  //
  const room = {};
  //
  const rowValues = [];
  for (let i = 1; i <= row.values.length; i++) {
    rowValues.push(row.getCell(i).value);
  }

  // remove null values at the end of each rows
  while (rowValues[rowValues.length - 1] === null) {
    rowValues.pop();
  }

  //
  const rowLength = rowValues.length;
  rowValues.forEach((value, i) => {
    if (i === 0) {
      if (value !== null) {
        room.id = value;
      } else {
        room.toCreate = true;
        room.clientId = nanoid();
      }
    } else if (i >= 1 && i <= rowLength - 2) {
      if (value !== null) {
        room.name = value;
        room.rank = i;
      }
      if (value === "x") {
        room.toDelete = true;
      }
    } else if (i === rowLength - 1) {
      if (value !== null) {
        if (room.name) {
          room.label = value;
        } else {
          room.name = value;
          room.rank = i;
          room.toDelete = value === "x";
        }
      }
    }
  });

  return room;
}
