import {useSelector} from "react-redux";

import useSelectedReportTemplate from "./useSelectedReportTemplate";

export default function useReportTemplateInSelectionPanel() {
  //
  const selectedReportTemplate = useSelectedReportTemplate();
  const editedReportTemplate = useSelector(
    (s) => s.reports.editedReportTemplate
  );
  const newReportTemplate = useSelector((s) => s.reports.newReportTemplate);
  const isEditingReportTemplate = useSelector(
    (s) => s.reports.isEditingReportTemplate
  );

  // helper - reportTemplate

  let reportTemplate = selectedReportTemplate ?? newReportTemplate;
  if (isEditingReportTemplate) reportTemplate = editedReportTemplate;

  // return

  return reportTemplate;
}
