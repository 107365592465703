import useNomenclatureInSelectionPanel from "../hooks/useNomenclatureInSelectionPanel";

import {Box, Paper, Typography} from "@mui/material";

export default function HeaderInSelectionPanelNomenclature() {
  // strings

  const newS = "Nouvelle nomenclature";

  // data

  const nomenclature = useNomenclatureInSelectionPanel();

  // helper - isNew

  const isNew = !nomenclature?.id;

  // helper - flash

  const flash = isNew;

  // helpers - color

  const gradient = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;
  const background = flash ? gradient : "unset";
  const color = flash ? "common.white" : "common.black";

  // helper - title

  const title = isNew ? newS : nomenclature?.name;

  return (
    <Box
      sx={{
        p: 1,
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          p: 0.5,
          height: (theme) => theme.spacing(5),
          width: 1,
          alignItems: "center",
          justifyContent: "space-between",
          overflow: "hidden",
          background,
          color,
        }}
      >
        <Typography
          variant="body2"
          sx={{fontWeight: "bold", minWidth: 0, px: 0.5}}
          noWrap
        >
          {title}
        </Typography>
      </Paper>
    </Box>
  );
}
