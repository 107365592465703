import getExcelHeaderFromNomenclature from "Features/nomenclatures/utils/getExcelHeaderFromNomenclature";
import applyStyleToRow from "./applyStyleToRow";
import getExcelRowsFromNomenclatureTreeItems from "Features/nomenclatures/utils/getExcelRowsFromNomenclatureTreeItems";
import {Work} from "@mui/icons-material";

export default function fillWorksheetWithNomenclatureTree({
  worksheet,
  data,
  rowStartIndex = 3,
}) {
  //

  console.log("data", data);

  //

  const nomenclatureTree = data;
  const items = data?.items;
  const rows = getExcelRowsFromNomenclatureTreeItems(items);

  // start & end

  const length = rows.length;
  worksheet.getRow(1).getCell(2).value = rowStartIndex;
  worksheet.getRow(2).getCell(2).value = rowStartIndex + 2 + length;
  worksheet.getRow(2).getCell(2).font = worksheet.getRow(1).getCell(2).font;

  // header

  const header = getExcelHeaderFromNomenclature(nomenclatureTree);

  header.forEach((row, index) => {
    const headerRow = worksheet.getRow(rowStartIndex + index);
    row.forEach((cell, index) => {
      headerRow.getCell(index + 2).value = cell;
    });
  });

  // style header

  const lastHeaderRow = worksheet.getRow(rowStartIndex + header.length - 1);
  lastHeaderRow.eachCell((cell) => {
    cell.border = {
      bottom: {style: "thin"},
    };
  });
  // add border to the first cell of the last header row
  const cell = worksheet.getCell(rowStartIndex + header.length - 1, 1);
  cell.border = {
    bottom: {style: "thin"},
  };

  // rows

  rows.forEach((row, index) => {
    const rowIndex = rowStartIndex + header.length + index;
    const worksheetRow = worksheet.getRow(rowIndex);

    row.forEach((cell, index) => {
      worksheetRow.getCell(index + 1).value = cell;
      if (index === 0) {
        worksheetRow.getCell(index + 1).font = {color: {argb: "FF808080"}};
      }
    });
  });
}
