import {useSelector, useDispatch} from "react-redux";

import {setMaterialsViewBy} from "../materialsSlice";

import {Box, ToggleButtonGroup, ToggleButton, Typography} from "@mui/material";

export default function ToggleButtonsMaterialsViewBy() {
  const dispatch = useDispatch();

  // string

  const byMaterialS = "matériaux";
  const byCategoryS = "catégorie";
  const byRoomS = "pièce";
  const byS = "Par";

  // data

  const materialsViewBy = useSelector((s) => s.materials.materialsViewBy);

  // handlers

  const handleViewByChange = (event, newViewBy) => {
    if (newViewBy !== null) {
      dispatch(setMaterialsViewBy(newViewBy));
    }
  };

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <Typography sx={{fontSize: 12, color: "text.secondary", mr: 1}}>
        {byS}
      </Typography>
      <ToggleButtonGroup
        value={materialsViewBy}
        exclusive
        onChange={handleViewByChange}
        size="small"
        sx={{height: 32}}
      >
        <ToggleButton value="MATERIAL">
          <Typography sx={{textTransform: "none", fontSize: 12}}>
            {byMaterialS}
          </Typography>
        </ToggleButton>
        <ToggleButton value="ROOM">
          <Typography sx={{textTransform: "none", fontSize: 12}}>
            {byRoomS}
          </Typography>
        </ToggleButton>
        <ToggleButton value="CATEGORY">
          <Typography sx={{textTransform: "none", fontSize: 12}}>
            {byCategoryS}
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
