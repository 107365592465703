import {useSelector, useDispatch} from "react-redux";

import {
  setNewReport,
  setEditedReport,
  setIsEditingReport,
} from "../reportsSlice";

import useReportInSelectionPanel from "../hooks/useReportInSelectionPanel";
import useReportTemplates from "../hooks/useReportTemplates";

import FormReport from "./FormReport";

export default function SectionReportInSelectionPanel({scene}) {
  const dispatch = useDispatch();

  // data

  const report = useReportInSelectionPanel();
  const reportTemplates = useReportTemplates(scene, {fetchData: true});

  // helpers

  const isNew = !report?.id;

  // handlers

  function handleChange(changedReport) {
    if (isNew) {
      dispatch(setNewReport(changedReport));
    } else {
      dispatch(setEditedReport(changedReport));
      dispatch(setIsEditingReport(true));
    }
  }

  return (
    <FormReport
      report={report}
      reportTemplates={reportTemplates}
      onChange={handleChange}
    />
  );
}
