import getItemsMapByClientId from "Utils/getItemsMapByClientId";

export default function getRoomsGroupsMaterialsCountMap(rooms, materials) {
  const map = {};

  const materialsByClientId = getItemsMapByClientId(materials);

  rooms.forEach((room) => {
    const roomsGroupId = room.roomsGroupId;
    const materialsData = room.materialsData || [];
    materialsData.forEach(({materialsGroupId, materialsClientIds}) => {
      const materialsClientIdsInScope = materialsClientIds.filter(
        (clientId) => materialsByClientId[clientId]
      );
      if (!map[roomsGroupId]) {
        map[roomsGroupId] = materialsClientIdsInScope.length;
      } else {
        map[roomsGroupId] += materialsClientIdsInScope.length;
      }
    });
  });

  return map;
}
