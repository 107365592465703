import useOrganisation from "../hooks/useOrganisation";

import {Box} from "@mui/material";

import PageOrganisationTopBar from "./PageOrganisationTopBar";

import useInitFetchOrganisation from "../hooks/useInitFetchOrganisation";

import PageOrganisationSectionEntitiesSelector from "./PageOrganisationSectionEntitiesSelector";
import PageOrganisationSectionSelectedEntities from "./PageOrganisationSectionSelectedEntities";
import PageOrganisationSectionSelection from "./PageOrganisationSectionSelection";

export default function PageOrganisation() {
  // init
  useInitFetchOrganisation();

  // data
  const organisation = useOrganisation();

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
      }}
    >
      <PageOrganisationTopBar />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          width: 1,
          justifyItems: "stretch",
          minHeight: 0,
        }}
      >
        <Box sx={{display: "flex", width: 240, height: 1}}>
          <PageOrganisationSectionEntitiesSelector
            organisation={organisation}
          />
        </Box>
        <Box sx={{flex: 1, height: 1}}>
          <PageOrganisationSectionSelectedEntities
            organisation={organisation}
          />
        </Box>
        <Box
          sx={{
            width: 400,
            height: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
          }}
        >
          <PageOrganisationSectionSelection organisation={organisation} />
        </Box>
      </Box>
    </Box>
  );
}
