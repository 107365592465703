import getItemsWithNumsFromItemsWithWeights from "./getItemsWithNumsFromItemsWithWeights";

export default function getItemsWithNumsFromItemsWithRanks(items) {
  const maxRank = items.reduce((acc, item) => {
    return Math.max(acc, item.rank ?? 0);
  }, 0);

  const itemsWithWeights = items.map((item) => {
    return {...item, weight: maxRank - item.rank};
  });

  return getItemsWithNumsFromItemsWithWeights(itemsWithWeights, "1");
}
