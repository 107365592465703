import {useState, useEffect} from "react";

import {Box} from "@mui/material";

export default function BlockDataView({viewData}) {
  // state

  const [isImageUrlValid, setIsImageUrlValid] = useState(true);

  // effect
  const _imageUrl = viewData?.imageUrl;

  useEffect(() => {
    if (_imageUrl) {
      fetch(_imageUrl)
        .then((response) => {
          if (response.ok) {
            setIsImageUrlValid(true);
          } else {
            setIsImageUrlValid(false);
          }
        })
        .catch(() => {
          setIsImageUrlValid(false);
        });
    }
  }, [_imageUrl]);

  // helper

  const imageUrl = isImageUrlValid ? viewData.imageUrl : viewData.zoneImageUrl;

  return (
    <Box sx={{width: 1}}>
      <img src={imageUrl} alt="zone" style={{width: "100%"}} />
    </Box>
  );
}
