export default function stateToRemoteFormTemplate(template) {
  const remoteTemplate = {...template};

  // organisation
  remoteTemplate.organisation = template.organisationId;
  delete remoteTemplate.organisationId;

  //
  return remoteTemplate;
}
