export default function remoteToStateReport(report) {
  const stateReport = {...report};

  //organisation
  stateReport.organisationId = report.organisation;
  delete stateReport.organisation;

  // scope
  stateReport.scopeId = report.scope;
  delete stateReport.scope;

  // reportTemplate
  stateReport.reportTemplateId = report.reportTemplate;
  delete stateReport.reportTemplate;

  return stateReport;
}
