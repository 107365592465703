import {useSelector, useDispatch} from "react-redux";

import {
  Add,
  LibraryAdd as AddGroup,
  ContentCopy as Copy,
  ContentPaste as Paste,
  Edit,
} from "@mui/icons-material";
import {
  setEditedRoomMaterialsData,
  setIsEditingRoomMaterials,
  setMaterialsViewBy,
  setMaterialsViewVariant,
  setMultipleSelectionMaterials,
  setSelectedRoomMaterialsClientIds,
  setCopiedMaterialsData,
} from "../materialsSlice";
import {updateRoom} from "Features/rooms/roomsSlice";

import useSelectedRoomInMaterials from "./useSelectedRoomInMaterials";

import cleanRoomMaterialsData from "../utils/cleanRoomMaterialsData";

import useAccessToken from "Features/auth/useAccessToken";
import fromMaterialsDataToClientIds from "../utils/fromMaterialsDataToClientIds";
import fromMaterialsClientIdsToData from "../utils/fromMaterialsClientIdsToData";
import addMaterialsDataToMaterialsData from "../utils/addMaterialsDataToMaterialsData";

export default function useActionsMaterialsMap({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // data

  const selectedMaterialsGroupId = useSelector(
    (s) => s.materials.selectedMaterialsGroupId
  );
  const selectedMaterialClientId = useSelector(
    (s) => s.materials.selectedMaterialClientId
  );
  const multipleSelection = useSelector(
    (s) => s.materials.multipleSelectionMaterials
  );
  const roomInMaterials = useSelectedRoomInMaterials();

  const selectedRoomMaterialsClientIds = useSelector(
    (s) => s.materials.selectedRoomMaterialsClientIds
  );

  const copiedMaterialsData = useSelector(
    (s) => s.materials.copiedMaterialsData
  );

  // handlers

  function handleMaterialsViewBy(viewBy) {
    dispatch(setMaterialsViewBy(viewBy));
  }

  function handleMaterialsViewVariant(viewVariant) {
    dispatch(setMaterialsViewVariant(viewVariant));
  }

  function handleToggleMultipleSelectionMaterials() {
    dispatch(setMultipleSelectionMaterials(!multipleSelection));
  }

  function handleEditRoomMaterials() {
    dispatch(setIsEditingRoomMaterials(true));
    dispatch(setEditedRoomMaterialsData(roomInMaterials.materialsData));
  }

  function handleCleanRoomMaterials() {
    const cleanMaterialsData = cleanRoomMaterialsData(
      roomInMaterials.materialsData
    );
    const updates = {
      id: roomInMaterials.id,
      roomsGroupId: roomInMaterials.roomsGroupId,
      materialsData: cleanMaterialsData,
    };
    dispatch(updateRoom({accessToken, updates}));
  }

  function handleCopyRoomMaterialsData() {
    let copiedMaterialsData = [
      {
        materialsGroupId: selectedMaterialsGroupId,
        materialsClientIds: selectedRoomMaterialsClientIds,
      },
    ];
    if (selectedRoomMaterialsClientIds.length === 0) {
      copiedMaterialsData = roomInMaterials.materialsData;
      const clientIds = fromMaterialsDataToClientIds(copiedMaterialsData);
      dispatch(setSelectedRoomMaterialsClientIds(clientIds));
    }
    dispatch(setCopiedMaterialsData(copiedMaterialsData));
  }

  async function handlePasteRoomMaterialsData() {
    const newMaterialsData = addMaterialsDataToMaterialsData(
      copiedMaterialsData,
      roomInMaterials.materialsData
    );
    const updates = {
      id: roomInMaterials.id,
      roomsGroupId: roomInMaterials.roomsGroupId,
      materialsData: newMaterialsData,
    };
    await dispatch(updateRoom({accessToken, updates}));
    dispatch(setCopiedMaterialsData([]));
  }

  // actions map

  const actionsMap = {
    createMaterialsGroup: {
      icon: <AddGroup fontSize="small" />,
      name: "Liste de matériaux",
      label: "Créer une liste de matériaux",
    },
    renameMaterialsGroup: {
      name: "Renommer",
      label: "Renommer la liste de matériaux",
      disabled: !selectedMaterialsGroupId,
    },
    createMaterial: {
      icon: <Add fontSize="small" />,
      name: "Créer un matériau",
      label: "Créer un matériau",
    },
    editRoomMaterials: {
      icon: <Edit fontSize="small" />,
      name: "Editer",
      label: "Editer les matériaux de la pièce",
      handler: handleEditRoomMaterials,
    },
    deleteMaterial: {
      name: "Supprimer",
      label: "Supprimer le matériau",
      disabled: !selectedMaterialClientId,
    },
    deleteMaterialsGroup: {
      name: "Supprimer",
      label: "Supprimer la liste de matériaux",
      disabled: !selectedMaterialsGroupId,
    },
    //
    setMaterialsViewByMaterial: {
      label: "Par matériau",
      handler: () => handleMaterialsViewBy("MATERIAL"),
    },
    setMaterialsViewByCategory: {
      label: "Par catégorie",
      handler: () => handleMaterialsViewBy("CATEGORY"),
    },
    setMaterialsViewByRoom: {
      label: "Par pièce",
      handler: () => handleMaterialsViewBy("ROOM"),
    },
    //
    setMaterialsViewVariantToList: {
      label: "Liste",
      handler: () => handleMaterialsViewVariant("LIST"),
    },
    setMaterialsViewVariantToTreeList: {
      label: "Arborescence",
      handler: () => handleMaterialsViewVariant("TREE_LIST"),
    },
    //
    toggleMultipleSelectionMaterials: {
      label: multipleSelection
        ? "Désactiver la sélection multiple"
        : "Activer la sélection multiple",
      handler: handleToggleMultipleSelectionMaterials,
    },
    //
    cleanRoomMaterialsData: {
      label: "Nettoyer les matériaux",
      handler: handleCleanRoomMaterials,
    },
    copyRoomMaterialsData: {
      icon: <Copy fontSize="small" />,
      label: "Copier les matériaux",
      handler: handleCopyRoomMaterialsData,
    },
    pasteRoomMaterialsData: {
      icon: <Paste fontSize="small" />,
      label: "Coller les matériaux",
      handler: handlePasteRoomMaterialsData,
      disabled: !copiedMaterialsData.length > 0,
    },
  };

  return actionsMap;
}
