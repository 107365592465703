export default function useKvtReport(data) {
  const reportTemplates = data?.reportTemplates ?? [];

  const kvt = {
    key: "report",
    groups: [
      {code: "INFO", label: "Info"},
      {code: "FORM", label: "Formulaire"},
      {code: "PDFS", label: "Autres PDFs"},
    ],
    fields: [
      {
        group: "INFO",
        key: "reportTemplate",
        name: "Modèle de rapport",
        type: "combo",
        options: reportTemplates,
      },
      {
        group: "INFO",
        key: "identification",
        name: "Identification",
        type: "section",
      },
      {group: "INFO", key: "name", name: "Titre du rapport", type: "text"},
      {group: "INFO", key: "clientReference", name: "Référence", type: "text"},
      {group: "INFO", key: "sectionVersion", name: "Version", type: "section"},
      {group: "INFO", key: "version", name: "N° version", type: "text"},
      {
        group: "INFO",
        key: "versionDescription",
        name: "Description des modifications",
        type: "textMultiline",
      },
      {
        group: "FORM",
        key: "mainFormData",
        name: "Données du formulaire",
        type: "formData",
      },
      {
        group: "PDFS",
        key: "pdfs",
        name: "PDFs",
        type: "pdfs",
      },
    ],
  };

  return kvt;
}
