import {Checkbox, List, Box, Typography, ListItemButton} from "@mui/material";

export default function ContainerRoomsSelectorFromTemplate({
  template,
  selection,
  onSelectionChange,
}) {
  const {title, items} = template;

  const selectedItems = items.filter((item) => selection.includes(item));

  // checkbox value

  const checked = selectedItems.length === items.length ?? false;
  const unchecked = selectedItems.length === 0;
  const indeterminate = !checked && !unchecked;

  // handler

  function handleSelectAll() {
    onSelectionChange(items);
  }

  function handleUnselectAll() {
    onSelectionChange([]);
  }

  function handleTitleCheckChange() {
    if (checked) {
      handleUnselectAll();
    } else {
      handleSelectAll();
    }
  }

  function handleToggleItem(item) {
    const itemsWithSelected = items.map((i) => {
      if (i === item) {
        if (selection.includes(item)) {
          return {item: i, selected: false};
        } else {
          return {item: i, selected: true};
        }
      } else {
        return {item: i, selected: selection.includes(i)};
      }
    });
    const newSelection = itemsWithSelected
      .filter((i) => i.selected)
      .map((i) => i.item);

    onSelectionChange(newSelection);
  }

  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      <Box
        sx={{
          p: 1,
          display: "flex",
          alignItems: "center",
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          size="small"
          onChange={handleTitleCheckChange}
        />
        <Typography variant="body2" sx={{fontWeight: "bold"}}>
          {title}
        </Typography>
      </Box>
      <List dense>
        {items.map((item) => {
          return (
            <ListItemButton
              sx={{p: 0}}
              key={item}
              onClick={() => handleToggleItem(item)}
              selected={selection.includes(item)}
            >
              <Box sx={{display: "flex", alignItems: "center", px: 1}}>
                <Checkbox size="small" checked={selection.includes(item)} />
                <Typography variant="body2">{item}</Typography>
              </Box>
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
}
