import {useSelector} from "react-redux";

import {DragDropContext, Droppable} from "react-beautiful-dnd";

import {Box, List, ListItemButton, Typography} from "@mui/material";

import ListItemBlock from "./ListItemBlock";
import {setTempSortedBlocksIds} from "../blocksSlice";

export default function ListBlocks({
  report,
  blocks = [],
  selectedBlockId,
  onClick,
  onOrderChange,
}) {
  // data

  const isEditing = useSelector((s) => s.blocks.isEditingListBlocks);
  const tempSortedBlocksIds = useSelector((s) => s.blocks.tempSortedBlocksIds);
  const reportSortedBlocksIds = report?.sortedBlocksIds;
  const blocksIds = blocks.map((b) => b.id);

  // helpers

  let sortedBlocksIds = blocksIds;
  if (reportSortedBlocksIds && !isEditing) {
    sortedBlocksIds = reportSortedBlocksIds.filter((id) =>
      blocksIds.includes(id)
    );
  } else if (isEditing && tempSortedBlocksIds?.length > 0) {
    sortedBlocksIds = tempSortedBlocksIds;
  }

  const sortedBlocks = sortedBlocksIds.map((id) =>
    blocks.find((b) => b.id === id)
  );

  // handlers

  function handleClick(block) {
    onClick(block);
  }

  function handleDragEnd(result) {
    const {destination, source, draggableId} = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const newIds = [...sortedBlocksIds];
    newIds.splice(source.index, 1);
    newIds.splice(destination.index, 0, draggableId);

    onOrderChange(newIds);
  }

  return (
    <Box sx={{width: 1}}>
      {report && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={report?.id}>
            {(provided, _) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <List dense disablePadding>
                  {sortedBlocks.map((block, index) => {
                    return (
                      <ListItemBlock
                        key={block.id}
                        block={block}
                        index={index}
                        draggable={isEditing}
                        onClick={handleClick}
                        selected={block.id === selectedBlockId}
                      />
                    );
                  })}
                </List>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Box>
  );
}
