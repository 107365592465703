import getFoundObjects from "Features/search/utilsSearch/getFoundObjects";
import getParentsNums from "Utils/getParentsNums";

export default function getFoundRooms(allRooms, options) {
  const withParents = options.withParents;
  const selectedOnly = options.selectedOnly;

  let rooms = allRooms;
  if (selectedOnly) {
    rooms = allRooms.filter((room) => room.selected);
  }

  let foundRooms = getFoundObjects(rooms, {
    searchText: options.searchText,
    searchKeys: ["num", "name"],
  });

  if (withParents) {
    // init
    const foundRoomsWithParents = [];
    const roomsByNum = allRooms.reduce((ac, room) => {
      ac[room.num] = room;
      return ac;
    }, {});
    const processedNumMap = {};

    // loop
    foundRooms.forEach((room) => {
      const parentsNums = getParentsNums(room.num);
      parentsNums.forEach((parentNum) => {
        const parent = roomsByNum[parentNum];
        if (parent && !processedNumMap[parentNum]) {
          foundRoomsWithParents.push(parent);
          processedNumMap[parentNum] = true;
        }
      });
      if (!processedNumMap[room.num]) {
        foundRoomsWithParents.push(room);
        processedNumMap[room.num] = true;
      }
    });

    // return
    foundRooms = foundRoomsWithParents;
  }

  //
  return foundRooms;
}
