import {useState, useEffect} from "react";

import {Paper, Box, Typography, IconButton, Dialog} from "@mui/material";
import {Upload, Download} from "@mui/icons-material";

import ContainerFilesSelector from "Features/files/components/ContainerFilesSelector";
import jsonObjectToFile from "Features/files/utils/jsonObjectToFile";

import {saveBlob} from "Features/files/utils";
import jsonFileToObject from "Features/files/utils/jsonFileToObject";
import KeyValueForm from "./KeyValueForm";

export default function FieldFormDataHeader({name, value, onChange}) {
  // strings

  const title = "Données du formulaire";

  // state

  const [open, setOpen] = useState(false);

  // handler

  function handleEditClick() {
    setOpen(true);
  }

  async function handleFilesChange(files) {
    try {
      const jsonFile = files[0];
      const data = await jsonFileToObject(jsonFile);
      //
      onChange(data);
      //
      setOpen(false);
    } catch (e) {
      console.error(e);
    }
  }

  function handleDownloadClick() {
    const blob = jsonObjectToFile(value);
    saveBlob(blob, `${name}.json`);
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <ContainerFilesSelector
          onFilesChange={handleFilesChange}
          accept=".json"
        />
      </Dialog>
      <Box
        sx={{
          width: 1,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            width: 1,
            color: "text.secondary",
            //bgcolor: "background.default",
            px: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{fontSize: 12, fontStyle: "italic"}}>
            {title}
          </Typography>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <IconButton size="small" onClick={handleDownloadClick}>
              <Download fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={handleEditClick}>
              <Upload fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}
