import {Box} from "@mui/material";

import FieldSection from "./FieldSection";
import FieldNumber from "./FieldNumber";
import FieldCombo from "./FieldCombo";
import FieldCheckbox from "./FieldCheckbox";
import FieldColor from "./FieldColor";
import FieldSelector from "./FieldSelector";
import FieldDate from "./FieldDate";
import FieldText from "./FieldText";
import FieldTextMultiline from "./FieldTextMultiline";
import FieldImageRead from "./FieldImageRead";
import FieldImage from "./FieldImage";
import FieldEditableImage from "./FieldEditableImage";
import FieldImageBase64 from "./FieldImageBase64";
import FieldFile3d from "./FieldFile3d";
import FieldSelectorZones from "./FieldSelectorZones";
import ToggleFormGroups from "./ToggleFormGroups";

import FieldListingIds from "Features/listings/components/FieldListingIds";
import FieldPackageIds from "Features/elementPackages/components/FieldPackageIds";
import FieldItemElementTypeId from "Features/elementTypes/components/FieldItemElementTypeId";
import FieldItemElementTypeIdOverview from "Features/elementTypes/components/FieldItemElementTypeIdOverview";
import FieldItemRessources from "Features/ressources/components/FieldItemRessources";
// import FieldItemLinkedRessources from "Features/ressources/components/FieldItemLinkedRessources";
import FieldItemHostedTypes from "Features/elementTypes/components/FieldItemHostedTypes";
import FieldItemVoids from "Features/measurements/components/FieldItemVoids";
// import FieldRessourceTypes from "Features/ressources/components/FieldRessourceTypes";
import FieldSectionQuantities from "Features/measurements/components/FieldSectionQuantities";
import FieldSelectorInputs from "./FieldSelectorInputs";
import FieldRessourceGroupsProxy from "Features/ressources/components/FieldRessourceGroupsProxy";
import TypesRessourcesRelations from "Features/relations/components/TypesRessourcesRelations";
import FieldFormTemplate from "./FieldFormTemplate";
import FieldRoom from "./FieldRoom";
import FieldRoomsIds from "./FieldRoomsIds";
import FieldMaterialCategory from "./FieldMaterialCategory";
import FieldMaterial from "./FieldMaterial";
import FieldMaterialsData from "./FieldMaterialsData";
import FieldPdfs from "./FieldPdfs";
import FieldBlockData from "./FieldBlockData";
import FieldNomenclatureTree from "./FieldNomenclatureTree";
import FieldCategoryCode from "./FieldCategoryCode";
import FieldChildren from "./FieldChildren";

export default function KeyValueForm({
  caplaEditor,
  scene,
  item,
  template,
  onChange,
  onNewData,
  onNewOption,
  lockedFields,
  hiddenFields,
  sizeKey = 6,
  canEdit = true,
  horizontal = false,
  horizontalHeader = false,
  group,
  onGroupChange,
  marginTop = false,
  selectorContainerRef,
}) {
  // handlers

  function handleFieldChange(key, value) {
    if (horizontalHeader) return;
    const newItem = {...item, [key]: value};
    if (onChange) onChange(newItem);
  }

  function handleNewOption(key, value) {
    if (onNewOption) onNewOption({key, value});
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: horizontal ? "row" : "column",
        height: 1,
        minHeight: 0,
      }}
    >
      {!horizontal && template?.groups && (
        <Box
          sx={{
            width: 1,
            py: 2,
            display: "flex",
            justifyContent: "center",
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <ToggleFormGroups
            groups={template?.groups}
            group={group}
            onGroupChange={onGroupChange}
          />
        </Box>
      )}
      {!horizontal && !template?.groups && marginTop && <Box sx={{p: 2}} />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        {template.fields.map((field) => {
          let show = true;
          if (template.groups) show = field.group === group;
          if (
            Array.isArray(hiddenFields) &&
            hiddenFields.includes(field?.key)
          ) {
            show = false;
          } else if (field?.condition && !horizontal) {
            if (field.condition.type === "showIfEqual") {
              const itemValue = item[field.condition?.key];
              if (field.condition.subkey) {
                if (!itemValue) {
                  show = false;
                } else if (
                  !field.condition.values.includes(
                    itemValue[field.condition.subkey]
                  )
                )
                  show = false;
              } else {
                if (!field.condition.values.includes(itemValue)) show = false;
              }
            } else if (field.condition.type === "showIfNotEqual") {
              const itemValue = item[field.condition.key];
              if (field.condition.subkey) {
                if (!itemValue) {
                  show = false;
                } else if (
                  field.condition.values.includes(
                    itemValue[field.condition.subkey]
                  )
                )
                  show = false;
              } else {
                if (field.condition.values.includes(itemValue)) show = false;
              }
            }
          }
          if (!show) return false;

          let fieldIsLocked = false;
          if (Array.isArray(lockedFields))
            fieldIsLocked = lockedFields.includes(field?.key);
          if (!canEdit) fieldIsLocked = true;

          switch (field?.type) {
            case "section": {
              return (
                <FieldSection
                  key={field.key}
                  title={field.name}
                  horizontal={horizontal}
                  horizontalHeader={horizontalHeader}
                />
              );
            }
            case "number": {
              return (
                <FieldNumber
                  key={field.key}
                  name={field.name}
                  value={
                    item[field.key]
                      ? Math.round(item[field.key] * 10000) / 10000
                      : null
                  }
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                  sizeKey={sizeKey}
                  horizontal={horizontal}
                  horizontalHeader={horizontalHeader}
                  width={field.width}
                />
              );
            }
            case "combo": {
              return (
                <FieldCombo
                  key={field.key}
                  name={field.name}
                  value={item[field.key]}
                  options={field.options}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  onNewOption={(v) => handleNewOption(field.key, v)}
                  createLabel={field.createLabel}
                  canCreate={field.canCreate}
                  locked={fieldIsLocked}
                  sizeKey={sizeKey}
                  horizontal={horizontal}
                  horizontalHeader={horizontalHeader}
                  sortByNum={field.sortByNum}
                />
              );
            }
            case "checkbox": {
              return (
                <FieldCheckbox
                  key={field.key}
                  name={field.name}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                  sizeKey={sizeKey}
                  horizontal={horizontal}
                  horizontalHeader={horizontalHeader}
                  width={field.width}
                />
              );
            }
            case "color": {
              return (
                <FieldColor
                  key={field.key}
                  name={field.name}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                  sizeKey={sizeKey}
                  horizontal={horizontal}
                  horizontalHeader={horizontalHeader}
                />
              );
            }
            case "selector": {
              return (
                <FieldSelector
                  key={field.key}
                  name={field.name}
                  value={item[field.key]}
                  options={field.options}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                  sizeKey={field.sizeKey ?? sizeKey}
                  horizontal={horizontal}
                  horizontalHeader={horizontalHeader}
                  width={field.width}
                />
              );
            }
            case "date": {
              return (
                <FieldDate
                  key={field.key}
                  name={field.name}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                  sizeKey={sizeKey}
                  horizontal={horizontal}
                  horizontalHeader={horizontalHeader}
                />
              );
            }
            case "text": {
              return (
                <FieldText
                  key={field.key}
                  name={field.name}
                  placeholder={field.placeholder}
                  value={item[field.key]}
                  multiline={field.multiline}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                  sizeKey={sizeKey}
                  horizontal={horizontal}
                  horizontalHeader={horizontalHeader}
                  width={field.width}
                />
              );
            }
            case "textMultiline": {
              return (
                <FieldTextMultiline
                  key={field.key}
                  name={field.name}
                  value={item[field.key]}
                  multiline={field.multiline}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                  sizeKey={sizeKey}
                  horizontal={horizontal}
                  horizontalHeader={horizontalHeader}
                  width={field.width}
                />
              );
            }
            case "imageRead": {
              return (
                <FieldImageRead
                  key={field.key}
                  imageUrl={item[field.key]}
                  height={field.height}
                  sizeKey={sizeKey}
                  horizontal={horizontal}
                  horizontalHeader={horizontalHeader}
                />
              );
            }
            case "image": {
              return (
                <FieldEditableImage
                  key={field.key}
                  name={field.name}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                  container={field.container}
                  scene={scene}
                />
              );
            }
            case "imageBase64": {
              return (
                <FieldImageBase64
                  key={field.key}
                  name={field.name}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                  height={field.height ?? 100}
                  width={field.width ?? 100}
                />
              );
            }
            case "fileUrl": {
              return (
                <FieldFile3d
                  key={field.key}
                  item={item}
                  scene={scene}
                  caplaEditor={caplaEditor}
                  name={field.name}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                />
              );
            }
            case "zones": {
              return (
                <FieldSelectorZones
                  name={field.name}
                  key={field.key}
                  scene={scene}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                />
              );
            }
            case "inputs": {
              return (
                <FieldSelectorInputs
                  name={field.name}
                  key={field.key}
                  scene={scene}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                />
              );
            }

            case "listingIds": {
              return (
                <FieldListingIds
                  scene={scene}
                  name={field.name}
                  key={field.key}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                />
              );
            }
            case "packageIds": {
              return (
                <FieldPackageIds
                  scene={scene}
                  name={field.name}
                  key={field.key}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                  scopedPDFs={item?.pdfIds?.length > 0 ? item.pdfIds : null}
                />
              );
            }
            case "ressources": {
              return (
                <FieldItemRessources
                  key={field.key}
                  item={item}
                  scene={scene}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                />
              );
            }
            case "ressourceGroups": {
              return (
                <FieldRessourceGroupsProxy
                  scene={scene}
                  name={field.name}
                  key={field.key}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                />
              );
            }
            case "relations": {
              return (
                <TypesRessourcesRelations
                  key={field.key}
                  item={item}
                  scene={scene}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                />
              );
            }
            // case "linkedRessources": {
            //   return (
            //     <FieldItemLinkedRessources
            //       key={field.key}
            //       item={item}
            //       scene={scene}
            //       value={item[field.key]}
            //       onChange={(v) => handleFieldChange(field.key, v)}
            //       locked={fieldIsLocked}
            //     />
            //   );
            // }
            // case "ressourceTypes": {
            //   return (
            //     <FieldRessourceTypes
            //       key={field.key}
            //       item={item}
            //       scene={scene}
            //       value={item[field.key]}
            //       onChange={(v) => handleFieldChange(field.key, v)}
            //       locked={fieldIsLocked}
            //     />
            //   );
            // }
            case "elementTypeId": {
              return (
                <FieldItemElementTypeId
                  key={field.key}
                  item={item}
                  scene={scene}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                  sizeKey={sizeKey}
                />
              );
            }
            case "elementTypeIdOverview": {
              return (
                <FieldItemElementTypeIdOverview
                  key={field.key}
                  item={item}
                  scene={scene}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                  sizeKey={sizeKey}
                />
              );
            }
            case "hostedTypes": {
              return (
                <FieldItemHostedTypes
                  key={field.key}
                  item={item}
                  scene={scene}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                />
              );
            }
            case "voids": {
              return (
                <FieldItemVoids
                  key={field.key}
                  item={item}
                  scene={scene}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  locked={fieldIsLocked}
                  caplaEditor={caplaEditor}
                />
              );
            }
            case "sectionMeasurementQuantities": {
              return (
                <FieldSectionQuantities
                  key={field.key}
                  measurement={item}
                  scene={scene}
                  editorPdf={caplaEditor?.editorPdf}
                />
              );
            }
            case "formTemplate": {
              return (
                <FieldFormTemplate
                  key={field.key}
                  name={field.name}
                  item={item}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                />
              );
            }
            case "room": {
              return (
                <FieldRoom
                  key={field.key}
                  name={field.name}
                  item={item}
                  roomsGroupsWithRooms={field.roomsGroupsWithRooms}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  treeContainerRef={selectorContainerRef}
                  sizeKey={field.sizeKey ?? sizeKey}
                />
              );
            }
            case "roomsIds": {
              return (
                <FieldRoomsIds
                  key={field.key}
                  name={field.name}
                  item={item}
                  roomsGroupsWithRooms={field.roomsGroupsWithRooms}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  treeContainerRef={selectorContainerRef}
                  sizeKey={field.sizeKey ?? sizeKey}
                />
              );
            }
            case "materialsData": {
              return (
                <FieldMaterialsData
                  key={field.key}
                  name={field.name}
                  item={item}
                  materialsGroupsWithNomenclatureAndMaterials={
                    field.materialsGroupsWithNomenclatureAndMaterials
                  }
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  onNewData={onNewData}
                  sizeKey={field.sizeKey ?? sizeKey}
                />
              );
            }
            case "material": {
              return (
                <FieldMaterial
                  key={field.key}
                  name={field.name}
                  item={item}
                  materialsGroupsWithNomenclatureAndMaterials={
                    field.materialsGroupsWithNomenclatureAndMaterials
                  }
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  treeContainerRef={selectorContainerRef}
                  sizeKey={field.sizeKey ?? sizeKey}
                />
              );
            }
            case "materialCategory": {
              return (
                <FieldMaterialCategory
                  key={field.key}
                  name={field.name}
                  item={item}
                  materialsNomenclature={field.materialsNomenclature}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  treeContainerRef={selectorContainerRef}
                  sizeKey={field.sizeKey ?? sizeKey}
                />
              );
            }

            case "categoryCode": {
              return (
                <FieldCategoryCode
                  key={field.key}
                  name={field.name}
                  item={item}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  sizeKey={field.sizeKey ?? sizeKey}
                />
              );
            }

            case "pdfs": {
              return (
                <FieldPdfs
                  key={field.key}
                  name={field.name}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                />
              );
            }
            case "blockData": {
              return (
                <FieldBlockData
                  caplaEditor={caplaEditor}
                  key={field.key}
                  item={item}
                  name={field.name}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                />
              );
            }
            case "nomenclatureTree": {
              return (
                <FieldNomenclatureTree
                  key={field.key}
                  item={item}
                  name={field.name}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                />
              );
            }
            case "children": {
              return (
                <FieldChildren
                  key={field.key}
                  sizeKey={sizeKey}
                  item={item}
                  name={field.name}
                  value={item[field.key]}
                  onChange={(v) => handleFieldChange(field.key, v)}
                  id={field.id}
                  options={field.options}
                />
              );
            }
            default:
              return <></>;
          }
        })}
      </Box>
    </Box>
  );
}
