export default function stateToRemoteReport(report) {
  const remoteReport = {...report};

  // organisation
  remoteReport.organisation = report.organisationId;
  delete remoteReport.organisationId;

  // scope
  remoteReport.scope = report.scopeId;
  delete remoteReport.scopeId;

  // reportTemplate
  remoteReport.reportTemplate = report.reportTemplateId;
  delete remoteReport.reportTemplateId;

  return remoteReport;
}
