import {useNavigate} from "react-router-dom";

import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";

import {Box, Typography} from "@mui/material";

import {
  CheckCircle as CheckedIcon,
  RadioButtonUnchecked as UncheckedIcon,
} from "@mui/icons-material";

import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useMaterialsByScene from "Features/materials/hooks/useMaterialsByScene";
import useSurveysByScene from "Features/surveys/hooks/useSurveysByScene";
import useSamples from "Features/samples/hooks/useSamples";
import useViewsByScene from "Features/views/hooks/useViewsByScene";
import useReportsByScene from "Features/reports/hooks/useReportsByScene";

import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";
import useSceneModuleColorMap from "Features/navigation/useSceneModuleColorMap";
import useSceneModulePathMap from "Features/navigation/useSceneModulePathMap";

export default function SectionSceneHomeInLeftPanelDiag({scene}) {
  const navigate = useNavigate();
  // data

  const modules = [
    "ROOMS",
    "MATERIALS",
    "SURVEYS",
    "SAMPLES",
    "VIEWS",
    "REPORTS",
  ];

  const nameMap = useSceneModuleNameMap();
  const iconMap = useSceneModuleIconMap();
  const colorMap = useSceneModuleColorMap();
  const pathMap = useSceneModulePathMap({
    sceneId: scene?.id ? scene.id : scene?.clientId,
  });

  // data - entities

  const rooms = useRoomsByScene(scene, {filterByScope: true});
  const materials = useMaterialsByScene(scene, {filterByScope: true});
  const surveys = useSurveysByScene(scene, {filterByScope: true});
  const samples = useSamples({filterByScope: true});
  const views = useViewsByScene(scene, {filterByScope: true});
  const reports = useReportsByScene(scene, {filterByScope: true});

  // helpers - checkedMap

  const checkedMap = {
    ROOMS: rooms.length > 0,
    MATERIALS: materials.length > 0,
    SURVEYS: surveys.length > 0,
    SAMPLES: samples.length > 0,
    VIEWS: views.length > 0,
    REPORTS: reports.length > 0,
  };

  // handlers

  function handleClick(sceneModule) {
    navigate(pathMap[sceneModule]);
  }

  return (
    <Box sx={{px: 2}}>
      {modules.map((sceneModule) => {
        const name = nameMap[sceneModule];
        const icon = iconMap[sceneModule];
        const color = colorMap[sceneModule];
        const path = pathMap[sceneModule];
        const checked = checkedMap[sceneModule];

        //
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 1,
            }}
          >
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Box
                sx={{
                  bgcolor: checked ? color : "grey.400",
                  color: "common.white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  width: 32,
                  height: 32,
                  mr: 2,
                }}
              >
                {icon}
              </Box>
              <Typography
                onClick={() => handleClick(sceneModule)}
                variant="body2"
                sx={{
                  cursor: "pointer",
                  ":hover": {textDecoration: "underline"},
                  ...(!checked && {color: "text.secondary"}),
                }}
              >
                {name}
              </Typography>
            </Box>
            <Box sx={{color: checked ? color : "grey.400"}}>
              {checked ? <CheckedIcon /> : <UncheckedIcon />}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
