import {useDispatch} from "react-redux";

import {setSelectedBlockId} from "../blocksSlice";

import {Box, Paper, Typography, Button} from "@mui/material";
import {forwardRef} from "react";

import BlockDataText from "./BlockDataText";
import BlockDataKeyValuePairs from "./BlockDataKeyValuePairs";
import BlockDataView from "./BlockDataView";

const SectionBlockContainer = forwardRef(({block, selected}, ref) => {
  const dispatch = useDispatch();

  // strings

  const editS = "Modifier";

  // helpers

  const isText = block.blockType === "TEXT";
  const isForm = block.blockType === "FORM";
  const isView = block.blockType === "VIEW";

  // handler

  function handleEditClick() {
    if (selected) {
      dispatch(setSelectedBlockId(null));
    } else {
      dispatch(setSelectedBlockId(block.id));
    }
  }

  return (
    <Box ref={ref} sx={{p: 1}}>
      <Typography
        sx={{
          fontSize: 12,
          color: "text.secondary",
          fontStyle: "italic",
          mb: 0.4,
        }}
      >
        {block.name}
      </Typography>
      <Paper
        elevation={selected ? 6 : 0}
        sx={{
          width: 1,
          bgcolor: "common.white",
          minHeight: "0px",
          cursor: "pointer",
        }}
        onClick={handleEditClick}
      >
        {isText && <BlockDataText text={block?.data?.text} />}
        {isForm && (
          <BlockDataKeyValuePairs keyValuePairs={block?.data?.keyValuePairs} />
        )}
        {isView && <BlockDataView viewData={block?.data?.viewData} />}
      </Paper>
    </Box>
  );
});

export default SectionBlockContainer;
