import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  fetchSamplesService,
  fetchSamplesByScopeService,
  createSampleService,
  fetchSampleService,
  updateSampleService,
  deleteSampleService,
} from "./services";
import {setIsEditing} from "Features/scenes/scenesSlice";

export const createSample = createAsyncThunk(
  "samples/createSample",
  createSampleService
);

export const fetchSamples = createAsyncThunk(
  "samples/fetchSamples",
  fetchSamplesService
);
export const fetchSamplesByScope = createAsyncThunk(
  "samples/fetchSamplesByScope",
  fetchSamplesByScopeService
);

export const fetchSample = createAsyncThunk(
  "samples/fetchSample",
  fetchSampleService
);

export const updateSample = createAsyncThunk(
  "samples/updateSample",
  updateSampleService
);
export const deleteSample = createAsyncThunk(
  "samples/deleteSample",
  deleteSampleService
);

const samplesSlice = createSlice({
  name: "samples",
  initialState: {
    fetchedSamplesBySurveyId: {},
    //
    samplesMap: {},
    selectedSampleId: null,
    editedSample: null,
    isEditingSample: false,
    newSample: {},
    //
  },
  reducers: {
    setSelectedSampleId: (state, action) => {
      state.selectedSampleId = action.payload;
    },
    setIsEditingSample: (state, action) => {
      state.isEditingSample = action.payload;
    },
    setEditedSample: (state, action) => {
      state.editedSample = action.payload;
    },
    setNewSample: (state, action) => {
      state.newSample = action.payload;
    },
  },
  extraReducers: {
    [createSample.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.samplesMap[item.id] = item;
    },
    [fetchSamples.fulfilled]: (state, action) => {
      const {items, surveyId} = action.payload;
      items.forEach((item) => {
        state.samplesMap[item.id] = item;
      });
      //
      state.fetchedSamplesBySurveyId[surveyId] = "done";
    },
    [fetchSamplesByScope.fulfilled]: (state, action) => {
      const {items, scopeId} = action.payload;
      items.forEach((item) => {
        state.samplesMap[item.id] = item;
      });
    },
    [fetchSample.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.samplesMap[item.id] = item;
    },
    [updateSample.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.samplesMap[item.id] = item;
    },
    [deleteSample.fulfilled]: (state, action) => {
      const sampleId = action.payload;
      delete state.samplesMap[sampleId];
    },
  },
});
export const {
  setSelectedSampleId,
  setEditedSample,
  setNewSample,
  setIsEditingSample,
} = samplesSlice.actions;

export default samplesSlice.reducer;
