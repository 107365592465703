import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  createScopeService,
  fetchScopesService,
  updateScopeService,
  deleteScopeService,
} from "./services";

import getStateScopeFromRemoteItem from "./utils/getStateScopeFromRemoteItem";
import getStateScopesFromRemoteItems from "./utils/getStateScopesFromRemoteItems";

export const createScope = createAsyncThunk(
  "scopes/createScope",
  createScopeService
);
export const fetchScopes = createAsyncThunk(
  "scopes/fetchScopes",
  fetchScopesService
);
export const updateScope = createAsyncThunk(
  "scopes/updateScope",
  updateScopeService
);
export const deleteScope = createAsyncThunk(
  "scopes/deleteScope",
  deleteScopeService
);

export const scopesSlice = createSlice({
  name: "scopes",
  initialState: {
    //
    itemsSceneId: null,
    //
    items: [],
    //
    selectedScopeId: null, // if "all", all models
    selectedScopeIdInModule: null,
    //
    lastUpdatedAt: null, // date when selectedScope is updated.
  },
  reducers: {
    setSelectedScopeId: (state, action) => {
      state.selectedScopeId = action.payload;
    },
    setSelectedScopeIdInModule: (state, action) => {
      state.selectedScopeIdInModule = action.payload;
    },
    updateLastUpdatedAt: (state) => {
      state.lastUpdatedAt = Date.now();
    },
  },
  extraReducers: {
    [createScope.fulfilled]: (state, action) => {
      const {item} = action.payload;
      console.log("[state] new elementTypeGroup", item);
      const newScope = {...item};
      newScope.sceneId = item.scene;
      delete newScope.scene;
      state.items.push(newScope);
    },
    [fetchScopes.fulfilled]: (state, action) => {
      const {items, sceneId} = action.payload;
      console.log("[state] fetch scene scopes", items);
      const stateScopes = getStateScopesFromRemoteItems(items);
      state.items = stateScopes;
      state.itemsSceneId = sceneId;
    },
    [updateScope.fulfilled]: (state, action) => {
      const {item} = action.payload;
      console.log("[state] update scene scope", item);
      const scopes = state.items.filter((g) => g.id !== item.id);
      state.items = [...scopes, getStateScopeFromRemoteItem(item)];
    },
    [deleteScope.fulfilled]: (state, action) => {
      const {scopeId} = action.payload;
      const scopes = state.items.filter((g) => g.id !== scopeId);
      state.items = scopes;
      state.selectedScopeId = null;
    },
    "scenes/fetchSharedRemoteScene/fulfilled": (state, action) => {
      const {scopes, scope} = action.payload;
      let newScopes = [];
      if (Array.isArray(scopes)) {
        newScopes = scopes.map((g) => ({...g, sceneId: g.scene}));
      }
      state.items = scopes;
      if (scope) {
        state.items = [scope];
        state.selectedScopeId = scope.id;
      }
    },
    // scopes auto updates
    "rooms/createRoomsGroup/fulfilled": (state, action) => {
      const {item, fromScopeId} = action.payload;
      console.log("[state] update Scope with", item, fromScopeId);
      if (fromScopeId) {
        const newScope = state.items.find((scope) => scope.id === fromScopeId);
        const scopes = state.items.filter((scope) => scope.id !== fromScopeId);
        const oldRoomsGroupsIds = newScope.data.roomsGroupsIds || [];
        newScope.data = {
          ...newScope.data,
          roomsGroupsIds: [...oldRoomsGroupsIds, item.id],
        };
        state.items = [...scopes, newScope];
      }
    },
    "materials/createMaterialsGroup/fulfilled": (state, action) => {
      const {item, fromScopeId} = action.payload;
      console.log("[state] update Scope with", item, fromScopeId);
      if (fromScopeId) {
        const newScope = state.items.find((scope) => scope.id === fromScopeId);
        const scopes = state.items.filter((scope) => scope.id !== fromScopeId);
        const oldMaterialsGroupsIds = newScope.data.materialsGroupsIds || [];
        newScope.data = {
          ...newScope.data,
          materialsGroupsIds: [...oldMaterialsGroupsIds, item.id],
        };
        state.items = [...scopes, newScope];
      }
    },
    "surveys/createSurvey/fulfilled": (state, action) => {
      const {item, fromScopeId} = action.payload;
      console.log("[state] update Scope with", item, fromScopeId);
      if (fromScopeId) {
        const newScope = state.items.find((scope) => scope.id === fromScopeId);
        const scopes = state.items.filter((scope) => scope.id !== fromScopeId);
        const oldSurveysIds = newScope.data?.surveysIds || [];
        newScope.data = {
          ...newScope.data,
          surveysIds: [...oldSurveysIds, item.id],
        };
        state.items = [...scopes, newScope];
      }
    },
    "articles/createArticlesGroup/fulfilled": (state, action) => {
      const {item, fromScopeId} = action.payload;
      console.log("[state] update Scope with", item, fromScopeId);
      if (fromScopeId && fromScopeId !== "all") {
        const newScope = state.items.find((scope) => scope.id === fromScopeId);
        const scopes = state.items.filter((scope) => scope.id !== fromScopeId);
        const oldArticlesGroupsIds = newScope.data?.articlesGroupsIds || [];
        newScope.data = {
          ...newScope.data,
          articlesGroupsIds: [...oldArticlesGroupsIds, item.id],
        };
        state.items = [...scopes, newScope];
      }
    },
    "elementTypes/createElementTypesGroup/fulfilled": (state, action) => {
      const {item, fromScopeId} = action.payload;
      console.log("[state] update Scope with", item, fromScopeId);
      if (fromScopeId) {
        const newScope = state.items.find((scope) => scope.id === fromScopeId);
        const scopes = state.items.filter((scope) => scope.id !== fromScopeId);
        const oldIds = newScope.data?.typesGroupIds || [];
        newScope.data = {
          ...newScope.data,
          typesGroupIds: [...oldIds, item.id],
        };
        state.items = [...scopes, newScope];
      }
    },
    "viewer3D/createModel/fulfilled": (state, action) => {
      const {model, fromScopeId} = action.payload;
      if (fromScopeId && fromScopeId !== "all") {
        const newScope = state.items.find((scope) => scope.id === fromScopeId);
        const scopes = state.items.filter((scope) => scope.id !== fromScopeId);
        if (model.type === "MEASUREMENTS") {
          const oldIds = newScope.data?.packageIds || [];
          newScope.data = {
            ...newScope.data,
            packageIds: [...oldIds, model.id],
          };
        } else if (model.type === "PDF") {
          const oldIds = newScope.data?.inputIds || [];
          newScope.data = {
            ...newScope.data,
            inputIds: [...oldIds, model.id],
          };
        } else if (model.type === "IMAGE") {
          const oldIds = newScope.data?.mapIds || [];
          newScope.data = {
            ...newScope.data,
            mapIds: [...oldIds, model.id],
          };
        }

        state.items = [...scopes, newScope];
      }
    },
  },
});

export const {
  setSelectedScopeId,
  setSelectedScopeIdInModule,
  updateLastUpdatedAt,
} = scopesSlice.actions;

export default scopesSlice.reducer;
