import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import ToolIfcs from "./ToolIfcs";
import ToolMarkers from "./ToolMarkers";
import ToolCameras from "./ToolCameras";

import ToolMeasure from "./ToolMeasure/ToolMeasure";
import ToolClipping from "./ToolClipping";
import ToolPosition from "./ToolPosition";
import ToolModels from "./ToolModels";
import ToolDebug from "./ToolDebug";
import ToolRotation from "./ToolRotation";

import ToolHide from "./ToolHide";
import ToolFilter from "./ToolFilter";

export default function ToolContainer({tool, caplaEditor, onClose, scene}) {
  // data

  const configCenter = useSelector((state) => state.viewer3D.configCenter);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {!configCenter && (
        <Box>
          {tool === "CAMERAS" && (
            <ToolCameras editor3d={caplaEditor.editor3d} />
          )}
          {tool === "IFC" && <ToolIfcs editor3d={caplaEditor.editor3d} />}
          {tool === "MARKERS" && (
            <ToolMarkers editor3d={caplaEditor.editor3d} scene={scene} />
          )}

          {tool === "MEASURE" && (
            <ToolMeasure editor3d={caplaEditor.editor3d} onClose={onClose} />
          )}
          {tool === "CLIPPING" && (
            <ToolClipping
              editor3d={caplaEditor.editor3d}
              onClose={onClose}
              scene={scene}
            />
          )}
          {tool === "POSITION" && (
            <ToolPosition
              caplaEditor={caplaEditor}
              onClose={onClose}
              scene={scene}
            />
          )}
          {tool === "MODELS" && (
            <ToolModels
              editor3d={caplaEditor.editor3d}
              onClose={onClose}
              scene={scene}
            />
          )}

          {tool === "FILTER" && <ToolFilter editor3d={caplaEditor.editor3d} />}
          {tool === "HIDE" && (
            <ToolHide editor3d={caplaEditor.editor3d} onClose={onClose} />
          )}
          {tool === "DEBUG" && <ToolDebug editor3d={caplaEditor.editor3d} />}
          {tool === "ROTATION" && (
            <ToolRotation caplaEditor={caplaEditor} onClose={onClose} />
          )}
        </Box>
      )}
    </Box>
  );
}
