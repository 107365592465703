import {Box} from "@mui/material";

import HeaderInSelectionPanelReportTemplate from "./HeaderInSelectionPanelReportTemplate";
import SectionReportTemplateInSelectionPanel from "./SectionReportTemplateInSelectionPanel";
import ActionsReportTemplateInSelectionPanel from "./ActionsReportTemplateInSelectionPanel";

export default function SectionReportTemplateInOrganisationSelectionPanel({
  organisation,
}) {
  // data

  //
  return (
    <Box sx={{width: 1, height: 1, display: "flex", flexDirection: "column"}}>
      <HeaderInSelectionPanelReportTemplate />
      <Box sx={{flex: 1}}>
        <SectionReportTemplateInSelectionPanel />
      </Box>
      <ActionsReportTemplateInSelectionPanel organisation={organisation} />
    </Box>
  );
}
