import React, {useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";
import useSelectedIssue from "Features/issues/useSelectedIssue";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";

import {Box, Typography, Paper} from "@mui/material";

import usePageType from "Features/navigation/usePageType";
import useSceneModule from "Features/navigation/useSceneModule";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";

import ViewerZone from "Features/zones/components/ViewerZone";

import useIsFullscreen from "../useIsFullscreen";

export default function SectionCallToActionSelectDataItem({
  editor,
  scene,
  viewer,
}) {
  const dispatch = useDispatch();

  // data

  const models = useSelector((s) => s.viewer3D.models);

  const selectedModelId = useSelector((s) => s.viewer3D.selectedModelId);
  let selectedModel = models.find((m) => m.id === selectedModelId);

  const os = useSelector((s) => s.ui.openSections);

  // helper - display

  const display = os.callToAction ? "flex" : "none";

  // effects

  useEffect(() => {
    if (selectedModel?.fromModel?.type === "PDF") {
      dispatch(setSelectedPdfModelId(selectedModel.fromModel.modelId));
    }
  }, [selectedModel?.id]);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        bgcolor: "common.white",
        display,
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Paper sx={{width: 1, height: 1, bgcolor: "red"}} elevation={12}>
        <ViewerZone editor={editor} scene={scene} />
      </Paper>
    </Box>
  );
}
