export default function useKvtRoomsGroup(data) {
  const rooms = data?.rooms || [];

  const template = {
    key: "kvfRoomsGroup",
    fields: [
      //{key: "num", type: "text", name: numS},
      {key: "name", type: "text", name: "Nom"},
      {
        key: "treeStructure",
        type: "section",
        name: "Structure de l'arborescence",
      },
      {key: "rankName1", type: "text", name: "Niveau 1"},
      {key: "rankName2", type: "text", name: "Niveau 2"},
      {key: "rankName3", type: "text", name: "Niveau 3"},
      {key: "tree", type: "section", name: "Arborescence"},
      {
        key: "childrenClientIds",
        type: "children",
        name: "1er niveau",
        options: rooms,
        id: "clientId",
      },
    ],
  };

  return template;
}
