import {useState} from "react";

import {Box, IconButton, Typography, Tooltip} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import MenuGenericActions from "./MenuGenericActions";

export default function SectionGenericActions({
  actionsMain,
  actionsMore,
  onClick,
}) {
  // actions = [{id:1,name,icon,inMore,isHidden,disabled}]

  // HARDCODED !!

  const specificActions = ["refreshQties"];

  // strings

  const moreS = "Plus";

  // localState

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // handlers

  function handleMoreActionClick(actionKey) {
    setAnchorEl(null);
    onClick(actionKey);
  }

  function handleMainActionClick(actionKey) {
    onClick(actionKey);
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 1,
        px: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          "&>*:not(:last-child)": {mr: 2},
        }}
      >
        {actionsMain.map((action) => {
          const key = action.key;
          const icon = action.icon ?? <More fontSize="small" />;
          const name = action.name ?? "Plus";
          const label = action.label ?? "...";
          const disabled = action.disabled;
          //
          const highlight = specificActions.includes(key);
          //
          return (
            <Box
              key={key}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Tooltip title={label}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: highlight ? "primary.flash" : "common.white",
                    ...(highlight && {color: "common.white"}),
                    borderRadius: "50%",
                  }}
                >
                  <IconButton
                    size="small"
                    disabled={disabled}
                    onClick={() => handleMainActionClick(key)}
                    color={highlight ? "inherit" : "action.main"}
                  >
                    {icon}
                  </IconButton>
                </Box>
              </Tooltip>
              {/* <Typography sx={{fontSize: 12, color: "text.secondary"}}>
              {name}
            </Typography> */}
            </Box>
          );
        })}
      </Box>
      {actionsMore?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Tooltip title={moreS}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "common.white",
                borderRadius: "50%",
              }}
            >
              <IconButton
                size="small"
                color="inherit"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <More fontSize="small" />
              </IconButton>
            </Box>
          </Tooltip>
          {/* <Typography sx={{fontSize: 12, color: "text.secondary"}}>
          {moreS}
        </Typography> */}
        </Box>
      )}

      <MenuGenericActions
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        onClick={handleMoreActionClick}
        actions={actionsMore}
      />
    </Box>
  );
}
