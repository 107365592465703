import {useSelector} from "react-redux";

import {List, ListItemButton, Box, Typography, IconButton} from "@mui/material";
import {CenterFocusStrong as Focus} from "@mui/icons-material";

import getItemRankFromNum from "Utils/getItemRankFromNum";

export default function ListRooms({
  rooms,
  onClick,
  selection,
  onSelectedRoomParentIdChange,
  caplaEditor,
  roomsCounters,
}) {
  rooms = rooms.filter(Boolean);

  // data

  const pdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);

  // handlers

  function handleClick(room) {
    onClick(room);
    const wasSelected = selection?.includes(room?.id);
    onSelectedRoomParentIdChange(wasSelected ? null : room.id); // keep the parent as the room => to create new room based on its parent.
  }

  function handleFocusClick(e, room) {
    e.stopPropagation();
    const roomLabel = room.roomLabels?.find(
      (label) =>
        label.pdfModelId === pdfModelId && label.roomClientId === room.clientId
    );
    console.log("focus on room", room, roomLabel);
    if (roomLabel?.id) {
      caplaEditor.editorPdf.roomLabelsManager.focusOnRoomLabelAnnotation(
        roomLabel.id
      );
    }
  }

  return (
    <List sx={{width: 1, bgcolor: "common.white"}} dense disablePadding>
      {rooms.map((room) => {
        const selected = selection?.includes(room?.id);
        const count = roomsCounters?.[room?.id];
        const isLeaf = !count > 0;
        const rank = getItemRankFromNum(room);
        const showFocus = room.roomLabels?.length > 0;

        return (
          <ListItemButton
            disableGutters
            divider
            key={room.id}
            selected={selected}
            onClick={() => handleClick(room)}
            sx={{p: 0, m: 0}}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: 1,
                justifyContent: "space-between",
                px: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{pl: `${(rank - 1) * 16}px`}}>
                  <Typography
                    sx={{
                      fontSize: 12,

                      ...(isLeaf && {fontWeight: "bold"}),
                      ...(rank === 1 && {color: "text.secondary"}),
                      ...(room.noParent && {color: "error.main"}),
                    }}
                  >
                    {room?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    visibility: showFocus ? "visible" : "hidden",
                    color: "grey.400",
                  }}
                >
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={(e) => handleFocusClick(e, room)}
                  >
                    <Focus fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
              {count > 0 && (
                <Typography sx={{fontSize: 12, color: "text.secondary"}}>
                  {count}
                </Typography>
              )}
            </Box>
          </ListItemButton>
        );
      })}
    </List>
  );
}
