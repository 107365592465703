import {Box, Typography} from "@mui/material";
import {ArrowDropDown as Down, Close} from "@mui/icons-material";

import SelectorRoomsGroup from "./SelectorRoomsGroup";
import SelectorGenericItemWithChips from "Components/SelectorGenericItemWithChips";
import getItemsMapById from "Utils/getItemsMapById";

export default function SelectorRoomsGroupContainer({
  variant,
  selectedRoomsGroupId,
  roomsGroups,
  roomsGroupsCounters,
  onSelectedRoomsGroupIdChange,
  isSelectingRoomsGroup,
  onIsSelectingRoomsGroupChange,
}) {
  // strings

  const groupS = "Arborescence des pièces";
  const groupsS = "Arborescences";

  // helpers

  const groupsMap = getItemsMapById(roomsGroups);
  const selectedRoomsGroup = groupsMap[selectedRoomsGroupId];

  // handlers

  function handleGroupClick(group) {
    onSelectedRoomsGroupIdChange(group.id);
  }

  function handleToggleSelectGroup() {
    onIsSelectingRoomsGroupChange(!isSelectingRoomsGroup);
  }

  return (
    <>
      <Box sx={{py: 2}}>
        {variant === "chips" && (
          <SelectorGenericItemWithChips
            items={roomsGroups}
            selectedItem={selectedRoomsGroup}
            onClick={handleGroupClick}
            counters={roomsGroupsCounters}
          />
        )}
        {variant === "list" && (
          <Box>
            <Typography
              sx={{
                fontSize: 13,
                color: "secondary.main",
                p: 0.5,
                px: 1,
                visibility: isSelectingRoomsGroup ? "hidden" : "visible",
              }}
            >
              {groupS}
            </Typography>
            <Box
              sx={{
                p: 1,
                bgcolor: isSelectingRoomsGroup
                  ? "common.white"
                  : "secondary.main",
              }}
            >
              <Box
                onClick={handleToggleSelectGroup}
                sx={{
                  px: 1,
                  py: 0.5,
                  bgcolor: "common.white",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  color: "text.secondary",
                }}
              >
                <Typography sx={{fontSize: 13}}>
                  {!isSelectingRoomsGroup ? selectedRoomsGroup?.name : groupsS}
                </Typography>
                {!isSelectingRoomsGroup ? <Down /> : <Close color="inherit" />}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {isSelectingRoomsGroup && variant === "list" && (
        <Box
          sx={{
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
          }}
        >
          <SelectorRoomsGroup
            items={roomsGroups}
            selectedItem={selectedRoomsGroup}
            onClick={handleGroupClick}
          />
        </Box>
      )}
    </>
  );
}
