import {useDispatch} from "react-redux";
import {deleteBlock} from "../blocksSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useDeleteBlock() {
  //
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const deleteS = async (block) => {
    const blockId = block.id;
    const reportId = block.reportId;
    if ((blockId, reportId, accessToken)) {
      await dispatch(deleteBlock({blockId, reportId, accessToken}));
    }
  };

  return deleteS;
}
