import {useSelector, useDispatch} from "react-redux";

import {setShowBlueprint} from "../zonesSlice";
import {Box, Paper, FormControlLabel, Typography, Switch} from "@mui/material";

export default function ButtonToggleBlueprint() {
  const dispatch = useDispatch();

  // strings

  const label = "Format plan";

  // data

  const show = useSelector((s) => s.zones.showBlueprint);

  // handler

  function handleChange(e) {
    const checked = e.target.checked;
    dispatch(setShowBlueprint(checked));
  }

  return (
    <Paper sx={{bgcolor: "common.white", pl: 2, py: 0.5}}>
      <FormControlLabel
        control={<Switch size="small" checked={show} onChange={handleChange} />}
        label={<Typography sx={{fontSize: 12}}>{label}</Typography>}
      />
    </Paper>
  );
}
