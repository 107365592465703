import {useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {setSnackbarMessage} from "Features/ui/uiSlice";
import {setSelectedRoomId} from "../roomsSlice";

import useRooms from "../hooks/useRooms";
import useCreateRooms from "../hooks/useCreateRooms";
import useSelectedRoomsGroup from "../hooks/useSelectedRoomsGroup";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  Typography,
  TextField,
} from "@mui/material";

import ListItemsDraggable from "Components/ListItemsDraggable";
import useAddRoomLabelToPdf from "Features/pdf/hooks/useAddRoomLabelToPdf";

import roomsFromTemplates from "../data/roomsFromTemplates";
import ContainerRoomsSelectorFromTemplate from "./ContainerRoomsSelectorFromTemplate";

export default function DialogCreateRoomsFromTemplate({
  open,
  onClose,
  scene,
  initName,
  roomLabel,
}) {
  const dispatch = useDispatch();

  // string

  const title = "Ajouter une pièce";

  const createS = "Créer";

  // state

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(initName);
  const [index, setIndex] = useState(0);
  const [names, setNames] = useState([]);

  useEffect(() => {
    setName(initName);
  }, [initName]);

  // data

  const selectedGroup = useSelectedRoomsGroup();
  const selectedRoomParentId = useSelector((s) => s.rooms.selectedRoomParentId);
  const roomsMap = useSelector((s) => s.rooms.roomsMap);
  const roomsByClientId = useRooms({variantByClientId: true});

  // data - function

  const createRooms = useCreateRooms();
  const addRoomLabelToPdf = useAddRoomLabelToPdf();

  // helpers

  const parent = selectedRoomParentId
    ? roomsMap[selectedRoomParentId]
    : selectedGroup;

  const parentChildrenClientIds = parent.childrenClientIds ?? [];

  const parentLabel = `Pièces ajoutées sous: ${parent.name}`;

  // helpers - items

  const items = parentChildrenClientIds.map(
    (clientId) => roomsByClientId[clientId]
  );
  const newItem = {clientId: "new", name, isNew: true};
  if (!index) {
    items.push(newItem);
  } else {
    items.splice(index - 1, 0, newItem);
  }

  // helper

  const groupLabel = selectedGroup?.fromScene
    ? selectedGroup.name
    : selectedGroup.name + " ✨";

  // handlers

  function handleClose() {
    onClose();
  }

  function handleNamesChange(newNames) {
    setNames(newNames);
  }

  async function handleSave() {
    const newRooms = names.map((name) => ({
      clientId: nanoid(),
      name,
      roomsGroupId: selectedGroup.id,
    }));
    if (loading) return;
    setLoading(true);
    const rooms = await createRooms({
      rooms: newRooms,
      parentId: selectedRoomParentId,
    });
    console.log("new rooms created", rooms);
    setLoading(false);

    //
    const message = `${rooms?.length} pièces ajoutées`;
    const triggeredAt = Date.now();
    dispatch(setSnackbarMessage({message, triggeredAt}));

    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>

      <Box sx={{bgcolor: "background.default", p: 0.5}}>
        <Typography sx={{fontSize: 13}}>{parentLabel}</Typography>
      </Box>
      <Box sx={{p: 1, display: "flex", "&>*:not(:last-child)": {mr: 1}}}>
        {roomsFromTemplates.map((template) => {
          return (
            <ContainerRoomsSelectorFromTemplate
              key={template.title}
              template={template}
              selection={names}
              onSelectionChange={handleNamesChange}
            />
          );
        })}
      </Box>
      <DialogActions>
        <Button disabled={loading} onClick={handleSave}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
