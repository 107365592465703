export default function getViewportRectangle(webViewer) {
  const docViewer = webViewer.Core.documentViewer;

  const documentContainer = docViewer.getScrollViewElement();

  const displayMode = docViewer.getDisplayModeManager().getDisplayMode();

  const pageNumber = docViewer.getCurrentPage();
  const pageHeight = docViewer.getPageHeight(pageNumber);
  const pageWidth = docViewer.getPageWidth(pageNumber);
  const rotation = docViewer.getCompleteRotation(pageNumber);

  const topLeft = {
    x: documentContainer.offsetLeft + documentContainer.scrollLeft,
    y: documentContainer.offsetTop + documentContainer.scrollTop,
  };
  const botRight = {
    x:
      documentContainer.offsetLeft +
      documentContainer.scrollLeft +
      documentContainer.offsetWidth,
    y:
      documentContainer.offsetTop +
      documentContainer.scrollTop +
      documentContainer.offsetHeight,
  };

  const topLeftPageCoordinate = displayMode.windowToPage(topLeft, pageNumber);
  const bottomRightPageCoordinate = displayMode.windowToPage(
    botRight,
    pageNumber
  );

  const coords = {
    topLeft: topLeftPageCoordinate,
    bottomRight: bottomRightPageCoordinate,
  };

  let x1 = coords.topLeft.x;
  let y1 = coords.topLeft.y;
  let x2 = coords.bottomRight.x;
  let y2 = coords.bottomRight.y;

  let renderRect = {x1, y1, x2, y2};

  if (rotation === 1) {
    renderRect = {
      x1: pageHeight - renderRect.y2,
      y1: renderRect.x1,
      x2: pageHeight - renderRect.y1,
      y2: renderRect.x2,
    };
  } else if (rotation === 3) {
    renderRect = {
      x2: renderRect.y2,
      y2: pageWidth - renderRect.x1,
      x1: renderRect.y1,
      y1: pageWidth - renderRect.x2,
    };
  }

  return {
    x: x1,
    y: y1,
    width: x2 - x1,
    height: y2 - y1,
  };
}
