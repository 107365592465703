import {useSelector, useDispatch} from "react-redux";

import {setNewBlock, setEditedBlock, setIsEditingBlock} from "../blocksSlice";

import useBlockInSelectionPanel from "../hooks/useBlockInSelectionPanel";

import FormBlock from "./FormBlock";
import useFormTemplatesByScene from "Features/forms/hooks/useFormTemplatesByScene";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";

export default function SectionBlockInSelectionPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();
  // data

  const block = useBlockInSelectionPanel();
  const formTemplates = useFormTemplatesByScene(scene, {fetchData: true});
  const zones = useZonesByScene(scene);

  // helpers

  const isNew = !block?.id;

  // handlers

  function handleChange(changedBlock) {
    if (isNew) {
      dispatch(setNewBlock(changedBlock));
    } else {
      dispatch(setEditedBlock(changedBlock));
      dispatch(setIsEditingBlock(true));
    }
  }

  return (
    <FormBlock
      caplaEditor={caplaEditor}
      block={block}
      onChange={handleChange}
      formTemplates={formTemplates}
      zones={zones}
    />
  );
}
