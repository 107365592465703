import {Box, List, ListItemButton, Typography} from "@mui/material";

import ListItemReport from "./ListItemReport";

import getListItemsReports from "../utils/getListItemsReports";

export default function ListReports({
  reports,
  onClick,
  onLinkClick,
  selection,
}) {
  // helpers

  const noReportsS = "Aucun rapport";

  // helpers - items

  const items = getListItemsReports(reports);

  // helpers - selectedReport

  const selectedReport = reports.find((r) => r.id === selection?.[0]);
  console.log(
    "selectedReport",
    selectedReport?.name + " " + selectedReport?.version
  );

  // handlers

  function handleClick(report) {
    onClick(report);
  }

  function handleLinkClick(report) {
    onLinkClick(report);
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        width: 1,
      }}
    >
      {reports.length === 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
            p: 2,
          }}
        >
          <Typography color="text.secondary">{noReportsS}</Typography>
        </Box>
      )}
      <List dense sx={{width: 1, bgcolor: "common.white"}} disablePadding>
        {items.map((report) => {
          return (
            <ListItemReport
              key={report.id}
              onClick={handleClick}
              onLinkClick={handleLinkClick}
              selectedReport={selectedReport}
              report={report}
            />
          );
        })}
      </List>
    </Box>
  );
}
