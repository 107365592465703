import {client} from "API/capla360";

import remoteToStateSample from "./utils/remoteToStateSample";
import stateToRemoteSample from "./utils/stateToRemoteSample";

export async function fetchSamplesService({accessToken, surveyId}) {
  const response = await client.get(`/surveys/${surveyId}/samples/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteSamples = response.data;
  const items = remoteSamples.map(remoteToStateSample);
  return {items, surveyId};
}

export async function fetchSamplesByScopeService({accessToken, scopeId}) {
  const response = await client.get(`/samples/by-scope/${scopeId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteSamples = response.data;
  const items = remoteSamples.map(remoteToStateSample);
  return {items, scopeId};
}

export async function createSampleService({accessToken, sample}) {
  const surveyId = sample.surveyId;
  const newSample = stateToRemoteSample(sample);
  const response = await client.post(
    `surveys/${surveyId}/samples/`,
    newSample,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteSample = response.data;
  const item = remoteToStateSample(remoteSample);
  return {item};
}

export async function fetchSampleService({accessToken, surveyId, sampleId}) {
  const response = await client.get(
    `/surveys/${surveyId}/samples/${sampleId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteSample = response.data;
  const item = remoteToStateSample(remoteSample);
  return {item};
}

export async function updateSampleService({accessToken, updates}) {
  const {surveyId, id: sampleId} = updates;
  const remoteUpdates = stateToRemoteSample(updates);
  const response = await client.put(
    `/surveys/${surveyId}/samples/${sampleId}/`,
    remoteUpdates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteSample = response.data;
  const item = remoteToStateSample(remoteSample);
  return {item};
}

export const deleteSampleService = async ({
  accessToken,
  sampleId,
  surveyId,
}) => {
  await client.delete(`/surveys/${surveyId}/samples/${sampleId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return sampleId;
};
