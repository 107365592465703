import getItemsMapById from "Utils/getItemsMapById";
import getParentsNums from "Utils/getParentsNums";

export default function getMaterialCategoriesWithMaterialClientIds(
  materialCategories,
  materials,
  options
) {
  // options

  const pullClientIdsToParents = options?.pullClientIdsToParents;

  // edge cases

  if (!materialCategories) return [];
  if (!materials) return materialCategories;

  // main

  const categoriesById = getItemsMapById(materialCategories);

  const categoryNumMaterialClientIdsMap = {};

  function addItemToMap(materialClientId, categoryNum) {
    if (!categoryNumMaterialClientIdsMap[categoryNum]) {
      categoryNumMaterialClientIdsMap[categoryNum] = [];
    }
    categoryNumMaterialClientIdsMap[categoryNum].push(materialClientId);
  }

  materials.forEach((material) => {
    if (
      material.materialCategoryId === "other" ||
      !material.materialCategoryId
    ) {
      addItemToMap(material.clientId, "0");
    } else {
      const category = categoriesById[material.materialCategoryId];
      addItemToMap(material.clientId, category?.num);
      //
      if (pullClientIdsToParents) {
        const categoryParentNums = getParentsNums(category?.num);
        if (Array.isArray(categoryParentNums)) {
          categoryParentNums.forEach((parentNum) => {
            addItemToMap(material.clientId, parentNum);
          });
        }
      }
    }
  });

  const categoriesWithMaterialClientIds = materialCategories?.map(
    (category) => {
      const categoryNum = category?.num ?? "0";
      const materialClientIds =
        categoryNumMaterialClientIdsMap[categoryNum] || [];
      return {...category, materialClientIds};
    }
  );

  // v not necessary, we handle the case "other" directly in the list

  //   if (categoryNumMaterialClientIdsMap["other"]) {
  //     const otherCategory = {
  //       id: "other",
  //       name: "Autre matériau",
  //       materialClientIds: categoryNumMaterialClientIdsMap["other"],
  //     };
  //     categoriesWithMaterialClientIds.push(otherCategory);
  //   }

  return categoriesWithMaterialClientIds;
}
