import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {setSelectedGroup, resetGroupSelectionMap} from "../librarySlice";

import useAddSceneElementTypes from "Features/elementTypes/hooks/useAddSceneElementTypes";
import {useNavigate} from "react-router-dom";
import useSceneModulePathMap from "Features/navigation/useSceneModulePathMap";

import {Box, Button, Chip} from "@mui/material";
import getItemsWithNums from "../utils/getItemsWithNums";

export default function CardSelectedTypes({scene}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // strings

  const addS = "Ajouter à la bimbox";

  // data

  const groupSelectionMap = useSelector((s) => s.library.groupSelectionMap);
  const elementTypes = useSelector((s) => s.library.elementTypes);
  const addTypes = useAddSceneElementTypes(scene);
  const pathMap = useSceneModulePathMap({
    sceneId: scene?.id ? scene.id : scene?.clientId,
  });

  // handlers

  function handleClick() {
    const newTypes = [];
    Object.entries(groupSelectionMap).forEach(([group, ids]) => {
      let types = elementTypes.filter((t) => ids.includes(t.id));
      types = getItemsWithNums(types);
      newTypes.push(...types);
    });
    console.log("addNewTypes", newTypes);
    addTypes(newTypes);
    dispatch(resetGroupSelectionMap());
    navigate(pathMap["ELEMENT_TYPES"]);
  }

  function handleGroupClick(group) {
    dispatch(setSelectedGroup(group));
  }

  return (
    <Box sx={{display: "flex", alignItems: "center", "&>*": {mr: 1}}}>
      {Object.entries(groupSelectionMap).map(([group, ids]) => {
        const label = `${group} x${ids.length}`;
        return (
          <Chip
            key={group}
            label={label}
            size="small"
            onClick={() => handleGroupClick(group)}
            sx={{mr: 1}}
          />
        );
      })}
      <Button size="small" onClick={handleClick}>
        {addS}
      </Button>
    </Box>
  );
}
