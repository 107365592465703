import {List, ListItemButton, Box, Typography, Button} from "@mui/material";
import ListItemNewMaterial from "./ListItemNewMaterial";
import getRoomsCountFromRoomsData from "../utils/getRoomsCountFromRoomsData";

export default function ListMaterials({
  materials,
  onClick,
  selection,
  onNewMaterial,
  onCreateMaterialClick,
}) {
  materials = materials.filter(Boolean);
  console.log("selection", selection);

  // strings

  const noRoomsS = "Pas de pièces";
  const noMaterialsS = "Pas de matériaux";
  const createMaterialS = "Créer un matériau";

  // handlers

  function handleClick(material) {
    onClick(material);
  }

  function handleCreateMaterialClick() {
    if (onCreateMaterialClick) onCreateMaterialClick();
  }

  return (
    <List sx={{width: 1}} dense disablePadding>
      {materials.map((material) => {
        const selected = selection?.includes(material?.clientId);
        const roomsCount = material.rooms?.length;
        const noRooms = !roomsCount;
        const roomsCountLabel = `${roomsCount} pièce${
          roomsCount > 1 ? "s" : ""
        }`;
        return (
          <ListItemButton
            disableGutters
            divider
            key={material.id}
            selected={selected}
            onClick={() => handleClick(material)}
            sx={{bgcolor: "common.white", p: 0}}
          >
            <Box sx={{px: 1, py: 0.5, width: 1}}>
              <Typography sx={{fontSize: 12}}>{material?.name}</Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{fontSize: 12, color: "text.secondary"}}>
                  {material?.code}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 12,
                    color: noRooms ? "error.main" : "text.secondary",
                  }}
                >
                  {noRooms ? noRoomsS : roomsCountLabel}
                </Typography>
              </Box>
            </Box>
          </ListItemButton>
        );
      })}
      {/* {onNewMaterial && (
          <ListItemNewMaterial
            onNewMaterial={onNewMaterial}
            variant="selectionPanel"
          />
        )} */}
      {onCreateMaterialClick && (
        <ListItemButton>{createMaterialS}</ListItemButton>
      )}
    </List>
  );
}
