import {useState} from "react";

import {Paper, Box, CircularProgress, IconButton} from "@mui/material";

import {Delete} from "@mui/icons-material";

import FileSelectorButton from "Features/files/components/FileSelectorButton";
import DialogImageViewer from "./DialogImageViewer";

import {Jimp} from "jimp";
import {blobToArrayBuffer} from "Features/files/utils";
import {isImageFile} from "Features/images/imageUtils";

export default function ContainerImageBase64({
  imageBase64,
  onDelete,
  onChange,
  width,
  height,
}) {
  //strings

  const selectImageString = "Depuis mon ordinateur";
  const dragS = "Glisser & déposer une image";

  // state

  const [loading, setLoading] = useState(false);
  //
  const [hover, setHover] = useState();
  const [open, setOpen] = useState(false);

  // helpers - image state

  const showLoader = !imageBase64 && !loading;
  const showLoading = loading;
  const showImage = imageBase64 && !loading;

  // handlers

  function handleOpenDialog() {
    setOpen(true);
  }
  function handleDragEnter() {
    setHover(true);
  }
  function handleDragLeave() {
    setHover(false);
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("DROP");
    setHover(false);
    try {
      let file = e.dataTransfer.items[0].getAsFile();
      if (isImageFile(file)) {
        handleFileChange(file);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  // handlers - files

  async function handleFileChange(file) {
    setLoading(true);
    const buffer = await blobToArrayBuffer(file);
    let image = await Jimp.fromBuffer(buffer);
    image = image.cover({w: 300, h: 200});
    const base64 = await image.getBase64("image/jpeg");
    setLoading(false);
    //
    onChange(base64);
  }

  function handleDelete() {
    onDelete();
  }

  return (
    <>
      <DialogImageViewer
        open={open}
        onClose={() => setOpen(false)}
        src={imageBase64}
        imageSize={{width, height}}
      />
      <Box
        sx={{
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
          position: "relative",
        }}
      >
        {showImage && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              bgcolor: "white",
              position: "absolute",
              bottom: 4,
              right: 4,
              zIndex: 1,
            }}
          >
            <IconButton size="small" color="primary" onClick={handleDelete}>
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        )}
        {showLoader && (
          <Box
            sx={{
              position: "absolute",
              left: "8px",
              bottom: "8px",
            }}
          ></Box>
        )}

        <Paper
          elevation={12}
          sx={{
            width,
            height,
            display: "flex",
            flexDirection: "column",
            display: "flex",
          }}
        >
          {showLoader && (
            <Box
              sx={{
                bgcolor: "background.default",
                position: "relative",
                width: 1,
                height: 1,
                py: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  height: 1,
                  width: 1,
                  border: (theme) => `2px dashed ${theme.palette.divider}`,
                  borderColor: (theme) =>
                    hover ? theme.palette.primary.flash : theme.palette.divider,
                }}
              />
              <FileSelectorButton
                onFileChange={handleFileChange}
                buttonName={selectImageString}
                variant="icon"
                icon="image"
                accept="image/*"
              />
              {/* <Typography variant="body2" color="text.secondary" sx={{mt: 1}}>
              {dragS}
            </Typography> */}
            </Box>
          )}
          {loading && <CircularProgress />}
          {showImage && (
            <Box
              sx={{
                cursor: "pointer",
                width: 1,
                height: 1,
                display: "flex",
              }}
              onClick={handleOpenDialog}
            >
              <img src={imageBase64} />
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
}
