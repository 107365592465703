import {useDispatch, useSelector} from "react-redux";

import {fetchSamplesByScope} from "../samplesSlice";

import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

import {Add, Delete, Upload, Download} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";

export default function useActionsSamplesMap({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // data

  const scope = useSelectedScope();
  const selectedSampleId = useSelector((s) => s.samples.selectedSampleId);

  // handlers

  function handleFetchSamples() {
    dispatch(fetchSamplesByScope({accessToken, scopeId: scope?.id}));
  }

  // actions map

  const actionsMap = {
    fetchSamples: {
      label: "Charger les échantillons",
      handler: handleFetchSamples,
    },
    deleteSample: {
      icon: <Delete fontSize="small" />,
      name: "Supprimer",
      label: "Supprimer l'échantillon",
      disable: !selectedSampleId,
    },
    exportExcelSamples: {
      icon: <Download fontSize="small" />,
      name: "Exporter",
      label: "Exporter les échantillons",
    },
    importExcelSamples: {
      icon: <Upload fontSize="small" />,
      name: "Importer",
      label: "Importer les échantillons",
    },
  };

  return actionsMap;
}
