import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  setIsEditingMaterial,
  setEditedMaterial,
  setIsCreatingRoomMaterial,
} from "../materialsSlice";

import useSelectedMaterial from "../hooks/useSelectedMaterial";
import useUpdateMaterial from "../hooks/useUpdateMaterial";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";
import getRoomsUpdatesFromMaterialWithRoomsUpdate from "Features/rooms/utils/getRoomsUpdatesFromEditedMaterial";
import {updateRoomsBatch} from "Features/rooms/roomsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function ActionsMaterialInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const saveS = "Save";
  const editS = "Edit";
  const cancelS = "Cancel";

  // state

  const [loading, setLoading] = useState(false);

  // data

  const isEditing = useSelector((s) => s.materials.isEditingMaterial);
  const selectedMaterial = useSelectedMaterial({withRooms: true});
  const editedMaterial = useSelector((s) => s.materials.editedMaterial);

  // helpers - roomsUpdates

  const roomsUpdates = getRoomsUpdatesFromMaterialWithRoomsUpdate(
    editedMaterial,
    selectedMaterial
  );

  const updateMaterial = useUpdateMaterial();

  // handlers

  function handleCancelClick() {
    dispatch(setIsEditingMaterial(false));
  }

  async function handleSaveClick() {
    try {
      // materials
      setLoading(true);
      const updatedMaterial = {...editedMaterial};
      await updateMaterial(updatedMaterial);

      // rooms
      if (roomsUpdates?.length > 0) {
        await dispatch(
          updateRoomsBatch({accessToken, updatesBatch: roomsUpdates})
        );
      }
      //
      dispatch(setIsEditingMaterial(false));
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            disabled={loading}
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
