import {client} from "API/capla360";

import remoteToStateNomenclature from "./utils/remoteToStateNomenclature";
import stateToRemoteNomenclature from "./utils/stateToRemoteNomenclature";

export async function fetchOrganisationNomenclaturesService({
  accessToken,
  organisationId,
}) {
  const response = await client.get(
    `/organisations/${organisationId}/nomenclatures/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteNomenclatures = response.data;
  const items = remoteNomenclatures.map(remoteToStateNomenclature);
  return {items, organisationId};
}

export async function postOrganisationNomenclatureService({
  accessToken,
  organisationId,
  nomenclature,
}) {
  const remoteNomenclature = stateToRemoteNomenclature(nomenclature);
  const response = await client.post(
    `/organisations/${organisationId}/nomenclatures/`,
    remoteNomenclature,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteItem = response.data;
  const item = remoteToStateNomenclature(remoteItem);
  return {item, organisationId};
}

export async function fetchOrganisationNomenclatureService({
  accessToken,
  organisationId,
  nomenclatureId,
}) {
  const response = await client.get(
    `/organisations/${organisationId}/nomenclatures/${nomenclatureId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteNomenclature = response.data;
  const item = remoteToStateNomenclature(item);
  return {item, organisationId};
}

export async function updateOrganisationNomenclatureService({
  accessToken,
  organisationId,
  nomenclatureId,
  updates,
}) {
  const remoteUpdates = stateToRemoteNomenclature(updates);

  const response = await client.patch(
    `/organisations/${organisationId}/nomenclatures/${nomenclatureId}/`,
    remoteUpdates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteNomenclature = response.data;
  const item = remoteToStateNomenclature(remoteNomenclature);
  return {item, organisationId};
}

export async function deleteOrganisationNomenclatureService({
  accessToken,
  organisationId,
  nomenclatureId,
}) {
  const response = await client.delete(
    `/organisations/${organisationId}/nomenclatures/${nomenclatureId}/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {nomenclatureId, organisationId};
}
