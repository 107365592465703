import {useState, useEffect} from "react";

import {Box, Typography, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import SearchBarObjectsV2 from "Features/search/components/SearchBarObjectsV2";

import ListMaterialCategoriesVariantTree from "./ListMaterialCategoriesVariantTree";
import getItemsWithChildrenFromNum from "Utils/getItemsWithChildrenFromNum";
import getMaterialCategoriesWithParentsFromNum from "../utils/getMaterialCategoriesWithParentsFromNum";
import ListMaterialCategories from "./ListMaterialCategories";
import getFoundCategoriesOrMaterials from "../utils/getFoundCategoriesOrMaterials";

export default function SelectorMaterialCategoryFromTree({
  materialsNomenclature,
  onClose,
  selectedMaterialCategoryId,
  onSelect,
}) {
  //

  console.log("[SelectorMaterialCategoryFromTree]", selectedMaterialCategoryId);

  // string

  const title = "Sélectionnez une catégorie";

  // state

  const [searchText, setSearchText] = useState("");
  const [focused, setFocused] = useState(false);

  const [showList, setShowList] = useState(false);

  // effect to avoid to unmount the ListCategories before catching the click event.
  useEffect(() => {
    const show = focused && searchText.length > 0;
    const timer = setTimeout(() => {
      setShowList(show);
    }, 1000);
    return () => clearTimeout(timer);
  }, [focused, searchText]);

  // helpers

  let materialCategories = materialsNomenclature?.tree?.items ?? [];
  materialCategories = getItemsWithChildrenFromNum(materialCategories);
  materialCategories =
    getMaterialCategoriesWithParentsFromNum(materialCategories); // to open tree on the selected item.

  // helpers - foundMaterialCategories

  materialCategories = getFoundCategoriesOrMaterials(materialCategories, {
    searchText,
    withParents: true,
  });

  // helper - selection

  const selection = selectedMaterialCategoryId
    ? [selectedMaterialCategoryId]
    : null;

  //const showList = true;

  // handlers

  function handleClose() {
    onClose();
  }

  function handleClick(materialCategory) {
    console.log("click materialCategory", materialCategory);
    const id = materialCategory?.id ?? null;
    onSelect(id);
    onClose();
  }

  return (
    <Box sx={{width: 1, height: 1, display: "flex", flexDirection: "column"}}>
      <Box
        sx={{
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {title}
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <Close fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{p: 1}}>
        <SearchBarObjectsV2
          searchText={searchText}
          onSearchTextChange={setSearchText}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </Box>

      <Box
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          minHeight: 0,
        }}
      >
        {!showList && (
          <ListMaterialCategoriesVariantTree
            materialCategories={materialCategories}
            selection={selection}
            onClick={handleClick}
          />
        )}
        {showList && (
          <ListMaterialCategories
            materialCategories={materialCategories}
            selection={selection}
            onClick={handleClick}
          />
        )}
      </Box>
    </Box>
  );
}
