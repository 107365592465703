import {useDispatch} from "react-redux";
import {useState} from "react";

import {createReport} from "../reportsSlice";

import useSelectedReport from "../hooks/useSelectedReport";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  TextField,
} from "@mui/material";

import useAccessToken from "Features/auth/useAccessToken";

export default function DialogDuplicateReport({open, onClose, scene}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();
  const sceneId = scene?.id;

  // strings

  const title = "Dupliquer le rapport";

  const createS = "Dupliquer";

  const descriptionLabel = "Description des modifications";

  // data

  const selectedReport = useSelectedReport();

  // state

  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");

  // helpers

  const version = selectedReport?.version;
  const nextVersion = version + 1;

  const versionLabel = `v${version} -> v${nextVersion}`;

  // handlers

  function handleClose() {
    onClose();
  }

  async function handleDuplicate() {
    const report = {
      ...selectedReport,
      version: nextVersion,
      versionDescription: description,
    };
    delete report.id;
    //
    if (loading) return;
    setLoading(true);
    //
    await dispatch(createReport({accessToken, report}));
    setLoading(false);
    handleClose();
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{width: "350px"}}>
          <Box
            sx={{
              display: "flex",
              width: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                bgcolor: "primary.light",
                p: 0.5,
                px: 1,
                mb: 4,
                color: "primary.contrastText",
              }}
            >
              <Typography>{versionLabel} </Typography>
            </Box>

            <Box sx={{width: 1}}>
              <TextField
                fullWidth
                size="small"
                multiline
                label={descriptionLabel}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDuplicate}>{createS}</Button>
      </DialogActions>
    </Dialog>
  );
}
