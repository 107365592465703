import {useSelector, useDispatch} from "react-redux";

import useSceneModule from "Features/navigation/useSceneModule";
import useSceneModuleColorMap from "Features/navigation/useSceneModuleColorMap";
import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";

import {setOpenLeftPanel} from "Features/navigation/navigationSlice";
import {setOpenSections} from "Features/ui/uiSlice";

import {Box, Tooltip, IconButton} from "@mui/material";

import {
  ChevronLeft as Close,
  ChevronRight as Open,
  TableChart as Table,
} from "@mui/icons-material";

export default function SectionLeftPanelHandel({viewer}) {
  //
  const dispatch = useDispatch();

  // strings

  const openS = "Ouvrir le panneau";
  const closeS = "Fermer le panneau";

  // const

  const w = 24;

  // data

  const os = useSelector((s) => s.ui.openSections);
  const openLeftPanel = useSelector((s) => s.navigation.openLeftPanel);

  const sceneModule = useSceneModule();
  const iconsMap = useSceneModuleIconMap();
  const colorMap = useSceneModuleColorMap();

  const bgcolor = colorMap[sceneModule] || colorMap.DEFAULT;

  // helpers

  const icon = iconsMap[sceneModule] || iconsMap.DEFAULT;
  const width = openLeftPanel ? w : 60;
  // handlers

  function handleToggleOpenLeftPanel() {
    const newOpenLeftPanel = !openLeftPanel;
    dispatch(setOpenLeftPanel(newOpenLeftPanel));
    if (newOpenLeftPanel && !viewer) {
      dispatch(
        setOpenSections({
          outlet: false,
          fixedViewersBox: os.fixedViewersBox,
          viewer3D: os.viewer3D,
          callToAction: false,
        })
      );
    }
  }
  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 80,
        ...(openLeftPanel && {width}),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        right: `-${width}px`,
        //top: "50%",
        top: "64px",
        borderRadius: "0 4px 4px 0",
        transform: "translateY(-50%)",
        //bottom: "72px",
        bgcolor,
        color: "common.white",
      }}
    >
      <Tooltip title={openLeftPanel ? closeS : openS} placement="right">
        <IconButton
          size="small"
          color="inherit"
          onClick={handleToggleOpenLeftPanel}
        >
          {openLeftPanel ? (
            <Close color="inherit" />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                "&>*:not(:last-child)": {mr: 0.5},
              }}
            >
              {/* <Table color="inherit" fontSize="small" /> */}
              {icon}
              <Open color="inherit" />
            </Box>
          )}
        </IconButton>
      </Tooltip>
    </Box>
  );
}
