export default function remoteToStateReportTemplate(template) {
  const stateTemplate = {...template};

  // organisation
  stateTemplate.organisationId = template.organisation;
  delete stateTemplate.organisation;

  // mainFormTemplate
  stateTemplate.mainFormTemplateId = template.mainFormTemplate;
  delete stateTemplate.mainFormTemplate;

  return stateTemplate;
}
