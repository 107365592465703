import {useState, useEffect} from "react";

import {useSelector} from "react-redux";

import {Box, IconButton, List, ListItemButton, Typography} from "@mui/material";
import {ArrowForwardIos as Arrow} from "@mui/icons-material";

import ListRoomsVariantTreeParents from "./ListRoomsVariantTreeParents";

import getItemChildrenFromNum from "Utils/getItemChildrenFromNum";
import getRoomsWithNum from "../utils/getRoomsWithNum";
import getItemsMapById from "Utils/getItemsMapById";

export default function ListRoomsVariantTree({
  rooms,
  roomsGroup,
  selection,
  onClick,
}) {
  //
  //rooms = getRoomsWithNum(roomsGroup, rooms); // !! warning, the rooms are computed in the main section.
  const roomsMap = getItemsMapById(rooms);

  // state

  const [parents, setParents] = useState([]);
  const [init, setInit] = useState(false);

  const showedRank = parents.length + 1;
  const lastParent = parents.length > 0 ? parents[parents.length - 1] : null;

  // helper - selection

  const selectedRoomId = selection?.[0] ?? null;
  const selectedRoom = roomsMap[selectedRoomId];

  // init

  const initParents = selectedRoom?.parents ?? [];

  useEffect(() => {
    if (selectedRoomId && !init) {
      setInit(true);
      setParents(initParents);
    }
  }, [selectedRoomId, init]);
  // helpers - items

  let items = getItemChildrenFromNum(lastParent, rooms);

  // handlers

  function handleClick(room) {
    console.log("room", room);
    onClick(room);
  }

  function handleParentClick(parent) {
    const parentIndex = parents.findIndex((p) => p.id === parent.id);
    setParents(parents.slice(0, parentIndex + 1));
  }

  function handleRootClick() {
    setParents([]);
  }

  function handleArrowClick(e, room) {
    e.stopPropagation();
    if (room.childrenClientIds) {
      setParents([...parents, room]);
    }
  }

  return (
    <Box>
      <ListRoomsVariantTreeParents
        parents={parents}
        onParentClick={handleParentClick}
        onRootClick={handleRootClick}
      />
      <List dense disablePadding>
        {items.map((item) => {
          const selected = selection.includes(item.id);
          const showArrow = item.childrenClientIds?.length > 0;
          return (
            <ListItemButton
              key={item.id}
              divider
              disableGutters
              selected={selected}
              onClick={() => handleClick(item)}
              sx={{p: 0}}
            >
              <Box
                sx={{
                  px: 1,
                  //py: 0.5,
                  width: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: 32,
                }}
              >
                <Typography sx={{fontSize: 12}}>{item.name}</Typography>
                {showArrow && (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      handleArrowClick(e, item);
                    }}
                  >
                    <Arrow fontSize="small" />
                  </IconButton>
                )}
              </Box>
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
}
