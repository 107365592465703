import {useState} from "react";

import {
  Box,
  Button,
  List,
  ListItemButton,
  IconButton,
  Typography,
} from "@mui/material";
import {ArrowDropDown as Down, ArrowDropUp as Up} from "@mui/icons-material";

export default function ListItemReport({
  report,
  onClick,
  onLinkClick,
  selectedReport,
}) {
  // helpers

  const count = report?.versions?.length || 0;
  const buttonLabel = `${count} version${count > 1 ? "s" : ""}`;

  const selected = report.clientReference === selectedReport?.clientReference;

  // state

  const [open, setOpen] = useState(false);

  // helper

  const subtitle = `Référence: ${report.clientReference}`;

  // handler

  function toggleExpand(e) {
    e.stopPropagation();
    setOpen(!open);
  }

  function handleClick(clickedReport) {
    onClick(clickedReport);
  }

  function handleLinkClick() {
    onLinkClick(report);
  }

  return (
    <Box sx={{width: 1}}>
      <ListItemButton
        onClick={() => handleClick(report)}
        divider
        sx={{p: 0}}
        selected={selected}
      >
        <Box
          sx={{
            width: 1,
            px: 1,
            py: 0.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "text.secondary",
          }}
        >
          <Box>
            <Typography
              onClick={handleLinkClick}
              sx={{
                fontSize: 13,
                cursor: "pointer",
                ":hover": {textDecoration: "underline"},
              }}
              variant="body2"
              color="text.primary"
            >
              {report.name}
            </Typography>
            <Typography sx={{fontSize: 12, color: "text.secondary"}}>
              {subtitle}
            </Typography>
          </Box>

          {!open && count > 1 && (
            <Button
              size="small"
              onClick={toggleExpand}
              endIcon={<Down />}
              color="inherit"
            >
              {buttonLabel}
            </Button>
          )}
          {open && count > 1 && (
            <IconButton onClick={toggleExpand} size="small">
              <Up />
            </IconButton>
          )}
        </Box>
      </ListItemButton>
      {count > 1 && open && (
        <List
          dense
          sx={{width: 1, bgcolor: "background.default"}}
          disablePadding
        >
          {report.versions.map((version) => {
            const name = `version v${version.version}`;
            const selected = version.id === selectedReport?.id;
            return (
              <ListItemButton
                divider
                key={version.id}
                onClick={() => handleClick(version)}
                selected={selected}
              >
                <Box>
                  <Typography sx={{fontSize: 12, color: "text.secondary"}}>
                    {name}
                  </Typography>
                </Box>
              </ListItemButton>
            );
          })}
        </List>
      )}
    </Box>
  );
}
