import {useState, useEffect} from "react";

import {Paper, Box, Typography, IconButton, Dialog} from "@mui/material";
import {Edit, Download} from "@mui/icons-material";

import ContainerFilesSelector from "Features/files/components/ContainerFilesSelector";
import jsonObjectToFile from "Features/files/utils/jsonObjectToFile";

import {saveBlob} from "Features/files/utils";
import jsonFileToObject from "Features/files/utils/jsonFileToObject";
import KeyValueForm from "./KeyValueForm";

function valueToExampleItem(value) {
  if (!value) return {};
  return value.reduce((acc, field) => {
    acc[field.key] = field.ex;
    return acc;
  }, {});
}

function valueWithExample(value, exampleItem) {
  if (!value) return [];
  return value.map((fieldsValues) => {
    let tempFieldsValues = {...fieldsValues};
    if (exampleItem[fieldsValues.key]) {
      tempFieldsValues.ex = exampleItem[fieldsValues.key];
    }
    return tempFieldsValues;
  });
}

export default function FieldFormTemplate({item, name, value, onChange}) {
  // state

  const [open, setOpen] = useState(false);
  const [exampleItem, setExampleItem] = useState({});

  const initExampleItem = valueToExampleItem(value);
  useEffect(() => {
    setExampleItem(initExampleItem);
  }, [item?.id]);

  // helper

  const fields = value ?? [];

  const kvt = {
    key: "report",
    fields,
  };

  // handler

  function handleEditClick() {
    setOpen(true);
  }

  async function handleFilesChange(files) {
    try {
      const jsonFile = files[0];
      const data = await jsonFileToObject(jsonFile);
      //
      const exampleItem = valueToExampleItem(data);
      //
      onChange(data);
      setExampleItem(exampleItem);
    } catch (e) {
      console.error(e);
    }
  }

  function handleDownloadClick() {
    const valueWithExample = value.map((fieldsValues) => {
      let tempFieldsValues = {...fieldsValues};
      if (exampleItem[fieldsValues.key]) {
        tempFieldsValues.ex = exampleItem[fieldsValues.key];
      }
      return tempFieldsValues;
    });
    const blob = jsonObjectToFile(valueWithExample);
    saveBlob(blob, `${item.name}.json`);
  }

  function handleFormValueChange(newExampleItem) {
    setExampleItem(newExampleItem);
    //
    const newValue = valueWithExample(value, newExampleItem);
    onChange(newValue);
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <ContainerFilesSelector
          onFilesChange={handleFilesChange}
          accept=".json"
        />
      </Dialog>
      <Box
        sx={{
          width: 1,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            width: 1,
            color: "text.secondary",
            //bgcolor: "background.default",
            px: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{fontSize: 13}}>{name}</Typography>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <IconButton size="small" onClick={handleDownloadClick}>
              <Download fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={handleEditClick}>
              <Edit fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{p: 2, width: 1}}>
          <Paper elevation={6}>
            <KeyValueForm
              sizeKey={5}
              template={kvt}
              item={exampleItem}
              onChange={handleFormValueChange}
            />
          </Paper>
        </Box>
      </Box>
    </>
  );
}
