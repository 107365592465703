import {useSelector} from "react-redux";

import useSelectedBlock from "./useSelectedBlock";

export default function useBlockInSelectionPanel() {
  //
  const selectedBlock = useSelectedBlock();
  const editedBlock = useSelector((s) => s.blocks.editedBlock);
  const newBlock = useSelector((s) => s.blocks.newBlock);
  const isEditingBlock = useSelector((s) => s.blocks.isEditingBlock);

  // helper - block

  let block = selectedBlock ?? newBlock;
  if (isEditingBlock) block = editedBlock;

  // return

  return block;
}
